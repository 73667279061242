<template>
  <!-- Loading Spinner -->
  <div v-if="isLoading" class="spinnerContainer">
    <div class="spinner"></div>
  </div>

  <!-- User Content -->

  <div v-else-if="user">
    <div class="profilePage">
      <div class="additionalSections">
        <div class="section">
          <div class="title-header">
            <h2 class="sectionTitle">My Applications</h2>
          </div>

          <div class="cardsContainer">
            <div
              v-for="app in userApps"
              :key="app.id"
              class="card"
              @click="goToGrantApp(app.id)"
            >
              <h3 class="cardTitle">{{ app.name }}</h3>
              <p>
                <strong>{{ app.agency }}</strong>
              </p>
            </div>

            <div class="card newcard" @click="createNewApp()">
              <h3 class="cardTitle">Start New Application</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Error Message -->
  <div v-else class="error-message">
    <p>User is not authenticated or profile could not be loaded.</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MyApps",
  data() {
    return {
      user: null,
      userApps: [],
      isLoading: true, // Initial loading state
    };
  },
  created() {
    this.fetchUser();
    this.getUserApps();
  },
  methods: {
    async fetchUser() {
      try {
        const response = await axios.get("/api/v1/user/");
        this.user = response.data;
        // console.log(this.user);
      } catch (error) {
        console.error("There was an error fetching the user data:", error);
        this.user = null;
      }
    },
    async getUserApps() {
      try {
        const response = await axios.get("api/v1/get-user-apps");
        // console.log(response.data[0]);
        // Assuming the response is an array of objects with the structure {id: 59, title: "File Title"}
        this.userApps = response.data.map((app) => ({
          id: app.id, // Use the actual ID property from your objects
          name: app.name, // Use the actual title property from your objects
          description: app.description,
          agency: app.agency,
        }));
        // console.log(response.data);
        this.isLoading = false; // Set loading to false even if there's an error
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    goToGrantApp(appId) {
      this.$router.push({ name: "GrantApp", params: { id: appId } });
    },
    createNewApp() {
      axios
        .get("api/v1/create-app")
        .then((response) => {
          // console.log("CREATE APP RESPONSE:", response.data);
          if ("doc_id" in response.data) {
            this.$router.push({
              name: "Document",
              params: { id: response.data.doc_id },
            });
          } else if ("id" in response.data) {
            this.$router.push({
              name: "GrantApp",
              params: { id: response.data.id },
            });
          } else {
            console.error("No app or doc id received");
          }
        })
        .catch((error) => {
          console.error("Error creating new app:", error);
          // Handle error appropriately
        });
    },
  },
};
</script>

<style scoped>
.title-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.completion-bar {
  height: 20px; /* Adjust as needed */
  background-color: #eee; /* Light grey background */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures inner bar stays within the container */
}

.completion-bar-inner {
  height: 100%;
  background-color: rgb(2, 104, 172); /* Adjust color as needed */
  width: 0%; /* Initial width */
  border-radius: 10px; /* Rounded corners */
}

.profilePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centers vertically in the container */
  align-items: right;
  /* Centers horizontally in the container */
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: var(--title2);
}

.section {
  background-color: var(--card-bkg);
  padding: 20px;
  margin-bottom: 20px;
  font-family: var(--title-2);
}

.sectionTitle {
  color: var(--text);
  margin-bottom: 10px;
  margin-right: 25px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
  justify-content: flex-start;
}

.card {
  width: 200px;
  height: 240px; /* 1.2:1 ratio */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: solid;
  border-color: var(--accent);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card:hover {
  border-color: var(--accent);
  background-color: var(--accent);
  color: white;
  cursor: pointer;
  /* Ensure the text color is white on hover */
}

.newcard {
  border-radius: 8px;
  border: dashed;
  border-color: var(--accent-semi);
  color: var(--accent-semi);
}

@media (max-width: 768px) {
  .card {
    width: calc(100% / 2 - 20px); /* Two cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%; /* One card per row on very small screens */
  }
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  /* Centers vertically in the container */
  align-items: center;
  /* Full viewport height minus any headers or footers if they exist */
  height: 100vh;
  /* Or if it should be the full height of a parent container, you could use 100% instead */
  /* height: 100%; */
  width: 100%;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 125px;
  height: 125px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
