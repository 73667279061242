import axios from "axios";

export const fetchUser = async () => {
  try {
    const response = await axios.get("api/v1/user/");
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

export const goHome = async (router) => {
  const response = await fetchUser(); // Now you can use await here
  if (response.role === "applicant") {
    router.push({ name: "Portal" });
  } else if (response.role === "search") {
    router.push({ name: "Search" });
  } else if (response.role === "legislation") {
    router.push({ name: "BillSearch" });
  } else {
    router.push({ name: "Dashboard" });
  }
}



export const fetchAppUsers = async (appID, role = null) => {
  try {
    const response = await axios.get("api/v1/app-users/", {
      params: {
        appID: appID,
        role: role
      }
    });
    return response.data; // Assuming you want to return the data from the response
  } catch (error) {
    console.error("fetchAppUsers Error fetchinag application users:", error);
    throw error; // Rethrow or handle error as needed
  }
}

export const fetchOrgUsers = async () => {
  try {
    const response = await axios.get("api/v1/org-users/", {
    });
    return response.data; // Assuming you want to return the data from the response
  } catch (error) {
    console.error("fetchOrgUsers Error fetching application users:", error);
    throw error; // Rethrow or handle error as needed
  }
}

export const inviteAs = async (email, role = null, to_app = null, to_org = null) => {
  try {
    console.log("SENDING", email, role, to_app, to_org);
    const response = await axios.post("api/v1/invite/", {
        email: email,
        role: role,
        to_app: to_app,
        to_org: to_org,
    });
    return response.data;
  } catch (error) {
    console.error("inviteAs Error fetching application users:", error);
    throw error; // Rethrow or handle error as needed
  }
}

export const revokeInvite = async (invite_id) => {
  try {
    const response = await axios.post("api/v1/revoke-invite/", {
        id: invite_id
    });
    return response.data; // Assuming you want to return the data from the response
  } catch (error) {
    console.error("revokeInvite error fetching application users:", error);
    throw error; // Rethrow or handle error as needed
  }
}