<template>
    <div 
    v-show="position.visibility === 'visible'" 
    ref="aiPopupRef" 
    @selectedText="updateHighlightedText" 
    class="ai-popup" 
    :style="{ top: position.top + 'px', left: position.left + 'px' }">
      
      <div v-if="aiResponse" class="ai-response">
        {{ aiResponse }}
        <div class="response-actions">
            <q-btn flat icon="fas fa-exchange-alt" size="sm" @click="updateEditorContent('replace', aiResponse)">
            <span class="button-label">Replace</span>
            <font-awesome-icon :icon="['fas', 'exchange-alt']" />
          </q-btn>
          <q-btn flat icon="fas fa-plus" size="sm" @click="updateEditorContent('add', aiResponse)">
            <span class="button-label">Append</span>
            <font-awesome-icon :icon="['fas', 'plus']" />
          </q-btn>
        </div>
      </div>
     
        <div class="preset-options">
            <q-input v-model="userRequest" 
                    placeholder="Type your request..." 
                    @keydown.enter="sendAIRequest('custom')" 
                    @click.stop = ""
                    outlined dense>
                    <template v-slot:append>
                <font-awesome-icon :icon="loading ? 'spinner' : ['fas', 'arrow-up']" 
                                class="cursor-pointer smaller-button" 
                                :class="{ 'fa-spin': loading }"
                                @click="sendAIRequest('custom')"></font-awesome-icon>
            </template>
            </q-input>
            <q-btn @click="sendAIRequest('improve')" flat no-caps align = "left" class = "full-width" icon-left="compress" label="Improve"></q-btn>
            <q-btn @click="sendAIRequest('shorten')" flat no-caps align = "left" class="full-width " icon-left="sync-alt" label="Shorten"></q-btn>
            <q-btn @click="sendAIRequest('extend')" flat no-caps align = "left" class="full-width " icon-left="expand" label="Expand"></q-btn>

      </div>
    </div>
  </template>
  
  <script setup>
  import { defineProps, defineEmits, onMounted, onUnmounted, ref, watch } from 'vue';
  import axios from 'axios';
  import { QBtn } from 'quasar';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExchangeAlt, faPlus, faCompress, faSyncAlt, faExpand, faArrowUp, faSpinner } from '@fortawesome/free-solid-svg-icons';

library.add(faExchangeAlt, faPlus, faCompress, faSyncAlt, faExpand, faArrowUp, faSpinner);

   
  // Define the props, including one for the selected text
  const props = defineProps({
    editor: Object
  });
  const emits = defineEmits(['closePopup']);
  
  const userRequest = ref('');
  const aiResponse = ref('');

  
  
  const aiPopupRef = ref(null);
  const highlightedText = ref(''); 
  watch(() => props.selectedText, (newText) => {
    highlightedText.value = newText;
  }, { immediate: true });
  
  // Listen for the selectedText event from the parent
  const updateHighlightedText = (text) => {
    highlightedText.value = text;
  };
  // Function to check if the click is outside the popup
  function handleClickOutside(event) {
    if (aiPopupRef.value && !aiPopupRef.value.contains(event.target)) {
      aiResponse.value = '';
      emits('closePopup'); 
    }
  }
  function updateEditorContent(actionType, text) {
      props.editor.chain().focus();
      if (actionType === 'replace') {
        props.editor.chain().deleteRange({ from: selectionStart.value, to: selectionEnd.value }).insertContent(text, { at: selectionStart.value }).run();
      } else if (actionType === 'add') {
        props.editor.chain().setTextSelection(selectionEnd.value).insertContent(text, { at: selectionEnd.value }).run();
   }
}


  
function handleInputKeydown(event) {
  event.stopPropagation();
}
  
  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
    showPopup();
  });
  
  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
  });
  

  const loading = ref(false);

  async function sendAIRequest(action) {
        loading.value = true;
        let endpoint = `/api/v1/ai/${action}/`;
        let payload = {
            text: selectedText.value,
        };

        if (action === 'custom') {
            payload.custom_action = userRequest.value.trim();
        }

        try {
            const response = await axios.post(endpoint, payload, {
            headers: { 'Content-Type': 'application/json' },
            });

            if (response.data && response.data.result) {
            aiResponse.value = response.data.result;
            }
        } catch (error) {
            console.error('AI Processing Error:', error);
        } finally {
            loading.value = false; 
        }
}
  

  const position = ref({ top: 0, left: 0, visibility: 'hidden' });
  const selectedText = ref('');

  const selectionStart = ref(0);
  const selectionEnd = ref(0);

  // Function to show the popup and calculate its position
  function showPopup() {
    const selection = window.getSelection();
    if (selection.rangeCount > 0 && !selection.isCollapsed) {
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      const scrollTop = window.scrollY;
      const scrollLeft = window.scrollX;
      const popupWidth = 300; //This is the width of the popup (should eventually change to directly reference)
      position.value = {
        top: rect.bottom + scrollTop + 5, //added five to move it just a little bit more below the text 
        left: rect.left + scrollLeft - (popupWidth) + (rect.width / 2),
        visibility: 'visible'
      };

      selectionStart.value = props.editor.state.selection.from;
      selectionEnd.value = props.editor.state.selection.to;
      selectedText.value = props.editor.state.doc.textBetween(selectionStart.value, selectionEnd.value, ' ');
    } else {
      console.log("No text selected");
    }
}



  </script>
  
  <style scoped>
  .ai-popup {
    position: absolute;
    z-index: 105;
    background: white;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 300px;
    overflow-y: auto;
    width: 300px; 
  }
  
  .response-actions {
    text-align: right; 
  }
  
  .ai-response {
    font-size: 0.9em;
    margin-bottom: 10px;
    position: relative; 
  }
  
  .full-width {
    width: 100%;
    margin-top: 5px; 
  }

 
.icon-left {
  margin-right: 5px; 
}


.smaller-button {
  background-color: #0096fa;
  border-radius: 10px; 
  padding: 3px; 
  color: white; 
  width: 12px;
  height: 12px;
}

.button-text {
  text-align: left; 
}

.response-actions q-btn {
  padding: 0;
  box-shadow: none !important; 
}

.button-label {
  margin-right: 8px; 
}

.response-actions q-btn {
  padding-left: 8px; 
  padding-right: 8px; 
  box-shadow: none !important;
}

.fa-spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  </style>