<template>
  <div v-if="answers">
    <div class="column justify-start items-start" style="width: 100%">
      <div class="back-text col" @click="backToApp()">Back to Application</div>
      <h4 class="col">{{ section_name }}</h4>
      <div class="col text-body1">{{ section_description }}</div>
    </div>

    <div class="q-pa-md">
      <q-separator
        style="width: 100%; height: 1px; margin: 10px 0"
      ></q-separator>
      <div
        v-for="(question, rowIndex) in questions"
        :key="`row-${rowIndex}`"
        class="row q-col-gutter-md flex items-center"
      >
        <div v-if="rowIndex === 0" class="col q-ma-md flex-col">
          <!-- Placeholder for column labels -->
        </div>
        <div
          v-else
          class="col q-ma-md flex-col"
          v-html="renderMarkdownWrapper(question.question_text)"
        ></div>

        <div
          v-for="(input, colIndex) in question.inputs"
          :key="`cell-${rowIndex}-${colIndex}`"
          class="col q-ma-md flex-col"
        >
          <template v-if="rowIndex === 0">
            <span class="text-weight-medium">{{ input.label_text }}</span>
          </template>
          <template v-else>
            <q-input
              v-model="answers[input.name]"
              outlined
              :debounce="500"
              :suffix="setSuffix(input.units)"
              :readonly="!input.editable"
              style="max-width: 200px"
              v-bind="input.config"
              @update:model-value="handleNewAnswer($event, question, input)"
            />
          </template>
        </div>
      </div>
      <div v-if="final_section" class="flex flex-center">
        <q-btn
          :loading="btn_saving"
          flat
          color="primary"
          class="q-ma-sm bg-primary text-white"
          size="lg"
          @click="backToApp()"
          >Save Form</q-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsonLogic from "json-logic-js";
import { renderMarkdown } from "@/helpers/helpers";

export default {
  name: "DynamicTable",
  props: {
    questions: {
      type: Array,
      required: true,
    },
    formAnswers: {
      type: Object,
      required: false,
      default: () => ({}), // Default to an empty object
    },
    draft_doc_id: {
      type: Number,
      required: false,
    },
    app_id: {
      type: Number,
      required: false,
    },
    section_name: {
      type: String,
      required: true,
    },
    section_description: {
      type: String,
      required: false,
      default: "",
    },
    final_section: {
      type: Boolean,
      required: false,
      default: false,
    },
    doc_name: {
      type: String,
      required: true,
    },
    docs_uploaded: {
      type: Boolean,
      required: true,
      default: false,
    },
    section_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      serverAnswers: {},
      answered: {},
      showTooltip: false,
      lastSaveTime: 0,
      btn_saving: false,
      answers: {
        type: Object,
        required: false,
        default: null,
      },
      test: { a: "b" },
    };
  },

  created() {
    this.answers = JSON.parse(JSON.stringify(this.formAnswers));
    this.serverAnswers = JSON.parse(JSON.stringify(this.formAnswers));
    this.questions.forEach((question) => {
      this.answered[question.id] = false; // All question unanswered to begin with
    });
    this.setCalcDependencies();
    this.updateQuestions();
  },
  watch: {
    // Watch for changes in the questions prop
    questions() {
      // Code to execute when questions changes
      this.setCalcDependencies();
      this.updateQuestions();
      this.updateCalcs();
    },
  },
  methods: {
    renderMarkdownWrapper(text) {
      return renderMarkdown(text);
    },
    handleNewAnswer(newVal, question, input) {
      if (input.config.prefix && input.config.prefix === "$") {
        this.formAnswers[input.name] = Number(newVal.replace(/,/g, "")).toFixed(
          2
        );
      } else {
        this.formAnswers[input.name] = newVal;
      }
      this.performUpdate(question);
      this.$emit("contentUpdate", this.questions);
      this.performSave();
    },

    async performSave(check_complete = false) {
      let toSave = new FormData();
      // console.log("FORM ANSWERS Dynamic Form", this.formAnswers);
      for (const id in this.formAnswers) {
        // formData should be the difference between what is on the server on the up-to-date local
        if (
          (!this.serverAnswers ||
            !(id in this.serverAnswers) ||
            this.formAnswers[id] !== this.serverAnswers[id]) &&
          !Number.isNaN(this.formAnswers[id])
        ) {
          toSave.append(id, this.formAnswers[id]);
        }
      }
      toSave.append("draft_doc_id", this.draft_doc_id);
      // If check_complete is true, then we are checking if the section is complete
      if (check_complete) {
        toSave.append("check_complete", true);
      } else {
        toSave.append("check_complete", false);
      }
      // console.log("SAVING");
      try {
        const url = "api/v1/save-section/";
        const headers = {
          ...axios.defaults.headers.common,
          "Content-Type": "multipart/form-data",
        };
        this.serverAnswers = JSON.parse(JSON.stringify(this.formAnswers)); //Update server answers with formAnswers
        await axios
          .post(url, toSave, { headers })
          .then((response) => {
            this.lastSaveTime = Date.now();

            // // console.log("Section saved successfully:", response.data);
          })
          .catch((error) => {
            console.error(
              "Error saving section:",
              error.response ? error.response.data : error.message
            );
          });
      } catch (error) {
        console.error(
          `Error fetching section questions ${this.draft_doc_id}:`,
          error
        );
      }
    },
    performUpdate(question = null) {
      if (question !== null) {
        this.answered[question.id] = true;
      }
      this.updateCalcs();
      this.updateQuestions();
    },
    updateQuestions() {
      this.questions.forEach((question) => {
        if ("display" in question) {
          let evaluated = jsonLogic.apply(question.display, this.formAnswers);
          question.display_calculated = evaluated;
        }
      });
    },
    setCalcDependencies() {
      this.questions.forEach((question) => {
        question.inputs.forEach((input) => {
          if ("calculation" in input) {
            input.calc_vars = this.extractVariableNames(input.calculation);
          }
        });
      });
    },
    findQuestionByInput(inputName) {
      for (const question of this.questions) {
        if (question.inputs.some((input) => input.name === inputName)) {
          return question;
        }
      }
      return null; // Return null if no question is found
    },
    extractVariableNames(jsonLogic) {
      let variables = new Set(); // Using a Set to avoid duplicates

      function recurse(obj) {
        if (Array.isArray(obj)) {
          // If the current object is an array, iterate over its elements
          obj.forEach(recurse);
        } else if (obj !== null && typeof obj === "object") {
          // If the current object is a non-null object, iterate over its properties
          for (let key in obj) {
            if (key === "var") {
              // If the current property is 'var', add its value to the set
              variables.add(obj[key]);
            } else {
              // Recursively apply the function to the value of the current property
              recurse(obj[key]);
            }
          }
        }
      }
      recurse(jsonLogic);
      return Array.from(variables); // Convert the set of variables to an array
    },
    async backToApp(check_complete = false) {
      this.btn_saving = true;
      try {
        // Wait for saveSection to complete
        await this.performSave(check_complete);
        this.btn_saving = false;
        this.$router.go(-1);
      } catch (error) {
        this.btn_saving = false;
        console.error("Error saving section:", error);
      }
    },
    updateCalcs(repeat = 5) {
      const customActionCalled = new Set();
      let customActionExecuted = false;
      for (let i = 0; i < repeat; i++) {
        this.questions.forEach((question) => {
          question.inputs.forEach((input) => {
            if (
              "calculation" in input &&
              input.calculation &&
              "calc_vars" in input &&
              input.calc_vars
            ) {
              // Don't update the calc input if there is a non-null answer for it already and it's editable
              if (
                input.name in this.formAnswers &&
                this.formAnswers[input.name] &&
                this.formAnswers[input.name] !== "" &&
                this.formAnswers[input.name] !== "null" &&
                input.editable
              ) {
                return;
              }
              var unansweredDependencies = false;
              input.calc_vars.forEach((var_name) => {
                var dependQuestion = this.findQuestionByInput(var_name);
                // If one of the dependent inputs is in a question that has not been answered
                if (!(var_name in this.formAnswers)) {
                  if (
                    dependQuestion !== null &&
                    dependQuestion.display_calculated
                  ) {
                    unansweredDependencies = true;
                  }
                }
              });
              if (unansweredDependencies) {
                // TODO eventually switch this to be the default val for the input
                if (input.input_type == "number") {
                  this.formAnswers[input.name] = NaN;
                } else if (
                  input.input_type == "checkbox" ||
                  input.input_type == "radio"
                ) {
                  this.formAnswers[input.name] = false;
                } else {
                  this.formAnswers[input.name] = null;
                }
                // TODO handle the case where the question has been answered but some of it's inputs are still not answered
              } else {
                if (
                  input.calculation.hasOwnProperty("custom_action") &&
                  input.calculation.custom_action
                ) {
                  // Check if this calc is a custom action
                  if (i < 1) {
                    //Only make the call once
                    this.custom_action_call(
                      input.calculation.custom_action.function_name,
                      input.calculation.custom_action.inputs
                    );
                    this.answered[question.id] = true;
                    customActionExecuted = true;
                  }
                } else if (input.calculation.hasOwnProperty("if")) {
                  // Check for nested custom_action within 'if'
                  const branches = [
                    input.calculation.if[1],
                    input.calculation.if[2],
                  ]; // True and false branches
                  branches.forEach((branch) => {
                    if (
                      branch &&
                      branch.hasOwnProperty("custom_action") &&
                      !customActionCalled.has(input.name) &&
                      i < 1
                    ) {
                      this.custom_action_call(
                        branch.custom_action.function_name,
                        branch.custom_action.inputs
                      );
                      this.answered[question.id] = true;
                      customActionCalled.add(input.name);
                      customActionExecuted = true;
                    }
                  });
                }
                if (!customActionExecuted) {
                  // if (input.name === "equipment_allow") {
                  //   console.log("EVALUATING", input.name, input.calculation);
                  //   console.log(
                  //     "EQUIPMENT TOTAL",
                  //     this.formAnswers["equipment_total"]
                  //   );
                  //   console.log(
                  //     "EQUIPMENT inel",
                  //     this.formAnswers["equipment_inel"]
                  //   );
                  // }

                  let evaluated = jsonLogic.apply(
                    input.calculation,
                    this.formAnswers
                  );

                  if (input.name === "equipment_allow") {
                    console.log("EVALUAED", evaluated);
                  }

                  if (
                    !isNaN(parseFloat(evaluated)) &&
                    isFinite(parseFloat(evaluated))
                  ) {
                    // hacky way to ensure dollars have cents applied
                    if (input.config.prefix && input.config.prefix === "$") {
                      // Ensure it's treated as a number with toFixed(2) for formatting
                      this.formAnswers[input.name] =
                        Number(evaluated).toFixed(2);
                      this.answers[input.name] = Number(evaluated).toFixed(2);
                      this.answered[question.id] = true; // Consider this question now answered;
                    } else {
                      // If it's not prefixed with "$", keep it as a number but don't format
                      this.formAnswers[input.name] = Number(evaluated);
                      this.answers[input.name] = Number(evaluated);
                      this.answered[question.id] = true; // Consider this question now answered;
                    }
                  } else {
                    // If evaluated is not a number or is infinite, set it as is
                    this.formAnswers[input.name] = evaluated;
                    this.answered[question.id] = true; // Consider this question now answered;
                  }
                }
              }
            }
          });
        });
      }
    },
    setPrefix(unit) {
      if (unit === "$") {
        return "$";
      } else {
        return "";
      }
    },
    setSuffix(unit) {
      if (unit !== "$" && unit !== "") {
        return unit;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.back-text {
  font-family: var(--title-2);
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(2, 104, 172);
  margin-bottom: 0.5rem;
  text-align: left;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.doc-title-text {
  font-family: var(--title-2);
  font-size: 1rem;
  color: rgb(116, 112, 112);
  margin-bottom: 0.5rem;
  text-align: left;
}

.grant-title-text {
  font-family: var(--title-2);
  font-size: 1rem;
  font-weight: 500;
  color: rgb(2, 104, 172);
  margin-bottom: 0.5rem;
  text-align: left;
}

.dynamic-form {
  max-height: 100%;
  padding: 16px;
  /* Make sure padding is included in the height */
  box-sizing: border-box;
}

.dynamic-form label {
  display: block;
}

.dynamic-form .question {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 800px;
}

.dynamic-form .form-group {
  margin-bottom: 10px;
}

.buttonText {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 30px;
  font-weight: 500;
}

.button {
  /* border: none; Remove default border */
  border-width: 2px;
  border-color: var(--accent);
  outline: none;
  /* Remove focus outline */
  margin: auto;
  border-radius: var(--br-3xs);
  background-color: var(--accent);
  /* width: 200px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  font-size: var(--title-2-size);
  cursor: pointer;
  /* Change cursor to pointer to indicate it's clickable */
  color: var(--bkg);
  font-family: var(--title-2);
}

.button:hover {
  background-color: var(--hover-accent);
  /* Change to a slightly different color on hover */
  color: var(--accent);
  /* Optional: Change text color on hover */
  /* You can add other effects like a border or shadow if desired */
}

.icon {
  position: relative;
  color: rgb(1, 150, 250);
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  /*box-shadow: 0.3s ease; */
}

.icon:hover {
  transform: scale(1.5);
  transition: transform 0.3s ease;
  /* Make the icon 10% larger on hover */
  color: rgb(1, 150, 250);
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); Add a subtle shadow for a "lifted" effect */
  cursor: pointer;
  /* Change cursor to indicate it's clickable */
}

.icon:active {
  color: var(--accent);
}

.disabled-icon {
  position: relative;
  color: gray;
  width: 24px;
  height: 24px;
}

.disabled-icon:hover {
  cursor: not-allowed;
}

/* .disabled-icon:active {
  color: var(--accent);
} */

.header-container {
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* Adjust as needed */
}

.text-container {
  float: left;
  /* Flexbox makes float unnecessary, but included for legacy reasons if needed */
}

.document-name {
  font-size: 1em;
  color: #666;
}

.tooltip {
  position: absolute;
  bottom: 130%;
  /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}

.button-container {
  float: right;
  position: relative;
  /* Needed to position the tooltip correctly */
}

.magic-wand-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Font Awesome magic wand icon */
.fa-magic {
  font-size: 24px;
  /* Size of the magic wand icon */
}

/* Clearfix if using floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.separator {
  /* Style your separator */
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}
</style>
