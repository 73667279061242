<template>
  <div class="body">
    <div v-if="!loading">
      <!-- <div class="header">
        <h4 class="text-weight-medium">Overview</h4>
      </div> -->
      <!-- <q-separator color="grey-7" /> -->
      <section>
        <h5 class="text-weight-bold">Projected Scoring</h5>
        <text-body1 class="text-blue-grey-9 text-weight-medium">
          Based on the answers you've provided, you can see the range in which
          you would likely to score if you submit a REAP grant application.
          Please note that all projected scores are estimates and are by no
          means reliable predictors of receiving a REAP grant.
        </text-body1>
        <div class="score-card-container">
          <score-card
            :eligible="true"
            :flags="eligibilityFlags"
            :answers="formAnswers"
          />
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Project Proposal</h5>
        <text-body1 class="text-blue-grey-9 text-weight-medium">
          Project proposal gathered from your initial questionnaire. Click to
          update it.
        </text-body1>
        <div
          class="proposal-text"
          @click="editProposal"
          v-html="proposal"
        ></div>
        <!-- Add class and quotes -->
      </section>

      <section>
        <h5 class="text-weight-bold">Context Documents</h5>
        <text-body1 class="text-blue-grey-9 text-weight-medium">
          Upload any additional documents that you think may provide helpful for
          your application. We'll use these documents to help draft your
          application.
        </text-body1>
        <div v-if="attachments" class="cardsContainer">
          <div
            v-for="file in attachments"
            :key="file.id"
            class="card card-complete"
          >
            <text-subtitle2 class="text-weight-medium" style="margin-top: 0">
              {{ file.name }}
            </text-subtitle2>
            <div class="card-footer">
              <div class="completion-bar-inner"></div>
              <font-awesome-icon
                icon="fas fa-trash"
                class="icon"
                @click.stop="deleteAttachment(file.id)"
              />
            </div>
          </div>
          <label class="upload-label">
            <input
              type="file"
              @change="onFileSelected"
              class="upload-input"
              accept=".pdf, .docx"
            />
            <div class="card card-upload">
              <text-body2 v-if="!uploading" style="margin-top: 0">
                Upload New Document
              </text-body2>
              <div v-else class="card-spinner-container">
                <div class="card-spinner"></div>
              </div>
            </div>
          </label>
        </div>
      </section>
    </div>
    <div v-else class="flex flex-center" style="height: 100%">
      <q-spinner color="primary" :size="80"></q-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Application",
  data() {
    return {
      proposal: null,
      user: null,
      loading: false,
      formAnswers: {},
      eligibilityFlags: [],
      iq_id: null,
      attachments: null,
      uploading: false,
      appID: null,
    };
  },
  props: {
    inputAppId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    statusClass() {
      return (status) => {
        switch (status) {
          case "complete":
            return "card-complete";
          case "partial":
            return "card-incomplete";
          case "incomplete":
          default:
            return "card-incomplete";
        }
      };
    },
  },
  mounted: async function () {
    this.loading = true; // Start loading
    this.appID = this.inputAppId;
    try {
      // Assuming fetchDocuments and fetchAttachments are async functions or return a Promise
      await this.fetchEligibilityAnswers();
      await this.fetchProposal();
      await this.fetchUserTier();
      await this.fetchAttachments();
    } catch (error) {
      console.error("Error in mounted hook:", error);
      // Handle any errors that occur during fetch
    } finally {
      this.loading = false; // Stop loading after both functions have completed
    }
  },
  methods: {
    async fetchAttachments() {
      try {
        const response = await axios.get("api/v1/get-user-docs/", {
          params: {
            doc_class: "CONTEXT", // Pass the docClass value as a query parameter
          },
        });
        // Assuming the response is an array of objects with the structure {id: 59, title: "File Title"}
        this.attachments = response.data.map((doc) => ({
          id: doc.id, // Use the actual ID property from your objects
          name: doc.title, // Use the actual title property from your objects
        }));
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    onFileSelected(event) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        this.uploadAttachment(selectedFile);
      }
      this.fetchAttachments();
    },
    async uploadAttachment(file) {
      this.uploading = true;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("doc_class", "CONTEXT");

      await axios
        .post("/api/v1/upload_doc/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // Calculate and update the upload progress
            this.uploadProgress = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            console.log(`Upload Progress: ${this.uploadProgress}%`);
          },
        })
        .then((response) => {
          // console.log("File uploaded successfully:", response.data);
          // Handle the successful response here
          // You might want to update your component's state to reflect the successful upload
        })
        .catch((error) => {
          console.error(
            "Error uploading file:",
            error.response ? error.response.data : error.message
          );
          // Handle errors here
        });
      await this.fetchAttachments();
      this.uploading = false;
    },
    async deleteAttachment(fileId) {
      this.loading = true;
      const response = await axios.get(`api/v1/delete-user-doc/`, {
        params: {
          id: fileId,
        },
      });
      await this.fetchAttachments();
      this.loading = false;
    },
    async fetchEligibilityAnswers() {
      try {
        const user_response = await axios.get("api/v1/user");
        let params = {
          doc_name: "REAP Eligibility and Competitiveness Questionnaire",
        };
        // Add app_id if we have it (e.g. coordinator)
        if (this.appID !== null) {
          params["app_id"] = this.appID;
        }
        const doc_response = await axios.get("api/v1/get-doc-answers/", {
          params: params,
        });
        this.formAnswers = doc_response.data;
        this.user = user_response.data;
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    async fetchUserTier() {
      try {
        const response = await axios.get(`api/v1/get-user-tier/`);
        this.iq_id = response.data.iq_id;
      } catch (error) {
        console.error("Error during tier verification:", error);
        // Handle the error appropriately
      }
    },
    async fetchProposal() {
      const doc_response = await axios.get("api/v1/get-doc-answers/", {
        params: {
          doc_name: "Initial Questionnaire",
        },
      });
      // console.log(doc_response.data);
      this.proposal = doc_response.data["project_proposal_description"];
    },
    async editProposal() {
      this.$router.push({
        name: "Document",
        params: { id: this.iq_id },
        query: { sectionId: "1" }, // sectionId as a query parameter
      });
    },
    async fetchAttachments() {
      try {
        const response = await axios.get("api/v1/get-user-docs/", {
          params: {
            doc_class: "CONTEXT", // Pass the docClass value as a query parameter
          },
        });
        // Assuming the response is an array of objects with the structure {id: 59, title: "File Title"}
        this.attachments = response.data.map((doc) => ({
          id: doc.id, // Use the actual ID property from your objects
          name: doc.title, // Use the actual title property from your objects
        }));
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    async fetchDocuments() {
      try {
        const doc_response = await axios.get("api/v1/get-app");
        this.documents = doc_response.data.documents.map((doc) => ({
          id: doc.doc_id, // Use the actual ID property from your objects
          name: doc.display_name, // Use the actual title property from your objects
          percent_complete: doc.percent_complete,
          status:
            doc.percent_complete === 1
              ? "complete"
              : doc.percent_complete > 0
              ? "partial"
              : "incomplete",
        }));
        this.uploads = doc_response.data.upload_docs.map((doc) => ({
          id: doc.doc_id, // Use the actual ID property from your objects
          name: doc.display_name, // Use the actual title property from your objects
          percent_complete: doc.percent_complete,
          status:
            doc.percent_complete === 1
              ? "complete"
              : doc.percent_complete > 0
              ? "partial"
              : "incomplete",
        }));
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    goToGrantApp(appId) {
      this.$router.push({ name: "GrantApp", params: { id: appId } });
    },
    downloadDocument(id) {
      this.isLoading = true; // Show the spinner

      try {
        const url = `api/v1/get-pdf/?doc_draft_id=${id}`;

        axios
          .get(url, {
            responseType: "blob", // Important for handling the PDF file
          })
          .then((response) => {
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a Blob URL
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Create a temporary link element
            const downloadLink = document.createElement("a");
            downloadLink.href = pdfUrl;
            downloadLink.setAttribute("download", "document.pdf"); // Assign a file name for the download

            // Append to the document and trigger a click
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Remove the link after triggering download
            document.body.removeChild(downloadLink);

            this.loading = false; // Hide the spinner after processing
          })
          .catch((error) => {
            this.isLoading = false; // Hide the spinner in case of an error
            console.error(
              "Error fetching PDF:",
              error.response ? error.response.data : error.message
            );
          });
      } catch (error) {
        this.isLoading = false;
        console.error("Error in form submission or PDF generation:", error);
      }
    },
  },
};
</script>

<style scoped>
section {
  margin-top: 30px;
}
.body {
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
  max-width: 1300px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items vertically */
  width: 100%;
}

.score-card-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* Add other styles as needed, e.g., full width or specific width */
}

.checklist ul {
  list-style-type: none;
  padding: 0;
}

.checklist li {
  margin-bottom: 10px;
}

.item {
  /* Styles for your list item */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.trashIcon {
  margin-right: 10px; /* Space between the icon and text */
  color: var(--accent); /* Icon color */
  /* Other styles as needed */
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
  margin-top: 20px;
  justify-content: flex-start;
}

.card {
  width: 150px;
  height: 195px; /* 1.3:1 ratio like letter paper (1.62 for golden ratio) */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separates progress bar and button */
  padding: 0px;
}

.card:hover {
  border-color: var(--accent);
  background-color: var(--accent);
  color: white;
  cursor: pointer;
  /* Ensure the text color is white on hover */
  .delete-button {
    color: white; /* Set the color of the delete button to white */
  }
}

.card-upload {
  color: var(--accent-semi);
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card-complete {
  color: var(--accent);
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-partial {
  border-radius: 8px;
  border: dashed;
  border-color: var(--accent);
  color: var(--accent);
}

.card-incomplete {
  border-radius: 8px;
  border-color: var(--accent-semi);
  /* color: var(--accent-semi); */
  color: var(--accent);
}
.upload-input {
  display: none;
}

.upload-label {
  display: flex;
  cursor: pointer;
  flex-shrink: 0;
}

.delete-button {
  color: var(--accent);
  border: 2px;
  border-radius: 2px;
  border: solid;
  background: none;
  border-color: var(--accent-semi);
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
}
.completion-bar {
  width: 70%; /* Adjust as needed */
  height: 20px; /* Height of the progress bar */
  background-color: #ddd; /* Background of the progress bar */
  border-radius: 10px; /* Optional: for rounded corners */
  overflow: hidden; /* Ensures the inner bar stays within the radius */
}

.completion-bar-inner {
  height: 100%;
  background-color: rgb(1, 150, 250); /* Color of the filled area */
  border-radius: 10px; /* Match the radius of the outer bar */
  /* Width is set dynamically in your Vue template */
}
.divider {
  border: none; /* Remove the default border */
  height: 1px; /* Set the height of the divider */
  background-color: rgba(0, 0, 0, 0.7); /* Light grey color */
  margin: 10px 0; /* Add some vertical spacing */
}

.proposal-text {
  /* background-color: rgba(0, 0, 0, 0.02); */
  border-radius: 8px;
  padding: 20px;

  cursor: pointer;
  transition: background-color 0.1s;
}
.proposal-text:hover {
  /* background-color: rgba(0, 0, 0, 0.03); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Full viewport height minus any headers or footers if they exist */
  height: 100vh;
  /* Or if it should be the full height of a parent container, you could use 100% instead */
  /* height: 100%; */
  width: 100%;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 125px;
  height: 125px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 100%;
}

.card-spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
}

.card {
  width: 150px;
  height: 195px; /* 1.3:1 ratio like letter paper (1.62 for golden ratio) */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  transition: background-color 0.1s;
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  transition: background-color 0.1s;
  overflow: hidden; /* Ensures that the folded corner does not spill out of the card's box */
}
.card::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Width of the folded corner */
  height: 10px; /* Height of the folded corner */
  background-color: rgba(0, 0, 0, 0.2); /* Same as the card's background */
  transform: translate(4px, -4px) rotate(45deg); /* Adjust the position and angle */
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.3); /* Optional: add shadow to the folded corner */
}

.card:hover {
  border-color: var(--accent);
  background-color: var(--accent);
  color: white;
  cursor: pointer;
  /* Ensure the text color is white on hover */
  .delete-button {
    color: white; /* Set the color of the delete button to white */
  }
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separates progress bar and button */
  padding: 0px;
}
.card-complete {
  color: var(--accent);
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-incomplete {
  border-radius: 8px;
  border-color: var(--accent-semi);
  /* color: var(--accent-semi); */
  color: var(--accent);
}

.completion-bar-inner {
  height: 100%;
  background-color: rgb(1, 150, 250); /* Color of the filled area */
  border-radius: 10px; /* Match the radius of the outer bar */
  /* Width is set dynamically in your Vue template */
}
.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separates progress bar and button */
  padding: 0px;
}
</style>
