<template>
  <div>
    <q-btn
      flat
      dense
      icon="link" 
      @click="toggleMenu"
      class="q-mx-xs"
      style="flex-shrink: 0;" 
    />

    <q-form
        v-if="isOpen"
        @submit.prevent="submit"
        class="fixed top-full z-max mt-1 flex w-60 overflow-hidden rounded-borders bg-white p-1"
        style="display: flex; align-items: center; justify-content: space-between; width: 100%; padding: 8px; box-shadow: 0 2px 5px rgba(0,0,0,0.2); border-radius: 8px;" 
        @click.stop=""
      >
    <q-input
      ref="inputRef"
      filled
      dense
      type="text"
      placeholder="Paste a link"
      v-model="linkUrl"
      class="flex-1 q-px-xs q-py-none"
      autogrow
      clearable
      style="min-width: 0; flex-grow: 1; flex-shrink: 0; width: auto;" 
    />

    <q-btn
      v-if="editor.getAttributes('link').href"
      flat
      icon="delete"
      color="red"
      class="q-ml-xs"
      style="flex-shrink: 0;" 
      @click="removeLink"
    ></q-btn>
    <q-btn
      v-else
      flat
      icon="check"
      color="green"
      class="q-ml-xs"
      style="flex-shrink: 0;"
      type="submit"
    ></q-btn>
  </q-form>
  </div>
</template>


<!-- The style section can be removed since all styles are now inline -->
<script>
import { ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  components: {
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    editor: Object,
  },
  setup(props) {
    const isOpen = ref(false);
    const linkUrl = ref(props.editor.getAttributes('link').href || '');

    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
    };

    const submit = () => {
      const url = linkUrl.value;
      if (url) {
        props.editor.chain().focus().setLink({ href: url }).run();
        isOpen.value = false;
      }
    };

    const removeLink = () => {
      props.editor.chain().focus().unsetLink().run();
      isOpen.value = false;
    };

    return {
      isOpen,
      linkUrl,
      toggleMenu,
      submit,
      removeLink,
    };
  },
};
</script>



<style scoped>

</style>
