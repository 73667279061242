<template>
  <!-- Loading Spinner -->
  <div v-if="isLoading" class="spinnerContainer">
    <div class="spinner"></div>
  </div>

  <!-- User Content -->

  <div v-else>
    <div class="profilePage">
      <div class="additionalSections">
        <div class="section">
          <div class="title-header">
            <h2 class="sectionTitle">My Documents</h2>
          </div>

          <div class="cardsContainer">
            <div v-for="file in uploadedFiles" :key="file.id" class="card">
              <h3 class="cardTitle">{{ file.name }}</h3>
              <button @click="deleteFile(file.id)" class="delete-button">
                <font-awesome-icon :icon="['fas', 'trash']" />
              </button>
            </div>
            <label class="upload-label">
              <input
                type="file"
                @change="onFileSelected"
                class="upload-input"
                accept=".pdf, .docx"
              />
              <div class="card newcard">
                <h3 class="cardTitle">Upload New Document</h3>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  name: "UploadDocument",
  data() {
    return {
      uploadProgress: null,
      uploadedFiles: [],
    };
  },
  created() {
    this.getUserDocs();
  },
  methods: {
    redirectToDocument() {
      this.$router.push({ name: "MyApps", params: {} });
    },
    async getUserDocs() {
      try {
        const response = await axios.get("api/v1/get-user-docs");
        // Assuming the response is an array of objects with the structure {id: 59, title: "File Title"}
        this.uploadedFiles = response.data.map((doc) => ({
          id: doc.id, // Use the actual ID property from your objects
          name: doc.title, // Use the actual title property from your objects
        }));
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    onFileSelected(event) {
      const selectedFile = event.target.files[0];
      // console.log(selectedFile);
      if (selectedFile) {
        this.uploadUserDoc(selectedFile);
      }
      this.getUserDocs();
      // this.$router.push(`/document/188`);
    },
    async uploadUserDoc(file) {
      const formData = new FormData();
      formData.append("file", file);

      await axios
        .post("/api/v1/upload_doc/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // Calculate and update the upload progress
            this.uploadProgress = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            // console.log(`Upload Progress: ${this.uploadProgress}%`);
          },
        })
        .then((response) => {
          // console.log("File uploaded successfully:", response.data);
          // Handle the successful response here
          // You might want to update your component's state to reflect the successful upload
        })
        .catch((error) => {
          console.error(
            "Error uploading file:",
            error.response ? error.response.data : error.message
          );
          // Handle errors here
        });
      this.getUserDocs();
    },
    async deleteFile(fileId) {
      const response = await axios.get(`api/v1/delete-user-doc/`, {
        params: {
          id: fileId,
        },
      });
      this.getUserDocs();
    },
  },
};
</script>

<style scoped>
.title-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.completion-bar {
  height: 20px; /* Adjust as needed */
  background-color: #eee; /* Light grey background */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures inner bar stays within the container */
}

.completion-bar-inner {
  height: 100%;
  background-color: rgb(2, 104, 172); /* Adjust color as needed */
  width: 0%; /* Initial width */
  border-radius: 10px; /* Rounded corners */
}

.profilePage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centers vertically in the container */
  align-items: right;
  /* Centers horizontally in the container */
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: var(--title2);
}

.section {
  background-color: var(--card-bkg);
  padding: 20px;
  margin-bottom: 20px;
  font-family: var(--title-2);
}

.sectionTitle {
  color: var(--text);
  margin-bottom: 10px;
  margin-right: 25px;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between cards */
  justify-content: flex-start;
}

.card {
  width: 200px;
  height: 240px; /* 1.2:1 ratio */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: solid;
  border-color: var(--accent);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card:hover {
  border-color: var(--accent);
  background-color: var(--accent);
  color: white;
  cursor: pointer;
  /* Ensure the text color is white on hover */
  .delete-button {
    color: white; /* Set the color of the delete button to white */
  }
}

.newcard {
  border-radius: 8px;
  border: dashed;
  border-color: var(--accent-semi);
  color: var(--accent-semi);
}
.upload-input {
  display: none;
}

.upload-label {
  display: flex;
  cursor: pointer;
  flex-shrink: 0;
  /* Prevent the upload button from shrinking */
  margin-left: auto;
  /* This will push the upload button to the right */
}

.delete-button {
  color: var(--accent);
  border: 2px;
  border-radius: 2px;
  border: solid;
  background: none;
  border-color: var(--accent-semi);
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .card {
    width: calc(100% / 2 - 20px); /* Two cards per row on smaller screens */
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%; /* One card per row on very small screens */
  }
}

.spinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  /* Centers vertically in the container */
  align-items: center;
  /* Full viewport height minus any headers or footers if they exist */
  height: 100vh;
  /* Or if it should be the full height of a parent container, you could use 100% instead */
  /* height: 100%; */
  width: 100%;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 125px;
  height: 125px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

