<template>
  <div class="score-card">
    <div class="score-header-container">
      <div v-if="eligible == null" class="subheader unsure">
        <q-icon name="question_mark" style="font-size: 4em"/>
        <h4>
          {{ answers["first_name_contact"] }}, we're not sure if your project is
          eligible
        </h4>
      </div>
      <div v-else-if="eligible" class="subheader eligible">
        <q-icon name="done" style="font-size: 4em" color="accent"/>
        <h4>{{ answers["first_name_contact"] }}, we think your project is eligible!</h4>
      </div>
      <div v-else class="subheader not-eligible">
        <q-icon name="close" style="font-size: 4em" color="primary"/>
        <h4>
          {{ answers["first_name_contact"] }}, your project may not be eligible. Contact us for more information.
        </h4>
      </div>
    </div>
    <div class="container">
      <div class="row items-start justify-between" style="font-size: 22px;">
        <div class="col items-start">
          <span class="flag-text"><b>Predicted Score Range:</b></span>
        </div>
        <div class="col items-start" style="text-align: right">
          <span>
            <b v-if="!answers['eei_total_score']">{{ low_score }} - {{ high_score }} / {{ total_score }}</b>
            <b v-else>{{ answers["eei_total_score"] }} / {{ total_score }}</b>
          </span>
        </div>
      </div>

      <div class="row items-start justify-between" style="font-size: 18px; padding-top: 10px">
        <span class="flag-text"><b>Score Breakdown:</b></span>
      </div>

      <div class="q-pa-md">
        <q-list bordered class="rounded-borders justify-between">
          <q-expansion-item
            v-for="criteria in text_data" :key="criteria.input_name"
            switch-toggle-side
            expand-separator
            :label="criteria.text"
            class="justify-start"
          >
          <template v-slot:header>
            <q-item-section class="text-weight-medium" style="text-align: left;">
              <span>{{ criteria.text }}</span>
            </q-item-section>

            <q-item-section side>
              <div class="row items-center">
                {{
                  Number.isNaN(answers[criteria.input_name]) ||
                  answers[criteria.input_name] == null
                    ? "??"
                    : answers[criteria.input_name]
                }}/{{ criteria.total_points }}
              </div>
            </q-item-section>
          </template>
            <q-card>
              <q-card-section>
                <div class="cell">
                  <font-awesome-icon
                    :icon="['fas', 'circle-question']"
                    class="check-icon"
                    style="margin-left: 25px"
                  />
                  <span v-html="criteria.explanation"></span>
                </div>
                <div
                  v-if="
                    Number.isNaN(answers[criteria.input_name]) ||
                    !(criteria.input_name in answers) ||
                    answers[criteria.input_name] == null
                  "
                  class="cell"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle-exclamation']"
                    class="x-icon"
                    style="margin-left: 25px"
                  />
                  <span class="flag-text">{{ criteria.missing }}</span>
                </div>
              </q-card-section>
            </q-card>
          </q-expansion-item>

        </q-list>
      </div>
      
      <!-- <div v-if="'reap_coordinator_name' in answers && answers['reap_coordinator_name'] !== null && answers['reap_coordinator_name'] !== 'null'" class="row items-start justify-between" style="font-size: 22px;">
        <div class="col items-start">
          <span class="flag-text"><b>Your REAP State Coordinator:</b></span>
        </div>
        <div class="col items-start" style="text-align: right">
          <span>
            <b>
              <a
                style="color: black"
                :href="'mailto:' + answers['reap_coordinator_email']"
                >{{ answers["reap_coordinator_name"] }}</a
              >
            </b>
          </span>
        </div> 
      </div>-->

    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreCard",
  props: {
    eligible: {
      type: Boolean,
      required: true,
    },
    flags: {
      type: Array,
      default: () => [],
    },
    answers: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      low_score: 0,
      high_score: 100,
      total_score: 100,
      text_data: [],
      eei_data: [
        {
          input_name: "environmental_points",
          text: "Environmental Impact Score:",
          total_points: 10,
          explanation:
            "This criteria assesses your project's environmental impact. 5 points are awarded for projects that do not produce greenhouse gases. One point will be awarded for each documented environmental benefit supported by the project; does not convert farmland; does not contribute to deforestation or addresses fire hazards on forest lands; documented water conservation; complies with EPA’s renewable fuel standards; and at least 25% of project components are biobased.",
          missing:
            "Please answer the environmental questions to calculate this score",
        },
        {
          input_name: "distressed_points",
          text: "Distressed/disadvantaged community score:",
          total_points: 15,
          explanation:
            "<p>This criteria assesses whether your project falls within a distressed or disadvantaged community. To determine if your project location qualifies, please refer to the following <a href='https://ruraldevelopment.maps.arcgis.com/apps/webappviewer/index.html?id=4acf083be4c44bb7864d90f97de0c788' target='_blank'>map</a> that shows those areas.</p>",
            missing: "Please provide the project address to calculate this score",
        },
        {
          input_name: "points_per_dollar",
          text: "Per grant dollar energy score:",
          total_points: 10,
          explanation:
            "<p>This criteria looks at how much energy is saved for each dollar of grant money asked for:</p> <ul> <li><strong>50,000 BTUs or more per dollar:</strong> 10 points awarded.</li> <li><strong>Less than 50,000 BTUs per dollar:</strong> Points calculated as (energy saved per dollar / 50,000) * 10, rounded to the nearest hundredth.</li></ul>",
          missing:
            "Please provide an estimate for the energy saved per grant dollar requested to calculate this score",
        },
        {
          input_name: "energy_replacement",
          text: "Energy replacement score:",
          total_points: 15,
          explanation:
            "<p>This criteria evaluates how much energy your project will replace:</p> <ul> <li><strong>50% or more:</strong> 15 points awarded.</li> <li><strong>35% to under 50%:</strong> 10 points awarded.</li> <li><strong>20% to under 35%:</strong> 5 points awarded.</li> <li><strong>Less than 20%:</strong> No points awarded.</li></ul>",
          missing:
            "Please provide the amount of energy the EEI system will save along with the amount of energy consumed by current processes to calculate this score",
        },
        {
          input_name: "prior_reap_points",
          text: "REAP history score:",
          total_points: 15,
          explanation:
            "<p>This criteria gives points to projects that are new to REAP funding or haven't received it recently.</p> <ul> <li><strong>Never funded:</strong> If you've never gotten a REAP grant or loan, you get 15 points.</li><li><strong>Not in the last 2 years:</strong> If it's been over 2 years since you got a REAP grant or loan, you get 5 points.</li><li><strong>Received funding recently:</strong> If you've gotten REAP funding in the last 2 years, you get no points.</li> </ul>",
          missing:
            "Please answer the question about prior REAP funding to calculate this score",
        },
        {
          input_name: "eei_payback_points",
          text: "Payback period score:",
          total_points: 15,
          explanation:
            "<p>This criteria evaluates the project's payback period:</p> <ul> <li><strong>Less than 4 years:</strong> 15 points awarded.</li> <li><strong>4 to under 8 years:</strong> 10 points awarded.</li> <li><strong>8 to 12 years:</strong> 5 points awarded.</li> <li><strong>Over 12 years:</strong> No points awarded.</li></ul>",
          missing:
            "Please provide the estimated project payback period to calculate this score",
        },
        {
          input_name: "commitment_points",
          text: "Committed funds score:",
          total_points: 10,
          explanation:
            "<p> This criteria awards points for providing proof that the applicant can cover the project costs. This may be contingent on the project receiving the grant funds. For instance, a bank letter of commitment for a loan may stipulate that the loan will only apply if that project is awarded REAP.</p> <ul> <li><strong>Fully covered (100% match):</strong> If you have full funding promised in writing, you get 15 points.</li> <li><strong>Mostly covered (over 50% but less than 100%):</strong> Points are given based on how much more than half your funding is promised. It is calculated as ((Your funding percentage - 50%) / 50%) * 15 points. </li> <li><strong>Half or less covered (50% or less):</strong> If your promised funding is half or less, you get no points.</li></ul>",
          missing:
            "Please answer the question around funding commitments to calculate this score",
        },
        {
          input_name: "admin_points",
          text: "State Director/Administrator score:",
          total_points: 10,
          explanation:
            "These points are assigned at the discretion of your REAP state coordinator and vary from state to state",
          missing:
            "You will need to talk to your REAP state coordinator to calculate this score",
        },
      ],
      res_data: [
        {
          input_name: "environmental_points",
          text: "Environmental Impact Score:",
          total_points: 10,
          explanation:
            "This criteria assesses your project's environmental impact. 5 points are awarded for projects that do not produce greenhouse gases. One point will be awarded for each documented environmental benefit supported by the project; does not convert farmland; does not contribute to deforestation or addresses fire hazards on forest lands; documented water conservation; complies with EPA’s renewable fuel standards; and at least 25% of project components are biobased.",
          missing:
            "Please answer the environmental questions to calculate this score",
        },
        {
          input_name: "distressed_points",
          text: "Distressed/disadvantaged community score:",
          total_points: 15,
          explanation:
            "<p>This criteria assesses whether your project falls within a distressed or disadvantaged community. To determine if your project location qualifies, please refer to the following <a href='https://ruraldevelopment.maps.arcgis.com/apps/webappviewer/index.html?id=4acf083be4c44bb7864d90f97de0c788' target='_blank'>map</a> that shows those areas.</p>",
            missing: "Please provide the project address to calculate this score",
        },
        {
          input_name: "points_per_dollar",
          text: "Per grant dollar energy score:",
          total_points: 10,
          explanation:
            "<p>This criteria looks at how much energy is saved for each dollar of grant money asked for:</p> <ul> <li><strong>50,000 BTUs or more per dollar:</strong> 10 points awarded.</li> <li><strong>Less than 50,000 BTUs per dollar:</strong> Points calculated as (energy saved per dollar / 50,000) * 10, rounded to the nearest hundredth.</li></ul>",
          missing:
            "Please provide the amount of energy the RES system will replace per dollar to calculate this score",
        },
        {
          input_name: "energy_replacement",
          text: "Energy replacement score:",
          total_points: 15,
          explanation:
          "<p>This criteria measures how much energy your project will replace:</p> <ul> <li><strong>More than 50%:</strong> 15 points awarded.</li> <li><strong>More than 25% up to 50%:</strong> 10 points awarded.</li> <li><strong>25% or less:</strong> 5 points awarded.</li></ul>",
          missing:
            "Please provide the amount of energy the RES system will generate along with the amount of energy consumed by current processes to calculate this score",
        },
        {
          input_name: "prior_reap_points",
          text: "REAP history score:",
          total_points: 15,
          explanation:
            "<p>This criteria gives points to projects that are new to REAP funding or haven't received it recently.</p> <ul> <li><strong>Never funded:</strong> If you've never gotten a REAP grant or loan, you get 15 points.</li><li><strong>Not in the last 2 years:</strong> If it's been over 2 years since you got a REAP grant or loan, you get 5 points.</li><li><strong>Received funding recently:</strong> If you've gotten REAP funding in the last 2 years, you get no points.</li> </ul>",
          missing:
            "Please answer the question about prior REAP funding to calculate this score",
        },
        {
          input_name: "res_payback_points",
          text: "Payback period score:",
          total_points: 15,
          explanation:
            "<p>This criteria evaluates the project's payback period:</p> <ul> <li><strong>Less than 10 years:</strong> 15 points awarded.</li> <li><strong>10 to under 15 years:</strong> 10 points awarded.</li> <li><strong>15 to 25 years:</strong> 5 points awarded.</li> <li><strong>Over 25 years:</strong> No points awarded.</li></ul>",
          missing:
            "Please provide the estimated payback period to calculate this score",
        },
        {
          input_name: "commitment_points",
          text: "Committed funds score:",
          total_points: 10,
          explanation:
            "<p> This criteria awards points for providing proof that the applicant can cover the project costs. This may be contingent on the project receiving the grant funds. For instance, a bank letter of commitment for a loan may stipulate that the loan will only apply if that project is awarded REAP.</p> <ul> <li><strong>Fully covered (100% match):</strong> If you have full funding promised in writing, you get 15 points.</li> <li><strong>Mostly covered (over 50% but less than 100%):</strong> Points are given based on how much more than half your funding is promised. We calculate it as ((Your funding percentage - 50%) / 50%) * 15 points. We then round the points to the nearest hundredth.</li> <li><strong>Half or less covered (50% or less):</strong> If your promised funding is half or less, you get no points.</li></ul>",
          missing:
            "Please answer the question around funding commitments to calculate this score",
        },
        {
          input_name: "admin_points",
          text: "State Director/Administrator score:",
          total_points: 10,
          explanation:
            "These points are assigned at the discretion of your REAP state coordinator and vary from state to state",
          missing:
            "You will need to talk to your REAP state coordinator to calculate this score",
        },
      ],
      showSublist: {
        environmental_points: false,
        distressed_points: false,
        eei_energy_replacement: false,
        prior_reap_points: false,
        eei_payback_points: false,
        commitment_points: false,
        admin_points: false,
      },
    };
  },
  mounted() {
    this.calculateScoreRange();
  },
  methods: {
    toggleSublist(category) {
      this.showSublist[category] = !this.showSublist[category];
    },
    calculateScoreRange() {
      let low = 0;
      let high = 0;
      let total = 0;
      if (this.answers["eei"]) {
        this.text_data = this.eei_data;
        this.eei_data.forEach((criteria) => {
          total += criteria.total_points;

          var criteria_score = this.answers[criteria.input_name];

          if (isNaN(criteria_score)) {
            high += criteria.total_points; // Treat NaN as the maximum possible value, i.e., the denominator
          } else {
            low += criteria_score;
            high += criteria_score;
          }
        });
      } else if (this.answers["res"]) {
        this.text_data = this.res_data;
        this.res_data.forEach((criteria) => {
          total += criteria.total_points;

          var criteria_score = this.answers[criteria.input_name];

          if (isNaN(criteria_score)) {
            high += criteria.total_points; // Treat NaN as the maximum possible value, i.e., the denominator
          } else {
            low += criteria_score;
            high += criteria_score;
          }
        });
      }
      this.low_score = low;
      this.high_score = high;
      this.total_score_score = total;
    },
  },
};
</script>

<style scoped>
.score-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  /* border-width: 2px; */
  border: solid;
  border-color: rgb(1, 150, 250);
  padding: 10px;
  text-align: center;
  margin: 10px 0px;
  width: 82%;
  max-width: 800px;
  height: 100%;
  min-height: 150px;
}

.score-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.score-table td {
  padding: 8px;
}

.score {
  text-align: right;
}

.dropdown-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}

.arrow {
  margin-right: 5px;
}

.cell {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  text-align: left;
}

.score-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--title-2);
  padding-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-family: var(--title-2);
  padding-bottom: 10px;
}

.subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-text {
  text-align: left;
  font-size: 24px;
  font-weight: 400;
  margin: 15px 15px 15px 0px;
}

.big-icon {
  width: 55px;
  height: 55px;
}

.flags-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
}

.flags-list li {
  display: flex;
  align-items: top;
  margin: 5px 0;
}

.flag-text {
  text-align: left; /* Aligns the text to the left */
  display: block; /* Makes the span behave like a block element */
  width: 100%; /* Optional: Ensures it takes the full width */
}

.scores-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  /* max-height: 100px;
  overflow-y: auto; */
}

.scores-list li {
  display: flex;
  align-items: top;
  margin: 5px 0;
}

.x-icon {
  margin-right: 5px;
  margin-top: 2px;
  color: var(--accent);
}

.check-icon {
  margin-right: 5px;
  margin-top: 2px;
  color: rgb(1, 150, 250);
}

.eligible .big-icon {
  color: rgb(1, 150, 250);
  margin-top: 5px;
  margin-right: 15px;
}

.not-eligible .big-icon {
  color: var(--accent);
  margin-top: 5px;
  margin-right: 15px;
}

.unsure .big-icon {
  color: rgb(2, 104, 172);
  margin-top: 5px;
  margin-right: 15px;
}
</style>
