<template>
    <div>
        <iframe v-if="src" :src="src" style="width:100%; height:830px;"></iframe>
    </div>
</template>

<script>
    export default {
    props: {
        src: {
        type: String,
        required: true
        }
    },
    mounted() {
        console.log("PDF Source URL:", this.src); // Check if src is received correctly
    },
    beforeDestroy() {
        if (this.src) {
        URL.revokeObjectURL(this.src);
        }
    }
    };
</script>
