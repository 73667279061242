<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container class="row justify-center items-center">
      <div v-if="org_url && org_url !== ''">
        <a :href="org_url" target="_blank">
          <img
            :src="imageUrl"
            @error="onImageError"
            alt="Top Image"
            class="top-center-image"
          />
        </a>
      </div>
      <div v-else>
        <img
          :src="imageUrl"
          @error="onImageError"
          alt="Top Image"
          class="top-center-image"
        />
      </div>
      <div class="section-container">
        <div v-if="!loading && sections">
          <div v-if="currentSectionIndex < sections.length">
            <div class="section-details">
              <landing-form
                ref="dynamicFormRef"
                :questions="sections[currentSectionIndex].questions"
                :section_name="sections[currentSectionIndex].name"
                :formAnswers="answers"
                :docId="docId"
                :app_id="appId"
                :doc_name="document.display_name"
                final_section
                :docs_uploaded="docs_uploaded"
                :demo_link="org_url"
                :demo_email="org_email"
                demo_text="Learn More"
                :org_name="org_short_name"
                @formSubmitted="generatePDF"
              />
            </div>
          </div>
        </div>
        <div v-else class="spinner-container">
          <div class="spinner"></div>
        </div>
      </div>
      <div class="footer q-flex q-justify-center q-items-center">
        <span class="powered-by-text">Powered by</span>
        <a href="https://aidygrants.com/" target="_blank" class="q-ml-sm">
          <img
            src="../assets/aidy_lockup.png"
            alt="Logo"
            class="bottom-center-logo"
          />
        </a>
      </div>
    </q-page-container>
    <aidy-assistant
      :section_id="section_id"
      :q_and_a="page_content"
      :sidebarInitialState="false"
    ></aidy-assistant>
  </q-layout>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Label",
  data() {
    return {
      grant: null,
      isSidebarOpen: false, // Initial state of the sidebar
      section_id: 0,
      document: null,
      sections: null,
      answers: {},
      docId: null,
      appId: null,
      currentSectionIndex: 0,
      allAnswers: [],
      loading: false,
      docs_uploaded: false,
      org_name: null,
      org_url: null,
      org_email: null,
      org_short_name: null,
      defaultExt: 'svg', // Default extension
      imageUrl: '',
    };
  },
  created() {
    this.getEligiblityDoc();
    this.getOrgInfo();
  },
  mounted() {
    this.setImageUrl();
  },
  computed: {
    contentStyle() {
      // If the sidebar is open, add 40vh margin to the right
      if (this.isSidebarOpen) {
        return {
          paddingRight: "320px",
        };
      } else {
        return {
          paddingRight: "0px",
        };
      }
    },
    currentPath() {
      return this.$route.path.substring(1); // Removes the leading '/' from the path
    },
    logo_filepath() {
      return "../assets/sun-logo.svg";
    },
  },
  methods: {
    getOrgInfo() {
      this.org_short_name = this.currentPath;
      // console.log("ORG NAME:", this.org_short_name);
      axios
        .get(`api/v1/get-org-info`, {
          params: {
            org_short_name: this.currentPath,
          },
        })
        .then((response) => {
          // console.log("ORG INFO:", response.data);

          // Assuming the response has a data object with org_name, org_url, and org_email
          if (response.data) {
            this.org_name = response.data.org_name;
            this.org_url = response.data.org_url;
            this.org_email = response.data.org_email;
          } else {
            console.error("No data found in response");
          }
        })
        .catch((error) => {
          console.error("Error fetching org info:", error);
        });
    },
    handleSidebarToggle(isOpen) {
      this.isSidebarOpen = isOpen; // Keep track of the sidebar state
    },
    handleSectionId(sectionId) {
      this.section_id = sectionId;
    },
    async getEligiblityDoc() {
      try {
        const response = await axios.get(`api/v1/get-eligibility-quiz/`);
        this.document = response.data;
        this.docId = this.document.id;

        // After the document is loaded, load each section
        if (this.document.sections && this.document.sections.length > 0) {
          this.sections = this.document.sections;
          this.$emit("update:sectionId", this.sections[0].id); // Emit an event when toggled
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    },
    handleSubmit(formAnswers) {
      // console.log("Form Answers:", formAnswers);
      // Send the answers to your backend or perform other actions
      // Then load the next section's questions if any
      this.allAnswers[this.currentSectionIndex] = formAnswers;
      if (this.currentSectionIndex < this.sections.length) {
        this.currentSectionIndex++;
      }
      if (this.$refs.dynamicFormRef) {
        this.$refs.dynamicFormRef.formSubmitted = true;
      }
    },
    setImageUrl() {
      this.imageUrl = `/${this.org_short_name}-logo.${this.defaultExt}`;
      // console.log("SET IMAGE URL:", this.imageUrl);
    },
    onImageError() {
      // If the SVG fails to load, try PNG
      if (this.defaultExt === 'svg') {
        this.defaultExt = 'png';
        this.setImageUrl();
      } else {
        // If both SVG and PNG fail, you can set a default/fallback image
        this.imageUrl = '/path/to/default-logo.png';
      }
    },
  },
});
</script>

<style scoped>
.top-center-image {
  display: block;
  margin: 40px auto 0px; /* Centers the image */
  width: 400px;
}

.section-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  text-align: center; /* Center aligns the content */
  padding: 20px 0; /* Adds padding at top and bottom */
}

.powered-by-text {
  margin-right: 10px; /* Spacing between text and logo */
  vertical-align: middle; /* Aligns text with the logo */
}

.bottom-center-logo {
  width: 30%;
  max-width: 200px;
  vertical-align: middle; /* Aligns logo with the text */
}
</style>
