<template>
  <div>
    <q-card class="q-pa-md q-ma-md">
      <q-card-section horizontal class="justify-between">
        <q-card-section>
          <div class="q-gutter-y-md">
            <div class="row">
              <q-img
                :src="`/department_logos/${Object.keys(this.bill.chamber)[0]}.png`"
                spinner-color="white"
                style="height: 100px; max-width: 100px; border-radius: 50%;"
                class='col q-pa-sm'
              ></q-img>
              <div class="col block q-pl-lg" >
                <h5 @click="navigateToBill(bill.id)" class="row clickable-title cursor-pointer">{{ bill.title }}</h5>
                <div class="row justify-start items-center">
                  <h6 style="padding-right: 10px;">
                    {{ bill.bill_type }} {{ bill.bill_number }}
                  </h6>
              <q-chip
                :key="program"
                class="text-weight-bold q-mr-sm"
                color="primary"
                text-color="white"
              >
                {{ bill.congress }}th
              </q-chip>
              <q-chip
                v-for="(value, committee) in bill.committees"
                :key="committee"
                class="text-weight-bold q-mr-sm ellipsis"
                color="accent"
                text-color="white"
              >
                {{ committee }}
              </q-chip>
              
            </div>
              </div>
            </div>
            <p class="text-weight-regular" style="text-align: justify; letter-spacing: 0.02em;">{{ bill.summary }}</p>
          </div>
          <div v-if="bill && bill.actions && bill.actions[0] && bill.actions[0]['actionDate'] && bill.actions[0]['text']" class="q-gutter-sm q-mt-md">
            <div>
              <b>Latest Action: </b>
              <q-chip
                icon="event"
                class="text-weight-bold"
                color="primary"
                text-color="white"
                size="sm"
                >{{ formatDate(bill.actions[0]["actionDate"]) }}</q-chip
              >
              {{ bill.actions[0]["text"] }}
            </div>
          </div>
        </q-card-section>
        <q-card-actions vertical class="justify-around q-px-md">
          <q-btn
            :loading="bookmarkLoading"
            flat
            round
            transparent
            :disabled="!isLoggedIn"
            color="primary"
            :color="isBookmarked ? 'primary' : 'secondary'"
            :icon="isBookmarked ? 'notifications_active' : 'notifications'"
            size="lg"
            @click="toggleBookmark(bill.id)"
          >
            <q-tooltip
              v-if="!isLoggedIn"
              class="bg-primary text-body2 flex flex-center text-center non-selectable"
              style="width: 150px"
              :offset="[10, 10]"
            >
              Must be signed in to track bill
            </q-tooltip>
            <q-tooltip
              v-if="isLoggedIn && !isBookmarked"
              class="bg-primary text-body2 flex flex-center text-center non-selectable"
              style="width: 150px"
              :offset="[10, 10]"
            >
              Track bill
            </q-tooltip>
            <q-tooltip
              v-if="isLoggedIn && isBookmarked"
              class="bg-primary text-body2 flex flex-center text-center non-selectable"
              style="width: 150px"
              :offset="[10, 10]"
            >
              Untrack bill
            </q-tooltip>
          </q-btn>
          
        </q-card-actions>
      </q-card-section>
    </q-card>
  </div>
</template>
  
<script>
import { computed, ref, toRefs, inject, watchEffect } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from 'vuex';

export default {
  name: "BillCard",
  props: {
    bill: {
      type: Object,
      required: true,
    },
    bookmarked: {
      type: Boolean,
      required: false,
    },
    // searchQuery: String,
  },
  setup(props, { emit }) {
    const { bill } = toRefs(props);
    const searchQuery = inject('searchQuery');
    const companyName = inject('companyName');
    const router = useRouter();
    const requestDialog = ref(false);
    const checkFitDialog = ref(false);
    const isBookmarked = ref(false); // Initial bookmark status
    watchEffect(() => {
      isBookmarked.value = bill.value.is_bookmarked || false;
    });

    const name = ref("");
    const email = ref("");
    const comments = ref("");

    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isLoggedIn);

    const sortedCategories = computed(() => {
      return Object.keys(props.bill.categories || {}).sort();
    });

    const grantRec = ref(null);
    const loadingRec = ref(false);

    const bookmarkLoading = ref(false);

    function navigateToBill(id) {
      router.push(`/bill-info/${id}`);
    }

    const toggleBookmark = async () => {
      bookmarkLoading.value = true;
      emit('bookmark-toggle', bill.id);

      try {
        const response = await axios.get('/api/v1/bookmark-bill', {
          params: { bill_id: bill.value.id},
        });
        bill.value.is_bookmarked = !isBookmarked.value;
        isBookmarked.value = !isBookmarked.value;
        bookmarkLoading.value = false;
      } catch (err) {
        bookmarkLoading.value = false;
        console.error("An unknown error occurred");
      }
    };

    const formatDate = (dateString) => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Helper function to get the ordinal suffix for day numbers
      function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      }

      const date = new Date(dateString);
      const day = date.getDate();
      const monthName = months[date.getMonth()];
      const year = date.getFullYear();

      return `${monthName} ${day}${getOrdinalSuffix(day)}, ${year}`;
    };

    return {
      sortedCategories,
      navigateToBill,
      bookmarkLoading,
      requestDialog,
      checkFitDialog,
      name,
      companyName,
      email,
      searchQuery,
      grantRec,
      loadingRec,
      isLoggedIn,
      toggleBookmark,
      formatDate,
      isBookmarked,
    };
  },
};
</script>
<style scoped>
.clickable-title {
  transition: color 0.1s, font-weight 0.1s;
}

.clickable-title:hover {
  color: rgba(0, 150, 250); /* This is an example color, change it as needed */
}
</style>