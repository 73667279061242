<template>
  <!-- Sidebar using Q-Drawer -->
  <q-drawer class="bg-grey-1" :width="375" v-model="isOpen" side="right" bordered>
    <div class="column justify-between full-height">
      <div class="col-1">
        <q-btn
          class="col self-start"
          dense
          flat
          icon="close"
          @click="toggleSidebar"
        />
        <h4 class="col text-center">Aidy Assistant</h4>
        <q-separator inset />
      </div>

      <div class="col scroll q-mt-lg" ref="scrollContainer">
        <div class="q-pa-md row justify-center" style="padding: 11px 0px;">
          <div style="width: 100%; max-width: 600px">
            <div>
              <div class="message bot-message" v-html="renderMarkdown('Hi there, what questions about this bill can I answer for you?')"></div>
            </div>
            <div v-for="(interaction, index) in interactions" :key="index">
              <div v-html="renderMarkdown(interaction.text)" :class="{'user-message': interaction.from === 'user', 'bot-message': interaction.from !== 'user'}" ></div>
              <div v-if="interaction.sources !== undefined" class="q-pl-sm q-gutter-sm">
                <q-btn 
                  v-for="source in interaction.sources" 
                  round
                  size="sm"
                  :label="source.index+1"
                  color="accent"
                  @click="$emit('highlight', source.content)"
                >
                  <q-tooltip>
                    See source
                  </q-tooltip>
                </q-btn>
              </div>
            </div>
            <div v-if="isTyping" style="padding: 10px;">
              <q-spinner-dots size="2rem" />
            </div>
          </div>
        </div>
      </div>

      <div class="col-1">
        <q-input
          square
          class="absolute-bottom"
          v-model="inputText"
          filled
          type="text"
          autogrow
          placeholder="Ask here..."
          @keypress.enter="handleKeyPress"
          :class="{ 'accent-color': inputText }"
        >
          <template v-slot:append>
            <q-btn
              icon="send"
              type="submit"
              :disabled="!inputText"
              @click="handleSubmit"
              round
              dense
            />
          </template>
        </q-input>
      </div>
    </div>
  </q-drawer>

  <!-- Toggle Button using Q-Btn -->
  <q-btn
    round
    color="primary"
    class="toggle-button"
    @click="toggleSidebar"
    v-if="!isOpen"
    ><img src="/small_logo.png" alt="Logo" style="width: 50px; height: 50px"
  /></q-btn>
</template>
<script>
import axios from "axios";
import { renderMarkdown } from "@/helpers/helpers";
export default {
  name: "BillAssistant",
  data() {
    return {
      isOpen: true, // start with the sidebar closed
      inputText: "", // The text entered in the input field
      isTyping: false,
      interactions: [],
    };
  },
  props: {
    sidebarInitialState: {
      type: Boolean,
      default: true,
    },
    opp_id: {
      type: Number,
      default: null,
    },
  },
  methods: {
    renderMarkdown(markdownText) {
      return renderMarkdown(markdownText);
    },
    scrollToBottom() {
      const container = this.$refs.scrollContainer;
      container.scrollTop = container.scrollHeight;
    },
    getName(interaction) {
      return "";
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;
      this.$emit("update:isOpen", this.isOpen); // Emit an event when toggled
    },
    handleSubmit(historyCount = 5) {
      this.isTyping = true;
      const userText = this.inputText;
      this.interactions.push({ text: userText, from: "user" });
      this.inputText = ""; // Clear the input field after submission

      const recentInteractions = this.interactions
        .slice(-historyCount)
        .map((interaction) => ({
          role: interaction.from === "user" ? "user" : "assistant",
          content: interaction.text,
        }));

      let vm = this; // Capture the Vue instance
      // Fetch data using POST method
      fetch(`${axios.defaults.baseURL}/api/v1/chat-bill/`, {
        //Be sure to update this for different environments
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: recentInteractions,
          opp_id: this.opp_id,
        }),
      })
        .then((response) => {
          const reader = response.body.getReader();
          let chunks = "";
          let firstChunkReceived = false;

          reader.read().then(function processChunk({ done, value }) {
            if (done) {
              vm.isTyping = false;
              return;
            }

            chunks += new TextDecoder().decode(value);

            // Check for complete JSON objects and process them
            while (chunks.indexOf("}") !== -1) {
              let chunkEnd = chunks.indexOf("}") + 1;
              let chunk = chunks.substring(0, chunkEnd);
              chunks = chunks.substring(chunkEnd);

              let data = JSON.parse(chunk);

              let contentText = ""

              // Hide the typing element when the first chunk is received
              if (!firstChunkReceived) {
                  // vm.isTyping = false;
                  contentText = data.content.replace("<br>", " "); // Replace line breaks with spaces
                  vm.interactions.push({ text: contentText, from: "bot" });
                  firstChunkReceived = true;
              } else {
                  // Check if the data chunk includes an 'index'
                  if (data.hasOwnProperty('index')) {
                    if (!vm.interactions[vm.interactions.length - 1].hasOwnProperty('sources')) {
                        vm.interactions[vm.interactions.length - 1].sources = [];
                    }
                    vm.interactions[vm.interactions.length - 1].sources.push(data);
                  } else {
                    // Append the content text to the last interaction
                    contentText = data.content.replace("<br>", " "); // Replace line breaks with spaces
                    vm.interactions[vm.interactions.length - 1]["text"] += contentText;
                    vm.scrollToBottom();
                  }
              }
          }

            return reader.read().then(processChunk);
          });
        })
        .catch((error) => {
          this.isTyping = false;
          console.error("Error:", error);
          // Handle error, possibly pushing an error message to `interactions`
          this.interactions.push({
            text: "Sorry, an error occurred.",
            from: "bot",
          });
        });
    },
    handleKeyPress(event) {
      if (event.shiftKey) {
        // If Shift + Enter, add a new line
        return;
      }

      // Prevent the default action to stop from adding a new line
      event.preventDefault();

      // Call handleSubmit to submit the form
      this.handleSubmit();
    },
    formatInteractions() {
      return this.interactions.map((interaction) => {
        return {
          role: interaction.from === "user" ? "user" : "assistant",
          content: interaction.text,
        };
      });
    },
  },
  created() {
    this.isOpen = this.sidebarInitialState;
  },
};
</script>

<style scoped>
.user-message {
  /* background-color: white; White background for user messages */
  text-align: right; /* Align text to the right */
  margin-left: auto; /* Push the message to the right */
  padding: 8px;
  margin-bottom: 4px;
  /* max-width: 80%; Limit message width */
}

.bot-message {
  background-color: #E0E0E0; /* Light grey background for bot messages */
  text-align: left; /* Align text to the left */
  padding: 8px;
  margin-bottom: 4px;
  /* max-width: 80%; Limit message width */
}

.source {
  display: inline-block; 
  margin: 5px; 
  padding: 8px; 
  width: 20px;
  height: 20px;
  background-color: rgba(0, 150, 250); 
  color: white; 
  border-radius: 50%;
}

.assistant {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0px;
  /* Align sidebar to the left */
  width: 0;
  /* Start with a zero width */
  height: 100vh;
  overflow-x: hidden;
  /* Hide the content that overflows on the x-axis */
  display: flex;
  flex-direction: column;
  background-color: var(--accent-semi);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: width 0.3s;
  /* Animate the width of the sidebar */
  z-index: 1000;
}

.sidebar-open {
  width: 420px;
  /* Set the sidebar width when it's open */
  box-sizing: border-box;
  overflow-y: auto;
  /* Allows scrolling within the sidebar if content is too tall */
}

.toggle-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* Position it on the right */
  z-index: 1002;
  /* Above the sidebar */
}

h2 {
  font-family: var(--title-2);
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: rgb(2, 104, 172);
  margin-top: 30px;
  padding: 10px 10px 5px 10px;
  margin-block-end: 5px;
}

.buttonText {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 30px;
  font-weight: 500;
}

.button {
  border: none;
  /* Remove default border */
  outline: none;
  /* Remove focus outline */
  border-radius: var(--br-3xs);
  background-color: transparent;
  width: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  font-size: var(--title-2-size);
  color: var(--bkg);
  font-family: var(--title-2);
  transition: width 0.1s;
}
.button:hover {
  width: 100px;
}

/* Add this new class for the form container */
.form-container {
  margin-top: auto;
  /* Pushes the form to the bottom */
  padding: 20px;
  min-width: 280px;
}

.input-form {
  position: relative;
  /* Needed for absolute positioning of the submit button */
}

.input-wrapper {
  position: relative;
  /* Container for input and button */
}

.input-group {
  position: relative;
  /* Establish a containing block for absolute positioning */
  display: flex;
  /* Use flexbox for aligning input and button */
  align-items: stretch;
  /* Align items to stretch to fill the container */
}

.text-input {
  font-family: var(--title-2);
  width: 100%;
  /* Input field should take up all available width */
  height: 80px;
  /* Set the height of the input */
  padding-right: 50px;
  /* Make padding for the icon */
  padding-left: 10px;
  /* Padding for the text */
  padding-top: 10px;
  /* Padding for the text */
  border-radius: var(--br-3xs);
  border: 1px solid #ccc;
  position: relative;
  /* Relative positioning for z-index context */
  z-index: 1;
  /* Ensures text input is above the submit icon layer */
  flex-grow: 1;
  /* Input field should grow to take available space */
  padding-right: 60px;
  /* Adjust padding to ensure space for the icon */
  overflow: auto;
  /* Allows scrolling for overflow text */
  word-wrap: break-word;
  /* Breaks words to prevent horizontal scrolling */
  white-space: pre-wrap;
  /* Wraps text and preserves whitespace and line breaks */
}

.message-text {
  font-family: var(--title-2);
}

.submit-icon {
  position: absolute;
  color: #ccc;
  right: 10px;
  /* Right aligned within the input-group */
  top: 50%;
  /* Start at 50% from the top */
  transform: translateY(-50%);
  /* Center vertically */
  background: transparent;
  border: none;
  padding: 0 15px;
  /* Adjust padding as needed */
  height: 100%;
  /* Height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

/* .submit-icon:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease;
} */

/* Adjust the size of the icon if necessary */
.fa-icon {
  font-size: 2em;
  /* Larger icon size */
  display: flex;
  /* Use flexbox for centering */
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  z-index: 1002;
  /* Above the sidebar */
}

.accent-color {
  color: var(--accent);
  /* Replace with your actual accent color variable or value */
}

/* Prevent default browser styling */
button:focus,
input:focus {
  outline: none;
}

.chat-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 8px;
}

.chat-msg {
  margin: 5px 0px;
  width: 90%;
  padding: 10px;
  border-radius: 10px;
  font-family: var(--title2);
  /* Apply the title2 font style */
}

.chat-user {
  align-self: flex-end;
  /* Aligns bot messages to the right */
  text-align: right;
  background-color: var(--bg-grey-3);
  /* Bot message background color */
  color: var(--text);
  /* For better contrast depending on your theme */
}

.chat-bot {
  align-self: flex-end;
  /* Aligns bot messages to the right */
  text-align: left;
  background-color: var(--accent);
  /* Bot message background color */
  color: var(--text);
  /* For better contrast depending on your theme */
}

.chat-header {
  min-width: 500px;
}

.thin-line {
  border: 0;
  border-top: 1px solid rgb(128 128 128);
  width: 80%;
  margin: 10px auto;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.dot {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.close-panel-btn {
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 3px 6px;
  font-size: 18px;
  border: none;
  background-color: transparent;
  color: rgb(128 128 128);
  cursor: pointer;
}

.close-panel-btn:hover {
  color: rgb(241, 146, 30);
}

p {
  margin: 0;
  /* Removes default paragraph margin */
}
</style>
