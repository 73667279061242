<template>
  <router-view />
</template>

<!-- <template>
  <q-layout view="hHh LpR fff">
    <q-drawer
      class="bg-grey-1 text-primary shadow-1 justify-between"
      v-model="drawer"
      show-if-above
      :width="200"
      :breakpoint="400"
    >
      <div>
        <img class="q-pa-md" alt="" src="/logo.svg" />
        <q-list>
          <q-item
            :class="{ 'bg-secondary': isActive('Dashboard') }"
            clickable
            @click="router.push({ name: 'Dashboard' })"
            v-ripple
          >
            <q-item-section avatar>
              <q-icon name="dashboard" />
            </q-item-section>
            <q-item-section> Dashboard </q-item-section>
          </q-item>
        </q-list>
      </div>
      <q-list class="absolute-bottom">
        <q-item
          :class="{ 'bg-secondary': isActive('User') }"
          clickable
          @click="router.push({ name: 'User' })"
          v-ripple
        >
          <q-item-section avatar>
            <q-icon name="person" />
          </q-item-section>
          <q-item-section> My Account </q-item-section>
        </q-item>
        <q-item clickable v-ripple>
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section> Sign Out </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const rightDrawerOpen = ref(false);
    const leftDrawerOpen = ref(true);

    const route = useRoute();
    const router = useRouter();
    const isActive = (routeName) => {
      return route.name === routeName;
    };

    return {
      miniState: ref(true),
      rightDrawerOpen,
      leftDrawerOpen,
      isActive,
      router,
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value;
      },
    };
  },
};
</script> -->
