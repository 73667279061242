<template>
  <div class="container">
    <div class="content">
      <h3 text-weight-bolder>Thank you for signing up for Aidy!</h3>
      <h5>
        To complete your REAP application with Aidy, please purchase a plan
        below.
      </h5>
      <button class="button" style="margin-top: 30px" @click="openCheckout">
        <div class="buttonText">Purchase</div>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Plans",
  data() {
    return {
      user: null,
      tier: null,
      iq_id: null,
    };
  },
  created() {
    this.getUserTier();
  },
  methods: {
    async getUserTier() {
      try {
        const response = await axios.get(`api/v1/get-user-tier/`);
        this.tier = response.data.tier;
        this.iq_id = response.data.iq_id;
        this.user_slug = response.data.slug;
        console.log("USER TIER:", this.tier);
        if (this.tier === "") {
          console.log("BRANCH");
          this.$router.push({
            name: "Document",
            params: { id: this.iq_id },
          });
        }
      } catch (error) {
        console.error("Error during tier verification:", error);
        // Handle the error appropriately
      }
    },
    async openCheckout() {
      try {
        // First, ensure the user tier is retrieved and processed
        await this.getUserTier();

        // If tier is empty, getUserTier would have navigated away, so below code won't run
        if (this.tier !== "") {
          const response = await axios.get("/api/v1/stripe/checkout/", {
            params: {
              user_slug: this.user_slug,
              user_tier: this.tier,
              return_url: window.location.href,
              success_url: window.location.origin + "/",
            },
          });
          const url = response.data.url;

          if (url) {
            window.location.href = url; // Redirects the user to the URL
          }
        }
      } catch (error) {
        console.error("Error in openCheckout:", error);
        // Handle errors appropriately
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex; /* Establishes a flex container */
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  height: 100vh; /* Takes up the full height of the viewport */
  text-align: center; /* Centers text within each child */
}

.content {
  display: flex; /* Establish this div as a flex container */
  flex-direction: column; /* Stacks flex items vertically */
  align-items: center; /* Center items horizontally within the container */
}

.buy-button {
  background-color: var(--accent);
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 1em;
  padding: 0.5em 1em;
}
.button {
  cursor: pointer;
  border: 3px solid var(--accent);
  border-radius: var(--br-3xs);
  padding: 10px;
  /* Remove padding here if you want the text to fill the entire button */
  background-color: var(--accent);
  align-items: center;
  /* Center the text vertically */
  justify-content: center;
  /* Center the text horizontally */
  height: 46px;
  display: flex;
  flex-direction: row;
  transition: background-color 0.3s, color 0.3s;
  color: white;
  /* Smooth transition for hover effects */

  &:hover {
    background-color: transparent;
    color: var(--accent);
    /* Ensure the text color is white on hover */
  }
}

.buttonText {
  font-size: var(--title-2-size);
  letter-spacing: 0.08em;
  font-weight: 500;
  font-family: var(--title-2);
  color: inherit;
  /* Inherit the color from the button */
  flex-grow: 1;
  /* Allows the text to fill the button */
  display: flex;
  /* Use flex to center the text inside the button */
  align-items: center;
  /* Center the text vertically */
  justify-content: center;
  /* Center the text horizontally */
}
</style>
