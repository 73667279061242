<template>
  <q-page-container v-if="bill" class="column items-center fit"
    style="overflow: hidden;">
    <q-splitter v-model="splitterModel" class="fit">
      <template v-slot:before>
        <div class="column q-gutter-md full-height" style="overflow: auto;">
          <div v-if="isPDF" class="row justify-center fit">
            <embed :src="bill.file_url" type="application/pdf" class="fit" />
          </div>
          <div v-else class="row justify-between fit">
            <div ref="xmlContainer" id="xmlContainer" v-html="htmlContent" class="full-height"></div>
          </div>
        </div>
      </template>
      <template v-slot:after>
        <div class="column q-gutter-md full-height" style="padding: 10px 0px 0px 10px; overflow: auto;">
          <state-bill-assistant :opp_id="bill_id" :sidebarInitialState="true"
            @highlight="highlightText"></state-bill-assistant>
        </div>
      </template>
    </q-splitter>
  </q-page-container>
  <div v-else class="flex flex-center" style="height: 100%; padding-top: 50vh;">
    <q-spinner color="primary" :size="120"></q-spinner>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import axios from "axios";

export default defineComponent({
  name: "Bill",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      bill: null,
      bill_id: this.id,
      isPDF: false,
      store: null,
      splitterModel: 75, // Default splitter position to make left side 75%,
      htmlContent: null
    }
  },
  async mounted() {
    this.store = useStore();
    if (this.store.getters.getStateBillId == this.bill_id) {
      this.bill = this.store.getters.getCurrStateBill;
      this.isPDF = this.bill.content_type === ".pdf";
      // console.log("IS PDF:", this.isPDF);
      if (!this.isPDF && this.bill.file_content) {
        this.htmlContent = this.bill.file_content;
      }
    }
    else {
      this.getBillText();
    }
  },
  methods: {
    getBillText() {
      axios.get("/api/state/get-statebill", { params: { bill_id: this.bill_id } })
        .then(response => {
          // console.log("BILL RESPONSE:", response.data);
          this.bill = response.data;
          // storing the bill information
          this.store.dispatch("updateStateBillId", this.bill_id);
          this.store.dispatch("updateCurrStateBill", this.bill);
          this.isPDF = this.bill.content_type === ".pdf";
          // console.log("IS PDF:", this.isPDF);
          this.$nextTick(() => {
            if (!this.isPDF && this.bill.file_content) {
              this.htmlContent = this.bill.file_content;
            }
          });
        })
        .catch(error => {
          console.error("Error fetching bill info:", error);
        });
    },
    findParagraphWithText(chunk_text) {
      const contentDiv = this.$refs.xmlContainer;
      if (!contentDiv) return;

      const normalizedChunk = chunk_text.replace(/\s+/g, '');
      const paragraphs = contentDiv.getElementsByTagName('p');
      let firstHighlighted = false;

      Array.from(paragraphs).forEach(p => {
        const normalizedParagraphText = p.textContent.replace(/\s+/g, '');

        if (normalizedParagraphText.length > 0) {
          if (normalizedChunk.includes(normalizedParagraphText) || normalizedParagraphText.includes(normalizedChunk)) {
            p.style.backgroundColor = 'yellow';
            if (!firstHighlighted) {
              p.scrollIntoView({ behavior: 'smooth', block: 'start' });
              firstHighlighted = true;
            }
          } else {
            p.style.backgroundColor = 'transparent';
          }
        } else {
          p.style.backgroundColor = 'transparent';
        }
      });
    },
  },
});
</script>

<style scoped>
.full-height {
  height: 100%;
}

.container {
  height: 100vh;
  width: 100%;
}

.content {
  height: 100%;
  width: 90%;
  box-sizing: border-box;
  transition: padding-right 0.3s ease;
  align-items: left;
}

.section-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-center-image {
  display: block;
  margin: 40px auto 0px;
  width: 30%;
  max-width: 300px;
  min-width: 200px;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.active-doc {
  background-color: var(--accent);
  color: white;
}

.markdown-body {
  line-height: 1.6;
  color: var(--text);
  border-radius: 5px;
  font-family: var(--title-2);
}

.hyperlink {
  text-decoration: underline;
}

.hyperlink:hover {
  font-weight: 500;
  color: var (--accent);
  cursor: pointer;
}

.highlight {
  background-color: yellow;
  color: black;
}
</style>