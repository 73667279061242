<template>
  <div class="full-width full-height body">
    <div v-if="loading">
      <q-card v-for="n in 8" class="q-pa-md q-ma-md">
        <div class="q-gutter-y-md">
          <q-skeleton bordered style="width: 620px"></q-skeleton>
          <q-skeleton bordered></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
        </div>
      </q-card>
    </div>
    <OppCard v-else v-for="opp in opps" :key="opp.id" :opp="opp" :searchQuery="searchQuery" :bookmarked="bookmarks.includes(opp.id)" @bookmark-toggle="handleBookmarkToggle"/>
  </div>
</template>
  
  
<script>
import { defineComponent, toRefs, provide, inject, onMounted, ref } from "vue";
import { useStore } from "vuex";
import OppCard from "./OppCard.vue";
import axios from "axios";
import { BookUser } from "lucide-vue-next";

export default defineComponent({
  name: "OppList",
  components: {
    OppCard,
  },
  props: {
    opps: Array,
    loading: Boolean,
    // searchQuery: String,
  },
  setup(props) {
    // Use `toRefs` to create a reactive reference for each prop
    const { opps, loading } = toRefs(props);
    const store = useStore();

    const searchQuery = inject('searchQuery');
    provide('searchQuery', searchQuery);

    const companyName = inject('companyName');
    provide('companyName', companyName);
    // All reactive states, refs, or computed properties should be declared here
    // and returned from setup() for use in the template

    console.log("searchQuery in OppList", searchQuery.value);
    
    onMounted(() => {
      fetchBookmarks();
    });

    const error = ref("");

    const bookmarks = ref([]);
    const fetchBookmarks = async () => {

        try {
          console.log("HERE");
          const response = await axios.get('/api/v1/get-fo-bookmarks', {
            params: { only_ids: true }
          });
          console.log('here2');
          console.log(response.data);
          bookmarks.value = response.data;
        } catch (err) {
          console.log("ERROR", err);
        }
    };

    const handleBookmarkToggle = async (id) => {
      console.log('Bookmark toggled for:', id);

      try {
        console.log("HERE");
        const response = await axios.post('/api/v1/toggle-fo-bookmark/', {}, {
          params: { fo_id: id }
        });
        fetchBookmarks();
      } catch (err) {
        error.value = err.response?.data?.error || "An unknown error occurred";
      }
    };

    return {
      opps,
      loading,
      searchQuery,
      companyName,
      handleBookmarkToggle,
      bookmarks,
    };
  },
});
</script>

<style scoped>
</style>