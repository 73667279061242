<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-1 text-primary shadow-1 justify-between"
      :width="240"
      style="overflow: hidden;"
    >
      <img class="q-pa-lg" :src="require('@/assets/logo.svg')" style="width: 100%;"/>
      <q-list>
        <q-item :class="{ 'bg-secondary': isActive('BillSearch') }" clickable
          @click="router.push({ name: 'BillSearch' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="gavel" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Federal</h6>
          </q-item-section>
        </q-item>
        <q-item :class="{ 'bg-secondary': isActive('StateBillSearch') }" clickable
          @click="router.push({ name: 'StateBillSearch' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="account_balance" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">State</h6>
          </q-item-section>
        </q-item>
        <q-item :class="{ 'bg-secondary': isActive('BookmarkedBills') }" class="cursor-pointer" clickable
          @click="router.push({ name: 'BookmarkedBills' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="notifications" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Saved Bills</h6>
          </q-item-section>
        </q-item>
      </q-list>
      <q-scroll-area class="full-height bg-grey-1" style="width: 100%; overflow-x: clip;">
        <q-separator inset class="q-my-sm" />
        <q-list padding style="width: 100%;">
          <div v-if="filtersPresent" @click="clearFilters" class="text-body2 q-pl-md clickable-title cursor-pointer">Clear Filters</div>
          <h6 class="q-pl-md" style="color: black">Chamber</h6>
          <q-chip
            v-for="chamber in chambers"
            :key="chamber.text"
            clickable
            v-ripple
            :outline="chamber.selected"
            @click="chamber.selected = !chamber.selected"
            class="q-ml-md grey-3 text-caption"
            style="max-width: 220px;"
          >
            <div class="ellipsis">{{ chamber.text }}</div>
          </q-chip>

          <q-separator inset class="q-my-sm" />

          <h6 class="q-pl-md" style="color: black">Policy Area</h6>
          <q-chip
            v-for="area in policy_areas"
            :key="area.text"
            clickable
            v-ripple
            :outline="area.selected"
            @click="area.selected = !area.selected"
            class="q-ml-md grey-3 text-caption ellipsis"
            style="max-width: 220px;"
          >
            <div class="ellipsis">{{ area.text }}</div>
          </q-chip>

          <q-separator inset class="q-my-sm" />

        </q-list>
      </q-scroll-area>
      <q-list v-if="signedIn" class="absolute-bottom" style="margin-bottom: 20px">
        <q-item clickable @click="router.push({ name: 'SignIn' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Sign Out</h6>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container
      class="row justify-center bg-tertiary"
      style="height: 100vh; overflow: auto"
    >
    <q-page class="column justify-start">
      <div class="column items-center" style="width: 100%;">
        <div class="row justify-end" style="width: 100%; height: 50px;">
          <q-btn
            v-if="!signedIn"
            color="primary"
            label="Sign In"
            class="q-mr-md q-mt-md"
            @click="showSignIn = true"
          >
          </q-btn>
          <q-btn
            v-else
            rounded
            color="primary"
            label=""
            class="q-mr-md q-mt-md"
            @click="router.push({ name: 'User' })"
          >
            <q-icon name="person" color="white" />
            <q-tooltip
              class="bg-primary text-white text-body2"
              :offset="[10, 10]"
            >My Account</q-tooltip>
          </q-btn>
        </div>
        
        <div class="row justify-center" style="max-width: 1200px">
          <q-input
            dense
            outlined
            autogrow
            standout
            rounded
            v-model="search"
            bg-color="white"
            autofocus
            placeholder="Search for current bills..."
            style="width: 500px"
            class="q-mt-lg q-mb-lg"
            @keydown.enter.prevent="fetchSearchResults"
          >
            <template v-slot:prepend>
              <q-icon v-if="search === ''" name="search" />
              <q-icon
                v-else
                name="clear"
                class="cursor-pointer"
                @click="search = ''"
              />
            </template>
          </q-input>
          <div v-if="search === ''" class="row text-center q-mt-md q-mb-md">
            <q-btn v-for="(item, index) in suggestedQs"
              square size="12px"
              color="primary" 
              class="q-ml-md text-caption text-weight-medium cursor-pointer" 
              text-color="white"
              no-caps
              @click.stop="searchSuggestion(item)"
            >
              {{ item }}
            </q-btn>
          </div>
          <div
            v-if="search !== '' && search !== lastSearchQuery && !loading && filteredOpps.length == 0"
            class="text-center q-mt-md"
            style="width: 100%;"
          >
            <p class="text-center">Press enter for AI search...</p>
          </div>
          <div
            v-if="!loading && filteredOpps.length == 0"
            class="text-center q-mt-md"
            style="width: 100%;"
          >
            <p class="text-center">Search to apply filters...</p>
          </div>
          <BillList :bills="filteredOpps" :loading="loading" :searchQuery="searchQuery"/>
        </div>
      </div>
    </q-page>
    </q-page-container>
  </q-layout>

  <q-dialog v-model="showSignIn">
    <sign-in-comp @signinComplete="signinComplete" @goToReg="goToRegistration" :linkToReg="false"></sign-in-comp>
  </q-dialog>

  <q-dialog v-model="showSignUp">
    <register-comp role="legislation" @registrationComplete="handleRegistrationComplete" @goToSignIn="goToSignIn" :linkToSignIn="false"></register-comp>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed, provide } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { fetchUser, goHome } from "@/api/userApi";
import axios from "axios";
import BillList from "@/components/BillList.vue";
import {
  formatFilters,
  extractUniqueAttributesAndConvertToList,
  filterOpportunitiesBySelections,
} from "@/helpers/search.js";
import Fuse from "fuse.js"; // Assuming you're using Fuse.js directly
import { useQuasar } from 'quasar'

export default {
  name: "BillSearch",
  components: {
    BillList,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const $q = useQuasar()

    const lastSearchQuery = ref("");

    const loading = ref(false);
    const leftDrawerOpen = ref(false);
    const search = ref("");
    const searchQuery = ref("");
    provide('searchQuery', searchQuery);

    const companyName = ref("");
    provide('companyName', companyName);

    const showAdvanced = ref(false);
    const showDateOptions = ref(false);
    const byWebsite = ref("");
    const byDate = ref("Any time");
    const error = ref("");

    const coreTech = ref("");
    const productDescription = ref("");

    const suggestedQs = ref([
      'Carbon border adjustment',
      'State Medicaid spending',
      'Permitting reform',
      'Oil and gas subsidies',
      'State Dept budget',
    ]);

    function onClear() {
      companyName.value = "";
      coreTech.value = "";
      productDescription.value = "";
      byWebsite.value = "";
      byDate.value = "Any time";
    }

    function changeDate(option) {
      byDate.value = option;
      showDateOptions.value = false;
    }

    function toggleLeftDrawer() {
      leftDrawerOpen.value = !leftDrawerOpen.value;
    }

    function searchSuggestion(suggested_query) {
      search.value = suggested_query;
      fetchSearchResults();
    }

    const isActive = (routeName) => {
      return route.name === routeName;
    };

    const bills = ref([]);

    // Policy areas often discussed in legislation, example values:
    const policy_areas = ref([]);

    // Common committees in both chambers of Congress:
    const committees = ref([]);

    // Chamber of Congress, already specified as House and Senate:
    const chambers = ref([]);

    // Possible statuses of legislation:
    const status = ref([
        "Introduced",
        "Committee Review",
        "House Debate",
        "Senate Debate",
        "Passed House",
        "Passed Senate",
        "To President",
        "Became Law",
        "Vetoed by President"
    ]);

    const showSignIn = ref(false);
    const showSignUp = ref(false);

    const signedIn = ref(false);
    

    const checkLoggedIn = () => {
      return store.dispatch("checkToken")
        .then(signedIn => {
          // console.log("Signed in?", signedIn);
          return signedIn;  // Return the signed in status for further processing
        })
        .catch(error => {
          console.error("Error checking token:", error);
          return false;  // Handle the error case by returning false
        });
    };

    const clearFilters = () => {
      policy_areas.value.forEach(area => area.selected = false);
      chambers.value.forEach(chamber => chamber.selected = false);
      committees.value.forEach(committee => committee.selected = false);
    };

    onMounted(async () => {
      signedIn.value = await checkLoggedIn();
      try {
        loading.value = true;
        const response = await axios.get("api/v1/search-bills/");
        bills.value = response.data.bills;
        const filters = response.data.filters;

        await formatFilters(
          filters.policy_areas,
          policy_areas
        );
        await formatFilters(
          filters.chambers,
          chambers
        );
        await formatFilters(
          filters.committees,
          committees
        );

        // Now transform the set of keys into the desired list format
        loading.value = false;
      } catch (error) {
        console.error("Error fetching opportunities:", error);
        loading.value = false;
      }
    });

    const filteredOpps = computed(() => {
      // Setup for selections)
      const selections = [
        { type: "policy_area", items: policy_areas.value },
        { type: "chamber", items: chambers.value },
        { type: "committees", items: committees.value },
        // Additional selections...
      ];
      // console.log("Selections:", selections);
      let filtered = filterOpportunitiesBySelections(bills.value, selections);

      return filtered;
    });

    const filtersPresent = computed(() => {
      return policy_areas.value.some(area => area.selected) ||
        chambers.value.some(chamber => chamber.selected) ||
        committees.value.some(committee => committee.selected);
    });

    const fetchSearchResults = async () => {
      try {
        loading.value = true;
        searchQuery.value = search.value;
        
        const selectedPolicies = policy_areas.value.filter(entry => entry.selected).map(entry => entry.text); 
        const selectedChambers = chambers.value.filter(entry => entry.selected).map(entry => entry.text); 
        // const selectedCommittees = committees.value.filter(entry => entry.selected).map(entry => entry.text); 
        
        // console.log("POLICY AREA FILTERS:", selectedPolicies);
        // console.log("COMMITTEE FILTERS:", selectedCommittees);
        // console.log("CHAMBER FILTERS:", selectedChambers);
        // const params = new URLSearchParams({
        //   query: search.value,
        // });
        const params = new URLSearchParams({
          query: search.value,
          policies: selectedPolicies.join(','),  // Join selected policies with a comma
          // committees: selectedCommittees.join(','),  // Join selected committees with a comma
          chambers: selectedChambers.join(','),  // Join selected chambers with a comma
        });
        const response = await axios.get(`/api/v1/search-bills/?${params}`);
        bills.value = response.data.bills;
        loading.value = false;
        lastSearchQuery.value = search.value;
      } catch (error) {
        console.error("There was an error fetching the data:", error);
        error.value = error; // Store the error in state, if any
      }
    };

    function handleRegistrationComplete() {
      signedIn.value = checkLoggedIn();
      // console.log("Registration complete");
      showSignUp.value = false;
    }

    function signinComplete() {
      signedIn.value = checkLoggedIn();
      // console.log("Signin complete:", signedIn.value);
      showSignIn.value = false;
      $q.notify('Login successful!')
    }

    function goToRegistration() {
      showSignIn.value = false;
      showSignUp.value = true;
    }

    function goToSignIn() {
      showSignUp.value = false;
      showSignIn.value = true;
    }

    return {
      router,
      loading,
      lastSearchQuery,
      signedIn,
      checkLoggedIn,
      clearFilters,
      filtersPresent,
      leftDrawerOpen,
      search,
      searchQuery,
      showAdvanced,
      showDateOptions,
      companyName,
      coreTech,
      productDescription,
      byWebsite,
      byDate,
      filteredOpps,
      suggestedQs,
      fetchSearchResults,
      searchSuggestion,
      policy_areas,
      chambers,
      committees,
      status,
      onClear,
      changeDate,
      toggleLeftDrawer,
      showSignIn,
      showSignUp,
      handleRegistrationComplete,
      signinComplete,
      goToRegistration,
      goToSignIn,
      isActive,
    };
  },
};
</script>
<style scoped>
.clickable-title {
  transition: color 0.1s, font-weight 0.1s;
}

.clickable-title:hover {
  color: rgba(0, 150, 250); /* This is an example color, change it as needed */
}
</style>