<template>
  <div>
    <pdf-viewer :src="pdfUrl" v-if="pdfUrl"></pdf-viewer>
    <div
      v-if="!loading && sections"
      class="q-pa-md"
      style="width: 100%; height: 100vh"
    >
      <q-carousel
        v-model="slide"
        @update:model-value="onSlideChange"
        arrows
        control-color="accent"
        :navigation="displayedSections.length > 1"
        height="100%"
        class="bg-transparent text-black items-start"
      >
        <q-carousel-slide
          v-for="(section, index) in displayedSections"
          :key="index"
          :name="section.id"
          class="column no-wrap flex-center justify-between"
          style="overflow-y: auto"
        >
          <dynamic-table
            v-if="section.table"
            :ref="section.id"
            :questions="section.questions"
            :section_name="section.name"
            :section_description="section.description"
            :formAnswers="answers"
            :draft_doc_id="this.draftDocId"
            :app_id="this.appId"
            :doc_name="this.document.display_name"
            :final_section="index == displayedSections.length - 1"
            :docs_uploaded="this.docs_uploaded"
            @contentUpdate="contentUpdate"
            :section_id="section.id"
          />
          <dynamic-form
            v-else
            :ref="section.id"
            :questions="section.questions"
            :section_name="section.name"
            :formAnswers="answers"
            :draft_doc_id="this.draftDocId"
            :app_id="this.appId"
            :doc_name="this.document.display_name"
            :final_section="index == displayedSections.length - 1"
            :docs_uploaded="this.docs_uploaded"
            @contentUpdate="contentUpdate"
            :section_id="section.id"
          />

          <div class="row justify-end" style="height: 50px"></div>
        </q-carousel-slide>
      </q-carousel>
    </div>
    <div v-else class="flex flex-center full-height" style="height: 100%">
      <q-spinner color="primary" :size="80"></q-spinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import jsonLogic from "json-logic-js";
import { goHome } from "@/api/userApi";

export default {
  name: "Document",
  data() {
    return {
      slide: null,
      document: null,
      sections: null,
      answers: null,
      draftDocId: null,
      appId: null,
      currentSectionIndex: 0,
      allAnswers: [],
      pdfUrl: null,
      loading: false,
      docs_uploaded: false,
    };
  },
  props: {
    id: String, // or String, depending on what `id` represents
    sectionId: {
      type: String,
      default: "", // or some other default value
    },
    // other props
  },
  computed: {
    displayedSections() {
      return this.sections.filter((section) =>
        this.checkSectionDisplay(section.id)
      );
    },
  },
  async created() {
    // console.log("CREATED DOCUMENT");
    if (this.sectionId) {
      this.currentSectionIndex = parseInt(this.sectionId, 10); // Convert to Number if necessary
      // Give assistant access to section
    }
    await this.getDocumentByID();
    this.$store.commit("setDocID", this.id);
  },
  methods: {
    onSlideChange(newValue) {
      this.$nextTick(() => {
        // Ensure that this.$refs[newValue] exists and has at least one component
        if (this.$refs[newValue] && this.$refs[newValue].length) {
          let component = this.$refs[newValue][0];
          component.performSave();
        } else {
        }
      });
    },
    getCurrentSectionId() {
      // Ensure that sections and currentSectionIndex are valid
      if (
        this.sections &&
        this.sections.length > 0 &&
        this.currentSectionIndex < this.sections.length
      ) {
        return this.sections[this.currentSectionIndex].id;
      }
      return null;
    },
    async getDocumentByID() {
      try {
        const documentId = this.$route.params.id; // Get the ID from the URL
        const response = await axios.get(`api/v1/get-draft-document/`, {
          params: {
            document_id: documentId,
          },
        });
        this.answers = response.data.answers;
        this.document = response.data;
        this.draftDocId = this.document.id;
        this.appId = this.document.app_id;

        this.docs_uploaded =
          "docs_uploaded" in response.data &&
          response.data.docs_uploaded === true;

        // console.log("DOCUMENT RETURNED:", response.data);

        // After the document is loaded, load each section
        if (this.document.sections && this.document.sections.length > 0) {
          this.sections = this.document.sections;
          this.$emit("update:sectionId", this.sections[0].id); // Emit an event when toggled
          this.slide = this.sections[0].id;
        }
        // console.log("SECTIONS FROM DOC CREATED:", this.sections);
      } catch (error) {
        console.error("Error fetching document:", error);
        goHome(this.$router);
      }
    },
    contentUpdate(page_content) {
      this.$emit("contentUpdate", page_content);
    },
    checkSectionDisplay(id) {
      // Find the section with the specified id
      const section = this.sections.find((section) => section.id === id);

      // Check if the section is found and has a 'display' property
      if (section && "display" in section) {
        let result = jsonLogic.apply(section.display, this.answers);
        // console.log("\n\nDISPLAY LOGIC:", section.display);
        // console.log("ANSWERS:", this.answers);
        // console.log("SECTION DISPLAY RESULT:", section.name, section.id, result);
        return result !== null ? result : false;
      }
      return false; // Return false if the section doesn't exist or has no 'display' property
    },
  },
};
</script>

<style scoped>
.section-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Pushes all content to the top of the container */
  padding: 20px; /* Optional padding */
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.middle-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distributes space between and around content items */
  min-height: 100vh; /* Ensures container takes up at least full viewport height */
  box-sizing: border-box;
  width: 100%;
}

.section-details {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically in the container */
  align-items: center; /* Centers horizontally in the container */
}

.document-id {
  text-align: center;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Full viewport height minus any headers or footers if they exist */
  height: 100vh;
  /* Or if it should be the full height of a parent container, you could use 100% instead */
  /* height: 100%; */
  width: 100%;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 125px;
  height: 125px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
