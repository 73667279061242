<template>
  <div v-if="!loading && appData" class="app-container">
    <h1 v-if="appData">{{ this.appData.name }}</h1>
    <div class="header-row">
      <score-card :priority="true" :flags="[]" :answers="{}" />
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 1.2em;
      "
    >
      <span><b>Application Status:</b> In progress</span>
      <button class="button" @click="downloadApp()">
        Download Application
      </button>
    </div>
    <br />
    <h2>Forms for you to fill out:</h2>
    <div class="documents-row" v-if="appData">
      <div v-for="document in appData.documents" :key="document.id">
        <div class="card">
          <h2 @click="navigateToDocument(document.doc_id)">
            {{ document.display_name }}
          </h2>

          <!-- Action Bar -->
          <div class="action-bar">
            <!-- Icons for previewing and downloading, replace with your icon components -->
            <font-awesome-icon
              icon="fas fa-download"
              class="icon"
              @click="downloadDocument(document.doc_id)"
            />
            <font-awesome-icon
              icon="fas fa-magnifying-glass"
              class="icon"
              @click="previewDocument(document.doc_id)"
            />
            <font-awesome-icon
              icon="fas fa-arrow-right"
              class="icon"
              @click="navigateToDocument(document.doc_id)"
            />
            <font-awesome-icon
              v-if="document.complete"
              icon="fas fa-check"
              class="icon"
              @click="navigateToDocument(document.doc_id)"
            />
            <!-- <span class="spinner" v-if="isLoading"></span>  -->
          </div>
        </div>
      </div>
    </div>
    <br />
    <h2>Information you need to collect:</h2>
    <div class="documents-row" v-if="appData">
      <div v-for="document in appData.upload_docs" :key="document.id">
        <div class="card">
          <h2 @click="navigateToDocument(document.doc_id)">
            {{ document.display_name }}
          </h2>
          <user-upload
            :question="document.serialized.sections[0].questions[0]"
            :ref="`question-${document.id}`"
            @answerUpdate="document.complete"
            style="height: 90%"
          />
          <!-- Action Bar -->
          <div class="upload-action-bar">
            <!-- Icons for previewing and downloading, replace with your icon components -->
            <font-awesome-icon
              icon="fas fa-download"
              class="icon"
              @click="downloadDocument(document.doc_id)"
            />
            <font-awesome-icon
              icon="fas fa-magnifying-glass"
              class="icon"
              @click="previewDocument(document.doc_id)"
            />
            <font-awesome-icon
              v-if="document.complete"
              icon="fas fa-check"
              class="icon"
              @click="navigateToDocument(document.doc_id)"
            />
            <!-- <span class="spinner" v-if="isLoading"></span>  -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="spinner-container">
    <div class="spinner"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GrantApp",
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      appData: null,
      isLoading: true,
    };
  },
  created() {
    this.fetchGrantApp();
  },
  methods: {
    async fetchGrantApp() {
      try {
        const response = await axios.get("api/v1/get-app", {
          params: { id: this.id },
        });
        this.appData = response.data;
        // console.log(this.id);
        // console.log(this.appData);
      } catch (error) {
        console.error("There was an error fetching the grant app data:", error);
      }
    },
    navigateToDocument(id) {
      // console.log("NAVIGATING TO DOCUMENT:", id);
      this.$router.push({ name: "Document", params: { id: id } });
    },
    downloadDocument(id) {
      // console.log("DOWNLOADING DOCUMENT:", id);
      this.isLoading = true; // Show the spinner

      try {
        const url = `api/v1/get-pdf/?doc_draft_id=${id}`;

        axios
          .get(url, {
            responseType: "blob", // Important for handling the PDF file
          })
          .then((response) => {
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a Blob URL
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Create a temporary link element
            const downloadLink = document.createElement("a");
            downloadLink.href = pdfUrl;
            downloadLink.setAttribute("download", "document.pdf"); // Assign a file name for the download

            // Append to the document and trigger a click
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Remove the link after triggering download
            document.body.removeChild(downloadLink);

            this.loading = false; // Hide the spinner after processing
          })
          .catch((error) => {
            this.isLoading = false; // Hide the spinner in case of an error
            console.error(
              "Error fetching PDF:",
              error.response ? error.response.data : error.message
            );
          });
      } catch (error) {
        this.isLoading = false;
        console.error("Error in form submission or PDF generation:", error);
      }
    },
    downloadApp(id) {
      // console.log("DOWNLAODING APP:", this.appData.name);
      this.isLoading = true; // Show the spinner

      try {
        const url = `api/v1/download-app/?app_id=${this.appData.id}`;

        axios
          .get(url, {
            responseType: "blob", // Important for handling the PDF file
          })
          .then((response) => {
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a Blob URL
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Create a temporary link element
            const downloadLink = document.createElement("a");
            downloadLink.href = pdfUrl;
            downloadLink.setAttribute("download", "REAP APPLICATION.zip"); // Assign a file name for the download

            // Append to the document and trigger a click
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Remove the link after triggering download
            document.body.removeChild(downloadLink);

            this.loading = false; // Hide the spinner after processing
          })
          .catch((error) => {
            this.isLoading = false; // Hide the spinner in case of an error
            console.error(
              "Error fetching PDF:",
              error.response ? error.response.data : error.message
            );
          });
      } catch (error) {
        this.isLoading = false;
        console.error("Error in form submission or PDF generation:", error);
      }
    },
    previewDocument(id) {
      // console.log("PREVIEWING DOCUMENT:", id);
      // console.log(this.appData.documents);
    },
  },
};
</script>

<style scoped>
.header-row {
  max-height: 220px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 30px; /* Space between cards */
  margin-bottom: 20px;
}
.app-container {
  padding: 20px;
}

.documents-row {
  display: flex;
  flex-direction: row; /* Keeps the horizontal layout */
  flex-wrap: wrap; /* Allows wrapping to the next line */
  gap: 30px; /* Space between cards */
  overflow-x: auto; /* This might not be necessary anymore */
}

.card {
  width: 200px;
  height: 240px; /* 1.2:1 ratio */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: solid;
  border-color: var(--accent);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; /* To position the action bar */
}

.card:hover {
  background-color: var(--accent);
  color: white;
  cursor: pointer;
  /* Ensure the text color is white on hover */
}

.card:hover .icon {
  color: white; /* Change icon color when card is hovered */
}

.action-bar {
  position: absolute;
  left: 20px;
  bottom: 45px;
}

.upload-action-bar {
  position: absolute;
  left: 20px;
  bottom: 10px;
}

.completion-bar {
  height: 20px; /* Adjust as needed */
  background-color: #eee; /* Light grey background */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures inner bar stays within the container */
}

.completion-bar-inner {
  height: 100%;
  background-color: rgb(2, 104, 172); /* Adjust color as needed */
  width: 0%; /* Initial width */
  border-radius: 10px; /* Rounded corners */
}

.icon {
  cursor: pointer; /* Change cursor on hover over icons */
  margin-right: 20px; /* Space between icons */
  color: var(--accent);
}

.icon:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease;
  color: white;
  cursor: pointer;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid rgb(1, 150, 250); /* Blue */
  border-right: 4px solid rgb(1, 150, 250); /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
  margin-left: 10px; /* Adjust as needed */
  /* position: absolute; Position the spinner absolutely within its container */
  left: -40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 1.5em;
  font-family: var(--title-2);
  margin-bottom: 20px;
}

h2 {
  font-size: 1.2em;
  font-family: var(--title-2);
  /* color: rgb(2, 104, 172); */
  margin-bottom: 10px;
  margin-top: 0px;
}

p {
  font-size: 0.9em;
  font-family: var(--title-2);
}
</style>
