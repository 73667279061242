<template>
    <div>
      <q-btn 
      flat
      dense 
      :icon="mdiTable" 
      />

      <q-menu v-model="showTableMenu" auto-close>
        <div class="menubar">
          <!-- Add other buttons for editor commands here -->
          <q-btn 
            flat
            dense 
            :icon="mdiTable" 
            class="menubar__button"
            label = "Insert Table"
            @click="insertTable"
            />
          <button class="menubar__button" @click="deleteTable" v-if="isActive('table')">
            <font-awesome-icon icon="fas fa-trash-alt" />
          </button>
          <!-- Add more table-related buttons here -->
        </div>
      </q-menu>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useEditor, EditorContent } from "@tiptap/vue-3";
  import StarterKit from "@tiptap/starter-kit";

  import { QBtn, QMenu } from 'quasar';
  import { mdiTable } from '@mdi/js';
  
  export default {
    components: {
      QBtn,
      QMenu,
      EditorContent,
      mdiTable
    },
    props: {
    editor: {
        type: Object,
        required: true
        }
    },
    setup(props) {
            const showTableMenu = ref(false);

            const insertTable = () => {
                // Ensure you access editor through props and check its existence
                if (props.editor && props.editor.value) {
                props.editor.value.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
                }
            };

            const deleteTable = () => {
                // Ensure you access editor through props and check its existence
                if (props.editor && props.editor.value) {
                props.editor.value.chain().focus().deleteTable().run();
                }
            };

            const isActive = (type) => {
                // Ensure you access editor through props and check its existence
                return props.editor && props.editor.value && props.editor.value.isActive(type);
            };

            // Expose the functions and reactive data to the template
            return { insertTable, deleteTable, isActive, showTableMenu, mdiTable };
            },
            beforeUnmount() {
            if (props.editor && props.editor.value) {
                props.editor.value.destroy();
            }
        }
  };
  </script>
  
  <style scoped>
  </style>