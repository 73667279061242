<template>
  <div v-if="grantData" class="grant-container">
    <div v-if="showPopup" class="popup-wrapper">
      <PopUp :text="popupText" @response="handlePopupResponse"/>
    </div>
    <div class="row justify-between">
      <h3><span class="text-weight-bold">Grant: </span> {{ grantData.name }}</h3>
      <q-btn v-if="grantData.changed" @click="saveGrant()">Save Grant</q-btn>
      <q-btn @click="exportGrant(grantData.id)" :loading="exportLoading">Export to JSON</q-btn>
    </div>
    <q-input v-model="grantData.name" label="Grant Title" @change="updateGrantUI()"/>
    <br>
    <q-input v-model="grantData.agency" label="Agency Name" @change="updateGrantUI()"/>
    <br>
    <q-input
      v-model="grantData.description"
      label="Grant Description"
      filled
      type="textarea"
      @change="updateGrantUI()"
    />
    <br>
    <q-btn v-if="checkDocumentOrder()" @click="reorderDocuments()">Update Document Order</q-btn>
    <br>
    <div
      v-for="(document, dIndex) in grantData.documents"
      :key="dIndex"
      class="justify-start"
    >
      <div class="row items-center justify-between" style="width: 100%; height: 60px;">
        <span>
          <q-btn @click="navigateToDocument(document.id)">
            <span class="text-weight-bold">Document: </span> {{ document.display_name }}
          </q-btn>
          ({{ document.name }})
        </span>
        <div>
          <q-btn v-if="document.id" @click.stop="onDeleteClicked(deleteDocument, document)">
            <q-icon name="delete"/>
          </q-btn>
          <q-btn v-if="dIndex > 0" @click.stop="moveDocument(document.id, -1)">
            <q-icon name="north"/>
          </q-btn>
          <q-btn v-if="dIndex < grantData.documents.length - 1" @click.stop="moveDocument(document.id, 1)">
            <q-icon name="south"/>
          </q-btn>
        </div>
      </div>
    </div>
    <q-btn v-if="!addingNewDocument && grantData.id" @click="addNewDocument()">Add Document</q-btn>
    <q-input v-if="addingNewDocument" v-model="newDocName" label="Document Title" />
    <q-btn v-if="addingNewDocument && newDocName !== ''" @click.stop="createDocument(newDocName)">
      Create Document
    </q-btn>
    <br>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GrantEdit",
  props: {
    grantData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      serverData: null,
      addingNewDocument: false,
      showPopup: false,
      popupText: 'Are you sure you want to delete this?',
      pendingAction: null,
      exportLoading: false,
      newDocName: "",
    };
  },
  created() {},
  mounted() {
    console.log("GRANT DATA IN EDITOR:", this.grantData);
    this.serverData = JSON.parse(JSON.stringify(this.grantData));
    // Assuming docData is fetched or set here
    this.grantData.changed = false;
  },
  methods: {
    onDeleteClicked(action, ...params) {
      this.showPopup = true;
      this.pendingAction = { action, params };
    },
    handlePopupResponse(response) {
      this.showPopup = false;
      if (response === 'yes' && this.pendingAction) {
        this.pendingAction.action(...this.pendingAction.params);
      }
      this.pendingAction = null;
    },

    addNewDocument() {
      // Update state to reflect a new document is being added
      this.addingNewDocument = true;
    },

    updateGrantUI() {
      this.grantData.changed = this.grantData.name !== this.serverData.name || 
                            this.grantData.description !== this.serverData.description ||
                            this.grantData.agency !== this.serverData.agency;
      // console.log("DOC CHANGED:", this.docData);
    },

    saveGrant() {
      // Save Grant
      console.log("Saving grant:",this.grantData.name);

      try {
          const url = "api/v1/update-grant/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"grant": this.grantData}, { headers })
              .then(response => {
                  this.serverData.name = this.grantData.name;
                  this.serverData.description = this.grantData.description;
                  this.serverData.agency = this.grantData.agency;
                  this.grantData.changed = false;
                  console.log("Successfully updated grant")
              })
              .catch(error => {
                  console.error(
                      "Error updating grant:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating grant ${this.grantData.name}:`,
              error
          );
      }
    },

    createDocument(documentName) {
      try {
        if (!documentName || documentName === "") {
          return;
        }
        const url = "api/v1/create-doc/"; // Adjusted endpoint
        const headers = {
          ...axios.defaults.headers.common,
          "Content-Type": "application/json",
        };
        const newDocument = {
          name: documentName,
          display_name: 'Untitled Document',
          order: this.grantData.documents.length
        };
        axios.post(url, { "doc": newDocument, "grantId": this.grantData.id }, { headers })
          .then(response => {
            let newDoc = JSON.parse(JSON.stringify(response.data.serialized))
            newDoc.changed = false;
            this.grantData.documents.push(newDoc);
            this.serverData.documents.push(newDoc);
            this.addingNewDocument = false;
            this.newDocName = "";
            console.log("Successfully created document");
          })
          .catch(error => {
            console.error("Error creating document:", error.response ? error.response.data : error.message);
          });
      } catch (error) {
        console.error("Error making create-document call", error);
      }
    },

    
    deleteDocument(document) {
      console.log("DELETING DOCUMENT:", document.name);
      try {
        const url = "api/v1/delete-doc/";
        const headers = {
          ...axios.defaults.headers.common,
          "Content-Type": "application/json",
        };
        axios.post(url, { "doc_id": document.id }, { headers })
          .then(response => {
            console.log("Successfully deleted document");
            let index = this.grantData.documents.findIndex(doc => doc.id === document.id);
            if (index !== -1) {
              this.grantData.documents.splice(index, 1);
              this.serverData.documents.splice(index, 1);
            }
          })
          .catch(error => {
            console.error("Error deleting document:", error.response ? error.response.data : error.message);
          });
      } catch (error) {
        console.error(`Error deleting document ${documentName}:`, error);
      }
    },


    moveDocument(docId, shift = 0) {
      let index = this.grantData.documents.findIndex(doc => doc.id === docId);
      if (index === -1 || index + shift < 0 || index + shift >= this.grantData.documents.length) {
        console.log("Invalid document or shift out of bounds");
        return;
      }
      const [document] = this.grantData.documents.splice(index, 1);
      this.grantData.documents.splice(index + shift, 0, document);
      console.log("DOCUMENTS AFTER MOVE:", this.grantData.documents);
    },


    reorderDocuments() {
      console.log("Reordering documents");
      let documents = this.grantData.documents;
      // Assuming each document has an 'order' property to update
      const documentOrderMap = documents.reduce((acc, doc, index) => {
        acc[doc.id] = index;
        return acc;
      }, {});
      console.log("DOCUMENT ORDER MAP:", documentOrderMap);
      try {
        const url = "api/v1/update-document-order/";
        const headers = {
          ...axios.defaults.headers.common,
          "Content-Type": "application/json",
        };

        axios.post(url, { "grantId": this.grantData.id, "orderMap": documentOrderMap }, { headers })
          .then(response => {
            console.log("Successfully reordered grant documents");
            // Update the order values of the documents locally
            documents.forEach((doc, index) => {
              doc.order = index;
            });
          })
          .catch(error => {
            console.error("Error reordering grant documents:", error.response ? error.response.data : error.message);
          });
      } catch (error) {
        console.error(`Error reordering documents for grant ${this.grantData.id}:`, error);
      }
    },

    
    checkDocumentOrder() {
      // console.log("CHECKING SECTION ORDER", this.docData.sections);
      for (let i = 0; i < this.grantData.documents.length; i++) {
          if (this.grantData.documents[i].order !== i && this.grantData.documents[i].id !== null) {
              // Mismatch found between section order and index
              return true;
          }
      }
      // No mismatches found, section orders are consistent with their indices
      return false;
    },
    
    exportGrant() {
      console.log("Saving grant:", this.grantData.name);
      this.exportLoading = true;

      try {
          const url = `api/v1/export-grant/?grant_id=${this.grantData.id}`;
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.get(url, {responseType: "blob",})
              .then(response => {
                  console.log("Successfully exported doc to JSON");
                  this.exportLoading = false;
              })
              .catch(error => { 
                  console.error(
                      "Error saving doc to JSON:",
                      error.response ? error.response.data : error.message
                  );
                  this.exportLoading = false;
              });
      } catch (error) {
          console.error(
              `Error saving document ${this.docData.name} to JSON:`,
              error
          );
      }
    },
    navigateToDocument(docId) {
      this.$router.push(`/doc-editor/${docId}`);
    }
  },
};
</script>

<style>

.json-editor {
  display: flex;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.header button {
  max-height: 20px;
}

.popup-wrapper {
  position: fixed; /* Fixed position relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for a semi-transparent background */
  z-index: 1000; /* Ensure it's on top */
}

.popup {
  /* Your existing popup styles */
  /* Add styles for padding, background, etc., as necessary */
  max-width: 90%; /* Optional: to ensure it doesn't get too wide on large screens */
  box-sizing: border-box; /* To include padding and borders in the width */
  z-index: 1001; /* Ensure it's above the semi-transparent background */
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically in the container */
  align-items: right; /* Centers horizontally in the container */
  /* text-align: center;      Ensures text within the children is centered */
  /* max-height: 100vh;           Takes up the full viewport height */
  padding: 0px; /* Optional padding */
  box-sizing: border-box;
  width: 100%;
}

.grant-container {
  padding-left: 0;
}

.document-container {
  padding-left: 20px; /* Indent documents */
}

.section-container {
  padding-left: 40px; /* Further indent sections */
}

.question-container {
  padding-left: 60px; /* Further indent questions */
}

.input-container {
  padding-left: 80px; /* Further indent inputs */
}

.grant-input, .document-input, .section-input, .question-input, .input-input {
  display: block; /* Make inputs take the full width of the container */
  margin-bottom: 10px; /* Space out inputs */
  width: 500px;
}

.dropdown-icon {
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.is-open {
  transform: rotate(90deg); /* Rotates the icon to point downwards */
}

h2, h3, h4 {
  cursor: pointer;
}

.arrow {
    display: inline-block;
}
.up {
  transform: rotate(-90deg);
}
.down {
  transform: rotate(90deg);
}

</style>
