<template>
  <div class="assistant-container bg-grey-1 bordered q-mt-none q-ml-none" @mouseenter="isMouseOver = true"
    @mouseleave="isMouseOver = false">
    <div class="column justify-between full-height">
      <div class="col-1 q-pt-md">
        <h4 class="col text-center">Aidy Assistant</h4>
        <q-separator inset />
      </div>

      <div class="col scroll q-mt-lg chat-container">
        <div class="q-pa-md row justify-center" style="padding: 11px 0px;" ref="scrollContainer">
          <div style="width: 100%; max-width: 600px">
            <div>
              <div class="message bot-message"
                v-html="renderMarkdown('Hi there, what questions about this bill can I answer for you?')"></div>
            </div>
            <div v-for="(interaction, index) in interactions" :key="index">
              <div v-html="renderMarkdown(interaction.text)"
                :class="{ 'user-message': interaction.from === 'user', 'bot-message': interaction.from !== 'user' }">
              </div>
              <div v-if="interaction.sources !== undefined" class="q-pl-sm q-gutter-sm">
                <q-btn v-for="source in interaction.sources" round size="sm" :label="source.index + 1" color="accent"
                  @click="$emit('highlight', source.content)">
                  <q-tooltip>
                    See source
                  </q-tooltip>
                </q-btn>
              </div>
            </div>
            <div v-if="isTyping" style="padding: 10px;">
              <q-spinner-dots size="2rem" />
            </div>
          </div>
        </div>
      </div>

      <div class="input-container">
        <q-input square class="q-mx-md" v-model="inputText" filled type="text" autogrow placeholder="Ask here..."
          @keypress.enter="handleKeyPress" :class="{ 'accent-color': inputText }">
          <template v-slot:append>
            <q-btn icon="send" type="submit" :disabled="!inputText" @click="handleSubmit" round dense />
          </template>
        </q-input>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { renderMarkdown } from "@/helpers/helpers";
export default {
  name: "BillAssistant",
  data() {
    return {
      isOpen: true, // start with the sidebar open
      inputText: "", // The text entered in the input field
      isTyping: false,
      interactions: [],
      isScrollingUp: false,
      isMouseOver: false,
    };
  },
  props: {
    sidebarInitialState: {
      type: Boolean,
      default: true,
    },
    opp_id: {
      type: Number,
      default: null,
    },
  },
  methods: {
    renderMarkdown(markdownText) {
      return renderMarkdown(markdownText);
    },
    scrollToBottom() {
      if (this.$refs.scrollContainer && !this.isScrollingUp) {
        this.$refs.scrollContainer.scrollIntoView({ block: "end", inline: "nearest" })
      }
    },
    handleScroll(event) {
      if (this.isMouseOver && event.deltaY < 0) {
        this.isScrollingUp = true;
      }
    },
    handleSubmit(historyCount = 5) {
      this.isTyping = true;
      const userText = this.inputText;
      this.interactions.push({ text: userText, from: "user" });
      this.inputText = ""; // Clear the input field after submission

      const recentInteractions = this.interactions
        .slice(-historyCount)
        .map((interaction) => ({
          role: interaction.from === "user" ? "user" : "assistant",
          content: interaction.text,
        }));

      let vm = this; // Capture the Vue instance
      // Fetch data using POST method
      fetch(`${axios.defaults.baseURL}/api/state/chat-statebill/`, {
        //Be sure to update this for different environments
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: recentInteractions,
          opp_id: this.opp_id,
        }),
      })
        .then((response) => {
          const reader = response.body.getReader();
          let chunks = "";
          let firstChunkReceived = false;

          reader.read().then(function processChunk({ done, value }) {
            if (done) {
              vm.isTyping = false;
              return;
            }

            chunks += new TextDecoder().decode(value);

            // Check for complete JSON objects and process them
            while (chunks.indexOf("}") !== -1) {
              let chunkEnd = chunks.indexOf("}") + 1;
              let chunk = chunks.substring(0, chunkEnd);
              chunks = chunks.substring(chunkEnd);

              let data = JSON.parse(chunk);

              let contentText = ""

              // Hide the typing element when the first chunk is received
              if (!firstChunkReceived) {
                contentText = data.content.replace("<br>", " "); // Replace line breaks with spaces
                vm.interactions.push({ text: contentText, from: "bot" });
                firstChunkReceived = true;
                vm.isScrollingUp = false;
                vm.scrollToBottom();
              } else {
                if (data.hasOwnProperty('index')) {
                  if (!vm.interactions[vm.interactions.length - 1].hasOwnProperty('sources')) {
                    vm.interactions[vm.interactions.length - 1].sources = [];
                  }
                  vm.interactions[vm.interactions.length - 1].sources.push(data);
                } else {
                  contentText = data.content.replace("<br>", " "); // Replace line breaks with spaces
                  vm.interactions[vm.interactions.length - 1]["text"] += contentText;
                  vm.scrollToBottom();
                }
              }
            }

            return reader.read().then(processChunk);
          });
        })
        .catch((error) => {
          this.isTyping = false;
          console.error("Error:", error);
          this.interactions.push({
            text: "Sorry, an error occurred.",
            from: "bot",
          });
        });
    },
    handleKeyPress(event) {
      if (event.shiftKey) {
        return;
      }
      event.preventDefault();
      this.handleSubmit();
    },
  },
  created() {
    this.isOpen = this.sidebarInitialState;
  },
  mounted() {
    console.log(this.$refs.scrollContainer)
    this.$refs.scrollContainer.addEventListener('wheel', this.handleScroll);
  },
};
</script>

<style scoped>
.full-height {
  height: 100%;
}

.assistant-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Ensure container does not exceed parent */
}

.chat-container {
  flex-grow: 1;
  overflow-y: auto;
  /* Ensure chat messages are scrollable */
  max-height: calc(100% - 60px);
  /* Adjust height to leave space for input */
}

.input-container {
  padding: 10px;
  background-color: #fff;
  /* Background color for input container */
  width: 100%;
}

.user-message {
  text-align: right;
  margin-left: auto;
  padding: 8px;
  margin-bottom: 4px;
}

.bot-message {
  background-color: #E0E0E0;
  text-align: left;
  padding: 8px;
  margin-bottom: 4px;
}

.accent-color {
  color: var(--accent);
}

button:focus,
input:focus {
  outline: none;
}

.chat-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.chat-msg {
  margin: 5px 0px;
  width: 90%;
  padding: 10px;
  border-radius: 10px;
  font-family: var(--title2);
}

.chat-user {
  align-self: flex-end;
  text-align: right;
  background-color: var(--bg-grey-3);
  color: var(--text);
}

.chat-bot {
  align-self: flex-end;
  text-align: left;
  background-color: var(--accent);
  color: var(--text);
}

.chat-header {
  min-width: 500px;
}

.thin-line {
  border: 0;
  border-top: 1px solid rgb(128 128 128);
  width: 80%;
  margin: 10px auto;
}

p {
  margin: 0;
}
</style>