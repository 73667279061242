<template>
  <div class="body">
    <q-spinner
      v-if="isLoading"
      class="absolute-center"
      color="primary"
      size="100px"
    />
    <div v-if="!isLoading">
      <div class="header">
        <div class="top-bar">
          <h4 class="text-weight-medium">Dashboard</h4>
        </div>
        <q-separator color="grey-7" />
      </div>
      <q-separator color="grey-7" />
      <section v-for="org in user.orgs">
        <h5 class="text-weight-bold">{{ org.name }} - Applications</h5>
        <div class="text-blue-grey-9 text-weight-medium">
          Select an application to view its details.
        </div>
        <q-separator color="grey-5 q-mb-md" />
        <div class="row justify-start q-gutter-md">
          <q-card
            @click="goToApp(app.id)"
            class="card"
            v-for="app in userApps[org.id]"
            :key="app.id"
          >
            <img :src="generateRandomFilename('reap_image')" />

            <q-card-section class="q-pb-sm my-edit-section">
              <q-input
                v-model="app.project_name"
                @blur="saveAppName(app)"
                @keyup.enter="saveAppName(app)"
                @click.stop=""
                dense
                borderless
                class="text-h6 ellipsis q-mt-none q-mb-none"
              />
            </q-card-section>

            <q-card-section class="q-pt-none q-pb-none">
              <div class="text-subtitle2 text-grey-8">
                {{ app.opportunity_title ? app.opportunity_title : "Unknown Grant" }}
              </div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <div class="text-subtitle2 text-grey-8">{{ app.agency }}</div>
            </q-card-section>
            <div v-if="user.role !== 'vendor'" class="absolute-top-left">
              <q-btn
                size="15px"
                icon="delete_forever"
                color="black"
                flat
                round
                @click.stop="confirmDelete(app.id)"
              />
            </div>
          </q-card>

          <q-card
            @click="flipDialog(org.id)"
            style="opacity: 0.7"
            class="card"
            v-if="user.role !== 'vendor'"
          >
            <div v-if="createFor!==org.id">
              <img
                style="filter: blur(2px)"
                :src="generateRandomFilename('reap_image')"
              />
              <q-card-section class="q-pb-none q-pt-sm">
                <div class="text-h6">New Application</div>
              </q-card-section>

              <q-card-section class="q-pt-none q-pb-none">
                <div class="text-subtitle2 text-grey-8">Click to Create</div>
              </q-card-section>
            </div>
            <div v-else class="flex flex-center" style="height: 100%">
              <q-spinner color="primary" :size="80"></q-spinner>
            </div>
          </q-card>
        </div>
      </section>

      <!-- <section
        v-if="
          formSignups &&
          user.role !== 'vendor_coordinator' &&
          user.role !== 'vendor'
        "
        v-for="org in user.orgs"
      >
        <h5 class="text-weight-bold">{{ org.name }} - Completed Eligibility Forms</h5>
        <div class="text-blue-grey-9 text-weight-medium">
          Below are all the completed eligibility forms for your organization.
        </div>
        <q-separator color="grey-5 q-mb-md" />
        <div class="row justify-start q-gutter-md">
          <q-card
            class="my-card bg-white text-primary column justify-between no-wrap"
            v-for="formSignup in formSignups[org.id]"
            :key="formSignup.id"
            style="width: 250px; height: 150px"
          >
            <q-card-section class="row">
              <div
                class="text-body1 text-weight-medium ellipsis"
                style="word-wrap: break-word"
              >
                {{ formSignup.email ? formSignup.email : "Unknown Email" }}
              </div>
            </q-card-section>

            <q-card-actions class="row justify-between">
              <q-btn flat @click.stop="createAppInvite(formSignup.email)">
                Create Application
              </q-btn>
              <q-btn flat @click.stop="deleteFormSignup(formSignup.id)">
                <q-icon name="delete_forever" color="primary" size="32px" />
              </q-btn>
            </q-card-actions>
          </q-card>
        </div>
      </section> -->
      <q-dialog v-model="showDialog" persistent class="q-pa-md">
        <div class="q-pa-md" style="background: white; width: 400px">
          <div class="row q-mb-md text-h6">Create Application</div>
          <div class="row q-mb-md text-body2">
            Please! choose which program you'd like to create an application for
            and click "Create App".
          </div>
          <div class="row q-mb-md">
            <q-select
              v-model="selected_grant"
              :options="grant_programs"
              label="Grant Program:"
              color="primary"
              class="full-width"
            />
          </div>
          <div class="row q-mb-md">
            <q-input
              v-model="projectName"
              label="Project Name:"
              color="primary"
              class="full-width"
              autocomplete="off"
            />
          </div>
          <div class="row justify-end">
            <q-btn
              flat
              label="Cancel"
              color="primary"
              @click="flipDialog"
              class="q-mr-sm"
            />
            <q-btn color="accent" @click="createNewApp" label="Create App" />
          </div>
        </div>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import { inviteAs } from "@/api/userApi";
import axios from "axios";

export default {
  name: "Dashboard",
  setup(props, { emit }) {
    const user = ref(null);
    const userApps = ref([]);
    const formSignups = ref([]);
    const creatingApp = ref(false);
    const isLoading = ref(true);
    const router = useRouter();
    const store = useStore();
    const $q = useQuasar();
    const showDialog = ref(false);
    const selected_grant = ref({
      label: "Rural Energy for America Program",
      value: "reap",
    });
    const projectName = ref("");
    const createFor = ref(null);

    const grant_programs = ref([
      { label: "Rural Energy for America Program", value: "reap" },
    ]);

    const flipDialog = async (org_id) => {
      createFor.value = org_id;
      showDialog.value = !showDialog.value;
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get("/api/v1/user/");
        user.value = response.data;
        // console.log("User data:", user.value);
      } catch (error) {
        console.error("There was an error fetching the user data:", error);
        user.value = null;
      }
    };

    const getUserApps = async () => {
      try {
        const response = await axios.get("/api/v1/get-user-apps");
        console.log(response.data);
        userApps.value = response.data;
        console.log("userApps", userApps.value);
        // userApps.value = response.data.map((app) => ({
        //   id: app.id,
        //   project: app.org_name,
        //   grant_name: app.opportunity_title,
        //   name: app.project_name,
        //   description: app.description,
        //   agency: app.agency,
        // }));
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    const getFormSignups = async () => {
      try {
        const response = await axios.get("/api/v1/get-form-signups");
        formSignups.value = response.data;
      } catch (error) {
        console.error("Error fetching form signups:", error);
      }
    };

    const createNewApp = async () => {
      showDialog.value = false;
      if (creatingApp.value) return;
      try {
        console.log("Selected grant:", selected_grant.value.value);
        const response = await axios.get("/api/v1/create-app", {
          params: {
            grant_name: selected_grant.value.value,
            project_name: projectName.value,
            org_id : createFor.value,
          },
        });
        let appId = response.data.id;
        if (
          user.value.role === "vendor_coordinator" ||
          user.value.role === "vendor"
        ) {
          router.push(`/vendor-portal/${appId}`);
        } else {
          router.push(`/app/${appId}`);
        }
      } catch (error) {
        console.error("Error creating new app:", error);
      } finally {
        createFor.value = null;
      }
    };

    const createAppInvite = async (email, role = "applicant") => {
      // Start by creating a new app
      creatingApp.value = true; // Assuming you want to indicate the process is underway
      try {
        const createAppResponse = await axios.get("/api/v1/create-app");
        let newAppId = createAppResponse.data.id;

        // Once the app is created, proceed to invite the user to this new app
        // Assuming inviteuser() is a function you will implement or replace inviteAs()
        // and assuming it takes email, role, and appId as parameters
        const inviteResponse = await inviteAs(email, role, newAppId);

        // Handle the invite response if needed
        // console.log("Invite sent successfully:", inviteResponse.data);

        // Optionally, navigate to the app's page or perform other actions
        router.push(`/app/${newAppId}`);
      } catch (error) {
        console.error("Error in creating app or sending invite:", error);
      } finally {
        creatingApp.value = false; // Process is done
      }
    };

    const deleteFormSignup = async (id) => {
      try {
        // Using a GET request for deletion, with query parameters
        const response = await axios.get(`/api/v1/delete-form-signup`, {
          params: { id: id },
        });
        console.log("Form signup deleted successfully", response.data);
        // Get the forms again
        getFormSignups();
      } catch (error) {
        console.error("Error in deleting form signup:", error);
      }
    };

    const confirmDelete = async (appId) => {
      $q.dialog({
        title: "Confirm Delete",
        message:
          "Do you want to delete this application? This action cannot be undone, and all data will be lost.",
        ok: { color: "negative", label: "Delete" },
        cancel: { color: "grey", label: "Cancel" },
        persistent: true,
      }).onOk(async () => {
        try {
          isLoading.value = true;
          await axios.post(`/api/v1/delete-app/`, { app_id: appId });
          await getUserApps();
          isLoading.value = false;
        } catch (error) {
          console.error("Error deleting file:", error);
        }
      });
    };

    const generateRandomFilename = (baseName) => {
      const number = Math.floor(Math.random() * 4) + 1; // Generates a random number between 1 and 4
      return `/${baseName}_${number}.png`;
    };

    const goToApp = (appId) => {
      // console.log("ROLE", user.value.role);
      if (
        user.value.role === "vendor_coordinator" ||
        user.value.role === "vendor"
      ) {
        store.commit("setAppID", appId);
        router.push(`/vendor-portal/${appId}`);
        return;
      } else {
        store.commit("setAppID", appId);
        router.push(`/app/${appId}`);
      }
    };

    const truncateDisplayName = (displayName) => {
      const maxLength = 25;
      if (displayName.length > maxLength) {
        return displayName.substring(0, maxLength) + "...";
      }
      return displayName;
    };

    onMounted(async () => {
      isLoading.value = true;
      await fetchUser();
      if (user.value.role === "applicant") {
        router.push("/portal");
      }
      await getUserApps();
      await getFormSignups();
      isLoading.value = false;
      store.commit("setAppID", null);
      store.commit("setDocID", null);
      store.commit("setSectionID", null);
    });

    return {
      store,
      user,
      userApps,
      formSignups,
      isLoading,
      showDialog,
      creatingApp,
      grant_programs,
      createFor,
      selected_grant,
      projectName,
      flipDialog,
      createNewApp,
      createAppInvite,
      deleteFormSignup,
      confirmDelete,
      generateRandomFilename,
      goToApp,
      truncateDisplayName,
    };
  },
  methods: {
    async saveAppName(app) {
      try {
        const response = await axios.get(`api/v1/update-appname/`, {
          params: {
            id: app.id,
            name: app.name,
          },
        });
        // handle response...
      } catch (error) {
        console.error(error);
        // handle error...
      }
    },
  },
};
</script>

<style scoped>
section {
  margin-top: 30px;
}
.body {
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
  max-width: 1300px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items vertically */
  width: 100%;
  padding-top: 40px;
}

.top-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 20px;
}

.card {
  height: 310px; /* Adjust this based on your needs */
  width: 300px; /* Adjust this based on your needs */
}

.card:hover {
  box-shadow: 0 0 5px 2px var(--accent);
  cursor: pointer;
}

.my-edit-section .edit-input .q-field__control {
  min-height: 0;
}
.my-edit-section .edit-input .q-field__inner {
  min-height: 0;
}
/* Ensure the input text matches the div's styling */
.my-edit-section .edit-input .q-field__control {
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
