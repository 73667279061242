<template>
  <div class="file-upload-container">
    <div v-html="renderMarkdown(question.question_text)"></div>
    <q-file
      v-for="ii in repeats"
      :key="ii"
      :label="
        selectedFiles[ii] ? selectedFiles[ii] : 'Click here to upload a file'
      "
      @input="
        (event) => {
          handleFileUpload(event, ii);
        }
      "
      bottom-slots
    >
      <template v-if="selectedFiles[ii]" v-slot:before
        ><q-icon color="primary" name="check"></q-icon
      ></template>
      <template v-if="selectedFiles[ii]" v-slot:append>
        <q-icon
          color="primary"
          name="find_in_page"
          @click.stop.prevent="openPdfPreview(ii)"
          class="cursor-pointer"
          ><q-tooltip class="bg-grey-7">Preview File</q-tooltip></q-icon
        >
        <q-icon
          color="primary"
          name="download"
          @click.stop.prevent="handleDownload(ii)"
          class="cursor-pointer"
          ><q-tooltip class="bg-grey-7">Download File</q-tooltip></q-icon
        >
      </template>
    </q-file>
    <div v-if="question.repeatable" class="row justify-left">
      <q-btn
        flat
        color="accent"
        class="q-ma-sm q-mt-none bg-accent text-white"
        size="md"
        @click="addRepeat"
      >
        + Add Another
      </q-btn>
    </div>

    <div v-if="requiredFlag && question.required" class="required-flag-text">
      This question is required
    </div>
    <q-dialog v-model="showPdfPreviewModal" class="full-width">
      <q-card class="full-width">
        <q-card-section class="row items-center">
          <div
            v-if="iframeLoading"
            class="flex flex-center"
            style="width: 100%; height: 700px"
          >
            <q-spinner color="primary" size="80px" />
          </div>
          <iframe
            v-else
            ref="pdfPreviewIframe"
            width="100%"
            height="700px"
          ></iframe>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            label="Close"
            color="primary"
            @click="showPdfPreviewModal = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import { useQuasar } from "quasar";
import { renderMarkdown } from "@/helpers/helpers";
import { downloadAnswerFile, previewAnswerFile, getPdfPreviewUrl } from "@/api/fileApi";

export default {
  name: "UserUpload",
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      required: false,
      default: () => ({}), // Default to an empty object
    },
    draft_doc_id: {},
  },
  methods: {
    async predict() {},
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const requiredFlag = ref(false);
    const repeats = ref(1);
    const $q = useQuasar();

    const pdfPreviewIframe = ref(null); // This creates a reactive reference

    let showPdfPreviewModal = ref(false);
    let iframeLoading = ref(false);

    let selectedFiles = ref({});
    let inputName = ref("");
    onMounted(() => {
      inputName.value = props.question.inputs[0].name;

      selectedFiles.value = Object.keys(props.answers)
        .filter((key) => key.startsWith(inputName.value))
        .reduce((obj, key) => {
          obj[key] =
            props.answers[key] instanceof File
              ? props.answers[key].name
              : props.answers[key];
          return obj;
        }, {});
      repeats.value = Math.max(1, Object.keys(selectedFiles.value).length);

      // console.log("PRE IF SELECTED VALUES", selectedFiles.value);

      let tempSelectedFiles = { ...selectedFiles.value };
      // console.log("PRE IF SELECTED VALUES", tempSelectedFiles);

      if (props.question.repeatable) {
        tempSelectedFiles = Object.entries(tempSelectedFiles).reduce(
          (newObj, [key, value]) => {
            const newKey = key.split("_").pop();
            newObj[newKey] = value;
            return newObj;
          },
          {}
        );
      } else {
        const firstKey = Object.keys(tempSelectedFiles)[0];
        tempSelectedFiles = { 1: tempSelectedFiles[firstKey] };
      }

      selectedFiles.value = tempSelectedFiles;
    });

    const addRepeat = () => {
      repeats.value += 1;
      selectedFiles.value[repeats.value] = "";
      // emit("answerUpdate", props.question, selectedFiles.value);
    };
    const handleFileUpload = async (event, ii) => {
      const file = event.target.files[0];
      const MAX_SIZE = 26214400;
      if (file && file.size > MAX_SIZE) {
        $q.notify({
          type: "negative",
          message: `$File(s) were too large. Size limit is 25MB`,
        });
        // Reset the input value to allow the same file to be selected again if needed
        event.target.value = "";
      } else {
        try {
          selectedFiles.value[ii] = file.name;
          let updatedInputs = {};
          let inputName = props.question.inputs[0].name;
          if (props.question.repeatable) {
            inputName = inputName + "_" + ii;
          }
          updatedInputs[inputName] = file;
          // console.log("UPDATED INPUTS", updatedInputs);
          emit("answerUpdate", props.question, updatedInputs);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    };

    const handleDownload = async (ii) => {
      isLoading.value = true; // Indicate loading state
      try {
        let fileName = props.question.inputs[0].name;
        if (props.question.repeatable) {
          await downloadAnswerFile(props.draft_doc_id, fileName, ii);
        } else {
          await downloadAnswerFile(props.draft_doc_id, fileName);
        }
      } catch (error) {
        console.error("Error downloading file:", error);
        $q.notify({
          type: "negative",
          message: "Error downloading file",
        });
      } finally {
        isLoading.value = false; // Reset loading state
      }
    };

   const openPdfPreview = async (ii) => {
      showPdfPreviewModal.value = true;
      iframeLoading.value = true;
      try {
        let fileUrl;
        let fileName = props.question.inputs[0].name;
        if (props.question.repeatable) {
          fileUrl = await previewAnswerFile(props.draft_doc_id, fileName, ii);
        } else {
          fileUrl = await previewAnswerFile(props.draft_doc_id, fileName);
        }
        if (fileUrl) {
          nextTick(() => {
            // Wait for Vue to process DOM updates
            nextTick(() => {
              // Now check and set the iframe src
              if (pdfPreviewIframe.value) {
                pdfPreviewIframe.value.src = fileUrl;
              }
            });
          });
          iframeLoading.value = false;
        }
      } catch (error) {
        // Handle the error here, e.g., by showing an error message
        console.error("Failed to load PDF preview:", error);
        $q.notify({
          color: "negative",
          position: "top",
          message: "This file type cannot be previewed",
          icon: "report_problem",
        });
      }
    }

    return {
      isLoading,
      requiredFlag,
      repeats,
      selectedFiles,
      showPdfPreviewModal,
      iframeLoading,
      pdfPreviewIframe,
      renderMarkdown,
      handleFileUpload,
      addRepeat,
      renderMarkdown,
      handleDownload,
      openPdfPreview
    };
  },
};
</script>

<style scoped>
.file-upload-container {
  margin-bottom: 15px;
  /* Adjust font weight */
}

input[type="file"] {
  display: none;
}

.required-flag-text {
  font-family: var(--title-2);
  color: var(--q-negative);
  font-size: 12px;
}
</style>
