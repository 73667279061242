<template>
  <div class="body">
    <div v-if="!loading">
      <div v-if="user_data && user_data.role === 'vendor'" class="header">
        <div class="top-bar">
          <h4 class="text-weight-regular">
            <span v-if="user_data && user_data.first_name"
              >Hi {{ user_data.first_name }},</span
            >
            <span v-else>Hi there,</span>
            welcome to your vendor REAP application portal
          </h4>
        </div>
        <q-separator color="grey-7" />
      </div>

      <section>
        <h4 class="text-weight-bold">REAP Application for {{ appOrgName }}</h4>
      </section>

      <section>
        <h5 class="text-weight-bold">Vendor Documents</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          Here are the documents that you need to provide directly as a vendor.
        </div>
        <div
          v-if="vendor_documents"
          class="q-pa-md row items-start q-gutter-md"
        >
          <div v-for="document in vendor_documents" :key="document.id">
            <q-card
              clickable
              class="my-card active-card bg-white text-primary column justify-between no-wrap cursor-pointer"
              @click.stop="
                this.$router.push({
                  name: 'Document',
                  params: { id: document.id },
                })
              "
            >
              <q-card-section class="row">
                <div class="text-body1 text-weight-medium">
                  {{ truncateDisplayName(document.display_name) }}
                </div>
              </q-card-section>
              <q-card-actions class="row">
                <q-icon
                  v-if="document.complete"
                  name="check"
                  color="primary"
                  size="32px"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </section>

      <section v-if="applicant_documents && applicant_documents.length > 0">
        <h5 class="text-weight-bold">Applicant Documents</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          Here are the forms that either you or the applicant will need to
          complete.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-for="document in applicant_documents" :key="document.id">
            <q-card
              clickable
              class="my-card active-card bg-white text-primary column justify-between no-wrap cursor-pointer"
              @click.stop="
                this.$router.push({
                  name: 'Document',
                  params: { id: document.id },
                })
              "
            >
              <q-card-section class="row">
                <div class="text-body1 text-weight-medium">
                  {{ truncateDisplayName(document.display_name) }}
                </div>
              </q-card-section>
              <q-card-actions class="row">
                <q-icon
                  v-if="document.complete"
                  name="check"
                  color="primary"
                  size="32px"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </section>
      <section>
        <h5 class="text-weight-bold">Supplemental Attachments</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          If you have other documents to attach to the application, add them
          here.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-if="uploads" v-for="upload in uploads">
            <q-card
              class="my-card bg-white text-primary column justify-between no-wrap"
              @click.stop="openPdfPreview(upload.id)"
              style="cursor: pointer"
            >
              <q-card-section class="row">
                <div
                  class="text-body1 text-weight-medium ellipsis"
                  style="word-wrap: break-word"
                >
                  {{ upload.name }}
                </div>
              </q-card-section>

              <!-- <q-separator primary /> -->

              <q-card-actions class="row justify-between">
                <q-btn flat @click.stop="handleDownload(upload.id)">
                  <q-icon name="download" color="primary" size="32px" />
                </q-btn>
                <q-btn flat @click.stop="removeFile(upload.id)">
                  <q-icon name="delete_forever" color="primary" size="32px" />
                </q-btn>
              </q-card-actions>
            </q-card>
          </div>
          <div>
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              style="opacity: 0.7"
              @click="triggerFileInput"
            >
              <q-card-section v-if="!uploading" class="row">
                <div
                  class="text-body1 text-weight-medium"
                  style="overflow: hidden"
                >
                  Upload New Document
                </div>
              </q-card-section>
              <div v-else class="flex flex-center full-height">
                <q-spinner color="primary" :size="50"></q-spinner>
              </div>
            </q-card>
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              @change="onFileSelected"
            />
          </div>
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Applicant</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          This is the primary applicant for this application. They will receive
          access to the Aidy upload portal.
        </div>
        <div class="q-pa-md row items-center q-gutter-md">
          <div v-for="applicant in applicants" :key="applicant.id">
            <q-card style="max-width: 17em">
              <q-icon name="person" color="primary" size="17em"></q-icon>

              <q-card-section>
                <div class="text-h6 ellipsis">
                  {{ applicant.first_name }} {{ applicant.last_name }}
                </div>
                <div class="text-h9 ellipsis">
                  {{ applicant.email }}
                </div>
                <q-badge
                  outline
                  align="middle"
                  :color="getBadgeColor(applicant)"
                >
                {{ applicant.roles[grantApp.org_id] }}
                </q-badge>
                <q-toggle
                  class="q-pa-none q-ma-none"
                  size="xs"
                  v-model="applicant.show_forms"
                  color="orange"
                  label="Show Forms"
                  @click.stop="updateShowForms(applicant)"
                />
              </q-card-section>
            </q-card>
          </div>
          <div v-for="pending in pendingInvites[grantApp.org_id]" :key="pending.id">
            <q-card style="max-width: 17em">
              <q-icon
                name="person"
                color="primary"
                size="17em"
                style="opacity: 0.3; filter: blur(2px)"
              ></q-icon>

              <q-card-section>
                <div
                  class="text-h6"
                  style="
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                ></div>
                <div class="text-h6 ellipsis">Invitation Sent</div>
                <div class="text-h9 ellipsis">{{ pending.to }}</div>
                <q-badge
                  outline
                  align="middle"
                  :color="getBadgeColor(pending)"
                  class="flex-shrink-0"
                >
                  {{ pending.role }}
                </q-badge>
              </q-card-section>
              <div class="absolute-top">
                <q-btn
                  class="full-width q-pt-sm"
                  color="white bg-primary"
                  label="Revoke Invitation"
                  size="sm"
                  :loading="revokingId == pending.id"
                  :disable="revokingId == pending.id"
                  @click.stop="confirmRevoke(pending.id)"
                  flat
                ></q-btn>
              </div>
            </q-card>
          </div>
          <div class="justify-center">
            <q-btn
              round
              icon="add"
              color="accent"
              @click="dialogVisible = true"
            >
              <q-tooltip class="bg-grey-7">Invite Applicant</q-tooltip></q-btn
            >
          </div>
          <q-dialog v-model="dialogVisible">
            <q-card v-if="!sendingInvite" style="width: 400px">
              <q-card-section>
                <div class="text-h6">Invite User</div>
              </q-card-section>

              <q-card-section>
                <q-input
                  v-model="emailAddress"
                  label="Email Address"
                  :rules="[
                    (val) =>
                      /.+@.+\..+/.test(val) || 'Must be a valid email address',
                  ]"
                />
                <q-select
                  v-model="userType"
                  label="User Type"
                  :options="[
                    { label: 'Applicant', value: 'applicant' },
                    { label: 'Vendor', value: 'vendor' },
                  ]"
                />
              </q-card-section>

              <q-card-section align="right">
                <q-btn
                  flat
                  label="Cancel"
                  color="primary"
                  @click="dialogVisible = false"
                />
                <q-btn
                  flat
                  label="Invite"
                  color="primary"
                  @click="inviteUser"
                />
              </q-card-section>
            </q-card>
            <q-spinner v-else color="primary" :size="80"></q-spinner>
          </q-dialog>
        </div>
      </section>
    </div>
    <div v-else class="flex flex-center" style="height: 100%">
      <q-spinner color="primary" :size="80"></q-spinner>
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { onMounted } from "vue";
import { fetchUser, fetchAppUsers, inviteAs, revokeInvite } from "@/api/userApi";
import {
  fetchFiles,
  fetchForms,
  deleteFile,
  saveFile,
  downloadFile,
} from "@/api/fileApi";
export default {
  name: "Portal",
  props: {
    userProp: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props,) {
    const loading = ref(false); // Initialize the loading state
    const appOrgName = ref(null);
    const showForms = ref(false);
    const router = useRouter();
    const route = useRoute();
    const appID = ref(route.params.id || null); // Initialize appID with the route parameter or null
    const dialogVisible = ref(false);
    const emailAddress = ref('');
    const userType = ref(null);
    const userTypes = ref([
      { label: 'Applicant', value: 'applicant' },
      { label: 'Vendor', value: 'vendor' }
    ]);
    const applicants = ref([]);
    const pendingInvites = ref([]);
    const sendingInvite = ref(false);
    const grantApp = ref(false);


    const fetchUsers = async () => {

      const user_data = ref(props.userProp);

      watch(() => props.userProp, (newValue) => {
        user_data.value = newValue;
      }, { deep: true });

      try {
        const response = await fetchAppUsers(appID.value, 'applicant'); // Adjust to fetch only applicants
        applicants.value = response.users;
        loading.value = false;
      } catch (error) {
        console.error('Error fetching users:', error);
        loading.value = false;
      }
    };

    const getBadgeColor = (applicant) => {
      if (user_data.role === "coordinator") return "accent";
      else if (user_data.role === "applicant") return "primary";
      else return "primary";
    };

    
    const inviteUser = async () => {
      try {
        console.log("HERE")
        console.log("APP ID", userType.value.value)
        await inviteAs(emailAddress.value, userType.value.value, appID.value);
        
        emailAddress.value = '';
        dialogVisible.value = false;
        await fetchUsers(); // Refresh list after invite
        await fetchPending(); // Refresh pending invites list
      } catch (error) {
        console.error('Error inviting user:', error);
      }
    };

    const getApp = async () => {
      try {
        const doc_response = await axios.get(`api/v1/get-app`, {
          params: {
            id: appID.value,
          },
        });
        grantApp.value = doc_response.data;
        console.log("GRANT APP", grantApp.value);
      } catch (error) {
        console.error("Error fetching documents:", error);
        goHome(this.$router);
      }
    };

    onMounted(() => {
      fetchUsers();
      getApp();
    });

    const fetchPending = async () => {
      try {
        const response = await axios.get("api/v1/get-pending/", {
          params: {
            appID: appID.value,
          },
        });
        console.log("PENDING", response.data);
        pendingInvites.value = response.data;
      } catch (error) {
        console.error('Error fetching pending invites:', error);
        // Handle error appropriately
      }
    };

    const fileInput = ref(null); // Define a ref to hold the DOM element

    const triggerFileInput = () => {
      nextTick(() => {
        if (fileInput.value) {
          fileInput.value.click();
        }
      });
    };

    const uploading = ref(false); // Initialize the uploading state
    const uploads = ref(null); // Initialize the uploads state
    // file, app_id, file_class, file_id = null
    const onFileSelected = async (event, id) => {
      const MAX_SIZE = 26214400;
      const selectedFile = event.target.files[0];
      uploading.value = true; // Set uploading to true when the upload starts
      if (selectedFile && selectedFile.size > MAX_SIZE) {
        this.$q.notify({
          type: "negative",
          message: `$File(s) were too large. Size limit is 25MB`,
        });
        // Reset the input value to allow the same file to be selected again if needed
        event.target.value = "";
        this.uploading = false;
      } else {
        await saveFile(selectedFile, app_id.value, "VENDOR_UPLOAD");
        uploads.value = await fetchFiles(app_id.value, "VENDOR_UPLOAD");
        uploading.value = false;
      }
    };

    // Get user info (especially App ID)
    const user_data = ref(null);

    // Get info about upload requests
    const requested_files = ref(null);
    const vendor_documents = ref(null);
    const applicant_documents = ref(null);

    const fetchPortalInfo = async (appId) => {
      try {
        // Assuming `route` is already defined elsewhere in your setup function
        const response = await axios.get("api/v1/get-vendor-portal-info/", {
          params: {
            id: appId, // This will append ?id=value to the URL if id exists
          },
        });
        // console.log("RESPONSE:", response);
        if ("redirect" in response.data) {
          await router.push({
            name: "Document",
            params: { id: response.data.redirect },
          });
        } else {
          requested_files.value = response.data.requested_files;
          vendor_documents.value = response.data.vendor_documents;
          applicant_documents.value = response.data.applicant_documents;
          showForms.value = response.data.user.show_forms;
          appOrgName.value = response.data.project_name;
        }
      } catch (error) {
        console.error("Error fetching portal info:", error);
      }
    };

    const removeFile = async (fileId) => {
      loading.value = true;
      await deleteFile(fileId);
      uploads.value = await fetchFiles(app_id.value, "VENDOR_UPLOAD");
      loading.value = false;
    };

    const handleDownload = async (fileId) => {
      try {
        await downloadFile(fileId);
      } catch (error) {
        console.error("Error downloading file:", error);
        // Handle any errors, such as showing a notification to the user
      }
    };

    const truncateDisplayName = (displayName) => {
      const maxLength = 55;
      if (displayName.length > maxLength) {
        return displayName.substring(0, maxLength) + "...";
      }
      return displayName;
    };

    const upload_docs = ref([]);
    const documents = ref([]);
    const revokingId = ref(null); // Add this line to define revokingId

    // Add the method to handle revocation confirmation
    const confirmRevoke = async (pendingId) => {
      revokingId.value = pendingId; // Set the revokingId before the confirmation
      try {
        // Replace this with your confirmation logic, e.g., a dialog
        // If confirmed:
        await revokeInvite(pendingId);
        await fetchPending(); // Refresh the pending invites list
      } catch (error) {
        console.error('Error revoking invitation:', error);
      } finally {
        revokingId.value = null; // Reset the revokingId after the process
      }
    };
    const app_id = ref(null);


    
    onMounted(async () => {
      loading.value = true;
    
      try {
        app_id.value = route.params.id ? route.params.id : null;
        user_data.value = await fetchUser();
        if (user_data.value.apps.length === 1) {
          app_id.value = user_data.value.apps[0];
        }
        uploads.value = await fetchFiles(app_id.value, "VENDOR_UPLOAD");

        await fetchPortalInfo(app_id.value);

        await fetchPending(app_id.value)
        // Get forms if supposed to show forms
        if (showForms.value) {
          let allDocs = await fetchForms(app_id.value);
          for (let doc of allDocs) {
            // Use `of` to iterate over array elements
            if (doc.upload_doc) {
              upload_docs.value.push(doc); // Use `.value` to access the reactive array
            } else {
              documents.value.push(doc); // Same here
            }
          }
        }
      } catch (error) {
        console.error("Error in mounted hook:", error);
      } finally {
        loading.value = false;
      }

    });

    return {
      appID,
      loading,
      uploading,
      uploads,
      fileInput,
      triggerFileInput,
      onFileSelected,
      removeFile,
      documents,
      upload_docs,
      requested_files,
      handleDownload,
      truncateDisplayName,
      showForms,
      user_data,
      vendor_documents,
      applicant_documents,
      appOrgName,
      loading,
      dialogVisible,
      emailAddress,
      userType,
      userTypes,
      applicants,
      inviteUser,
      sendingInvite,
      fetchPending,
      pendingInvites,
      getBadgeColor,
      user_data,
      revokingId,
      confirmRevoke,
      grantApp,
    };
  },
};
</script>

<style scoped>
section {
  margin-top: 30px;
  margin-bottom: 0px;
}
.body {
  padding: 40px;
  padding-top: 20px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
  max-width: 1300px;
}

.my-card {
  width: 180px;
  height: 200px;
}

.active-card:hover {
  box-shadow: 0 0 5px 2px var(--accent);
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items vertically */
  width: 100%;
}

.top-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 20px;
}

.my-card {
  width: 180px;
  height: 200px;
}

.active-card:hover {
  box-shadow: 0 0 5px 2px var(--accent);
  cursor: pointer;
}
</style>
