<template>
    <q-item clickable v-ripple>
        <q-item-section avatar top>
            <q-avatar
                :color="member.party === 'Democratic' ? 'accent' : (member.party === 'Republican' ? 'primary' : 'grey')"
                text-color="white">
                {{ member.party === 'Democratic' ? 'D' : (member.party === 'Republican'
                    ?
                    'R' : 'I') }}
            </q-avatar>
        </q-item-section>

        <q-item-section>
            <q-item-label lines="1">{{ member.title }} {{ member.name }}<span
                    v-if="original_cosponsor">*</span></q-item-label>
            <q-item-label caption>{{ member.state }} {{ member.district !== "N/A" ? ' - ' + member.district : ''
                }}</q-item-label>
        </q-item-section>
    </q-item>
</template>

<script>
export default {
    name: 'Member',
    props: {
        member: {
            type: Object,
            required: true
        }
    },
    setup() {

    }
}
</script>