<template>
  <div class="body">
    <q-spinner
      v-if="isLoading"
      class="absolute-center"
      color="primary"
      size="100px"
    />
    <div v-if="!isLoading">
      <div class="body">
        <q-spinner
          v-if="isLoading"
          class="absolute-center"
          color="primary"
          size="100px"
        />
        <div>
          <div class="header">
            <div class="top-bar">
              <h4 class="text-weight-medium">Bookmarked Bills</h4>
            </div>
          </div>
          <q-separator color="grey-7 q-mb-lg" />

          <q-tabs
            v-model="tab"
            dense
            class="row text-grey q-mt-lg"
            active-color="primary"
            indicator-color="primary"
            align="left"
            narrow-indicator
          >
            <q-tab name="federal" label="Federal"></q-tab>
            <q-tab name="state" label="State"></q-tab>
          </q-tabs>

          <q-separator></q-separator>

          <q-tab-panels
            v-model="tab"
            animated
            class="q-mb-md rounded-borders"
          >
            <q-tab-panel name="federal">
              <BillList :bills="fedBills" :loading="false" searchQuery=""/>
            </q-tab-panel>
            <q-tab-panel name="state">
              <StateBillList :bills="stateBills" :loading="false" searchQuery=""/>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { ref, onMounted, watchEffect } from "vue";
import { useQuasar } from "quasar";
import {
  fetchOrgUsers,
  fetchUser,
  inviteAs,
  revokeInvite,
} from "@/api/userApi";
import { mdiConsoleLine } from "@mdi/js";
import BillList from "@/components/BillList.vue";
import StateBillList from "@/views/StateLegislation/Components/BillList.vue";

export default {
  name: "Bookmarked Bills",
  components: {
    BillList,
    StateBillList,
  },
  setup() {
    const isLoading = ref(false);
    const dialogVisible = ref(false);
    const emailAddress = ref("");
    const asType = ref("");
    const members = ref(null);
    const currentUser = ref(null);
    const pendingInvites = ref(null);
    const sendingInvite = ref(false);
    const revokingId = ref(null);
    const $q = useQuasar();
    const inviteTo = ref(null);

    const tab = ref("federal");

    const fedBills = ref([]);
    const stateBills = ref([]);

    // Converted to the Composition API style
    onMounted(async () => {
      isLoading.value = true;
      await fetchSearchResults();
      isLoading.value = false;
    });

    const getBadgeColor = (user) => {
      if (user.role === "coordinator") return "accent";
      else if (user.role === "applicant") return "primary";
      else return "primary";
    };

    const fetchPending = async () => {
      const response = await axios.get("api/v1/get-pending/");
      pendingInvites.value = response.data;
    };

    const revokeInvitation = async (invite_id) => {
      revokingId.value = invite_id;
      await revokeInvite(invite_id);
      await fetchPending();
      revokingId.value = null;
    };

    const confirmRevoke = async (pendingId) => {
      $q.dialog({
        title: "Confirm Revoke",
        message:
          "Are you sure that you want to revoke this invitation? You can always re-invite the user later.",
        ok: { color: "negative", label: "Revoke" },
        cancel: { color: "grey", label: "Cancel" },
        persistent: true,
      }).onOk(async () => {
        try {
          isLoading.value = true;
          await revokeInvitation(pendingId);
          isLoading.value = false;
        } catch (error) {
          console.error("Error deleting file:", error);
        }
      });
    };

    const fetchSearchResults = async () => {
      try {
        isLoading.value = true;

        const response = await axios.get(`/api/v1/get-bill-bookmarks`);
        fedBills.value = response.data.federal;
        stateBills.value = response.data.state;
        isLoading.value = false;
      } catch (error) {
        console.error("There was an error fetching the data:", error);
        error.value = error; // Store the error in state, if any
      }
    };

    // Ensure appID is managed correctly, possibly as a reactive property if it changes
    const appID = ref(null); // Example, you may need to adjust based on your actual usage

    return {
      isLoading,
      fedBills,
      stateBills,
      dialogVisible,
      emailAddress,
      asType,
      members,
      currentUser,
      pendingInvites,
      sendingInvite,
      revokingId,
      getBadgeColor,
      confirmRevoke,
      fetchSearchResults,
      inviteTo,
      appID, // Make sure to return it if used in the template
      tab,
    };
  },
};
</script>

<style scoped>
.body {
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
  max-width: 1300px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items vertically */
  width: 100%;
  padding-top: 40px;
}

.top-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 20px;
}
</style>
