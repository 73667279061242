<template>
  <div class="full-page-container">
    <div class="column justify-start" style="padding: 40px 40px 0px 40px">
      <div class="back-text" @click="backToDashboard()">Back to Dashboard</div>
      <div class="row items-end" style="gap: 20px">
        <h4 class="text-weight-medium">{{project_name }}</h4>
        <h5 class="text-weight-regular">{{ status }}</h5>
      </div>
      <div class="row items-center">
        <h5 class="text-weight-medium">{{grant_name ? grant_name : "Unknown Grant"}}</h5>
        <div v-if="close_date" style="padding-left: 10px">
          <q-chip icon="event" class="text-weight-bold" color="accent" text-color="white" >Deadline: {{ formatCloseDate(close_date) }}</q-chip>
        </div>
      </div>
    </div>

    <div
      class="row justify-between"
      style="
        margin-left: 40px;
        margin-right: 40px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      "
    >
      <q-tabs
        v-model="tab"
        class="text-grey-7"
        active-color="primary"
        indicator-color="accent"
        align-items="justify"
        style="max-width: 500px"
      >
        <q-tab label="Application" name="application" />
        <q-tab label="Invite" name="overview" />
        <q-tab label="Notes" name="notes" />
      </q-tabs>
      <q-btn
        flat
        class="q-ma-sm bg-primary text-white"
        icon="download"
        :loading="downloading"
        size="md"
        label=""
        @click.stop="confirmAppDownload()"
      >
        <q-tooltip class="bg-accent">Download Application</q-tooltip>
      </q-btn>
    </div>

    <component
      ref="currentTabComponent"
      style="width: 100%; margin-top: 0px"
      :inputAppId="this.appID"
      :orgId="this.org_id"
      :requestedFiles="this.requested_files"
      :userProp="this.lead_applicant"
      :is="currentComponent"
    ></component>
  </div>
</template>
<script>
import axios from "axios";
import { watch, ref, computed } from "vue";
import Application from "./Application.vue";
import Overview from "./Overview.vue";
import Notes from "./Notes.vue";
import { goHome } from "@/api/userApi";

export default {
  components: {
    Application,
    Overview,
    Notes,
  },
  setup() {
  
      const tab = ref("application");
      let previousTab = '';
      const currentTabComponent = ref(null);

      // Map tab names to component names
      const currentComponent = computed(() => {
        const componentMap = {
          application: 'Application',
          overview: 'Overview',
          notes: 'Notes',
        };
        return componentMap[tab.value] || 'Application'; // Default to 'Application' if no match
      });
      
      // Watch for changes in the `tab` property
      watch(() => tab.value, (newVal, oldVal) => {
        if (oldVal === 'notes') {
          // Check if the previous tab was 'Notes'
          if (currentTabComponent.value && currentTabComponent.value.saveNotes) {
            // Call saveNotes if it exists on the current component
            currentTabComponent.value.saveNotes();
          }
        }
      });

      return {
        tab,
        currentComponent,
        currentTabComponent
      };
  },
  data() {
    return {
      appID: null,
      status: "In Progress",
      org_name: "",
      org_id: null,
      grant_name: "",
      close_date: "",
      project_name: "",
      requested_files: [],
      downloading: false,
      lead_applicant: null,
    };
  },
  async created() {
    // + converts to int
    this.appID = +this.$route.params.id;
    this.$store.commit("setAppID", this.appID);
    await this.getApp();
  },
  methods: {
    formatCloseDate(dateString) {
      const parts = dateString.split('-');
      const date = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
      
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC' // Ensure formatting uses UTC
      });
    },
    backToDashboard() {
      this.$store.commit("setAppID", null);
      this.$router.push({ name: "Dashboard" });
    },
    async getApp() {
      try {
        const doc_response = await axios.get(`api/v1/get-app`, {
          params: {
            id: this.appID,
          },
        });
        // If response says to redirect
        console.log("DOC RESPONSE", doc_response);
        if ("redirect" in doc_response.data) {
          // console.log("REDIRECTING");
          this.$router.push({
            name: "Document",
            params: { id: doc_response.data.redirect },
          });
        } else {
          this.status = doc_response.data.status;
          this.org_name = doc_response.data.org_name;
          this.org_id = doc_response.data.org_id;
          this.requested_files = doc_response.data.requested_files;
          this.lead_applicant = doc_response.data.lead_applicant;
          this.grant_name= doc_response.data.opportunity_title;
          this.project_name= doc_response.data.project_name;
          this.close_date= doc_response.data.close_date;
          this.agency = doc_response.data.agency;
          // console.log("LEAD APP INFO:", this.lead_applicant);
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
        goHome(this.$router);
      }
    },
    async confirmAppDownload() {
      // If the document is incomplete, show a confirmation dialog
      if (this.status !== "Complete") {
        this.$q
          .dialog({
            title: "Confirm Download",
            message:
              "This application is not yet complete. Are you sure you want to download it?",
            ok: { color: "primary", label: "Download" },
            cancel: { color: "grey", label: "Cancel" },
            persistent: true,
          })
          .onOk(async () => {
            this.downloadApp();
          });
      } else {
        //otw just download
        this.downloadApp();
      }
    },
    downloadApp() {
      // console.log("downloadApp");
      this.downloading = true; // Show a spinner while processing
      try {
        const url = `api/v1/download-app/?app_id=${this.appID}`;
        // console.log(url);

        axios
          .get(url, {
            responseType: "blob", // Important for handling the PDF file
          })
          .then((response) => {
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a Blob URL
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Create a temporary link element
            const downloadLink = document.createElement("a");
            downloadLink.href = pdfUrl;
            downloadLink.setAttribute("download", "REAP APPLICATION.zip"); // Assign a file name for the download

            // Append to the document and trigger a click
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Remove the link after triggering download
            document.body.removeChild(downloadLink);

            this.downloading = false; // Hide the spinner after processing
          })
          .catch((error) => {
            this.downloading = false; // Hide the spinner in case of an error
            console.error(
              "Error fetching PDF:",
              error.response ? error.response.data : error.message
            );
          });
      } catch (error) {
        this.isLoading = false;
        console.error("Error in form submission or PDF generation:", error);
      }
    },
  },
};
</script>

<style scoped>
.full-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.back-text {
  font-family: var(--title-2);
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(2, 104, 172);
  margin-bottom: 0.5rem;
  text-align: left;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}
</style>
