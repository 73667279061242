<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container v-if="bill" class="column bg-tertiary items-center" style="overflow: auto;">
      <q-page class="column justify-start">
        <div class="row justify-between" style="width: 100%; height: 50px;">
          <q-btn flat label="Back to Search" icon="arrow_back_ios" class="q-mr-md q-mt-md"
            @click="backToSearch"></q-btn>
          <q-btn v-if="!signedIn" color="primary" label="Sign In" class="q-mr-md q-mt-md" @click="showSignIn = true">
          </q-btn>
          <q-btn v-else rounded color="primary" label="" class="q-mr-md q-mt-md"
            @click="$router.push({ name: 'User' })">
            <q-icon name="person" color="white" />
            <q-tooltip class="bg-primary text-white text-body2" :offset="[10, 10]">My Account</q-tooltip>
          </q-btn>
        </div>

        <div class="column q-gutter-md" style="padding: 40px 40px 0px 40px; max-width: 1200px">

          <div class="row shadow-2 rounded-borders q-pa-md bg-white">
            <div class="col q-pl-md q-pr-md">
              <div class="row justify-between items-start">
                <q-img :src="`/department_logos/${Object.keys(this.bill.chamber)[0]}.png`" spinner-color="white"
                  style="height: 150px; max-width: 150px; border-radius: 50%;" class='col q-pa-sm'></q-img>
                <div class="col q-pl-lg">
                  <h4 class="row text-weight-medium flex-auto">
                    {{ bill.title ? bill.title : "Unknown Bill" }}
                  </h4>
                  <div class="row justify-start items-center q-pt-sm">
                    <h5 class="text-weight-medium" style="padding-right: 10px;">{{ bill.bill_type }} {{ bill.bill_number
                      }}</h5>
                    <q-chip :key="program" class="text-weight-bold q-mr-sm" color="primary" text-color="white">
                      {{ bill.congress }}th
                    </q-chip>
                    <q-chip v-for="(value, committee) in bill.committees" :key="committee"
                      class="text-weight-bold q-mr-sm" color="accent" text-color="white">
                      {{ committee }}
                    </q-chip>
                    <div v-if="bill.actions.length > 0" class="text-subtitle1 q-pt-md">
                      <b>Latest Action: </b>
                      <q-chip icon="event" class="text-weight-bold" color="primary" text-color="white" size="sm">{{
                        formatDate(bill.actions[0]["actionDate"]) }}</q-chip>
                      {{ bill.actions[0]["text"] }}
                    </div>

                  </div>
                </div>
              </div>
              <div class="row justify-between q-pt-sm">
                <div class="text-body1 q-pt-md"><b>Summary: </b>{{ bill.summary }}</div>
              </div>
              <div class="row justify-start q-pt-md">
                <q-btn @click="viewLatestText" color="primary" label="View latest text" />
              </div>
            </div>
          </div>

          <q-tabs v-model="tab" dense class="row text-grey q-mt-lg" active-color="primary" indicator-color="primary"
            align="left" narrow-indicator>
            <q-tab name="status" label="Status"></q-tab>
            <q-tab name="versions" label="Versions"></q-tab>
            <q-tab name="votes" label="votes" v-if="bill.votes.length > 0"></q-tab>

            <!-- <q-tab name="cosponsors" label="Cosponsors"></q-tab> -->
            <!-- <q-tab v-if="Object.keys(this.grant.mk_files).length > 0" name="documents" label="Documents"></q-tab> -->
          </q-tabs>

          <q-separator></q-separator>

          <q-tab-panels v-model="tab" animated class="q-mb-md shadow-2 rounded-borders">
            <q-tab-panel name="status">
              <div class="row justify-between">
                <div class="col q-ml-lg">
                  <h6 class="text-weight-bold">Cosponsors</h6>
                  <q-list v-if="bill.cosponsors.length > 0" class="q-pt-lg">
                    <q-item clickable v-ripple v-for="(cosponsor, index) in bill.cosponsors">
                      <q-item-section avatar top>
                        <q-avatar
                          :color="cosponsor.member.party === 'Democratic' ? 'accent' : (cosponsor.member.party === 'Republican' ? 'primary' : 'grey')"
                          text-color="white">
                          {{ cosponsor.member.party === 'Democratic' ? 'D' : (cosponsor.member.party === 'Republican' ?
                            'R' : 'I') }}
                        </q-avatar>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label lines="1">{{ cosponsor.member.title }} {{ cosponsor.member.name }}<span
                            v-if="cosponsor.original_cosponsor">*</span></q-item-label>
                        <q-item-label caption>
                          {{ cosponsor.member.state }}
                          {{ cosponsor.member.district !== "N/A" ? ' - ' + cosponsor.member.district : '' }}
                        </q-item-label>
                      </q-item-section>
                      <q-item-section side>{{ cosponsor.date }}</q-item-section>
                    </q-item>
                    <q-item>
                      <q-item-section side class="text-caption">
                        * Original Cosponsor
                      </q-item-section>
                    </q-item>
                  </q-list>
                  <div v-else>
                    <q-item>
                      <q-item-section>
                        <q-item-label>No cosponsors are available for this bill yet</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <q-separator vertical inset></q-separator>
                <div class="col q-ml-lg">
                  <h6 class="text-weight-bold">Actions</h6>
                  <q-list v-if="bill.actions.length > 0">
                    <q-stepper v-model="action_step" ref="stepper" color="primary" vertical animated
                      style="box-shadow: none !important">

                      <q-step v-for="(step, index) in bill.actions" :key="index" :name="index + 1"
                        :title="step.actionCode ? step.actionCode : step.text" :caption="formatDate(step.actionDate)"
                        :icon="step.icon" :done="action_step > bill.actions.length" inactive-color="accent">
                        {{ step.actionCode ? step.text : "" }}
                        <q-stepper-navigation>
                          <q-btn v-if="action_step < bill.actions.length" @click="$refs.stepper.next()" color="primary"
                            label="Previous Action" />
                          <q-btn v-if="action_step > 1" flat color="primary" @click="$refs.stepper.previous()"
                            label="Next Action" class="q-ml-sm" />
                        </q-stepper-navigation>
                      </q-step>
                    </q-stepper>
                  </q-list>
                  <div v-else>
                    <q-item>
                      <q-item-section>
                        <q-item-label>No actions are available for this bill yet</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </div>
            </q-tab-panel>
            <q-tab-panel name="versions">
              <div class="row justify-between">
                <div class="col">
                  <h6 class="text-weight-bold">Text Versions</h6>
                  <q-list v-if="bill.textVersions.length > 0">
                    <q-item clickable v-ripple v-for="(version, index) in bill.textVersions" :key="index"
                      @click="viewLatestText">
                      <q-item-section avatar>
                        <q-icon color="primary" name="description" />
                      </q-item-section>

                      <q-item-section>
                        <q-item-label lines="1">Version No. {{ index + 1 }}</q-item-label>
                        <q-item-label caption> {{ version.text_type }}</q-item-label>
                      </q-item-section>

                      <q-item-section side>{{ formatDate(version.actionDate) }}</q-item-section>
                    </q-item>
                  </q-list>
                  <div v-else>
                    <q-item>
                      <q-item-section>
                        <q-item-label>No text versions are available for this bill yet</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <q-separator vertical inset></q-separator>
                <div class="col q-ml-lg">
                  <h6 class="text-weight-bold">Amendments</h6>
                  <q-list v-if="bill.amendments.length > 0">
                    <q-item clickable v-ripple v-for="(amendment, index) in bill.amendments" :key="index"
                      @click="goToAmemdment(amendment.text_id)">
                      <q-item-section avatar>
                        <q-icon color="primary" name="description" />
                      </q-item-section>

                      <q-item-section>
                        <q-item-label lines="1">Amendment No. {{ amendment.number }}</q-item-label>
                        <q-item-label caption> {{ amendment.purpose }} </q-item-label>
                      </q-item-section>
                      <q-item-section side>{{ formatDate(amendment.submitted_date) }}</q-item-section>
                    </q-item>
                  </q-list>
                  <div v-else>
                    <q-item>
                      <q-item-section>
                        <q-item-label>No amendments are available for this bill.</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </div>
            </q-tab-panel>

            <q-tab-panel name="votes">
              <!-- NOTE: change this when you get back from lunch to work on bill actions -->
              <VoteDisplay :votes="bill.votes" />
            </q-tab-panel>

            <!-- <q-tab-panel v-if="Object.keys(this.grant.mk_files).length > 0" name="documents">
            <div class="row justify-between">
              <h6 class="text-weight-bold">Program Documents</h6>
            </div>
            <div class="row justify-between">
              <div class="col-2">
                <q-list padding class="rounded-borders text-primary">
                  <q-item
                    v-for="(docName, index) in Object.keys(grant.mk_files)"
                    clickable
                    v-ripple
                    :active="activeDoc === docName"
                    @click="activeDoc = docName"
                    active-class="active-doc"
                  >
                    <q-item-section class="ellipsis">{{ docName }}</q-item-section>
                  </q-item>
                </q-list>
              </div>
              
              <div class="col-10">
                <div class="q-pa-md rounded-borders bg-white">
                  <div
                    v-html="renderMarkdownWrapper(grant.mk_files[activeDoc])"
                    class="markdown-body"
                  ></div>
                </div>
              </div>
            </div>
          </q-tab-panel> -->

          </q-tab-panels>
        </div>
      </q-page>
    </q-page-container>
    <div v-else class="flex flex-center" style="height: 100%; padding-top: 50vh;">
      <q-spinner color="primary" :size="120"></q-spinner>
    </div>
  </q-layout>

  <q-dialog v-model="showSignIn">
    <sign-in-comp @signinComplete="signinComplete" @goToReg="goToRegistration" :linkToReg="false"></sign-in-comp>
  </q-dialog>

  <q-dialog v-model="showSignUp">
    <register-comp role="legislation" @registrationComplete="handleRegistrationComplete" @goToSignIn="goToSignIn"
      :linkToSignIn="false"></register-comp>
  </q-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { renderMarkdown } from "@/helpers/helpers";
import VoteDisplay from "@/components/votes/VoteDisplay.vue";
import axios from "axios";

export default defineComponent({
  name: "Bill Info",
  components: {
    VoteDisplay,
  },
  data() {
    return {
      bill: null,
      action_step: 1,
      bill_id: this.$route.params.id,
      tab: "status",
      activeDoc: null,
      store: null,
      showSignIn: false,
      showSignUp: false,
      signedIn: false,
      textId: 6,
    }
  },
  async mounted() {
    this.store = useStore();
    this.signedIn = await this.checkLoggedIn();
    this.getBillInfo();
  },
  computed: {
    // textId() {
    //   return this.bill.textVersions[0].id;
    // },
    contentStyle() {
      // If the sidebar is open, add 40vh margin to the right
      if (this.isSidebarOpen) {
        return {
          paddingRight: "420px",
        };
      } else {
        return {
          paddingRight: "0px",
        };
      }
    },
  },
  methods: {
    handleSidebarToggle(isOpen) {
      this.isSidebarOpen = isOpen; // Keep track of the sidebar state
    },
    handleSectionId(sectionId) {
      this.section_id = sectionId;
    },
    handleSubmit(formAnswers) {
      // Send the answers to your backend or perform other actions
      // Then load the next section's questions if any
      this.allAnswers[this.currentSectionIndex] = formAnswers;
      if (this.currentSectionIndex < this.sections.length) {
        this.currentSectionIndex++;
      }
      if (this.$refs.dynamicFormRef) {
        this.$refs.dynamicFormRef.formSubmitted = true;
      }
    },
    backToSearch() {
      this.$router.push({ name: "BillSearch" });
    },
    viewLatestText() {
      this.$router.push(`/bill/${this.bill.textVersions[0].id}`);
    },
    goToAmemdment(amendmentId) {
      if (amendmentId) {
        this.$router.push(`/bill/${amendmentId}`);
      }
    },
    getBillInfo() {
      // Get grant info from the server
      axios
        .get("/api/v1/get-bill-info", {
          params: {
            bill_id: this.bill_id,
          },
        })
        .then((response) => {
          // console.log("BILL RESPONSE:", response.data);
          this.bill = response.data;
          // console.log("TEXT ID:", this.bill.textVersions[0].id);
          this.textId = this.bill.textVersions[0].id;
        })
        .catch((error) => {
          console.error("Error fetching grant info:", error);
        });
    },
    formatDate(dateString) {
      if (!dateString) return "";

      const date = new Date(dateString);
      if (isNaN(date)) return "";

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Helper function to get the ordinal suffix for day numbers
      function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      }

      const day = date.getDate();
      const monthName = months[date.getMonth()];
      const year = date.getFullYear();

      return `${monthName} ${day}${getOrdinalSuffix(day)}, ${year}`;
    },
    checkLoggedIn() {
      return this.store.dispatch("checkToken")
        .then(signedIn => {
          return signedIn;  // Return the signed in status for further processing
        })
        .catch(error => {
          console.error("Error checking token:", error);
          return false;  // Handle the error case by returning false
        });
    },
    renderMarkdownWrapper(text) {
      return renderMarkdown(text);
    },
    handleRegistrationComplete() {
      this.signedIn = checkLoggedIn();
      // console.log("Registration complete");
      this.showSignUp = false;
    },
    signinComplete() {
      this.signedIn = checkLoggedIn();
      // console.log("Signin complete:", signedIn.value);
      this.showSignIn = false;
    },
    goToRegistration() {
      this.showSignIn = false;
      this.showSignUp = true;
    },
    goToSignIn() {
      this.showSignUp = false;
      this.showSignIn = true;
    }
  },
});
</script>

<style scoped>
.custom-stepper,
.custom-stepper .q-stepper__tab,
.custom-stepper .q-stepper__content {
  border: none !important;
}

.container {
  height: 100vh;
  width: 100%;
}

.content {
  height: 100%;
  width: 90%;
  /* padding-left: 40px; */
  box-sizing: border-box;
  transition: padding-right 0.3s ease;
  align-items: left;
  /* Smooth transition for the margin change */
}

.section-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-center-image {
  display: block;
  margin: 40px auto 0px;
  /* Centers the image */
  width: 30%;
  max-width: 300px;
  min-width: 200px;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.active-doc {
  background-color: var(--accent);
  color: white;
}

.markdown-body {
  /* Add your markdown styles here */
  line-height: 1.6;
  color: var(--text);
  border-radius: 5px;
  font-family: var(--title-2);
}

.hyperlink {
  text-decoration: underline;
}

.hyperlink:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}
</style>
