<template>
  <div class="full-width full-height body">
    <div v-if="loading">
      <q-card v-for="n in 8" :key="n" class="q-pa-md q-ma-md">
        <div class="q-gutter-y-md">
          <q-skeleton bordered style="width: 620px"></q-skeleton>
          <q-skeleton bordered></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
        </div>
      </q-card>
    </div>
    <BillCard v-else v-for="bill in bills" :key="bill.id" :bill="bill" />
  </div>
</template>

<script>
import { defineComponent, toRefs, provide, inject, onMounted, ref } from "vue";
import BillCard from "./BillCard.vue";

export default defineComponent({
  name: "BillList",
  components: {
    BillCard,
  },
  props: {
    bills: Array,
    loading: Boolean,
  },
  setup(props) {
    const { bills, loading } = toRefs(props);
    const searchQuery = inject("searchQuery");
    provide("searchQuery", searchQuery);

    const error = ref("");

    return {
      searchQuery,
      error,
    };
  },
});
</script>

<style scoped>
</style>
