<template>
  <div class="dynamic-form">
    <!-- Header container using Flexbox -->
    <div class="header-container">
      <!-- Text container for title and document name -->
      <div class="text-container">
        <div class="back-text" @click="backToApp()">Back to Application</div>
        <!-- Slide this over a little because the R looks shifted left otherwise -->
        <h4 class="q-pl-none" style="margin-left: -0.05em;" >Rural Energy for America Program</h4>
        <div class="doc-title-text">{{ doc_name }}</div>
        <div class="grant-title-text">{{ section_name }}</div>
      </div>
      <!-- Button container for magic wand icon -->
      <div class="button-container">
        <button
          class="magic-wand-button"
          @mouseover="showTooltip = true"
          @mouseleave="showTooltip = false"
        >
          <font-awesome-icon
            icon="fas fa-wand-magic-sparkles"
            :class="{ icon: docs_uploaded, 'disabled-icon': !docs_uploaded }"
            @click="predictSection"
          />
          <span class="tooltip" v-if="showTooltip">
            {{ docs_uploaded ? "Predict Section" : "Need docs" }}
          </span>
        </button>
      </div>
    </div>
    <form @submit.prevent="backToApp((check_complete = true))">
      <div v-for="question in questions" :key="question.id" class="question">
        <div
          v-if="
            !('display_calculated' in question) || question.display_calculated
          "
        >
          <multiple-choice
            v-if="question.question_type === 'MC'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="draft_doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <check-all
            v-else-if="question.question_type === 'CA'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="draft_doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <short-answer
            v-else-if="question.question_type === 'MP'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="draft_doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <long-answer
            v-else-if="question.question_type === 'SA'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="draft_doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <user-upload
            v-else-if="question.question_type === 'UP'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="draft_doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <render-markdown
            v-else-if="question.question_type === 'MD'"
            :question="question"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
        </div>
      </div>
      <div v-if="final_section" class="flex flex-center">
        <q-btn
          type="submit"
          :loading="btn_saving"
          flat
          color="primary"
          class="q-ma-sm bg-primary text-white"
          size="lg"
        >
          Save Form
        </q-btn>
      </div>
    </form>
  </div>
  <br />
</template>

<script>
import axios from "axios";
import UserUpload from "./forms/UserUpload.vue";
import ShortAnswer from "./forms/ShortAnswer.vue";
import User from "../views/User.vue";
import jsonLogic from "json-logic-js";
import RenderMarkdown from "./forms/RenderMarkdown.vue";
import { parse } from "marked";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';


export default {
  components: { UserUpload, ShortAnswer, User, RenderMarkdown },
  name: "DynamicForm",
  props: {
    questions: {
      type: Array,
      required: true,
    },
    formAnswers: {
      type: Object,
      required: false,
      default: () => ({}), // Default to an empty object
    },
    draft_doc_id: {
      type: Number,
      required: false,
    },
    app_id: {
      type: Number,
      required: false,
    },
    section_name: {
      type: String,
      required: true,
    },
    final_section: {
      type: Boolean,
      required: false,
      default: false,
    },
    doc_name: {
      type: String,
      required: true,
    },
    docs_uploaded: {
      type: Boolean,
      required: true,
      default: false,
    },
    section_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      serverAnswers: {},
      answered: {},
      showTooltip: false,
      lastSaveTime: 0,
      debouncedSave: null,
      debouncedUpdate: null,
      btn_saving: false,
      repeats: {},
    };
  },
  created() {
    // console.log("Questions ON CREATE", this.questions);
    this.serverAnswers = JSON.parse(JSON.stringify(this.formAnswers));
    this.questions.forEach((question) => {
      this.answered[question.id] = false; // All question unanswered to begin with
    });
    // Set up repeatables
    for (let question of this.questions) {
      if (question.hasOwnProperty("repeatable") && question.repeatable) {
        for (let input of question.inputs) {
          this.repeats[input.name] = [];
        }
      }
    }

    this.setCalcDependencies();
    this.updateQuestions();
    this.updateRepeats();
    this.updateCalcs();

    // Logic to save answers
    // Initialize debouncedSave
    this.debouncedSave = this.debounce(this.performSave, 3000);
    // Initialize debouncedUpdate
    this.debouncedUpdate = this.debounce(this.performUpdate, 1000);
    this.$store.commit("setSectionID", this.section_id);
  },
  watch: {
    // Watch for changes in the questions prop
    questions() {
      // Code to execute when questions changes
      this.setCalcDependencies();
      this.updateQuestions();
      this.updateCalcs();
      this.updateRepeats();
    },
  },
  methods: {
    async backToApp(check_complete = false) {
      this.btn_saving = true;
      try {
        // Wait for saveSection to complete
        await this.performSave(check_complete);
        this.btn_saving = false;
        this.$router.go(-1);
      } catch (error) {
        this.btn_saving = false;
        console.error("Error saving section:", error);
      }
    },
    handleNewAnswer(question = null, newAnswers = {}) {
      // Update this.formAnswers with values from newAnswers
      Object.keys(newAnswers).forEach((key) => {
        this.formAnswers[key] = newAnswers[key];
      });

      if (
        question &&
        (question.question_type == "SA" || question.question_type == "MP")
      ) {
        this.debouncedUpdate(question);
        // IGNORE Upload types, just have them save on form save or backToApp
      } else {
        // Fixed missing parenthesis here
        this.performUpdate(question);
      }

      this.$emit("contentUpdate", this.questions);
      if (question.question_type != "UP") {
        // console.log("SAVING");
        this.saveSection();
      }
    },
    performUpdate(question = null) {
      // Handle new answers here
      // // console.log("HANDLING UPDATE:",question.question_text);
      if (question !== null) {
        this.answered[question.id] = true;
      }
      // console.log("UPDATING QUESTIONS");
      this.updateQuestions();
      this.updateRepeats();
      this.updateCalcs();
    },
    async performSave(check_complete = false) {
      let toSave = new FormData();
      // console.log("FORM ANSWERS Dynamic Form", this.formAnswers);
      for (const id in this.formAnswers) {
        // formData should be the difference between what is on the server on the up-to-date local
        if (
          (!this.serverAnswers ||
            !(id in this.serverAnswers) ||
            this.formAnswers[id] !== this.serverAnswers[id]) &&
          !Number.isNaN(this.formAnswers[id])
        ) {
          // // console.log(id, this.formAnswers[id]);
          toSave.append(id, this.formAnswers[id]);
          // console.log("ID AND VAL", id, this.formAnswers[id]);
        }
      }
      toSave.append("draft_doc_id", this.draft_doc_id);
      // If check_complete is true, then we are checking if the section is complete
      if (check_complete) {
        toSave.append("check_complete", true);
      } else {
        toSave.append("check_complete", false);
      }

      try {
        const url = "api/v1/save-section/";
        // console.log("SAVING SECTION");
        const headers = {
          ...axios.defaults.headers.common,
          "Content-Type": "multipart/form-data",
        };
        this.serverAnswers = JSON.parse(JSON.stringify(this.formAnswers)); //Update server answers with formAnswers
        await axios
          .post(url, toSave, { headers })
          .then((response) => {
            this.lastSaveTime = Date.now();

            // // console.log("Section saved successfully:", response.data);
          })
          .catch((error) => {
            console.error(
              "Error saving section:",
              error.response ? error.response.data : error.message
            );
          });
      } catch (error) {
        console.error(
          `Error fetching section questions ${this.draft_doc_id}:`,
          error
        );
      }
    },
    updateQuestions() {
      this.questions.forEach((question) => {
        if ("display" in question) {
          let evaluated = jsonLogic.apply(question.display, this.formAnswers);
          question.display_calculated = evaluated;
        }
      });
    },
    updateCalcs(repeat = 5) {
      const customActionCalled = new Set();
      let customActionExecuted = false;
      for (let i = 0; i < repeat; i++) {
        this.questions.forEach((question) => {
          question.inputs.forEach((input) => {
            if (
              "calculation" in input &&
              input.calculation &&
              "calc_vars" in input &&
              input.calc_vars
            ) {
              // Don't update the calc input if there is a non-null answer for it already and it's editable
              if (
                input.name in this.formAnswers &&
                this.formAnswers[input.name] &&
                this.formAnswers[input.name] !== "" &&
                this.formAnswers[input.name] !== "null" &&
                input.editable
              ) {
                return;
              }
              var unansweredDependencies = false;
              input.calc_vars.forEach((var_name) => {
                // First add the repeat arrays to formAnswers temporarily
                if (this.repeats.hasOwnProperty(var_name)) {
                  this.formAnswers[var_name] = this.repeats[var_name];
                }
                // just check it the var has an answer in formanswers and that the question it's in is display_calculated == true
                // TODO update findQuestionByInput so it searches all questions
                var dependQuestion = this.findQuestionByInput(var_name);
                // If one of the dependent inputs is in a question that has not been answered
                if (!(var_name in this.formAnswers)) {
                  if (
                    dependQuestion !== null &&
                    dependQuestion.display_calculated
                  ) {
                    unansweredDependencies = true;
                  }
                }
                // if (
                //   !this.answered[dependQuestion.id] &&
                //   dependQuestion.display_calculated
                // ) {
                //   console.log("DEPENDENT", dependQuestion.id);
                //   unansweredDependencies = true;
                // }
              });
              if (unansweredDependencies) {
                // TODO eventually switch this to be the default val for the input
                if (input.input_type == "number") {
                  this.formAnswers[input.name] = NaN;
                } else if (
                  input.input_type == "checkbox" ||
                  input.input_type == "radio"
                ) {
                  this.formAnswers[input.name] = false;
                } else {
                  this.formAnswers[input.name] = null;
                }
                // TODO handle the case where the question has been answered but some of it's inputs are still not answered
              } else {
                if (
                  input.calculation.hasOwnProperty("custom_action") &&
                  input.calculation.custom_action
                ) {
                  // Check if this calc is a custom action
                  if (i < 1) {
                    //Only make the call once
                    this.custom_action_call(
                      input.calculation.custom_action.function_name,
                      input.calculation.custom_action.inputs
                    );
                    this.answered[question.id] = true;
                    customActionExecuted = true;
                  }
                } else if (input.calculation.hasOwnProperty("if")) {
                  // Check for nested custom_action within 'if'
                  const branches = [
                    input.calculation.if[1],
                    input.calculation.if[2],
                  ]; // True and false branches
                  branches.forEach((branch) => {
                    if (
                      branch &&
                      branch.hasOwnProperty("custom_action") &&
                      !customActionCalled.has(input.name) &&
                      i < 1
                    ) {
                      this.custom_action_call(
                        branch.custom_action.function_name,
                        branch.custom_action.inputs
                      );
                      this.answered[question.id] = true;
                      customActionCalled.add(input.name);
                      customActionExecuted = true;
                    }
                  });
                }
                if (!customActionExecuted) {
                  let evaluated = jsonLogic.apply(
                    input.calculation,
                    this.formAnswers
                  );
                  if (
                    !isNaN(parseFloat(evaluated)) &&
                    isFinite(parseFloat(evaluated))
                  ) {
                    // hacky way to ensure dollars have cents applied
                    if (input.config.prefix && input.config.prefix === "$") {
                      // Ensure it's treated as a number with toFixed(2) for formatting
                      this.formAnswers[input.name] =
                        Number(evaluated).toFixed(2);
                      this.answered[question.id] = true; // Consider this question now answered;
                    } else {
                      // If it's not prefixed with "$", keep it as a number but don't format
                      this.formAnswers[input.name] = Number(evaluated);
                      this.answered[question.id] = true; // Consider this question now answered;
                    }
                  } else {
                    // If evaluated is not a number or is infinite, set it as is
                    if (
                      parseFloat(evaluated) === Infinity ||
                      parseFloat(evaluated) === -Infinity
                    ) {
                      this.formAnswers[input.name] = null;
                      this.answered[question.id] = true; // Consider this question now answered;
                    } else {
                      this.formAnswers[input.name] = evaluated;
                      this.answered[question.id] = true;
                    } // Consider this question now answered;
                  }
                }
              }

              // Remove any input lists added here
              input.calc_vars.forEach((var_name) => {
                if (this.repeats.hasOwnProperty(var_name)) {
                  delete this.formAnswers[var_name];
                }
              });
            }
          });
        });
      }
    },
    setCalcDependencies() {
      this.questions.forEach((question) => {
        question.inputs.forEach((input) => {
          if ("calculation" in input) {
            input.calc_vars = this.extractVariableNames(input.calculation);
          }
        });
      });
    },
    extractVariableNames(jsonLogic) {
      let variables = new Set(); // Using a Set to avoid duplicates

      function recurse(obj) {
        if (Array.isArray(obj)) {
          // If the current object is an array, iterate over its elements
          obj.forEach(recurse);
        } else if (obj !== null && typeof obj === "object") {
          // If the current object is a non-null object, iterate over its properties
          for (let key in obj) {
            if (key === "var") {
              if (obj[key] !== "current" && obj[key] !== "accumulator") {
                // If the current property is 'var', add its value to the set
                variables.add(obj[key]);
              }
            } else {
              // Recursively apply the function to the value of the current property
              recurse(obj[key]);
            }
          }
        }
      }
      recurse(jsonLogic);
      return Array.from(variables); // Convert the set of variables to an array
    },
    findQuestionByInput(inputName) {
      for (const question of this.questions) {
        if (question.inputs.some((input) => input.name === inputName)) {
          return question;
        }
      }
      return null; // Return null if no question is found
    },
    // Debounce function
    debounce(func, wait, immediate) {
      let timeout;
      return function (...args) {
        const context = this;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
    saveSection() {
      this.debouncedSave();
    },
    async predictSection() {
      if (!this.docs_uploaded) {
        return; // Don't do anything if no docs uploaded
      }
      // Assuming 'questions' is an array of question objects
      for (const question of this.questions) {
        if (question.display_calculated) {
          const questionComponent = this.$refs[`question-${question.id}`][0];
          // Call predict on each component
          if (question.question_type == "SA") {
            questionComponent.predict(question.inputs[0].name);
          } else {
            questionComponent.predict();
          }
        }
      }
    },
    flagPredictingAnswers() {
      // Assuming 'questions' is an array of question objects
      for (const refKey in this.$refs) {
        if (Object.hasOwnProperty.call(this.$refs, refKey)) {
          const questionComp = this.$refs[refKey][0];
          // Check if questionComp is not null or undefined
          if (questionComp) {
            if ("isLoading" in questionComp) {
              questionComp.isLoading = true;
            }
          } else {
            console.error(`Reference '${refKey}' is undefined or null`);
          }
        }
      }
    },
    serialize_for_url(obj) {
      const params = new URLSearchParams();
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          params.append(key, obj[key]);
        }
      }
      return params.toString();
    },
    custom_action_call(function_name, inputs) {
      function_name = function_name.replace(/_/g, "-");

      const data = {};
      inputs.forEach((item) => {
        if (item.var && this.formAnswers.hasOwnProperty(item.var)) {
          data[item.var] = this.formAnswers[item.var];
        }
      });

      const serialized_url_data = this.serialize_for_url(data);
      const url = `api/v1/${function_name}/?${serialized_url_data}`;

      axios
        .get(url)
        .then((response) => {
          for (const key in response.data) {
            this.formAnswers[key] = response.data[key];
          }
        })
        .catch((error) => {
          console.error(
            `Error calling custom action ${function_name}:`,
            error.response ? error.response.data : error.message
          );
        });
    },
    getFormAnswers() {
      return this.formAnswers;
    },
    updateRepeats() {
      // Iterate through each entry in formAnswers
      for (const [inputName, answer] of Object.entries(this.formAnswers)) {
        // Check if the inputName matches the pattern indicating a repeat
        const match = inputName.match(/(.+)_(\d+)$/);
        if (match) {
          // Extract the base input name and the repeat index from the match
          const baseInputName = match[1];
          const repeatIndex = parseInt(match[2], 10); // Convert index to integer

          // Check if this base input name is in the repeats dictionary
          if (this.repeats.hasOwnProperty(baseInputName)) {
            // Ensure the repeats array is long enough to include this repeat index
            // This step may not be necessary if repeats array is always correctly initialized
            while (this.repeats[baseInputName].length <= repeatIndex) {
              this.repeats[baseInputName].push(undefined);
            }
            // Update the repeats dictionary with the answer
            this.repeats[baseInputName][repeatIndex] = answer;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.back-text {
  font-family: var(--title-2);
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(2, 104, 172);
  margin-bottom: 0.5rem;
  text-align: left;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.doc-title-text {
  font-family: var(--title-2);
  font-size: 1rem;
  color: rgb(116, 112, 112);
  margin-bottom: 0.5rem;
  text-align: left;
}

.grant-title-text {
  font-family: var(--title-2);
  font-size: 1rem;
  font-weight: 500;
  color: rgb(2, 104, 172);
  margin-bottom: 0.5rem;
  text-align: left;
}

.dynamic-form {
  /* max-height: 100%; */
  padding: 16px;
  margin-bottom: 20px;
  /* Make sure padding is included in the height */
  box-sizing: border-box;
}

.dynamic-form label {
  display: block;
}

.dynamic-form .question {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 800px;
}

.dynamic-form .form-group {
  margin-bottom: 10px;
}

.buttonText {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 30px;
  font-weight: 500;
}

.button {
  /* border: none; Remove default border */
  border-width: 2px;
  border-color: var(--accent);
  outline: none;
  /* Remove focus outline */
  margin: auto;
  border-radius: var(--br-3xs);
  background-color: var(--accent);
  /* width: 200px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  font-size: var(--title-2-size);
  cursor: pointer;
  /* Change cursor to pointer to indicate it's clickable */
  color: var(--bkg);
  font-family: var(--title-2);
}

.button:hover {
  background-color: var(--hover-accent);
  /* Change to a slightly different color on hover */
  color: var(--accent);
  /* Optional: Change text color on hover */
  /* You can add other effects like a border or shadow if desired */
}

.icon {
  position: relative;
  color: rgb(1, 150, 250);
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  /*box-shadow: 0.3s ease; */
}

.icon:hover {
  transform: scale(1.5);
  transition: transform 0.3s ease;
  /* Make the icon 10% larger on hover */
  color: rgb(1, 150, 250);
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); Add a subtle shadow for a "lifted" effect */
  cursor: pointer;
  /* Change cursor to indicate it's clickable */
}

.icon:active {
  color: var(--accent);
}

.disabled-icon {
  position: relative;
  color: gray;
  width: 24px;
  height: 24px;
}

.disabled-icon:hover {
  cursor: not-allowed;
}

/* .disabled-icon:active {
  color: var(--accent);
} */

.header-container {
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* Adjust as needed */
}

.text-container {
  float: left;
  /* Flexbox makes float unnecessary, but included for legacy reasons if needed */
}

.document-name {
  font-size: 1em;
  color: #666;
}

.tooltip {
  position: absolute;
  bottom: 130%;
  /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}

.button-container {
  float: right;
  position: relative;
  /* Needed to position the tooltip correctly */
}

.magic-wand-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Font Awesome magic wand icon */
.fa-magic {
  font-size: 24px;
  /* Size of the magic wand icon */
}

/* Clearfix if using floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
</style>
