<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-drawer class="bg-grey-1 text-primary shadow-1 justify-between" show-if-above bordered :width="240"
      :breakpoint="400" style="overflow: hidden;">
      <img class="q-pa-lg" alt="" src="/logo.svg" style="width: 100%;" />
      <q-list>
        <q-item :class="{ 'bg-secondary': isActive('BillSearch') }" clickable
          @click="router.push({ name: 'BillSearch' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="gavel" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Federal</h6>
          </q-item-section>
        </q-item>
        <q-item :class="{ 'bg-secondary': isActive('StateBillSearch') }" clickable
          @click="router.push({ name: 'StateBillSearch' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="account_balance" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">State</h6>
          </q-item-section>
        </q-item>
        <q-item :class="{ 'bg-secondary': isActive('BookmarkedBills') }" class="cursor-pointer" clickable
          @click="router.push({ name: 'BookmarkedBills' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="notifications" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Saved Bills</h6>
          </q-item-section>
        </q-item>
      </q-list>
      <q-scroll-area class="full-height bg-grey-1" style="width: 100%; overflow-x: clip;">
        <q-list padding style="width: 100%;">
          <div v-if="filtersPresent" @click="clearFilters" class="text-body2 q-pl-md clickable-title cursor-pointer">
            Clear Filters</div>
          <h6 class="q-pl-md" style="color: black">States</h6>
          <q-separator inset class="q-my-sm" />
          <div class="full-height bg-grey-1" style="overflow-x: clip; overflow-y: scroll;">
            <q-chip v-for="state in states" :key="state.text" clickable v-ripple :outline="state.selected"
              @click="state.selected = !state.selected" class="q-ml-md grey-3 text-caption" style="width: 50px;">
              <div class="ellipsis">{{ state.text }}</div>
            </q-chip>
          </div>
        </q-list>
      </q-scroll-area>

      <q-list v-if="signedIn" class="absolute-bottom" style="margin-bottom: 20px">
        <q-item clickable @click="router.push({ name: 'SignIn' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Sign Out</h6>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container class="row justify-center bg-tertiary" style="height: 100vh; overflow: auto">
      <div class="column items-center" style="width: 100%;">
        <div class="row justify-end" style="width: 100%; height: 50px;">
          <q-btn v-if="!signedIn" color="primary" label="Sign In" class="q-mr-md q-mt-md" @click="showSignIn = true">
          </q-btn>
          <q-btn v-else rounded color="primary" label="" class="q-mr-md q-mt-md" @click="router.push({ name: 'User' })">
            <q-icon name="person" color="white" />
            <q-tooltip class="bg-primary text-white text-body2" :offset="[10, 10]">My Account</q-tooltip>
          </q-btn>
        </div>
        
        <div class="row justify-center" style="max-width: 1200px">
          <div class="column items-center" style="max-width: 1200px;">
            <q-input dense outlined autogrow standout rounded v-model="search" bg-color="white" autofocus
              placeholder="Search for state bills..." style="width: 500px" class="q-mt-lg q-mb-xs"
              @keydown.enter.prevent="fetchSearchResults">
              <template v-slot:prepend>
                <q-icon v-if="search === ''" name="search" />
                <q-icon v-else name="clear" class="cursor-pointer" @click="search = ''" />
              </template>
            </q-input>

            <div v-if="selectedStates.length > 0" class="row justify-start" style="max-width: 500px; width: 100%;">
            <q-chip v-for="state in selectedStates" :key="state" color="blue" text-color="white" style = "width: 60px;"
              removable remove-icon="close" remove-icon-left @remove="toggleStateSelection(state)">
              {{ state }}
            </q-chip>
          </div>

          <div v-if="searchHasOccurred && !loading" class="row justify-center q-mt-md q-mb-md" style="width: 100%;">
            <q-btn
              color="blue"
              style="font-size: 12px; padding: 5px 10px;"
              @click="advancedSearch"
              title="Will generate a map of bills only relevant to the query."
            >
              <template v-slot:default>
                Generate Map
              </template>
            </q-btn>
          </div>
        </div>
                      
          <div v-if="search === '' && lastSearchQuery === ''" class="row text-center q-mt-md q-mb-md">
            <q-btn v-for="(item, index) in suggestedQs"
              square size="12px"
              color="primary" 
              class="q-ml-md text-caption text-weight-medium cursor-pointer" 
              text-color="white"
              no-caps
              @click.stop="searchSuggestion(item)"
            >
              {{ item }}
            </q-btn>
          </div>

          <q-banner v-if="showNotice" class="q-mt-md q-mb-md bg-yellow text-black rounded-banner z-max"
            style="position: absolute; bottom: 0;" dense>
            <div class="banner-content">
              The following states are not currently supported, but coming soon: Alabama, Arkansas, California, and Hawaii.
            </div>
            <q-btn dense flat round icon="close" @click="showNotice = false" class="close-btn" size="xs" />
          </q-banner>
          <!-- Map Component -->
          <div v-if="lastSearchQuery !== ''" class="full-width row justify-center">
            <USMap :loading="mapLoading" :highlightedStates="highlightedStates" @stateClicked="filterBillsByState" />
          </div>

          
          <div class="row justify-end q-mt-xs q-mb-xs" style="width: 100%;">
          <q-select
            v-if ="showSort"
            v-model="sortBy"
            :options="sortOptions"
            label="Sort by"
            dense
            class="q-mr-md"
            map-options
          />
        </div>



          <BillList :bills="sortedBills" :loading="loading" :searchQuery="searchQuery" />

          
        </div>
      </div>
      <q-page-sticky position="bottom-right" :offset="[30, 30]" class="z-top">

        <q-btn v-if="!buttonDisabled && !rightDrawerOpen" color="primary"
          class="toggle-button items-center justify-center" @click="toggleRightDrawer"
          style="width: 50px; height: 50px; border-radius: 20px" text-color="black"> <img src="/small_logo.png"
            alt="Logo" style="width: 50px; height: 50px" />
        </q-btn>
      </q-page-sticky>
    </q-page-container>


    <!-- Right side drawer for chatting with all bills -->
    <q-drawer side="right" v-model="rightDrawerOpen" class="bg-grey-1" bordered :width="rightDrawerSize">
      <div class="full-width row justify-center q-mb-xl">
        <AllBillAssistant :bills="sortedBills" @close="closeDrawer"></AllBillAssistant>
      </div>
    </q-drawer>
  </q-layout>
  <q-dialog v-model="showSignIn">
    <sign-in-comp @signinComplete="signinComplete" @goToReg="goToRegistration" :linkToReg="false"></sign-in-comp>
  </q-dialog>

  <q-dialog v-model="showSignUp">
    <register-comp role="legislation" @registrationComplete="handleRegistrationComplete" @goToSignIn="goToSignIn"
      :linkToSignIn="false"></register-comp>
  </q-dialog>
</template>



<script>
import { ref, onMounted, computed, provide, watch} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex"; // Import useStore from vuex
import axios from "axios";
import USMap from "@/views/StateLegislation/Components/USMap.vue";
import BillList from "@/views/StateLegislation/Components/BillList.vue";
import AllBillAssistant from "@/views/StateLegislation/Components/AllBillAssistant.vue";

import {
  formatFilters,
  extractUniqueAttributesAndConvertToList,
  filterOpportunitiesBySelections,
} from "@/helpers/search.js";

export default {
  name: "StateBillSearch",
  components: {
    BillList,
    USMap,
    AllBillAssistant,
  },

  setup() {
    const router = useRouter();
    const store = useStore(); // Initialize the Vuex store
    const loading = ref(false);
    const mapLoading = ref(false);
    const showSort = ref(false);
    const leftDrawerOpen = ref(false);
    const rightDrawerOpen = ref(false);
    const smallPercent = 0.3;
    const largePercent = 0.3;
    const rightDrawerSize = ref(window.innerWidth >= 1500 ? window.innerWidth * largePercent : window.innerWidth * smallPercent);
    const search = ref(store.getters.getSearchQuery || ""); // Initialize with the stored search query
    const searchQuery = ref("");
    const searchType = ref("default");
    const searchTypes = ref(["default", "statewide"]);
    const highlightedStates = ref(store.getters.getHighlightedStates || []); // Initialize with the stored highlighted states
    provide('searchQuery', searchQuery);

    const lastSearchQuery = ref("");

    const bills = ref(store.getters.getSearchResults || []); // Initialize with the stored search results

    const states = ref(store.getters.getStates || []);

    const suggestedQs = ref([
      'Medicaid expansion',
      'Education spending',
      'Abortion bans',
      'Background checks',
      'Bail reform',
    ]);

    const policy_areas = ref([]);
    const chambers = ref([]);
    const committees = ref([]);
    const selectedState = ref("");
    const filtersPresent = ref(false);
    const showSignIn = ref(false);
    const showSignUp = ref(false);
    const signedIn = ref(false);
    const showNotice = ref(true);

    const checkLoggedIn = async () => {
      try {
        const signedIn = await store.dispatch("checkToken");
        return signedIn;
      } catch (error) {
        return false;
      }
    };

    onMounted(async () => {
      signedIn.value = await checkLoggedIn();
      if (bills.value.length === 0) {
        // Only fetch data if no results are stored
        try {
          loading.value = true;
          

          const params = new URLSearchParams({ query: "random" });
          const response = await axios.get(`/api/state/statewide-search/?${params}`);
          bills.value = response.data.bills;
          highlightedStates.value =  [];

          await formatFilters(
            response.data.supported_states,
            states
          );
          loading.value = false;
          window.addEventListener('resize', updateRightDrawerSize);
          setTimeout(() => {
            showNotice.value = false;
          }, 3500);

        } catch (error) {

          console.error("Error fetching bills:", error);

        }
      }
    });
    const route = useRoute();
    const isActive = (routeName) => {
      return route.name === routeName;
    };

    const filteredBills = computed(() => {
      const selections = [
        { type: "policy_area", items: policy_areas.value },
        { type: "chamber", items: chambers.value },
        { type: "committee", items: committees.value },
      ];

      const filtered = filterOpportunitiesBySelections(bills.value, selections).filter(bill => {
        if (selectedState.value && selectedState.value.trim() !== "") {
          return bill.state_abbr === selectedState.value;
        }
        return true;
      });


      return filtered;
    });

    const fetchSearchResults = async () => {
      try {
        loading.value = true;
        sortBy.value = "relevance";
        
        searchQuery.value = search.value;
        highlightedStates.value =  [];
        selectedState.value = "";
        const selectedStates = states.value.filter(state => state.selected).map(state => state.text);
        const params = new URLSearchParams({
          query: search.value,
          states: selectedStates.join(','),
        });

        const response = await axios.get(`/api/state/statewide-search/?${params}`);
        bills.value = response.data.bills;

        // Store the search query, results, and highlighted states in Vuex
        store.dispatch('updateSearchQuery', search.value);
        store.dispatch('updateSearchResults', response.data.bills);
        store.dispatch('updateHighlightedStates', response.data.states || []);
        store.dispatch('updateStates', states.value);
        
        lastSearchQuery.value = search.value;
        showSort.value = true;
        loading.value = false;
      } catch (error) {
        console.error("There was an error fetching the data:", error);
        loading.value = false;
      }
    };
    
    const filterBillsByState = (stateName) => {
      selectedState.value = stateName;
    };

    function handleRegistrationComplete() {
      signedIn.value = checkLoggedIn();
      showSignUp.value = false;
    }

    function signinComplete() {
      signedIn.value = checkLoggedIn();
      showSignIn.value = false;
    }

    function goToRegistration() {
      showSignIn.value = false;
      showSignUp.value = true;
    }

    function goToSignIn() {
      showSignUp.value = false;
      showSignIn.value = true;
    }

    function toggleRightDrawer() {
      console.log("Toggling right drawer", rightDrawerOpen.value);
      rightDrawerOpen.value = !rightDrawerOpen.value;
    }

    function updateRightDrawerSize() {
      if (window.innerWidth >= 1500)
        rightDrawerSize.value = window.innerWidth * largePercent;
      else {
        rightDrawerSize.value = window.innerWidth * smallPercent;
      }
    }

    const buttonDisabled = computed(() => {
      return bills.value.length <= 0;
    })

    function closeDrawer(data) {
      console.log("Closing drawer");
      rightDrawerOpen.value = !rightDrawerOpen.value;
    }

    function searchSuggestion(suggested_query) {
      search.value = suggested_query;
      fetchSearchResults();
    }

    const searchHasOccurred = computed(() => {
      return lastSearchQuery.value !== "" && highlightedStates.value.length == 0;
    });
    
    const advancedSearch = async () => {
      try {
        loading.value = true;
        mapLoading.value = true;
        searchQuery.value = search.value;
        selectedState.value = "";
        const selectedStates = states.value.filter(state => state.selected).map(state => state.text);
        const params = new URLSearchParams({
          query: search.value,
          states: selectedStates.join(','),
        });

        const response = await axios.get(`/api/state/advanced-state-search/?${params}`);
        bills.value = response.data.bills;
        highlightedStates.value = response.data.states || [];

        // Store the search query, results, and highlighted states in Vuex
        store.dispatch('updateSearchQuery', search.value);
        store.dispatch('updateSearchResults', response.data.bills);
        store.dispatch('updateHighlightedStates', response.data.states || []);
        
        lastSearchQuery.value = search.value;
        mapLoading.value = false;
        loading.value = false;
      } catch (error) {
        console.error("There was an error fetching the data:", error);
        loading.value = false;
        mapLoading.value = false;
      }
    };

    const toggleStateSelection = (stateAbbr) => {
      const state = states.value.find(s => s.text === stateAbbr);
      if (state) {
        state.selected = !state.selected;
      }
    };

    
    const sortBy = ref("relevance");
    const sortOptions = ref([
      { label: "Relevance", value: "relevance" },
      { label: "Latest Action Date", value: "latest_action_date" }
    ]);


    const sortedBills = computed(() => {
      const sorted = [...filteredBills.value];
      if (sortBy.value.value === "latest_action_date") {
        sorted.sort((a, b) => new Date(b.actions.date) - new Date(a.actions.date));
      } else {
        sorted.sort((a, b) => a.rank - b.rank);
      }
      return sorted;
    });
   

    return {
      router,
      loading,
      signedIn,
      checkLoggedIn,
      leftDrawerOpen,
      rightDrawerOpen,
      toggleRightDrawer,
      rightDrawerSize,
      buttonDisabled,
      search,
      searchQuery,
      filteredBills,
      fetchSearchResults,
      policy_areas,
      chambers,
      committees,
      searchType,
      searchTypes,
      showSignIn,
      showSignUp,
      states,
      mapLoading,
      highlightedStates,
      filterBillsByState,
      handleRegistrationComplete,
      signinComplete,
      selectedState,
      goToRegistration,
      goToSignIn,
      showNotice,
      lastSearchQuery,
      suggestedQs,
      searchSuggestion,
      isActive,
      closeDrawer,
      searchHasOccurred,
      advancedSearch,
      toggleStateSelection,
      filtersPresent,
      selectedStates: computed(() => states.value.filter(state => state.selected).map(state => state.text)),
      sortBy,
      sortOptions,
      sortedBills,
      showSort,
    };
  },
};
</script>

<style scoped>
html, body, #q-app, #app, #app-container {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.q-layout {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensure the main layout container fills the entire screen */
}

.q-page-container {
  flex: 1; /* Takes up the remaining space */
  overflow: auto; /* Only shows a scrollbar if needed */
}

/* Ensure the drawer and header do not expand beyond their content size */
.q-drawer, .header {
  flex-shrink: 0;
}

.rounded-banner {
  position: relative;
  max-width: 1000px;
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 14px;
  /* Make the 'x' button smaller */
}

.banner-content {
  padding-right: 30px;
}
</style>