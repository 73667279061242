<template>
  <div ref = "editorRef" class="editor-styling" @click="focusEditor" :class="{ 'editor-focused': isEditorFocused }">
    <Popup
      v-if="editor" 
      :editor="editor"
      @showAIPopup="handleShowAIPopup"
    />
    <AiPopup
      v-if="aiPopupVisible"
      :editor="editor"
      @closePopup="handleClosePopup"
    />
    <TableOptions v-if="editor && editorRef" :editor="editor" :editorRef="editorRef" />
    <NewLine v-if="editor"  :editor="editor" :editorRef/>
    <EditorContent :editor="editor"/>
  </div>
</template>


<script setup>
import { watch, watchEffect, onBeforeUnmount, ref,onMounted,  defineEmits, nextTick } from 'vue';
import { useEditor, EditorContent, Extension } from '@tiptap/vue-3';
import { Node, mergeAttributes } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import Popup from './Extensions/popup.vue';
import AiPopup from './Extensions/AiPopup.vue';
import NewLine from './Extensions/newline.vue';
import TableOptions from './Extensions/TableOptions.vue';
import Color from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import TextStyle from '@tiptap/extension-text-style';
import { useStorage, useDebounceFn } from "@vueuse/core";
import { ListItem } from '@tiptap/extension-list-item';
import { Table } from "@tiptap/extension-table";
import { TableRow } from "@tiptap/extension-table-row";
import { TableCell } from "@tiptap/extension-table-cell";
import { TableHeader } from "@tiptap/extension-table-header";
import TableExtension from '@tiptap/extension-table';
import Image from '@tiptap/extension-image';
import ImageResize from 'tiptap-extension-resize-image';
import TableResize from './Extensions/tablesetup';
import { columnResizing, tableEditing } from 'prosemirror-tables';


import axios from "axios";

const editorRef = ref(null);
const isEditorFocused = ref(false); 
const emit = defineEmits(['update:currentValue']);
const props = defineProps({
  defaultValue: Object,
  storageKey: {
    type: String,
    default: "novel__content",
  },
  onUpdate: {
    default: () => {},
  },
  onDebouncedUpdate: {
    default: () => {},
  },
  debounceDuration: {
    type: Number,
    default: 750,
  }
});

const currentValue = ref(props.defaultValue || {});


const content = useStorage(props.storageKey, props.currentValue); 

const Section = Node.create({
  name: 'section',

  // You might want to add additional attributes based on your needs
  addAttributes() {
    return {
      id: {
        default: null,
      },
    };
  },

  group: 'block',
  draggable: true, // Enable draggable

  // Defining how the node will be rendered in the editor
  parseHTML() {
    return [
      {
        tag: 'section', // Defines which HTML tag corresponds to this node
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['section', mergeAttributes(HTMLAttributes), 0];
  },
});

const editor = useEditor({
  extensions: [
    StarterKit,
    TableExtension.configure({
      resizable: true
    }),
    Underline,
    Section,
    Color,
    Highlight,
    TextStyle,
    TableRow,
    TableCell,
    TableHeader,
    ImageResize,
    columnResizing(),
    tableEditing(),
    Link.configure({
          openOnClick: true,
        }),
    ],
  onUpdate: ({ editor }) => { // Corrected destructuring
    // props.onUpdate(editor.editor);
    // debouncedUpdate({ editor }); // Pass an object with the editor property
    emit('update:currentValue', editor.getJSON());
  },
  editorProps: {
    handleDOMEvents: {
      focus: () => {
        isEditorFocused.value = true;
        return false; 
      },
      blur: () => {
        isEditorFocused.value = false;
        return false;
      }
    }
  }
});

const debouncedUpdate = useDebounceFn(({ editor }) => {
  const json = editor.getJSON();
  content.value = json;
  props.onDebouncedUpdate(editor);
}, props.debounceDuration);

const hydrated = ref(false);
watchEffect(() => {
  if (editor.value && !hydrated.value) {
    const initialContent = props.defaultValue ? props.defaultValue : JSON.parse(content.value);
    const setEditorContent = async () => {
      await nextTick();
      try {
        editor.value.commands.setContent(initialContent);
        currentValue.value = initialContent; 
      } catch (e) {
        console.error('Failed to set initial content', initialContent, e);
      }
      hydrated.value = true;
    };
    setEditorContent();
  }
});

watch(() => props.defaultValue, (newValue) => {
  if (newValue && editor.value && JSON.stringify(newValue) !== JSON.stringify(editor.value.getJSON())) {
    editor.value.commands.setContent(newValue);
  }
}, { deep: true });





defineExpose({
  editor,handleShowAIPopup, handleClosePopup
});

const aiPopupVisible = ref(false); 
const aiPopupPosition = ref({ top: 0, left: 0 }); 
function handleClosePopup() {
  aiPopupVisible.value = false;
}

const selectedText = ref('');

function handleShowAIPopup() {
  aiPopupVisible.value = true;
}

async function uploadImage(file) {
    try {
      const formData = new FormData();
      formData.append('image', file);
      console.log("UPLOAD IMAGE TRIGGERING")
      const response = await axios.post('/api/v1/upload-image/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.data && response.data.imageUrl) {
        return response.data.imageUrl; // The URL of the uploaded image
      }
    } catch (error) {
      console.error('Image upload error:', error);
      return null;
    }
  }

   const handleDrop = async (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files).filter(file => file.type.startsWith('image/'));
    if (files.length > 0) {
      await insertImage(files[0]);
    }
  };

    onMounted(() => {
  
    document.addEventListener('paste', async (event) => {
      if (event.clipboardData) {
        const files = Array.from(event.clipboardData.files).filter(file => file.type.startsWith('image/'));
        if (files.length > 0) {
          event.preventDefault();
          await insertImage(files[0]);
        }
      }
    });
  
    document.addEventListener('drop', handleDrop);
  });

  async function insertImage(file) {
    const imageUrl = await uploadImage(file);
    if (imageUrl) {
      editor.value.chain().focus().setImage({ src: imageUrl }).run();
    }
  }

  function focusEditor(event) {
  // Check if the event target is not a part of the popup
  if (!event.target.closest('.popup-class')) { 
    if (editor.value) {
      editor.value.commands.focus();
    }
  }
}
</script>


<style>

.editor-styling {
  position: relative;
  min-height: 500px;
  width: full;
  margin-left: auto;
  margin-right: auto;
  max-width: 824px;
  border-color: stone-200;
  background-color: white;
  padding: 48px; /* Converted from 'p-12' where 1 unit is typically 4px */
  padding-left: 32px; 
  padding-right: 32px;
  background-color: white;
  border-width: 1px;
  margin-bottom: 20vh;
  border-color: #e2e8f0; /* 'border-stone-200', you need to adjust this value based on your actual color palette */
  border-radius: 0.5rem; /* 'sm:rounded-lg', assuming 'lg' is about 0.5rem */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /* 'sm:shadow-lg' */
}



@media (max-width: 1024px) {
  .editor-styled {
    border-width: 0; /* No border on smaller screens */
    border-radius: 0; /* No rounded corners on smaller screens */
    box-shadow: none; /* No shadow on smaller screens */
  }
}


.editor-focused .editor-wrapper {
  border-width: 2px; 
  border-color: #666; 
}

.ProseMirror:focus {
    outline: none;
  }



.magic-wand {
  position: absolute;
  z-index: 101; /* Ensure this is above the floating toolbar */
  cursor: pointer; /* Indicates it's clickable */
}

.ProseMirror a {
    color: blue !important;
    text-decoration: underline !important;
}

.ProseMirror .is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--novel-stone-400);
  pointer-events: none;
  height: 0;
}
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--novel-stone-400);
  pointer-events: none;
  height: 0;
}


.ProseMirror img {
  transition: filter 0.1s ease-in-out;
  max-width: 100%;
  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &.ProseMirror-selectednode {
    outline: 3px solid #5abbf7;
    filter: brightness(90%);
  }
}

.img-placeholder {
  position: relative;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 3px solid var(--novel-stone-200);
    border-top-color: var(--novel-stone-800);
    animation: spinning 0.6s linear infinite;
  }
}

@keyframes spinning {
  to {
    transform: rotate(360deg);
  }
}

/* Custom TODO list checkboxes – shoutout to this awesome tutorial: https://moderncss.dev/pure-css-custom-checkbox-style/ */

ul[data-type="taskList"] li > label {
  margin-right: 0.2rem;
  user-select: none;
}

@media screen and (max-width: 768px) {
  ul[data-type="taskList"] li > label {
    margin-right: 0.5rem;
  }
}

ul[data-type="taskList"] li > label input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--novel-white);
  margin: 0;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 5px;
  border: 2px solid var(--novel-stone-900);
  margin-right: 0.3rem;
  display: grid;
  place-content: center;

  &:hover {
    background-color: var(--novel-stone-50);
  }

  &:active {
    background-color: var(--novel-stone-200);
  }

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em;
    transform-origin: center;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }
}

ul[data-type="taskList"] li[data-checked="true"] > div > p {
  color: var(--novel-stone-400);
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}


/* Base styles for h1, h2, and h3 within an article or section with a 'prose' class */
.ProseMirror h1 {
    font-size: 2.25rem; /* equivalent to prose-xl for larger screens */
    margin-top: 0;
    margin-bottom: 0.8rem;
    font-weight: bold;
    line-height: 1.2;
}

.ProseMirror h2 {
    font-size: 1.5rem; /* slightly smaller than h1 */
    margin-top: 2rem;
    margin-bottom: 0.75rem;
    font-weight: semibold;
    line-height: 1.3;
}

.ProseMirror h3 {
    font-size: 1.25rem; /* smaller than h2 */
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: normal;
    line-height: 1.4;
    text-decoration: underline; /* as indicated by prose-headings:underline */
}

/* Responsive adjustments (similar to Tailwind's responsive classes) */
@media (max-width: 768px) {
    .ProseMirror h1 {
        font-size: 1.875rem;
    }
    .ProseMirror h2 {
        font-size: 1.375rem;
    }
    .ProseMirror h3 {
        font-size: 1.125rem;
    }
}

.ProseMirror table .column-resize-handle {
  cursor: col-resize; /* This cursor type indicates horizontal resizing */
}

/* Optionally, style the handle itself for better visibility */
.ProseMirror table .column-resize-handle::after {
  content: '';
  position: absolute;
  top: 0;
  right: -2px; /* Adjust based on the handle's width */
  width: 4px; /* Width of the resize handle */
  height: 100%;
  background-color: rgba(0,0,0,0.2); /* Visible handle */
}
/* Inline code */
.ProseMirror code {
    font-family: monospace;
    background-color: rgba(135, 131, 120, 0.15);
    padding: 0.2em 0.4em;
    font-size: 0.875em;
    border-radius: 4px;
    color: #d63384; /* Adjust color based on your theme */
}

/* Block-level code (usually within a pre element) */
.ProseMirror pre {
    background-color: #2d2d2d; /* Dark background for visibility */
    color: #f8f8f2;
    overflow-x: auto;
    padding: 1em;
    border-radius: 6px;
    font-family: 'Courier New', Courier, monospace;
    line-height: 1.5;
}

.ProseMirror pre code {
    background: none;
    padding: 0;
    font-size: 1em;
    color: inherit; /* Ensures code inherits from pre element */
}

/* Blockquote styling */
.ProseMirror blockquote {
    padding-left: 1em;
    border-left: 4px solid #9ca3af; /* Light gray color */
    margin: 1em 0;
    color: #6b7280; /* Dark gray for text */
    font-style: italic;
    background-color: #f9fafb; /* Light background to stand out */
}

/* Adjustments for blockquote content */
.ProseMirror blockquote p {
    margin: 0; /* Remove default margins */
    padding: 0.5em 0;
    line-height: 1.6;
}


:root {
  --novel-black: rgb(0 0 0);
  --novel-white: rgb(255 255 255);
  --novel-stone-50: rgb(250 250 249);
  --novel-stone-100: rgb(245 245 244);
  --novel-stone-200: rgb(231 229 228);
  --novel-stone-300: rgb(214 211 209);
  --novel-stone-400: rgb(168 162 158);
  --novel-stone-500: rgb(120 113 108);
  --novel-stone-600: rgb(87 83 78);
  --novel-stone-700: rgb(68 64 60);
  --novel-stone-800: rgb(41 37 36);
  --novel-stone-900: rgb(28 25 23);

  --novel-highlight-default: #ffffff;
  --novel-highlight-purple: #f6f3f8;
  --novel-highlight-red: #fdebeb;
  --novel-highlight-yellow: #fbf4a2;
  --novel-highlight-blue: #c1ecf9;
  --novel-highlight-green: #acf79f;
  --novel-highlight-orange: #faebdd;
  --novel-highlight-pink: #faf1f5;
  --novel-highlight-gray: #f1f1ef;
}

.ProseMirror table {
    border-collapse: collapse;
  }

  .ProseMirror th,
  .ProseMirror td {
    border: 1px solid black;
    padding: 8px; 
  }

  .editor-styling .ProseMirror table {
  table-layout: fixed; /* This forces columns to respect the set widths, ignoring content size */
  width: 100%; /* Ensures table is responsive to editor width */
}
.editor-styling .ProseMirror table td,
.editor-styling .ProseMirror table th {
  word-wrap: break-word; /* Allows long words to be broken and wrapped to the next line */
  white-space: normal; /* Overrides any 'nowrap' that might be applied elsewhere */
  overflow-wrap: break-word; /* Ensures that words break to prevent overflow */
  hyphens: auto; /* Automatically add hyphens where appropriate */
}


</style>