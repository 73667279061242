<template>
  <q-btn
    flat
    class="q-mx-xs"
    :style="{ margin: '0' }"
    @click.stop
  >
    <component :is="activeItem.icon" class="q-mr-sm" :style="{ marginRight: '4px', width: '24px', height: '24px' }" />

    <q-icon name="keyboard_arrow_down" class="small-dropdown-icon" :style="{ padding: '2px', width: '2px', height: '2px' }" />
    <q-menu ref="menuRef" v-model="isNodeOpen" :style="{ zIndex: 100000 }">
      <q-scroll-area style="height: 320px; width: 200px;">
      <q-list
        style="width: 200px; max-height: 320px"
        class="rounded border border-stone-200 bg-white shadow-xl"
      >
        <q-item
          v-for="(item, index) in nodeItems"
          :key="index"
          clickable
          v-ripple
          class="q-my-xs text-stone-600 hover:bg-stone-100 flex items-center"
          @click="executeCommand(item)"
          :style="{ height: '20px', padding: '0px 0px' }"
        >
          <div class="flex items-center" :style="{ marginRight: '12px' }">
            <component :is="item.icon" :style="{ width: '24px', height: '24px', marginRight: '12px' }" />
            <span class="menu-item-text" :style="{ textAlign: 'left' }">{{ item.name }}</span>
          </div>
        </q-item>
      </q-list>
    </q-scroll-area>
    </q-menu>       
  </q-btn>
</template>



<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { Editor } from "@tiptap/core";
import {
  Check,
  ChevronDown,
  Heading1,
  Heading2,
  Heading3,
  TextQuote,
  ListOrdered,
  TextIcon,
  Code,
  List,
  CheckSquare,
} from "lucide-vue-next";

// Props are defined using the defineProps function in the <script setup>
const props = defineProps({
  editor: {
    type: Object,
    required: true
  }
});

const isNodeOpen = ref(false);
const linkUrl = ref(props.editor.getAttributes('link').href || '');

function toggleMenu() {
  isNodeOpen.value = !isNodeOpen.value;
  // if (isNodeOpen.value) {
  //   document.addEventListener('mousedown', handleClickOutside);
  // } else {
  //   document.removeEventListener('mousedown', handleClickOutside);
  // }
}

// Event handlers are now defined as functions
const menuRef = ref(null);

function handleClickOutside(event) {
  // Ensure menuRef.value is the actual DOM node
  const menuElement = menuRef.value.$el || menuRef.value;

  if (menuElement && !menuElement.contains(event.target)) {
    isNodeOpen.value = false;
    document.removeEventListener('mousedown', handleClickOutside);
  }
}


// onBeforeUnmount(() => {
//   document.removeEventListener('mousedown', handleClickOutside);
// });

const nodeItems = ref([
        {
          name: "Text",
          icon: TextIcon,
          command: () =>
            props.editor.chain().focus().toggleNode("paragraph", "paragraph").run(),
          isActive: () =>
            props.editor.isActive("paragraph") &&
            !props.editor.isActive("bulletList") &&
            !props.editor.isActive("orderedList"),
        },
        {
          name: "Heading 1",
          icon: Heading1,
          command: () =>
            props.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => props.editor.isActive("heading", { level: 1 }),
        },
        {
          name: "Heading 2",
          icon: Heading2,
          command: () =>
            props.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => props.editor.isActive("heading", { level: 2 }),
        },
        {
          name: "Heading 3",
          icon: Heading3,
          command: () =>
            props.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => props.editor.isActive("heading", { level: 3 }),
        },
        // {
        //   name: "To-do List",
        //   icon: CheckSquare,
        //   command: () => props.editor.chain().focus().toggleTaskList().run(),
        //   isActive: () => props.editor.isActive("taskItem"),
        // },
        {
          name: "Bullet List",
          icon: List,
          command: () => props.editor.chain().focus().toggleBulletList().run(),
          isActive: () => props.editor.isActive("bulletList"),
        },
        {
          name: "Numbered List",
          icon: ListOrdered,
          command: () => props.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => props.editor.isActive("orderedList"),
        },
        {
          name: "Quote",
          icon: TextQuote,
          command: () =>
            props.editor
              .chain()
              .focus()
              .toggleNode("paragraph", "paragraph")
              .toggleBlockquote()
              .run(),
          isActive: () => props.editor.isActive("blockquote"),
        },
        {
          name: "Code",
          icon: Code,
          command: () => props.editor.chain().focus().toggleCodeBlock().run(),
          isActive: () => props.editor.isActive("codeBlock"),
        }
]);

const executeCommand = (item) => {
  if (item && item.command) {
    item.command();
  }
};

const activeItem = computed(() => {
  const active = nodeItems.value.find(item => item.isActive());
  return active ? active : { name: "Multiple", icon: 'help_outline' };
});


</script>


<style scoped>

</style>
