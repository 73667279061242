<template>
  <div style="width: fit-content;" class="justify-center">
    <div class="row">
      <q-skeleton type="rect" width="800px" height="500px" v-if="loading"></q-skeleton>
      <svg v-else v-if="highlightedStates.length > 0" :width="mapSize" :height="mapSize / 1.6" viewBox="200 0 680 560">
        <g>
          <path v-for="state in states" :key="state.id" :d="state.path"
            :fill="highlightedStates.includes(state.name) ? 'var(--accent)' : 'white'" @mouseover="hoverState(state)"
            @mouseout="unhoverState" @click="stateClicked(state)" :data-state-id="state.id" />
        </g>
      </svg>
    </div>
    <div class="row justify-end">
        <q-btn
        flat
        color="primary"
        text-color="black"
        v-if="selectedState != null && !loading"
        @click="resetSelection()"
        style="height: 10%;"
        no-caps
        label="Clear Filters"
      />
      <!-- <q-tooltip v-if="hoveredState" :anchor="hoveredStateAnchor" self="top middle">
        {{ hoveredState.name }}
      </q-tooltip> -->
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { QTooltip } from 'quasar';
import statePaths from './state_paths.json';  // Adjust the path as necessary
import { mapState } from 'vuex';

export default {
  name: 'USMap',
  components: {
    QTooltip
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    highlightedStates: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const states = ref(statePaths);

    const hoveredState = ref(null);
    const hoveredStateAnchor = ref('');

    const selectedState = ref(null);

    // map size
    const smallPercent = 0.45;
    const largePercent = 0.3;
    const mapSize = ref(window.innerWidth < 2000 ? window.innerWidth * smallPercent : window.innerWidth * largePercent);

    const hoverState = (state) => {
      const targetElement = document.querySelector(`[data-state-id="${state.id}"]`);
      const boundingRect = targetElement.getBoundingClientRect();
      hoveredState.value = state;
      hoveredStateAnchor.value = `${boundingRect.x + (boundingRect.width / 2)},${boundingRect.y}`;
    };

    const unhoverState = () => {
      hoveredState.value = null;
      hoveredStateAnchor.value = '';
    };

    const stateClicked = (state) => {
      if (selectedState.value != null) {
        const targetElement = document.querySelector(`[data-state-id="${selectedState.value.id}"]`);
        targetElement.classList.remove('clicked');
      }
      const targetElement = document.querySelector(`[data-state-id="${state.id}"]`);
      targetElement.classList.add('clicked');
      selectedState.value = state
      emit('stateClicked', state.name);
    };

    const resetSelection = () => {
      if (selectedState.value != null) {
        const targetElement = document.querySelector(`[data-state-id="${selectedState.value.id}"]`);
        targetElement.classList.remove('clicked');
      }
      selectedState.value = null;
      emit('stateClicked', "");
    }

    onMounted(() => {
      window.addEventListener('resize', resizeMap);
    });

    function resizeMap() {
      mapSize.value = window.innerWidth < 2000 ? window.innerWidth * smallPercent : window.innerWidth * largePercent;
    }

    return {
      states,
      hoveredState,
      hoveredStateAnchor,
      hoverState,
      unhoverState,
      stateClicked,
      selectedState,
      resetSelection,
      mapSize,
    };
  }
};
</script>

<style scoped>
/* Add styles for the map and tooltips */
path {
  cursor: pointer;
  stroke: #000;
  stroke-width: 1;
  transition: fill 0.3s;
}

path:hover {
  fill: lightblue;
}

.clicked {
  fill: var(--dark-blue)
}
</style>