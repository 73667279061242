<template>
  <div>
    <q-card class="q-pa-md q-ma-md">
      <q-card-section horizontal>
        <q-card-section>
          <div class="q-gutter-y-md">
            <div class="row">
              <q-img
              :src="`/department_logos/${opp.agency}.jpg`"
                spinner-color="white"
                style="height: 120px; max-width: 120px; border-radius: 50%;"
                class='col q-pa-sm'
              ></q-img>
              <div class="col full-height q-pl-lg">
                <h5 @click="navigateToGrantInfo(opp.id)" class="row clickable-title cursor-pointer">{{ opp.gen_title }}</h5>
                <div class="row justify-start items-center">
                  <h6 style="padding-right: 10px;">
                    {{ Object.keys(opp.department).length > 0 && Object.keys(opp.department)[0] !== '' ? Object.keys(opp.department)[0] : opp.agency }}
                  </h6>
              <q-chip
                v-for="(value, program) in opp.program"
                :key="program"
                class="text-weight-bold q-mr-sm"
                color="primary"
                text-color="white"
              >
                {{ program }}
              </q-chip>
              <q-chip
                v-for="(value, phase) in opp.phase"
                :key="phase"
                class="text-weight-bold q-mr-sm"
                color="accent"
                text-color="white"
              >
                {{ phase }}
              </q-chip>
              
            </div>
              </div>
            </div>
            <p class="text-weight-regular" style="text-align: justify; letter-spacing: 0.02em;">{{ opp.gen_description }}</p>
          </div>
          <div class="q-gutter-sm q-mt-md">
            <q-chip
              v-for="category in sortedCategories"
              :key="category"
              class="q-mr-sm q-mb-sm"
              dense
              outline
            >
              {{ category }}
            </q-chip>
          </div>
        </q-card-section>
        <q-card-actions vertical class="justify-around q-px-md">
          <q-btn
            flat
            round
            color="accent"
            size="lg"
            @click.stop="triggerCheck(opp.id)"
            ><font-awesome-icon
              icon="fas fa-wand-magic-sparkles"
              :class="{ icon: docs_uploaded, 'disabled-icon': !docs_uploaded }"
            /><q-tooltip
              class="bg-accent text-white text-body2"
              :offset="[10, 10]"
            >
              Check fit
            </q-tooltip></q-btn
          >
          <q-btn
            flat
            round
            transparent
            :disabled="!isLoggedIn"
            color="primary"
            :icon="isBookmarked ? 'bookmark' : 'bookmark_border'"
            size="lg"
            @click="toggleBookmark(opp.id)"
            ><q-tooltip
            v-if="!isLoggedIn"
              class="bg-primary text-body2 flex flex-center text-center non-selectable"
              style="width: 150px"
              :offset="[10, 10]"
            >
              Must be signed in to bookmark
            </q-tooltip><q-tooltip
            v-if="isLoggedIn"
              class="bg-primary text-body2 flex flex-center text-center non-selectable"
              style="width: 150px"
              :offset="[10, 10]"
            >
              Bookmark Opportunity
            </q-tooltip></q-btn
          >
        </q-card-actions>
      </q-card-section>
      <q-separator v-if="loadingRec"></q-separator>
      <q-card-section horizontal v-if="loadingRec">
        <q-card-section>
          <div class="q-gutter-y-md">
            <div class="text-subtitle1">Aidy's Recommendation</div>
            <div class="row">
              <div v-if="!grantRec" style="padding: 10px;">
                <q-spinner-dots size="2rem"/>
              </div>
              <p v-if="grantRec" class="text-weight-regular" style="text-align: justify; letter-spacing: 0.02em;">{{ grantRec }}</p>
            </div>
          </div>
        </q-card-section>
      </q-card-section>
    </q-card>

    <q-dialog v-model="requestDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section class="text-center">
          <div class="text-h7 q-mb-sm">Should Aidy support this program?</div>
          <div class="text-h5">Let us Know!</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input
            class="q-mb-md"
            v-model="name"
            placeholder="Your Name"
            autofocus
            ><template v-slot:prepend>
              <q-icon name="person" color="orange" /> </template
          ></q-input>
          <q-input
            class="q-mb-md"
            v-model="companyName"
            placeholder="Company Name"
            autofocus
            ><template v-slot:prepend>
              <q-icon name="store" color="orange" /> </template
          ></q-input>
          <q-input
            class="q-mb-md"
            v-model="email"
            placeholder="Email"
            type="email"
            autofocus
            ><template v-slot:prepend>
              <q-icon name="mail" color="orange" /> </template
          ></q-input>
          <q-input
            class="q-mb-md"
            v-model="comments"
            placeholder="Comments/Feedback"
            autofocus
            ><template v-slot:prepend>
              <q-icon name="chat_bubble" color="orange" /> </template
          ></q-input>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancel" v-close-popup></q-btn>
          <q-btn
            flat
            label="Request!"
            v-close-popup
            @click="requestOpp(opp.id, opp.title)"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="checkFitDialog" persistent>
      <q-card style="min-width: 500px">
        <q-card-section class="text-center">
          <h5 class="q-mb-sm">Tell us about your company...</h5>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-input
            class="q-mb-md"
            v-model="companyName"
            label="Company Name"
            placeholder="Aidy"
            autofocus
            >
            <template v-slot:prepend>
              <q-icon name="store" color="orange" /> 
            </template>
          </q-input>
          <q-input
            class="q-mb-md"
            v-model="searchQuery"
            type="textarea"
            label="Description"
            placeholder="Describe what your company works on, or just paste in your company/project blurb..."
            autofocus
          >
            <template v-slot:prepend>
              <q-icon name="description" color="orange" /> 
            </template>
          </q-input>
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancel" v-close-popup></q-btn>
          <q-btn
            flat
            label="Check Fit"
            v-close-popup
            :disabled="!companyName || !searchQuery"
            @click.stop="checkFit(opp.id)"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
  
<script>
import { computed, ref, toRefs, inject, watchEffect } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from 'vuex';

export default {
  name: "OppCard",
  props: {
    opp: {
      type: Object,
      required: true,
    },
    bookmarked: {
      type: Boolean,
      required: false,
    },
    // searchQuery: String,
  },
  setup(props, { emit }) {
    const { opp } = toRefs(props);
    const searchQuery = inject('searchQuery');
    const companyName = inject('companyName');
    const router = useRouter();
    const requestDialog = ref(false);
    const checkFitDialog = ref(false);
    const isBookmarked = ref(false); // Initial bookmark status
    watchEffect(() => {
      isBookmarked.value = props.bookmarked || false;
    });

    const name = ref("");
    const email = ref("");
    const comments = ref("");

    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isLoggedIn);

    const sortedCategories = computed(() => {
      return Object.keys(props.opp.categories || {}).sort();
    });

    const grantRec = ref(null);
    const loadingRec = ref(false);

    function navigateToGrantInfo(id) {
      router.push(`/grant-info/${id}`);
    }

    async function requestOpp(id, title) {
      try {
        const params = new URLSearchParams({
          name: name.value,
          company_name: companyName.value,
          email: email.value,
          comments: comments.value,
          opp_id: id,
          opp_title: title,
        });
        const response = await axios.post(`/api/v1/sbir-request/?${params}`);
        // console.log("RESPONSE", response);
      } catch (error) {
        console.error("There was an error fetching the data:", error);
        state.error = error; // Store the error in state, if any
      }
    }

    function triggerCheck(id) {
      if (searchQuery !== null && searchQuery.value !== "" && companyName !== null && companyName.value !== "") {
        checkFit(id);
      } else {
        checkFitDialog.value = true;
      }
    }

    function checkFit(id) {
      // let query = searchQuery ? searchQuery.value : companyDescription.value;
      // console.log("OPP CARD QUERY", query);
      try {
        loadingRec.value = true;
        // Fetch data using POST method
        fetch(`${axios.defaults.baseURL}/api/v1/sbir-check-fit/`, {
          //Be sure to update this for different environments
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            company_name: companyName.value,
            company_description: searchQuery.value,
            opp_id: id
          }),
        })
        .then((response) => {
          const reader = response.body.getReader();
          let chunks = "";
          let firstChunkReceived = false;

          reader.read().then(function processChunk({ done, value }) {
            if (done) {
              return;
            }

            chunks += new TextDecoder().decode(value);

            // Check for complete JSON objects and process them
            while (chunks.indexOf("}") !== -1) {
              let chunkEnd = chunks.indexOf("}") + 1;
              let chunk = chunks.substring(0, chunkEnd);
              chunks = chunks.substring(chunkEnd);

              let data = JSON.parse(chunk);
              let contentText = data.content.replace("<br>", " "); // Replace line breaks with spaces

              // Hide the typing element when the first chunk is received
              if (!firstChunkReceived) {
                grantRec.value = contentText;
                firstChunkReceived = true;
              } else {
                grantRec.value += contentText;
              }
            }

            return reader.read().then(processChunk);
          });
        })
        .catch((error) => {
          this.isTyping = false;
          console.error("Error:", error);
          // Handle error, possibly pushing an error message to `interactions`
          this.interactions.push({
            text: "Sorry, an error occurred.",
            from: "bot",
          });
        });
      } catch (error) {
        console.error("There was an error fetching the data:", error);
        state.error = error; // Store the error in state, if any
      }
    }

    const toggleBookmark = async (opp_id) => {
      emit('bookmark-toggle', opp_id);
      // error.value = null;
      // message.value = null;

      // try {
      //   console.log("HERE");
      //   const response = await axios.post('/api/v1/toggle-fo-bookmark', {
      //     params: { fo_id: opp_id},
      //   });
      //   console.log('here2');
      //   message.value = response.data.message;
      //   isBookmarked.value = !isBookmarked.value;
      // } catch (err) {
      //   error.value = err.response?.data?.error || "An unknown error occurred";
      // }
    };

    return {
      sortedCategories,
      navigateToGrantInfo,
      requestDialog,
      checkFitDialog,
      name,
      companyName,
      email,
      requestOpp,
      triggerCheck,
      searchQuery,
      checkFit,
      grantRec,
      loadingRec,
      isLoggedIn,
      toggleBookmark,
      isBookmarked,
    };
  },
};
</script>
<style scoped>
.clickable-title {
  transition: color 0.1s, font-weight 0.1s;
}

.clickable-title:hover {
  color: rgba(0, 150, 250); /* This is an example color, change it as needed */
}
</style>