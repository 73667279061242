<template>
  <div class="q-pa-md items-start" style="max-width: 600px">
    <grant-edit v-if="grantData" :grantData="grantData"></grant-edit>
    <div v-else class="spinner-container">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GrantEditor",
  data() {
    return {
      grantData: null,
    };
  },
  created() {
    this.getDoc();
  },
  methods: {
    async getDoc() {
      try {
        const grantId = this.$route.params.id; // Get the ID from the URL
        const response = await axios.get(`api/v1/get-grant/`, {
          params: {
            grant_id: grantId,
          },
        });
        this.grantData = response.data;
        console.log("GRANT RETURNED:",this.grantData);
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }
  },
};
</script>

<style scoped>
.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically in the container */
  align-items: right; /* Centers horizontally in the container */
  /* text-align: center;      Ensures text within the children is centered */
  /* max-height: 100vh;           Takes up the full viewport height */
  padding: 20px; /* Optional padding */
  box-sizing: border-box;
  width: 100%;
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Full viewport height minus any headers or footers if they exist */
  height: 100vh;
  /* Or if it should be the full height of a parent container, you could use 100% instead */
  /* height: 100%; */
  width: 100%;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 125px;
  height: 125px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
