import { Extension } from '@tiptap/core';
import { tableEditing, columnResizing } from 'prosemirror-tables';

const TableResize = Extension.create({
  name: 'tableResize',

  addProseMirrorPlugins() {
    return [
      columnResizing(),
      tableEditing(), // Allows table editing features like adding/removing columns
    ];
  },
});

export default TableResize;
