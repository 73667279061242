<template>
  <div v-if="inputData">
    <div v-if="showPopup" class="popup-wrapper">
      <PopUp :text="popupText" @response="handlePopupResponse"/>
    </div>
    <q-input
      v-model="inputData.name"
      label="Input Name:"
      @change="updateInputUI()"
    />
    <br>
    <q-input
      v-model="inputData.label_text"
      label="Label Text:"
      filled
      @change="updateInputUI()"
    />
    <br>
    <q-input
      v-model="inputData.default"
      label="Default:"
      filled
      @change="updateInputUI()"
    />
    <br>
    <q-input
      v-model="inputData.default_text"
      label="Default Text:"
      filled
      @change="updateInputUI()"
    />
    <br>
    <q-input
      v-model="inputData.default_num"
      label="Default Number:"
      filled
      @change="updateInputUI()"
    />
    <br>
    <q-input
      v-model="inputData.placeholder"
      label="Placeholder:"
      filled
      @change="updateInputUI()"
    />
    <br>
    <q-input
      v-model="inputData.units"
      label="Units:"
      filled
      @change="updateInputUI()"
    />
    <br>
    <q-input
      v-model="inputData.cell_id"
      label="Cell ID:"
      filled
      @change="updateInputUI()"
    />
    <br>
    <q-checkbox 
      color="primary"  
      keep-color
      indeterminate-icon="check_box_outline_blank"
      unchecked-icon="check_box_outline_blank"
      v-model="inputData.repeatable"
      label="Repeatable:"
      @update:model-value="updateInputUI()"
    />
    <br>
    <q-checkbox 
      color="primary"  
      keep-color
      indeterminate-icon="check_box_outline_blank"
      unchecked-icon="check_box_outline_blank"
      v-model="inputData.editable"
      label="Editable:"
      @update:model-value="updateInputUI()"
    />
    <br>
    <div class="json-editor">
      <VueJSONEditor
        :content="{json: inputData.calculation}"
        :onChange="updateCalculation"
        label="Calculation Logic"
      />
    </div>
    <q-select 
      v-model="inputData.preset_type" 
      :options="preset_types"
      label="Preset Type:"
      color="primary"
      @update:model-value="updateInputUI()"
    />
    <br>
    <q-select 
      v-if="inputData.preset_type=='custom'"
      v-model="inputData.input_type" 
      :options="input_types"
      label="Input Type:"
      color="primary"
      @update:model-value="updateInputUI()"
    />
    <br>
    <div v-if="inputData.preset_type=='custom'" class="json-editor">
      <VueJSONEditor
        :content="{json: inputData.config}"
        :onChange="updateConfig"
        label="Config"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "InputEditor",
  props: {
    inputData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      serverData: null,
      addingNewQuestion: false,
      showPopup: false,
      popupText: 'Are you sure you want to delete this?',
      pendingAction: null,
      changed: false,
      input_types: [
        "text",
        "number",
        "radio",
        "checkbox",
        "upload"
      ],
      preset_types: [
        "state-abbreviation",
        "dollars",
        "percent",
        "phone",
        "zip",
        "date",
        "time",
        "datetime",
        "ssn",
        "ein",
        "uei",
        "email",
        "number",
        "text",
        "textarea",
        "radio",
        "checkbox",
        "upload",
        "custom"
      ]
    };
  },
  mounted() {
    this.serverData = JSON.parse(JSON.stringify(this.inputData));
    // console.log("INPUT DATA:",this.inputData);
    // Assuming docData is fetched or set here
    this.inputData.changed = false;
  },
  methods: {
    onDeleteClicked(action, ...params) {
      this.showPopup = true;
      this.pendingAction = { action, params };
    },
    handlePopupResponse(response) {
      this.showPopup = false;
      if (response === 'yes' && this.pendingAction) {
        this.pendingAction.action(...this.pendingAction.params);
      }
      this.pendingAction = null;
    },

    saveInput(question_id = null) {
      try {
          // Add the question_id to the input data if it's provided
          if (question_id !== null) {
              this.inputData.question_id = question_id;
          }
          const url = "api/v1/update-input/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"input": this.inputData}, { headers })
              .then(response => {
                  this.serverData.name = this.inputData.name;
                  this.serverData.description = this.inputData.input_type;
                  this.serverData.label_text = this.inputData.label_text;
                  this.serverData.default = this.inputData.default;
                  this.serverData.default_num = this.inputData.default_num;
                  this.serverData.default_text = this.inputData.default_text;
                  this.serverData.cell_id = this.inputData.cell_id;
                  this.serverData.units = this.inputData.units;
                  this.serverData.placeholder = this.inputData.placeholder;
                  this.serverData.calculation = this.inputData.calculation;
                  this.serverData.config = this.inputData.config;
                  this.serverData.preset_type = this.inputData.preset_type;
                  this.serverData.editable = this.inputData.editable;
                  this.inputData.changed = false;
                  console.log("Successfully updated input")
              })
              .catch(error => {
                  console.error(
                      "Error updating input:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating input ${this.inputData.name}:`,
              error
          );
      }
    },

    updateInputUI() {
      console.log("UPDATING INPUT:",this.inputData.name);

      this.inputData.changed = this.inputData.name !== this.serverData.name ||
            this.inputData.input_type !== this.serverData.input_type ||
            this.inputData.label_text !== this.serverData.label_text ||
            this.inputData.default !== this.serverData.default||
            this.inputData.default_num !== this.serverData.default_num ||
            this.inputData.default_text !== this.serverData.default_text ||
            this.inputData.units !== this.serverData.units ||
            this.inputData.cell_id !== this.serverData.cell_id ||
            this.inputData.placeholder !== this.serverData.placeholder ||
            this.inputData.calculation !== this.serverData.calculation ||
            this.inputData.preset_type !== this.serverData.preset_type ||
            this.inputData.editable !== this.serverData.editable ||
            this.inputData.config !== this.serverData.config;

      // console.log("Question changed status:", grantQuestion.changed);
      return this.inputData.changed;
    },

    updateCalculation(content) {
      this.inputData.calculation = content.json;
      this.updateInputUI();
    },

    updateConfig(content) {
      this.inputData.config = content.json;
      this.updateInputUI();
    },
  },
};
</script>

<style>
.json-editor {
  display: flex;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.header button {
  max-height: 20px;
}

.popup-wrapper {
  position: fixed; /* Fixed position relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for a semi-transparent background */
  z-index: 1000; /* Ensure it's on top */
}

.popup {
  /* Your existing popup styles */
  /* Add styles for padding, background, etc., as necessary */
  max-width: 90%; /* Optional: to ensure it doesn't get too wide on large screens */
  box-sizing: border-box; /* To include padding and borders in the width */
  z-index: 1001; /* Ensure it's above the semi-transparent background */
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically in the container */
  align-items: right; /* Centers horizontally in the container */
  /* text-align: center;      Ensures text within the children is centered */
  /* max-height: 100vh;           Takes up the full viewport height */
  padding: 0px; /* Optional padding */
  box-sizing: border-box;
  width: 100%;
}

.grant-container {
  padding-left: 0;
}

.document-container {
  padding-left: 20px; /* Indent documents */
}

.section-container {
  padding-left: 40px; /* Further indent sections */
}

.question-container {
  padding-left: 60px; /* Further indent questions */
}

.input-container {
  padding-left: 80px; /* Further indent inputs */
}

.grant-input, .document-input, .section-input, .question-input, .input-input {
  display: block; /* Make inputs take the full width of the container */
  margin-bottom: 10px; /* Space out inputs */
  width: 500px;
}

.dropdown-icon {
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.is-open {
  transform: rotate(90deg); /* Rotates the icon to point downwards */
}

h2, h3, h4 {
  cursor: pointer;
}

.arrow {
    display: inline-block;
}
.up {
  transform: rotate(-90deg);
}
.down {
  transform: rotate(90deg);
}

</style>
