<template>
  <div class="body">
    <div
      class="col-12 bg-white q-pa-lg"
      style="
        width: 360px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      "
    >
      <!-- Full width column -->
      <div v-if="noInvite" class="row justify-center text-negative q-mt-md">
        This invitation is no longer valid. You can request a new invitation
        from the administrator listed on the invitation.
      </div>
      <div v-else>
        <div class="row justify-center q-mb-md">
          <!-- Centered row with margin bottom -->
          <img src="/logo.svg" style="width: 200px" />
        </div>
        <div class="row justify-center q-ma-md text-h5 text-primary">Join</div>
        <!-- Centered row with margin bottom -->
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="firstName"
            type="text"
            label="First Name"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="person" color="orange" /> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="lastName"
            type="text"
            label="Last Name"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="person" color="orange" /> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="newPassword"
            :type="isPwd ? 'password' : 'text'"
            label="New Password"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="lock" color="orange" /> </template
            ><template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              ></q-icon> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="confirmPassword"
            :type="isPwd ? 'password' : 'text'"
            label="Confirm New Password"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="lock" color="orange" /> </template
            ><template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              ></q-icon> </template
          ></q-input>
        </div>
        <!-- Centered row with margin bottom -->
        <div class="row justify-center">
          <q-btn
            flat
            :loading="loading"
            class="bg-primary text-white"
            @click="submitNewPassword"
            label="Join"
            style="width: 100%; height: 50px"
          />
        </div>
        <!-- Centered row -->
        <div v-if="error" class="row justify-center text-negative q-mt-md">
          {{ error }}
        </div>
      </div>
    </div>
    <q-dialog v-model="alert">
      <q-card class="q-pa-sm">
        <q-card-section>
          <div class="text-h6 text-primary">Success!</div>
        </q-card-section>

        <q-card-section class="q-pt-none"> Welcome to Aidy! </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
  
  <script>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex"; // Import useStore from Vuex
import { useRouter, useRoute } from "vue-router"; // Import useRouter from Vue Router
import { fetchUser } from "@/api/userApi";

export default {
  props: {
    uid: String,
    token: String,
  },
  setup(props) {
    const firstName = ref("");
    const lastName = ref("");
    const newPassword = ref("");
    const confirmPassword = ref("");
    const error = ref("");
    const store = useStore(); // Use the Vuex store
    const router = useRouter(); // Use the Vue Router
    const route = useRoute();
    const loading = ref(false);
    const alert = ref(false);
    const noInvite = ref(false);
    const isPwd = ref(true);

    const org = ref("");
    const invite_token = ref("");

    const submitNewPassword = async () => {
      // console.log("TRYING");
      if (newPassword.value !== confirmPassword.value) {
        error.value = "Passwords do not match.";
        return;
      }
      try {
        loading.value = true;
        // console.log("JOINING");
        const response = await axios.post("/api/v1/join/", {
          first_name: firstName.value,
          last_name: lastName.value,
          password: newPassword.value,
          org: org.value,
          invite_token: invite_token.value,
        });
        // console.log("POST JOIN");
        // alert("Password successfully reset!");
        alert.value = true;

        const userData = {
          username: response.data["username"],
          password: newPassword.value,
        };
        await store.dispatch("login", userData);
        const user_data = await fetchUser(); // Now you can use await here
        // console.log("USER_DATSA", user_data);
        if (user_data.role == "applicant") {
          router.push({ name: "Portal" });
          return;
        } else {
          router.push({ name: "Dashboard" });
          return;
        }
      } catch (err) {
        console.error(err);
        loading.value = false;
        // If the error response has a message, display it; otherwise, display a default message
        error.value =
          err.response && err.response.data && err.response.data.detail
            ? err.response.data.detail
            : "An unexpected error occurred. Please try again.";
      }
    };

    const fetchInvite = async (org_value, invite_value) => {
      try {
        // console.log("INVITE ORG", org_value);
        // console.log("INVITE TOKEN", invite_value);
        const response = await axios.get("/api/v1/get-invite/", {
          params: {
            // This is the correct way to include query parameters
            org_token: org_value,
            invite_token: invite_value,
          },
        });
        // If the request is successful, return an object indicating success and the data
        return { success: true, data: response.data, error: null };
      } catch (error) {
        console.error("Error fetching invite:", error);
        // Return an object indicating failure, no data, and the error message
        // You can customize the error message based on the error object
        return { success: false, data: null, error: null };
      }
    };

    onMounted(async () => {
      // Fixed: Correctly use onMounted with an async arrow function
      org.value = route.query.org;
      invite_token.value = route.query.invite;
      // console.log("ORG", org.value);
      // console.log("INVITE", invite_token.value);
      await store.dispatch("logout"); // Assuming logout might return a Promise
      const { success, data, error } = await fetchInvite(
        org.value,
        invite_token.value
      );
      // console.log(success, data, error);
      // console.log("DATA", data);

      if (!success) {
        console.error("Invite fetch failed:", error);
        // Optionally set a flag to show an error message in the UI
        noInvite.value = true; // Assuming `noInvite` is a reactive property to control UI state
        return; // Stop further execution if invite fetch failed
      }

      // Check if the invite has been used
      if (data.is_used) {
        router.push({ name: "SignIn" });
      }
      loading.value = false;
    });

    return {
      firstName,
      lastName,
      newPassword,
      confirmPassword,
      error,
      submitNewPassword,
      loading,
      alert,
      noInvite,
      isPwd,
    };
  },
};
</script>
  
<style scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    to top,
    #fa9600 0%,
    var(--accent-semi) 50%,
    #ffffff 100%
  );
}
</style>
  