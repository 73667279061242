<template>
    <div class="notes-container">
      
      <section>
        <div v-if="loading" class="q-pa-md">
          <q-spinner color="primary" />
        </div>
        <div v-else>
          <h5 class="text-weight-bold">Notes</h5>
          <!-- <editor v-model="notesContent" class="q-mt-lg" /> -->
          <!-- Adjusted to use novel-vue Editor -->
          <Editor :defaultValue="notesContent" @update:currentValue="handleEditorUpdate" class="q-mt-lg" />
        
          <q-btn label="Save Notes" @click="saveNotes" @blur="saveNotes" class="q-mt-md" color="primary" />
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
  import axios from "axios";
  import { useRoute } from 'vue-router'; 
  import {
    fetchNotes, 
  } from "@/api/fileApi";
  
  import Editor from "@/components/TipTap/Editor.vue";
  
  export default {
    name: "Application",
    components: {
      Editor
    },
    props: {
      inputAppId: {
        type: Number,
        default: null,
      },
      userProp: {
        type: Object,
        default: null,
      },
      requestedFiles: {
        type: Array,
        default: [""],
      },
    },
    setup(props, {emit}) {

      const loading = ref(false);
      const notesContent = ref({});
      const appID = ref(props.inputAppId);
      const route = useRoute();
  
      // onMounted(async () => {
      //   loading.value = true;
      //   const data = await fetchNotes(appID.value);
      //   notesContent.value = JSON.stringify(data);
      //   loading.value = false;
      // });
      onMounted(async () => {
          loading.value = true;  // Start loading state
          try {
              const response = await fetchNotes(appID.value);
              console.log("Full Response:", response);

              // Assuming the needed data is directly in `response` and not `response.data`
              if (response && response.type === "doc") {
                  notesContent.value = response;  // Directly using the parsed object from the response
              } else {
                  // Default initialization if no valid data received
                  notesContent.value = { type: 'doc', content: [{ type: 'paragraph' }] };
              }
          } catch (error) {
              console.error('Failed to fetch notes:', error);
              // Set to default on error
              notesContent.value = { type: 'doc', content: [{ type: 'paragraph' }] };
          }
          loading.value = false;  // End loading state
      });






      // This function captures updates from the editor
      const handleEditorUpdate = (newContent) => {
        notesContent.value = newContent;
      };
      const saveNotes = async () => {
        try {
          console.log("APP ID", appID)
          console.log("APP ID2", appID.value)
          const response = await axios.post(`api/v1/update-notes/`, {
            id: appID.value,
            notes: notesContent.value,
          });
        } catch (error) {
          console.error("ERROR UPDATING NOTES", error);
        }
      };
      onBeforeUnmount(() => {
        saveNotes().then(() => {
          emit('notesSaved');
        });
      });
  
      return { loading, notesContent, saveNotes, handleEditorUpdate };
    },
  };
  </script>
  
  <style scoped>
section {
  margin-top: 30px;
}

.notes-container {
  max-width: 600px; /* Adjust based on your layout */
  margin: auto;
}

.body {
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
  max-width: 1300px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items vertically */
  width: 100%;
}

.my-card {
  width: 180px;
  height: 200px;
}

.active-card:hover {
  box-shadow: 0 0 5px 2px var(--accent);
  cursor: pointer;
}

.score-card-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.checklist ul {
  list-style-type: none;
  padding: 0;
}

.checklist li {
  margin-bottom: 10px;
}

.item {
  /* Styles for your list item */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.trashIcon {
  margin-right: 10px; /* Space between the icon and text */
  color: var(--accent); /* Icon color */
  /* Other styles as needed */
}
</style>
  