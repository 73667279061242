<template>
  <div class="full-width full-height body">
    <div v-if="loading">
      <q-card v-for="n in 8" class="q-pa-md q-ma-md">
        <div class="q-gutter-y-md">
          <q-skeleton bordered style="width: 620px"></q-skeleton>
          <q-skeleton bordered></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
          <q-skeleton bordered square></q-skeleton>
        </div>
      </q-card>
    </div>
    <BillCard v-else v-for="bill in bills" :key="bill.id" :bill="bill"/>
  </div>
</template>
  
  
<script>
import { defineComponent, toRefs, provide, inject, onMounted, ref, watchEffect } from "vue";
import { useStore } from "vuex";
import BillCard from "./BillCard.vue";
import axios from "axios";
import { BookUser } from "lucide-vue-next";

export default defineComponent({
  name: "BillList",
  components: {
    BillCard,
  },
  props: {
    bills: Array,
    loading: Boolean,
  },
  setup(props) {
    // Use `toRefs` to create a reactive reference for each prop
    const { bills, loading } = toRefs(props);
    const store = useStore();

    const searchQuery = inject('searchQuery');
    provide('searchQuery', searchQuery);

    const companyName = inject('companyName');
    provide('companyName', companyName);
    // All reactive states, refs, or computed properties should be declared here
    // and returned from setup() for use in the template
    
    onMounted(() => {
      // fetchBookmarks();
    });

    const error = ref("");

    const bookmarks = ref([]);

    return {
      // bills,
      // loading,
      searchQuery,
      companyName,
      // handleBookmarkToggle,
      bookmarks,
    };
  },
});
</script>

<style scoped>
</style>