<template>
  <div class="dynamic-form">
    <!-- Header container using Flexbox -->
    <div class="header-container">
      <!-- Text container for title and document name -->
      <div class="text-container">
        <h4>REAP Eligibility and Competitiveness Questionnaire</h4>
        <div class="doc-title-text">Rural Energy for America Program</div>
        <div v-if="!formSubmitted" class="grant-title-text">
          {{ section_name }}
        </div>
      </div>
    </div>
    <div v-if="formSubmitted" class="header-row">
      <score-card
        :eligible="formAnswers['is_eligible']"
        :flags="eligibilityFlags"
        :answers="formAnswers"
      />
    </div>
    <div class="register-container" v-if="formSubmitted">
      <h4 style="margin-bottom: 20px; text-align: center">
        Interested in applying for a REAP grant? We can help.
      </h4>
      <div v-if="demo_link && demo_link !== ''">
        <a :href="demo_link" target="_blank" style="text-decoration: none">
          <q-btn
            flat
            color="primary"
            class="q-ma-sm bg-primary text-white"
            size="lg"
          >
            {{ demo_text }}
          </q-btn>
        </a>
        <h4 style="margin-bottom: 0px; text-align: center;">or</h4>
      </div>
      <h4>
        Email us at <a :href="`mailto:${demo_email}`">{{ demo_email }}</a>
      </h4>
    </div>
    <form v-if="!formSubmitted" @submit.prevent="showResults">
      <div v-for="question in questions" :key="question.id" class="question">
        <div
          v-if="
            !('display_calculated' in question) || question.display_calculated
          "
        >
          <multiple-choice
            v-if="question.question_type === 'MC'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <check-all
            v-else-if="question.question_type === 'CA'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <short-answer
            v-else-if="question.question_type === 'MP'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <long-answer
            v-else-if="question.question_type === 'SA'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer"
          />
          <user-upload
            v-else-if="question.question_type === 'UP'"
            :question="question"
            :answers="formAnswers"
            :draft_doc_id="doc_id"
            :ref="`question-${question.id}`"
            @answerUpdate="handleNewAnswer(question)"
          />
        </div>
      </div>
      <div v-if="final_section" class="flex flex-center">
        <q-btn
          type="submit"
          :loading="btn_saving"
          flat
          color="primary"
          class="q-ma-sm bg-primary text-white"
          size="lg"
        >
          Submit
        </q-btn>
      </div>
      <!-- <button class="button" v-if="final_section">Submit</button> -->
    </form>
  </div>
</template>

<script>
import axios from "axios";
import UserUpload from "./forms/UserUpload.vue";
import ShortAnswer from "./forms/ShortAnswer.vue";
import User from "../views/User.vue";
import jsonLogic from "json-logic-js";
export default {
  components: { UserUpload, ShortAnswer, User },
  name: "DynamicForm",
  props: {
    questions: {
      type: Array,
      required: true,
    },
    formAnswers: {
      type: Object,
      required: false,
      default: () => ({}), // Default to an empty object
    },
    doc_id: {
      type: Number,
      required: false,
    },
    app_id: {
      type: Number,
      required: false,
    },
    section_name: {
      type: String,
      required: true,
    },
    final_section: {
      type: Boolean,
      required: false,
      default: false,
    },
    doc_name: {
      type: String,
      required: true,
    },
    demo_link: {
      type: String,
      required: false,
      default: "https://calendly.com/greg-aidy/welcome",
    },
    demo_text: {
      type: String,
      required: false,
      default: "Learn More",
    },
    demo_email: {
      type: String,
      required: false,
      default: "info@aidygrants.com",
    },
    org_name: {
      type: String,
      required: false,
      default: "aidy_dupe",
    },
  },
  data() {
    return {
      formSubmitted: false,
      isEligible: null,
      isPriority: null,
      priorityInputName: null,
      eligibleInputName: "is_eligible",
      priorityInputs: [
        "energy_25_to_50_percent",
        "energy_up_to_25_percent",
        "applicant_veteran_yes",
        "applicant_socially_disadvantaged_yes",
        "prior_reap_funding_no",
        "cost_commitment_25_to_50_percent",
        "cost_commitment_less_25_percent",
      ],
      eligibilityInputs: [
        "farmer_below_50_percent_income",
        "construction_started_yes",
      ],
      answered: {},
      priorityFlags: [],
      eligibilityFlags: [],
      email: "", // The text entered in the email field
      emailSubmitted: false,
      showTooltip: false,
      debouncedUpdate: null,
    };
  },
  created() {
    this.setCalcDependencies();
    this.updateQuestions();
    this.updateCalcs();
    this.questions.forEach((question) => {
      this.answered[question.id] = false; // All question unanswered to begin with
    });
    // Add org name to answers if it exists
    if (this.org_name !== null) {
      this.formAnswers["org_name"] = this.org_name;
    }
    // Initialize debouncedUpdate
    this.debouncedUpdate = this.debounce(this.performUpdate, 1000);
  },
  methods: {
    async custom_action_call(function_name, inputs) {
      function_name = function_name.replace("_", "-");

      const data = {};
      inputs.forEach((item) => {
        if (item.var && this.formAnswers.hasOwnProperty(item.var)) {
          data[item.var] = this.formAnswers[item.var];
        }
      });
      const serialized_url_data = this.serialize_for_url(data);
      const url = `api/v1/${function_name}/?${serialized_url_data}`;

      await axios
        .get(url)
        .then((response) => {
          // Directly update the formAnswers
          Object.keys(response.data).forEach((key) => {
            this.formAnswers[key] = response.data[key];
          });
        })
        .catch((error) => {
          console.error(
            `Error calling custom action ${function_name}:`,
            error.response ? error.response.data : error.message
          );
          retval = "ERROR";
        });
    },
    handleNewAnswer(question = null, newAnswers = {}) {
      // Update this.formAnswers with values from newAnswers
      Object.keys(newAnswers).forEach((key) => {
        this.formAnswers[key] = newAnswers[key];
      });
      // Handle new answers here
      if (question !== null) {
        this.answered[question.id] = true;
        if (`question-${question.id}` in this.$refs) {
          let questionRef = this.$refs[`question-${question.id}`][0];
          questionRef.toggleRequiredFlag(false);
        }
      }
      // Debounce if the q is an SA or MP
      if (
        question &&
        (question.question_type == "SA" || question.question_type == "MP")
      ) {
        this.debouncedUpdate(question);
      } else {
        // OTW just call it directly
        this.performUpdate(question);
      }
    },
    performUpdate(question = null) {
      this.updateQuestions();
      this.updateCalcs();
    },
    showResults() {
      this.handleNewAnswer();
      var formComplete = true;
      this.questions.forEach((question) => {
        if (
          question.required &&
          question.display_calculated &&
          !this.answered[question.id]
        ) {
          //Required, displayed, and currently unanswered
          let questionRef = this.$refs[`question-${question.id}`][0];
          if (typeof questionRef.validate === "function") {
            questionRef.validate();
          }
          questionRef.toggleRequiredFlag(true);
          formComplete = false;
        }
      });
      if (!formComplete) {
        alert("Please complete the required questions before submitting");
      } else {
        this.submitForm();
        this.isEligible = this.formAnswers[this.eligibleInputName];
        this.isPriority = this.formAnswers[this.priorityInputName];
        this.formSubmitted = true;
        this.collectFlags();
      }
      // Smooth scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    async submitForm() {
      try {
        // Replace with your API endpoint and the necessary data structure
        const response = await axios.post("api/v1/save-email/", {
          answers: this.formAnswers,
        });
      } catch (error) {
        console.error(error);
        // Handle error
      }
    },
    collectFlags() {
      let eFlags = [];
      let pFlags = [];
      this.questions.forEach((question) => {
        if (question.question_type === "MC") {
          question.inputs.forEach((input) => {
            if (this.formAnswers[input.name] && input.flag) {
              if (this.eligibilityInputs.includes(input.name)) {
                eFlags.push({ text: input.flag, type: input.flag_type });
              }
              if (this.priorityInputs.includes(input.name)) {
                pFlags.push({ text: input.flag, type: input.flag_type });
              }
            }
          });
        }
      });
      this.eligibilityFlags = eFlags;
      this.priorityFlags = pFlags;
    },
    updateQuestions() {
      this.questions.forEach((question) => {
        if ("display" in question) {
          let evaluated = jsonLogic.apply(question.display, this.formAnswers);
          question.display_calculated = evaluated;
        }
      });
    },
    updateCalcs(repeat = 5) {
      for (let i = 0; i < repeat; i++) {
        this.questions.forEach((question) => {
          question.inputs.forEach((input) => {
            if ("calculation" in input && input.calculation) {
              var unansweredDependencies = false;
              input.calc_vars.forEach((var_name) => {
                var dependQuestion = this.findQuestionByInput(var_name);
                // If one of the dependent inputs is in a question that has not been answered
                // TODO: fix this logic eventually
                if (
                  !this.answered[dependQuestion.id] &&
                  dependQuestion.display_calculated
                ) {
                  unansweredDependencies = true;
                }
              });
              if (unansweredDependencies) {
                if (input.input_type == "number") {
                  this.formAnswers[input.name] = NaN;
                } else if (
                  input.input_type == "checkbox" ||
                  input.input_type == "radio"
                ) {
                  this.formAnswers[input.name] = false;
                } else {
                  this.formAnswers[input.name] = null;
                }
                // TODO handle the case where the question has been answered but some of it's inputs are still not answered
              } else {
                if (
                  input.calculation.hasOwnProperty("custom_action") &&
                  input.calculation.custom_action
                ) {
                  // Check if this calc is a custom action
                  if (i < 1) {
                    //Only make the call once
                    this.custom_action_call(
                      input.calculation.custom_action.function_name,
                      input.calculation.custom_action.inputs
                    )
                      .then((response) => {
                        this.answered[question.id] = true; //Consider this question now answered;
                      })
                      .catch((error) => {
                        console.error(
                          "Error getting custom action response:",
                          error
                        );
                      });
                  }
                } else {
                  let evaluated = jsonLogic.apply(
                    input.calculation,
                    this.formAnswers
                  );
                  this.formAnswers[input.name] = evaluated;
                  this.answered[question.id] = true; //Consider this question now answered;
                }
              }
            }
          });
        });
      }
    },
    setCalcDependencies() {
      this.questions.forEach((question) => {
        question.inputs.forEach((input) => {
          if ("calculation" in input) {
            input.calc_vars = this.extractVariableNames(input.calculation);
          }
        });
      });
    },
    extractVariableNames(jsonLogic) {
      let variables = new Set(); // Using a Set to avoid duplicates

      function recurse(obj) {
        if (Array.isArray(obj)) {
          // If the current object is an array, iterate over its elements
          obj.forEach(recurse);
        } else if (obj !== null && typeof obj === "object") {
          // If the current object is a non-null object, iterate over its properties
          for (let key in obj) {
            if (key === "var") {
              // If the current property is 'var', add its value to the set
              variables.add(obj[key]);
            } else {
              // Recursively apply the function to the value of the current property
              recurse(obj[key]);
            }
          }
        }
      }
      recurse(jsonLogic);
      return Array.from(variables); // Convert the set of variables to an array
    },
    findQuestionByInput(inputName) {
      for (const question of this.questions) {
        if (question.inputs.some((input) => input.name === inputName)) {
          return question;
        }
      }
      return null; // Return null if no question is found
    },
    serialize_for_url(obj) {
      const params = new URLSearchParams();
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          params.append(key, obj[key]);
        }
      }
      return params.toString();
    },
    // Debounce function modified to handle arguments
    debounce(func, wait, immediate) {
      let timeout;
      return function (...args) {
        const context = this;
        const later = function () {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    },
  },
};
</script>

<style scoped>
.register-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.back-text {
  font-family: var(--title-2);
  font-size: 0.8rem;
  font-weight: 400;
  color: rgb(2, 104, 172);
  margin-bottom: 0.5rem;
  text-align: left;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.title-text {
  font-family: var(--title-2);
  font-size: 1.7rem;
  color: var(--text);
  margin-bottom: 0.5rem;
  text-align: left;
}

.doc-title-text {
  font-family: var(--title-2);
  font-size: 1rem;
  color: rgb(116, 112, 112);
  margin-bottom: 0.5rem;
  text-align: left;
}

.grant-title-text {
  font-family: var(--title-2);
  font-size: 1rem;
  font-weight: 500;
  color: rgb(2, 104, 172);
  margin-bottom: 0.5rem;
  text-align: left;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px; /* Space between cards */
  margin-bottom: 20px;
}

.dynamic-form {
  max-height: 100%;
  padding: 16px;
  /* Make sure padding is included in the height */
  box-sizing: border-box;
}

.dynamic-form label {
  display: block;
}

.dynamic-form .question {
  margin-top: 20px;
  margin-bottom: 10px;
  max-width: 800px;
}

.dynamic-form .form-group {
  margin-bottom: 10px;
}

.inputItem {
  border: 2px solid var(--accent-semi);
  font-family: var(--title-2);
  font-size: var(--font-size-xl);
  background-color: var(--bkg);
  align-self: stretch;
  border-radius: var(--br-smi);
  box-sizing: border-box;
  height: 46px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  /* Add some space below each input */
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  /* Aligns the icon vertically in the center */
}

.inputIcon {
  position: absolute;
  left: 10px;
  /* Space from the left edge of the input field */
  height: 20px;
  /* Adjust the size of the icon */
  font-size: 20px;
  /* Adjust the font size for FontAwesome icon */
  color: #aaa;
  /* Icon color */
  top: 50%;
  transform: translateY(-50%);
  /* Center the icon vertically */
}

.inputItem {
  padding-left: 40px;
  /* Adjust padding to make space for the icon, considering the icon's size */
  /* ... other styles ... */
}

.submit-icon {
  position: absolute;
  color: #ccc;
  right: 10px;
  /* Right aligned within the input-group */
  top: 50%;
  /* Start at 50% from the top */
  transform: translateY(-50%);
  /* Center vertically */
  background: transparent;
  border: none;
  padding: 0 15px;
  /* Adjust padding as needed */
  height: 100%;
  /* Height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

.fa-icon {
  font-size: 2em;
  /* Larger icon size */
  display: flex;
  /* Use flexbox for centering */
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  z-index: 1002;
  /* Above the sidebar */
}

.accent-color {
  color: var(--accent);
  /* Replace with your actual accent color variable or value */
}

.email-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonText {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 30px;
  font-weight: 500;
}

.button {
  /* border: none; Remove default border */
  border-width: 2px;
  border-color: var(--accent);
  outline: none;
  /* Remove focus outline */
  margin: auto;
  border-radius: var(--br-3xs);
  background-color: var(--accent);
  /* width: 200px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  font-size: var(--title-2-size);
  cursor: pointer;
  /* Change cursor to pointer to indicate it's clickable */
  color: var(--bkg);
  font-family: var(--title-2);
}

.button:hover {
  background-color: var(--hover-accent);
  /* Change to a slightly different color on hover */
  color: var(--accent);
  /* Optional: Change text color on hover */
  /* You can add other effects like a border or shadow if desired */
}

.icon {
  position: relative;
  color: rgb(1, 150, 250);
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
  /*box-shadow: 0.3s ease; */
}

.icon:hover {
  transform: scale(1.5);
  transition: transform 0.3s ease;
  /* Make the icon 10% larger on hover */
  color: rgb(1, 150, 250);
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); Add a subtle shadow for a "lifted" effect */
  cursor: pointer;
  /* Change cursor to indicate it's clickable */
}

.icon:active {
  color: var(--accent);
}

.disabled-icon {
  position: relative;
  color: gray;
  width: 24px;
  height: 24px;
}

.disabled-icon:hover {
  cursor: not-allowed;
}

/* .disabled-icon:active {
  color: var(--accent);
} */

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  /* Adjust as needed */
}

.text-container {
  float: left;
  /* Flexbox makes float unnecessary, but included for legacy reasons if needed */
}

.document-name {
  font-size: 1em;
  color: #666;
}

.tooltip {
  position: absolute;
  bottom: 130%;
  /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}

.button-container {
  float: right;
  position: relative;
  /* Needed to position the tooltip correctly */
}

.magic-wand-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Font Awesome magic wand icon */
.fa-magic {
  font-size: 24px;
  /* Size of the magic wand icon */
}

/* Clearfix if using floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
</style>
