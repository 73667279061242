<template>
  <div class="body">
    <div
      class="col-12 bg-white q-pa-lg"
      style="
        width: 360px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      "
    >
      <!-- Full width column -->
      <div class="row justify-center q-mb-md">
        <!-- Centered row with margin bottom -->
        <img src="/logo.svg" style="width: 200px" />
      </div>
      <div class="row justify-center q-mb-md text-grey-8 text-center">
        Enter your new password
      </div>
      <!-- Centered row with margin bottom -->
      <div class="row-1 justify-center q-mb-md">
        <q-input
          outlined
          v-model="newPassword"
          :type="isPwd ? 'password' : 'text'"
          label="New Password"
          style="width: 100%"
          ><template v-slot:prepend>
            <q-icon name="lock" color="orange" /> </template
          ><template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            ></q-icon> </template
        ></q-input>
      </div>
      <div class="row-1 justify-center q-mb-md">
        <q-input
          outlined
          v-model="confirmPassword"
          :type="isPwd ? 'password' : 'text'"
          label="Confirm New Password"
          style="width: 100%"
          ><template v-slot:prepend>
            <q-icon name="lock" color="orange" /> </template
          ><template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            ></q-icon> </template
        ></q-input>
      </div>
      <!-- Centered row with margin bottom -->
      <div class="row justify-center">
        <q-btn
          flat
          :loading="loading"
          class="bg-primary text-white"
          @click="submitNewPassword"
          label="Set New Password"
          style="width: 100%; height: 50px"
        />
      </div>
      <!-- Centered row -->
      <div v-if="error" class="row justify-center text-negative q-mt-md">
        {{ error }}
      </div>
    </div>
    <q-dialog v-model="alert">
      <q-card class="q-pa-sm">
        <q-card-section>
          <div class="text-h6 text-primary">Alert</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Password successfully reset!
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { useStore } from "vuex"; // Import useStore from Vuex
import { useRouter } from "vue-router"; // Import useRouter from Vue Router
import { fetchUser } from "@/api/userApi";

export default {
  props: {
    uid: String,
    token: String,
  },
  setup(props) {
    const newPassword = ref("");
    const confirmPassword = ref("");
    const error = ref("");
    const store = useStore(); // Use the Vuex store
    const router = useRouter(); // Use the Vue Router
    const loading = ref(false);
    const alert = ref(false);
    const isPwd = ref(true);

    const submitNewPassword = async () => {
      if (newPassword.value !== confirmPassword.value) {
        error.value = "Passwords do not match.";
        return;
      }
      try {
        loading.value = true;
        const response = await axios.post("/api/v1/reset-password/", {
          uid: props.uid,
          token: props.token,
          password: newPassword.value,
        });
        // alert("Password successfully reset!");
        alert.value = true;

        const userData = {
          username: response.data["username"],
          password: newPassword.value,
        };
        await store.dispatch("login", userData);
        const user_data = await fetchUser(); // Now you can use await here
        loading.value = false;
        // console.log("USER_DATSA", user_data);
        if (user_data.role == "applicant") {
          router.push({ name: "Portal" });
          return;
        } else {
          router.push({ name: "Dashboard" });
          return;
        }

        // Redirect to login or another page
      } catch (err) {
        console.error(err);
        loading.value = false;
      }
    };

    return {
      newPassword,
      confirmPassword,
      error,
      submitNewPassword,
      loading,
      alert,
      isPwd,
    };
  },
};
</script>

<style scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    to top,
    #fa9600 0%,
    var(--accent-semi) 50%,
    #ffffff 100%
  );
}
</style>
