<template>
  <div class="form-field-sharpbase">
    <div class="label-and-spinner">
      <!-- Spinner element -->
      <span class="spinner" v-if="isLoading"></span>
      <div v-html="renderMarkdownWrapper(question.question_text)"></div>
    </div>
    <div
      v-for="i in repeats"
      :key="i"
      :class="{ repeated: question.repeatable }"
    >
      <label
        v-for="input in question.inputs"
        :key="input.id"
        class="fields-wrapper"
      >
        <div v-if="question.inputs.length > 1" class="input-label-text">
          {{ input.label_text }}
        </div>
        <q-input
          outlined
          v-if="question.repeatable"
          color="accent"
          type="textarea"
          :prefix="setPrefix(input.units)"
          :suffix="setSuffix(input.units)"
          :ref="`input-${input.id}_${i - 1}`"
          :id="`input-${input.id}_${i - 1}`"
          :name="`input-${input.id}_${i - 1}`"
          :readonly="!input.editable"
          step="any"
          v-model="answers[`${input.name}_${i - 1}`]"
          :placeholder="input.placeholder"
          @update:model-value="onInput($event, input.id, i - 1)"
          v-bind="input.config"
        />
        <q-input
          outlined
          v-else
          color="accent"
          label-color="primary"
          type="textarea"
          :suffix="setSuffix(input.units)"
          :ref="`input-${input.id}`"
          :id="`input-${input.id}`"
          :name="`input-${input.id}`"
          :readonly="!input.editable"
          step="any"
          v-model="answers[input.name]"
          :placeholder="input.placeholder"
          :error="requiredFlag && question.required"
          error-message="This question is required"
          @update:model-value="onInput($event, input.id, i - 1)"
          v-bind="input.config"
        />

        <div class="predict-failed-text" v-show="predictFailed[input.name]">
          We couldn't find an answer to this question in your docs; please
          answer it manually
        </div>
      </label>
    </div>
    <!-- <div v-if="requiredFlag && question.required" class="required-flag-text">
      This question is required
    </div> -->
    <div class="error-label">Error message (if any)</div>
  </div>
  <!-- <button @click.prevent="predict">Predict Q</button> -->
  <div v-if="question.repeatable" class="flex flex-center">
    <q-btn
      flat
      color="accent"
      class="q-ma-sm bg-accent text-white"
      size="md"
      @click.stop="addAnother"
    >
      + Add Another
    </q-btn>
  </div>
</template>

<script>
import axios from "axios";
import { marked } from "marked";
import { rules } from "@/helpers/configRules";
import { renderMarkdown } from "@/helpers/helpers";
export default {
  name: "LongAnswer",
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      required: false,
      default: () => ({}), // Default to an empty object
    },
    draft_doc_id: {},
  },
  data() {
    return {
      isLoading: false,
      predictFailed: {},
      requiredFlag: false,
      repeats: 1,
    };
  },
  created() {
    this.question.inputs.forEach((input) => {
      this.predictFailed[input.name] = false;
      // if (input.config.rules) {
      //   input.config.rules = this.convertRules(input.config.rules);
      // }

      if (!input.config) {
        input.config = {}; // Initialize config object if it doesn't exist
      }
      if (!input.config.rules) {
        input.config.rules = []; // Corrected this line
      } else {
        input.config.rules = this.convertRules(input.config.rules);
      }
      if (
        this.question.required &&
        !input.config.rules.includes(rules["required"])
      ) {
        input.config.rules.push(rules["required"]);
      }
    });
    this.repeats = this.highestNumber();
  },
  methods: {
    renderMarkdownWrapper(text) {
      return renderMarkdown(text);
    },
    testInput() {},
    convertRules(ruleStrings) {
      return ruleStrings.map((ruleString) => rules[ruleString]);
    },
    highestNumber() {
      if ("name" in this.question.inputs[0]) {
        const pattern = new RegExp(`^${this.question.inputs[0].name}_(\\d+)$`);
        const numbers = Object.keys(this.answers)
          .map((key) => {
            const match = key.match(pattern);
            return match ? parseInt(match[1]) : null;
          })
          .filter((number) => number !== null);
        // console.log("INPUT:", this.question.inputs[0].name);
        // console.log("NUMBERS:", numbers);
        return numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
      }
    },
    setInputValue(inputName, value) {
      this.isLoading = false;
      if (
        typeof value === "string" &&
        value.includes("IDK") &&
        this.predictFailed.hasOwnProperty(inputName)
      ) {
        // console.log("INCLUDES IDK:", inputName);
        this.predictFailed[inputName] = true;
        return;
      } else {
        let updatedInputs = {};
        updatedInputs[inputName] = value;
        this.$emit("answerUpdate", this.question, updatedInputs);
      }
    },
    onInput(newVal, inputId, index = 0) {
      let updatedInputs = {};
      let targetInput = this.question.inputs.find(
        (input) => input.id === parseInt(inputId)
      );
      if (this.question.repeatable) {
        if (targetInput) {
          updatedInputs[`${targetInput.name}_${index}`] = newVal;
        }
      } else {
        if (targetInput) {
          updatedInputs[targetInput.name] = newVal;
        }
      }

      this.$emit("answerUpdate", this.question, updatedInputs);
      this.$nextTick(() => {
        if (this.question.repeatable) {
          const inputElement = this.$refs[`input-${inputId}_${index}`][0];
          if (inputElement) {
            inputElement.focus();
          }
        } else {
          const inputElement = this.$refs[`input-${inputId}`][0];
          if (inputElement) {
            inputElement.focus();
          }
        }
      });
    },
    togglePredictFailed() {
      this.predictFailed = !this.predictFailed;
    },
    toggleRequiredFlag(value) {
      if (value === undefined) {
        // If no value is provided, toggle the flag
        this.requiredFlag = !this.requiredFlag;
      } else {
        // If a value is provided, set the flag to that value
        this.requiredFlag = value;
      }
    },
    async addAnother() {
      this.repeats += 1;
    },
    answered() {
      for (const input of this.question.inputs) {
        if (
          this.answers.hasOwnProperty(input.name) &&
          this.answers[input.name] !== null
        ) {
          return true;
        }
      }
      return false;
    },
    toggleRequiredFlag(value) {
      if (value === undefined) {
        // If no value is provided, toggle the flag
        this.requiredFlag = !this.requiredFlag;
      } else {
        // If a value is provided, set the flag to that value
        this.requiredFlag = value;
      }
    },
    async predict(input_name) {
      if (!this.answered() && !this.question.repeatable) {
        try {
          this.isLoading = true;
          // Construct the URL with query parameters
          const baseUrl = axios.defaults.baseURL;
          const url = `${baseUrl}/api/v1/predict-question/?q_id=${this.question.id}&doc_id=${this.draft_doc_id}`;

          const headers = {
            Authorization: axios.defaults.headers.common["Authorization"],
            "Content-Type": "application/json", // Common header for JSON requests
          };

          const vm = this;

          let answer = "";
          // Fetch data using POST method
          fetch(url, {
            method: "GET",
            headers: headers,
          })
            .then((response) => {
              const reader = response.body.getReader();
              let chunks = "";
              let firstChunkReceived = false;

              reader.read().then(function processChunk({ done, value }) {
                if (done) {
                  // vm.saveSection(); //override debounce since we're done here
                  return;
                }

                chunks += new TextDecoder().decode(value);

                // Check for complete JSON objects and process them
                while (chunks.indexOf("}") !== -1) {
                  let chunkEnd = chunks.indexOf("}") + 1;
                  let chunk = chunks.substring(0, chunkEnd);
                  chunks = chunks.substring(chunkEnd);

                  let data = JSON.parse(chunk);
                  let contentText = data.content.replace("<br>", " "); // Replace line breaks with spaces
                  answer += contentText;
                  if (!firstChunkReceived) {
                    firstChunkReceived = true;
                  }
                  vm.setInputValue(input_name, answer);
                }

                return reader.read().then(processChunk);
              });
            })
            .catch((error) => {
              this.isTyping = false;
              console.error("Error:", error);
            });
        } catch (error) {
          console.error(
            `Error in predictSection for question ${this.question.id}:`,
            error
          );
        }
      }
    },
    setPrefix(unit) {
      if (unit === "$") {
        return "$";
      } else {
        return "";
      }
    },
    setSuffix(unit) {
      if (unit !== "$" && unit !== "") {
        return unit;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.repeated {
  padding: 10px;
  margin-bottom: 30px;
  border: 4px solid #f3f3f3; /* Light grey */
  border-radius: 10px;
}
.form-field-sharpbase {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  text-align: left;
  margin-bottom: 0rem;
}

/* .q-field--outlined {
    border: 1px solid rgba(199, 13, 13, 0.24);
    border-radius: 2px;
    transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
} */

.form-field-sharpbase input:disabled {
  background-color: #f0f0f0; /* Light gray background */
  color: #a0a0a0; /* Dimmed text color */
  /* Additional styling as needed */
}

.input-label-text {
  font-family: var(--title-2);
  font-size: 0.85rem;
  color: #4a4949;
  margin-bottom: 0.25rem;
}

.label-and-spinner {
  display: flex;
  align-items: center; /* Aligns label and spinner vertically */
  position: relative;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid rgb(1, 150, 250); /* Blue */
  border-right: 4px solid rgb(1, 150, 250); /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
  margin-left: 10px; /* Adjust as needed */
  position: absolute; /* Position the spinner absolutely within its container */
  left: -40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fields-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
}

.error-label {
  font-size: var(--font-size-3xs);
  font-weight: 300;
  color: var(--gantt-ui-design);
  display: none; /* Show this only if there's an error */
}

.predict-failed-text {
  font-family: var(--title-2);
  color: var(--accent);
  font-size: 12px;
  margin-top: 4px;
}

/* Add any additional styles you need here */
</style>
