<template>
  <div class="form-field-sharpbase">
    <div class="label-and-spinner">
      <!-- Spinner element -->
      <span class="spinner" v-if="isLoading"></span>
      <div v-html="renderMarkdownWrapper(question.question_text)"></div>
    </div>
    <div
      v-for="i in repeats"
      :key="i"
      :class="{ repeated: question.repeatable }"
    >
      <label
        v-for="input in question.inputs"
        :key="input.id"
        class="fields-wrapper"
      >
        <div v-if="question.inputs.length > 1" class="input-label-text">
          {{ input.label_text }}
        </div>
        <q-input
          outlined
          v-if="question.repeatable"
          color="accent"
          :ref="(el) => collectInputRef(el, `input-${input.id}_${i - 1}`)"
          :id="`input-${input.id}_${i - 1}`"
          :name="`input-${input.id}_${i - 1}`"
          :readonly="!input.editable"
          step="any"
          v-model="answers[`${input.name}_${i - 1}`]"
          :placeholder="input.placeholder"
          @update:model-value="onInput($event, input.id, i - 1)"
          v-bind="input.config"
        />
        <q-input
          outlined
          v-else
          color="accent"
          label-color="primary"
          :ref="(el) => collectInputRef(el, `input-${input.id}`)"
          :id="`input-${input.id}`"
          :name="`input-${input.id}`"
          :readonly="!input.editable"
          step="any"
          v-model="answers[input.name]"
          :placeholder="input.placeholder"
          @update:model-value="onInput($event, input.id, i - 1)"
          v-bind="input.config"
        />

        <div class="predict-failed-text" v-show="predictFailed[input.name]">
          We couldn't find an answer to this question in your docs; please
          answer it manually
        </div>
      </label>
    </div>
    <!-- <div v-if="requiredFlag && question.required" class="required-flag-text">
      This question is required
    </div> -->
    <div class="error-label">Error message (if any)</div>
  </div>
  <!-- <button @click.prevent="predict">Predict Q</button> -->
  <div v-if="question.repeatable" class="flex flex-center">
    <q-btn
      flat
      color="accent"
      class="q-ma-sm bg-accent text-white"
      size="md"
      @click.stop="addAnother"
    >
      + Add Another
    </q-btn>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted } from "vue";
import axios from "axios";
import { rules } from "@/helpers/configRules";
import { renderMarkdown } from "@/helpers/helpers";

export default {
  name: "ShortAnswer",
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      required: false,
      default: () => ({}), // Default to an empty object
    },
    draft_doc_id: {},
  },
  setup(props, { emit }) {
    const isLoading = ref(false);
    const predictFailed = reactive({});
    const requiredFlag = ref(false);
    const repeats = ref(1);

    const inputRefs = ref({});
    const validate = () => {
      let allValid = true; // Assume all inputs are valid initially

      Object.keys(inputRefs.value).forEach((key) => {
        const inputRef = inputRefs.value[key];
        // Check if the ref and the validate method exist
        if (inputRef && typeof inputRef.validate === "function") {
          const isValid = inputRef.validate();
          if (!isValid) {
            allValid = false; // Mark as invalid if any input fails validation
          }
        }
      });

      return allValid; // Return the overall validation result
    };

    const renderMarkdownWrapper = (text) => {
      return renderMarkdown(text);
    };

    const collectInputRef = (el, name) => {
      if (el) {
        inputRefs.value[name] = el;
      }
    };

    const convertRules = (ruleStrings) => {
      const newRules = ruleStrings.map((ruleString) => rules[ruleString]);
      return newRules;
    };

    onMounted(() => {
      props.question.inputs.forEach((input) => {
        predictFailed[input.name] = false;

        if (!input.config) {
          input.config = {}; // Initialize config object if it doesn't exist
        }
        if (!input.config.rules) {
          input.config.rules = []; // Initialize rules array if it doesn't exist
        } else {
          input.config.rules = convertRules(input.config.rules); // Use convertRules without "this"
        }
        if (
          props.question.required && // Access question prop via props
          !input.config.rules.includes(rules["required"])
        ) {
          input.config.rules.push(rules["required"]);
        }
      });
      // Initialize repeats based on the highest number
      repeats.value = highestNumber();
    });
    const highestNumber = () => {
      if ("name" in props.question.inputs[0]) {
        const pattern = new RegExp(`^${props.question.inputs[0].name}_(\\d+)$`);
        const numbers = Object.keys(props.answers)
          .map((key) => {
            const match = key.match(pattern);
            return match ? parseInt(match[1], 10) : null;
          })
          .filter((number) => number !== null);
        return numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
      }
      return 1;
    };
    const setInputValue = (inputName, value) => {
      isLoading.value = false;
      if (
        typeof value === "string" &&
        value.includes("IDK") &&
        predictFailed.hasOwnProperty(inputName)
      ) {
        predictFailed[inputName] = true;
      } else {
        let updatedInputs = {};
        updatedInputs[inputName] = value;
        emit("answerUpdate", props.question, updatedInputs);
      }
    };

    const toggleRequiredFlag = (value) => {
      if (value === undefined) {
        requiredFlag.value = !requiredFlag.value;
      } else {
        requiredFlag.value = value;
      }
    };

    const onInput = (newVal, inputId, index = 0) => {
      let updatedInputs = {};
      let targetInput = props.question.inputs.find(
        (input) => input.id === parseInt(inputId)
      );
      if (targetInput.config.prefix && targetInput.config.prefix === "$") {
        newVal = newVal.replace(/,/g, "");
      }
      if (props.question.repeatable) {
        if (targetInput) {
          updatedInputs[`${targetInput.name}_${index}`] = newVal;
        }
      } else {
        if (targetInput) {
          updatedInputs[targetInput.name] = newVal;
        }
      }
      emit("answerUpdate", props.question, updatedInputs);
    };

    const addAnother = () => {
      repeats.value += 1;
    };

    const answered = () => {
      return props.question.inputs.some(
        (input) =>
          props.answers.hasOwnProperty(input.name) &&
          props.answers[input.name] !== null
      );
    };
    const predict = async () => {
      if (!answered() && !props.question.repeatable) {
        isLoading.value = true;
        try {
          const url = `api/v1/predict-question/?q_id=${props.question.id}&doc_id=${props.draft_doc_id}`;
          const response = await axios.get(url);
          const answers = response.data;
          isLoading.value = false;
          if (Object.keys(answers).length === 0) {
            togglePredictFailed();
          } else {
            Object.entries(answers).forEach(([inputName, value]) => {
              setInputValue(inputName, value);
            });
          }
        } catch (error) {
          console.error(
            `Error predicting question ${props.question.id}:`,
            error
          );
          isLoading.value = false;
        }
      }
    };
    return {
      isLoading,
      predictFailed,
      requiredFlag,
      repeats,
      inputRefs,
      renderMarkdownWrapper,
      setInputValue,
      toggleRequiredFlag,
      onInput,
      addAnother,
      answered,
      predict,
      collectInputRef,
      validate,
    };
  },
};
</script>

<style scoped>
.repeated {
  padding: 10px;
  margin-bottom: 30px;
  border: 4px solid #f3f3f3; /* Light grey */
  border-radius: 10px;
}
.form-field-sharpbase {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  text-align: left;
  margin-bottom: 0rem;
}

/* .q-field--outlined {
    border: 1px solid rgba(199, 13, 13, 0.24);
    border-radius: 2px;
    transition: border-color 0.36s cubic-bezier(0.4, 0, 0.2, 1);
} */

.form-field-sharpbase input:disabled {
  background-color: #f0f0f0; /* Light gray background */
  color: #a0a0a0; /* Dimmed text color */
  /* Additional styling as needed */
}

.input-label-text {
  font-family: var(--title-2);
  font-size: 0.85rem;
  color: #4a4949;
  margin-bottom: 0.25rem;
}

.label-and-spinner {
  display: flex;
  align-items: center; /* Aligns label and spinner vertically */
  position: relative;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid rgb(1, 150, 250); /* Blue */
  border-right: 4px solid rgb(1, 150, 250); /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
  margin-left: 10px; /* Adjust as needed */
  position: absolute; /* Position the spinner absolutely within its container */
  left: -40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fields-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
}

.error-label {
  font-size: var(--font-size-3xs);
  font-weight: 300;
  color: var(--gantt-ui-design);
  display: none; /* Show this only if there's an error */
}

.predict-failed-text {
  font-family: var(--title-2);
  color: var(--accent);
  font-size: 12px;
  margin-top: 4px;
}

/* Add any additional styles you need here */
</style>
