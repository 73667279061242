<template>
  <div v-if="docData" class="grant-container">
    <div v-if="showPopup" class="popup-wrapper">
      <PopUp :text="popupText" @response="handlePopupResponse"/>
    </div>
    <div class="row justify-between">
      <h3><span class="text-weight-bold">Document: </span> {{ docData.display_name ? docData.display_name : 'Untitled' }}</h3>
      <q-btn v-if="docData.changed" @click="saveDocument()">Save Doc</q-btn>
      <q-btn @click="exportDoc(docData.id)" :loading="exportLoading">Export to JSON</q-btn>
    </div>
    <q-input v-model="docData.name" label="Document Title" @change="updateDocUI()"/>
    <br>
    <q-input v-model="docData.display_name" label="Display Name" @change="updateDocUI()"/>
    <br>
    <q-input v-model="docData.file_name" label="File Name" @change="updateDocUI()"/>
    <br>
    <q-select 
      v-model="docData.file_type" 
      :options="file_type_choices"
      label="File Type:"
      color="primary"
      @change="updateDocUI()"
    />
    <br>
    <div class="json-editor">
      <VueJSONEditor
        :content="{json: docData.assigned_to}"
        :onChange="updateUserType"
        label="User types:"
      />
    </div>
    <br>
    <q-input
      v-model="docData.description"
      label="Document Description"
      filled
      type="textarea"
      @change="updateDocUI()"
    />
    <br>
    <q-checkbox 
      color="primary"  
      keep-color
      indeterminate-icon="check_box_outline_blank"
      unchecked-icon="check_box_outline_blank"
      v-model="docData.upload_doc"
      label="Upload Document"
      @change="updateDocUI()"
    />
    <br>
    <div class="json-editor">
      <VueJSONEditor
        :content="{json: docData.include}"
        :onChange="updateInclude"
        label="Include Logic"
      />
    </div>
    <br>
    <q-btn v-if="checkSectionOrder()" @click="reorderSections()">Update Section Order</q-btn>
    <q-expansion-item
      v-for="(section, sIndex) in docData.sections"
      :key="sIndex"
      switch-toggle-side
      expand-separator
      :label="section.name"
      caption="Click to expand"
      class="justify-start"
    >
      <template v-slot:header>
        <div class="row items-center justify-between" style="width: 100%">
          <span><span class="text-weight-bold">Section:</span> {{ section.name ? section.name : 'Untitled' }}</span>
          <div>
            <q-btn v-if="!section.id" @click.stop="createSection(section)">
              Create Section
            </q-btn>
            <q-btn v-if="section.id && section.changed" @click="saveSection(sIndex)">
              Save Section
            </q-btn>
            <q-btn v-if="section.id" @click.stop="onDeleteClicked(deleteSection, section.id)">
              <q-icon name="delete"/>
            </q-btn>
            <q-btn v-if="sIndex > 0" @click.stop="moveSection(section.id, -1)">
              <q-icon name="north"/>
            </q-btn>
            <q-btn v-if="sIndex < docData.sections.length - 1" @click.stop="moveSection(section.id, 1)">
              <q-icon name="south"/>
            </q-btn>
          </div>
        </div>
      </template>
      <div class="q-pa-lg">
        <SectionEditor :ref="`sectionEditor-${sIndex}`" v-if="section" :sectionData="section"></SectionEditor>
      </div>
    </q-expansion-item>
    <q-btn v-if="!addingNewSection && docData.id" @click="addNewSection()">Add Section</q-btn>
  </div>
</template>

<script>
import axios from "axios";
import { onMounted, provide, ref } from 'vue';

export default {
  name: "DocumentEditor",
  props: {
    docData: {
      type: Object,
      required: true,
    }
  },
  setup() {
    // Initialize grantInputs with a default value or as empty
    const grantInputs = ref(null);

    // Perform the API call
    const fetchGrantInputs = async () => {
      try {
        const response = await axios.get('api/v1/get-grant-inputs/');
        // Update grantInputs with the response data
        grantInputs.value = response.data;
        console.log("GRANT INPUTS:", grantInputs.value);
      } catch (error) {
        console.error("Error fetching grant inputs:", error);
      }
    };

    // You can call fetchGrantInputs directly in setup or use onMounted,
    // depending on when you need to fetch the data
    fetchGrantInputs();

    // Provide grantInputs ref to be injectable by descendant components
    provide('grant_input', grantInputs);

    return { grantInputs };
  },
  data() {
    return {
      serverData: null,
      visibility: {
        documentsVisible: true, // Initially showing documents
      },
      addingNewSection: false,
      addingNewQuestion: false,
      addingNewInput: false,
      showPopup: false,
      popupText: 'Are you sure you want to delete this?',
      pendingAction: null,
      exportLoading: false,
      user_types: [
        "applicant",
        "coordinator",
        "vendor"
      ],
      file_type_choices: [
        "pdf",
        "docx",
        "xlsx",
      ]
    };
  },
  created() {},
  mounted() {
    this.serverData = JSON.parse(JSON.stringify(this.docData));
    // Assuming docData is fetched or set here
    this.docData.changed = false;
    this.docData.sectionsVisible = true; // Directly assign the property
    this.docData.sections.forEach(sec => {
      sec.changed = false;
      sec.questions.forEach(q => {
        q.changed = false;
        q.inputs.forEach(input => {
          input.changed = false;
        });
      });
    });
    // console.log("DOCDATA:",this.docData);
    // console.log("SERVERDATA:",this.serverData);
  },
  computed: {
    sortedSections() {
      return [...this.docData.sections].sort((a, b) => a.order - b.order);
    }
  },
  methods: {
    toggle(key) {
      this.visibility[key] = !this.visibility[key];
    },
    toggleSection(key) {
      this.docData[key] = !this.docData[key];
    },
    toggleQuestion(section, key) {
      section[key] = !section[key];
    },
    toggleInput(question, key) {
      question[key] = !question[key];
    },

    onDeleteClicked(action, ...params) {
      this.showPopup = true;
      this.pendingAction = { action, params };
    },
    handlePopupResponse(response) {
      this.showPopup = false;
      if (response === 'yes' && this.pendingAction) {
        this.pendingAction.action(...this.pendingAction.params);
      }
      this.pendingAction = null;
    },

    addNewSection() {
      // Create a new blank section object
      const newSection = {
        name: '',
        description: '',
        file_name: '',
        file_type: null,
        questions: [],
        order: this.docData.sections.length
      };

      // Add this new document to the grantData.documents array
      this.docData.sections.push(newSection);
      this.serverData.sections.push(newSection);

      // Update state to reflect a new document is being added
      this.addingNewSection = true;
    },

    updateDocUI() {
      this.docData.changed = this.docData.name !== this.serverData.name || 
                            this.docData.display_name !== this.serverData.display_name || 
                            this.docData.file_name !== this.serverData.file_name || 
                            this.docData.file_type !== this.serverData.file_type || 
                            this.docData.upload_doc !== this.serverData.upload_doc || 
                            this.docData.description !== this.serverData.description ||
                            this.docData.assigned_to !== this.serverData.assigned_to ||
                            this.docData.include !== this.serverData.include;
    },

    updateInclude(content) {
      console.log("onChange", content);
      this.docData.include = content.json;
      this.updateDocUI();
    },

    updateUserType(content) {
      console.log("onChange", content);
      this.docData.assigned_to = content.json;
      this.updateDocUI();
    },

    saveDocument() {
      // Save Document
      console.log("Saving doc:",this.docData.name);

      try {
          const url = "api/v1/update-doc/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"doc": this.docData}, { headers })
              .then(response => {
                  this.serverData.name = this.docData.name;
                  this.serverData.description = this.docData.description;
                  this.serverData.file_name = this.docData.file_name;
                  this.serverData.file_type = this.docData.file_type;
                  this.serverData.assigned_to = this.docData.assigned_to;
                  this.serverData.upload_doc = this.docData.upload_doc;
                  this.serverData.include = this.docData.include;
                  this.docData.changed = false;
                  console.log("Successfully updated document")
              })
              .catch(error => {
                  console.error(
                      "Error updating document:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating document ${this.docData.name}:`,
              error
          );
      }
    },

    saveSection(index) {
      console.log("Saving section in wrapper:",index);
      // Access the correct SectionEditor by its ref
      const sectionEditorRef = this.$refs[`sectionEditor-${index}`];
      console.log("SECTION EDITOR REF:",sectionEditorRef);
      if (sectionEditorRef && sectionEditorRef[0]) {
        // Call saveSection on the SectionEditor component
        sectionEditorRef[0].saveSection();
      }
    },

    createSection(sectionData) {
      try {
          const url = "api/v1/create-section/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"section": sectionData, "docId": this.docData.id, "index": 0}, { headers })
              .then(response => {
                let sectionIndex = this.docData.sections.findIndex(section => section === sectionData);
                // TODO fix response handling here
                // If section index found
                if (sectionIndex !== -1) {
                    //Update server data
                    this.serverData.sections[sectionIndex] = JSON.parse(JSON.stringify(response.data.serialized));
                    //Update doc data
                    this.docData.sections[sectionIndex] = JSON.parse(JSON.stringify(response.data.serialized));
                    //Set changed back to false
                    this.docData.sections[sectionIndex].changed = false;
                } else {
                    console.error("Section not found with id:", secId);
                }
                console.log("Successfully created section")
              })
              .catch(error => {
                  console.error(
                      "Error creating section:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error making create-section call`,
              error
          );
      }
    },
    
    deleteSection(sec_id) {
      console.log("DELETING SEC ID:",sec_id);
      try {
          const url = "api/v1/delete-section/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };
          console.log("DELETING SECTION:",sec_id);
          axios.post(url, {"section_id": sec_id}, { headers })
              .then(response => {
                  console.log("Successfully deleted section")
                  let index = this.docData.sections.findIndex(section => section.id === sec_id);

                  if (index !== -1) { // Check if the document was found
                      this.docData.sections.splice(index, 1); // Remove the section
                      this.serverData.sections.splice(index, 1); // Remove the section
                  }
              })
              .catch(error => {
                  console.error(
                      "Error deleting section:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating section ${sec_id}:`,
              error
          );
      }
    },

    moveSection(secId, shift = 0) {
      let index = this.docData.sections.findIndex(section => section.id === secId);

      // Check if the section is found and the shift is within bounds
      if (index === -1 || index + shift < 0 || index + shift >= this.docData.sections.length) {
        console.log("Invalid section or shift out of bounds");
        return;
      }

      // Remove the section from its current position
      const [section] = this.docData.sections.splice(index, 1);

      // Insert the section at its new position
      this.docData.sections.splice(index + shift, 0, section);

      console.log("SECTIONS AFTER MOVE:", this.docData.sections);
    },

    reorderSections() {
      // Reorder sections
      console.log("Reordering sections");
      let sections = this.docData.sections;
      const sectionIndexMap = sections.reduce((acc, section, index) => {
        acc[section.id] = index;
        return acc;
      }, {});
      console.log("SECTION ORDER MAP:", sectionIndexMap);
      try {
          const url = "api/v1/update-section-order/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"doc_id": this.docData.id, "order_map": sectionIndexMap}, { headers })
              .then(response => {
                  console.log("Successfully reordered document sections")
                  // Update the order values of the sections locally
                  sections.forEach((section, index) => {
                      section.order = index;
                  });
              })
              .catch(error => {
                  console.error(
                      "Error reordering document sections:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
        console.error(
            `Error updating section ${this.docData.id}:`,
            error
        );
      }
    },
    
    checkSectionOrder() {
      // console.log("CHECKING SECTION ORDER", this.docData.sections);
      for (let i = 0; i < this.docData.sections.length; i++) {
          if (this.docData.sections[i].order !== i && this.docData.sections[i].id !== null) {
              // Mismatch found between section order and index
              return true;
          }
      }
      // No mismatches found, section orders are consistent with their indices
      return false;
    },
    
    exportDoc() {
      console.log("Exporting document:", this.docData.name);
      this.exportLoading = true;
      try {
          const url = `api/v1/export-document/?doc_id=${this.docData.id}`;
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.get(url, {responseType: "blob",})
              .then(response => {
                  console.log("Successfully exported doc to JSON");
                  this.exportLoading = false;
              })
              .catch(error => { 
                  console.error(
                      "Error saving doc to JSON:",
                      error.response ? error.response.data : error.message
                  );
                  this.exportLoading = false;
              });
      } catch (error) {
          console.error(
              `Error saving document ${this.docData.name} to JSON:`,
              error
          );
      }
    },
  },
};
</script>

<style>

.json-editor {
  display: flex;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.header button {
  max-height: 20px;
}

.popup-wrapper {
  position: fixed; /* Fixed position relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for a semi-transparent background */
  z-index: 1000; /* Ensure it's on top */
}

.popup {
  /* Your existing popup styles */
  /* Add styles for padding, background, etc., as necessary */
  max-width: 90%; /* Optional: to ensure it doesn't get too wide on large screens */
  box-sizing: border-box; /* To include padding and borders in the width */
  z-index: 1001; /* Ensure it's above the semi-transparent background */
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically in the container */
  align-items: right; /* Centers horizontally in the container */
  /* text-align: center;      Ensures text within the children is centered */
  /* max-height: 100vh;           Takes up the full viewport height */
  padding: 0px; /* Optional padding */
  box-sizing: border-box;
  width: 100%;
}

.grant-container {
  padding-left: 0;
}

.document-container {
  padding-left: 20px; /* Indent documents */
}

.section-container {
  padding-left: 40px; /* Further indent sections */
}

.question-container {
  padding-left: 60px; /* Further indent questions */
}

.input-container {
  padding-left: 80px; /* Further indent inputs */
}

.grant-input, .document-input, .section-input, .question-input, .input-input {
  display: block; /* Make inputs take the full width of the container */
  margin-bottom: 10px; /* Space out inputs */
  width: 500px;
}

.dropdown-icon {
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.is-open {
  transform: rotate(90deg); /* Rotates the icon to point downwards */
}

h2, h3, h4 {
  cursor: pointer;
}

.arrow {
    display: inline-block;
}
.up {
  transform: rotate(-90deg);
}
.down {
  transform: rotate(90deg);
}

</style>
