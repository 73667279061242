<template>
  <q-btn
      flat
      class="flex items-center gap-1 p-2 text-sm font-medium text-stone-600 hover:bg-stone-100 active:bg-stone-200 focus:outline-none"
      :label="'A'"
      :style="{
        color: activeColorItem?.color,
        backgroundColor: activeHighlightItem?.color,
      }"
      @click.stop
    >
      <q-icon name="keyboard_arrow_down" class="w-4 h-4" />


  <q-menu
      v-model="isColorOpen"
      anchor="bottom start" 
      self="top start"   
      :style="{ zIndex: 100000 }" 
    >
    <q-scroll-area style="height: 320px; width: 160px;">
    <div class="q-pa-md" style = "max-height: 320px; overflow-y: auto;">
      <div class="text-stone-500 font-bold">Color</div>
      <q-list>
        <q-item
          v-for="(textColor, index) in TEXT_COLORS"
          :key="`text-${index}`"
          clickable
          v-ripple
          @click="setTextColor(textColor)"
        >
          <q-item-section side>
            <span class="px-1 py-px font-medium border rounded-sm border-stone-200"
                  :style="{ color: textColor.color }">A</span>
          </q-item-section>
          <q-item-section>
            {{ textColor.name }}
          </q-item-section>
          <q-item-section side v-if="editor.isActive('textStyle', { color: textColor.color })">
            <q-icon name="check" class="w-4 h-4" />
          </q-item-section>
        </q-item>
      </q-list>
      <div class="text-stone-500 q-mt-md font-bold">Highlight</div>
      <q-list>
        <q-item
          v-for="(highlightColor, index) in HIGHLIGHT_COLORS"
          :key="`highlight-${index}`"
          clickable
          v-ripple
          @click="setHighlightColor(highlightColor)"
        >
          <q-item-section side>
            <span class="flex items-center justify-center font-medium border border-stone-200"
                  :style="{ backgroundColor: highlightColor.color, color: activeColorItem?.color, width: '24px', height: '24px' }">A</span>
          </q-item-section>
          <q-item-section>
            {{ highlightColor.name }}
          </q-item-section>
          <q-item-section side v-if="editor.isActive('highlight', { color: highlightColor.color })">
            <q-icon name="check" class="w-4 h-4" />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-scroll-area>
  </q-menu>
</q-btn>
</template>
<script>
import { computed, ref } from 'vue';

export default {
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const isColorOpen = ref(false);

    const toggleColorMenu = () => {
      console.log("CHANGING", isColorOpen.value)
      isColorOpen.value = !isColorOpen.value;
    };

    const TEXT_COLORS = [
      { name: "Default", color: "#000000" }, 
      { name: "Purple", color: "#9333EA" },
      { name: "Red", color: "#E00000" },
      { name: "Yellow", color: "#EAB308" },
      { name: "Blue", color: "#2563EB" },
      { name: "Green", color: "#008A00" },
      { name: "Orange", color: "#FFA500" },
      { name: "Pink", color: "#BA4081" },
      { name: "Gray", color: "#A8A29E" },
    ];

    const HIGHLIGHT_COLORS = [
      { name: "Default", color: "#FFFFFF" },
      { name: "Yellow", color: "#FFEB3B" },
      { name: "Purple", color: "#9C27B0" },
      { name: "Red", color: "#F44336" },
      { name: "Blue", color: "#2196F3" },
      { name: "Green", color: "#4CAF50" },
      { name: "Orange", color: "#FF9800" },
      { name: "Pink", color: "#E91E63" },
      { name: "Gray", color: "#9E9E9E" },
    ];

    const activeColorItem = computed(() => 
      TEXT_COLORS.find(({ color }) => props.editor.isActive("textStyle", { color }))
    );

    const activeHighlightItem = computed(() => 
      HIGHLIGHT_COLORS.find(({ color }) => 
        props.editor.isActive("highlight", { color })
      )
    );

    const setTextColor = (color) => {
      props.editor.commands.unsetColor();
      if (color.name !== 'Default') {
        props.editor.chain().focus().setColor(color.color || '').run();
      }
      isColorOpen.value = false; // Close menu after setting the color
    };

    const setHighlightColor = (color) => {
      props.editor.commands.unsetHighlight();
      if (color.name !== 'Default') {
        props.editor.chain().focus().toggleHighlight({ color: color.color }).run();
      }
      isColorOpen.value = false; // Close menu after setting the color
    };

    return {
      isColorOpen,
      toggleColorMenu,
      setTextColor,
      setHighlightColor,
      activeColorItem,
      activeHighlightItem,
      TEXT_COLORS,
      HIGHLIGHT_COLORS,
    };
  }
};
</script>



