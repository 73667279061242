<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container
      v-if="grant"
      class="column bg-tertiary items-center"
      style="overflow: auto"
    >

      <div class="row justify-end" style="width: 100%; height: 50px;">
        <q-btn
          v-if="!signedIn"
          color="primary"
          label="Sign In"
          class="q-mr-md q-mt-md"
          @click="showSignIn = true"
        >
        </q-btn>
        <q-btn
          v-else
          rounded
          color="primary"
          label=""
          class="q-mr-md q-mt-md"
          @click="router.push({ name: 'User' })"
        >
          <q-icon name="person" color="white" />
          <q-tooltip
            class="bg-primary text-white text-body2"
            :offset="[10, 10]"
          >My Account</q-tooltip>
        </q-btn>
      </div>

      <div
        class="column q-gutter-md"
        style="padding: 40px 40px 0px 40px; max-width: 1200px"
      >

        <div class="row justify-between">
          <q-btn
            flat
            label="Back to Search"
            icon="arrow_back_ios"
            @click="backToSearch"
          ></q-btn>
          <q-btn
            flat
            type="a"
            :href="grant.grant_url"
            target="_blank"
            label="View Listing"
          ></q-btn>
        </div>

        <div class="row shadow-2 rounded-borders q-pa-md bg-white">
          <div class="col q-pl-md q-pr-md">
            <div class="row justify-between items-start">
              <q-img
              :src="`/department_logos/${grant.agency}.jpg`"
                spinner-color="white"
                style="height: 150px; max-width: 150px; border-radius: 50%;"
                class='col q-pa-sm'
              ></q-img>
              <div class="col q-pl-lg">
                <h4 class="row text-weight-medium flex-auto">
                {{ grant.name ? grant.name : "Unknown Grant" }}
              </h4>
              <div class="row justify-start items-center q-pt-sm">
              <h5 class="text-weight-medium" style="padding-right: 10px;">{{ grant.agency }}</h5>
              <q-chip
                icon="event"
                class="text-weight-bold"
                color="accent"
                text-color="white"
                >Deadline: {{ formatDate(grant.deadline) }}</q-chip
              >
              <q-chip
                v-for="(value, program) in grant.program"
                :key="program"
                class="text-weight-bold q-mr-sm"
                color="primary"
                text-color="white"
              >
                {{ program }}
              </q-chip>
              <q-chip
                v-for="(value, phase) in grant.phase"
                :key="phase"
                class="text-weight-bold q-mr-sm"
                color="accent"
                text-color="white"
              >
                {{ phase }}
              </q-chip>
            </div>
              </div>
              <q-btn
                v-if="grant.application_created"
                color="accent"
                icon="article"
              >
                <q-tooltip class="bg-accent">Create Application</q-tooltip>
              </q-btn>
            </div>
            <div class="row justify-start items-center q-pt-sm">
              <q-chip
                icon="payments"
                class="text-weight-bold"
                color="primary"
                text-color="white"
                >Total Funding Available: ${{
                  grant.total_funding.toLocaleString()
                }}</q-chip
              >
              <q-slider
                  class="row q-p-lg"
                  style="padding-left: 50px; padding-bottom: 20px; width: 65%;"
                  readonly
                  v-model="grant.avg_funding"
                  :color="primary"
                  :inner-min="grant.min_funding"
                  :inner-max="grant.max_funding"
                  markers
                  :marker-labels="markerLabels"
                  label-always
                  :label-value="slideLabel"
                  switch-label-side
                  switch-marker-labels-side
                  :min="grant.min_funding"
                  :max="grant.max_funding"
                />
            </div>
            <div v-if="grant.contact_email || grant.contact_link" class="row justify-between q-pt-sm">
              <div v-if="grant.contact_email" class="text-body1 q-pt-sm">
                <b>Agency Program Contact: </b>
                <a :href="`mailto:${grant.contact_email}`" class="hyperlink">{{ grant.contact_name }}</a>
              </div>
              <div v-else class="text-body1 q-pt-sm">
                <a :href="`mailto:${grant.contact_link}`" class="hyperlink"><b>Agency Contact Form</b></a>
              </div>
            </div>
            <div class="row justify-between q-pt-sm">
              <div class="text-body1 q-pt-md">{{ grant.description }}</div>
            </div>
          </div>
        </div>

        <q-tabs
          v-model="tab"
          dense
          class="row text-grey q-mt-lg"
          active-color="primary"
          indicator-color="primary"
          align="left"
          narrow-indicator
        >
          <q-tab name="eligibility" label="Eligibility"></q-tab>
          <q-tab name="application" label="Application"></q-tab>
          <q-tab v-if="Object.keys(this.grant.mk_files).length > 0" name="documents" label="Documents"></q-tab>
        </q-tabs>

        <q-separator></q-separator>

        <q-tab-panels
          v-model="tab"
          animated
          class="q-mb-md shadow-2 rounded-borders"
        >
          <q-tab-panel name="eligibility">
            <div class="row justify-between">
              <div class="col">
                <h6 class="text-weight-bold">Eligibility Criteria</h6>
                <q-list>
                  <q-item
                    clickable
                    v-ripple
                    v-for="(entity, index) in grant.eligibilityCriteria"
                    :key="index"
                  >
                    <q-item-section avatar>
                      <q-icon color="primary" name="groups" />
                    </q-item-section>

                    <q-item-section>{{ entity }}</q-item-section>
                  </q-item>
                </q-list>
              </div>
              <q-separator vertical inset></q-separator>
              <div class="col q-ml-lg">
                <h6 class="text-weight-bold">Eligible Projects</h6>
                <q-list>
                  <q-item
                    clickable
                    v-ripple
                    v-for="(project, index) in grant.eligibleProjects"
                    :key="index"
                  >
                    <q-item-section avatar>
                      <q-icon color="primary" name="newspaper" />
                    </q-item-section>

                    <q-item-section>{{ project }}</q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>
          <q-tab-panel name="application">
            <div class="row justify-between">
              <div class="col">
                <h6 class="text-weight-bold">Application Process</h6>
                <q-stepper
                  v-model="process_step"
                  ref="stepper"
                  color="primary"
                  vertical
                  animated
                  style="box-shadow: none !important"
                >
                  <q-step
                    v-for="(step, index) in grant.app_steps"
                    :key="index"
                    :name="index + 1"
                    :title="step.title"
                    :caption="step.caption"
                    :icon="step.icon"
                    :done="process_step > step.name"
                  >
                    {{ step.description }}
                  </q-step>

                  <template v-slot:navigation>
                    <q-stepper-navigation>
                      <q-btn
                        v-if="process_step < grant.app_steps.length"
                        @click="$refs.stepper.next()"
                        color="primary"
                        label="Next"
                      />
                      <q-btn
                        v-if="process_step > 1"
                        flat
                        color="primary"
                        @click="$refs.stepper.previous()"
                        label="Back"
                        class="q-ml-sm"
                      />
                    </q-stepper-navigation>
                  </template>
                </q-stepper>
              </div>
              <q-separator vertical inset></q-separator>
              <div class="col q-ml-lg">
                <h6 class="text-weight-bold">Application Materials</h6>
                <q-list>
                  <q-item
                    clickable
                    v-ripple
                    v-for="(document, index) in grant.grant_documents"
                    :key="index"
                  >
                    <q-item-section avatar>
                      <q-icon color="primary" name="description" />
                    </q-item-section>

                    <q-item-section>{{ document }}</q-item-section>
                  </q-item>
                </q-list>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel v-if="Object.keys(this.grant.mk_files).length > 0" name="documents">
            <div class="row justify-between">
              <h6 class="text-weight-bold">Program Documents</h6>
            </div>
            <div class="row justify-between">
              <div class="col-2">
                <q-list padding class="rounded-borders text-primary">
                  <q-item
                    v-for="(docName, index) in Object.keys(grant.mk_files)"
                    clickable
                    v-ripple
                    :active="activeDoc === docName"
                    @click="activeDoc = docName"
                    active-class="active-doc"
                  >
                    <q-item-section class="ellipsis">{{ docName }}</q-item-section>
                  </q-item>
                </q-list>
              </div>
              
              <div class="col-10">
                <div class="q-pa-md rounded-borders bg-white">
                  <div
                    v-html="renderMarkdownWrapper(grant.mk_files[activeDoc])"
                    class="markdown-body"
                  ></div>
                </div>
              </div>
            </div>
          </q-tab-panel>

        </q-tab-panels>

        <div v-if="grant.eligibility_quiz" class="q-mt-md row">
          <q-btn
            color="primary"
            label="Check Eligibility"
            @click="applyNow"
          ></q-btn>
        </div>
      </div>
    </q-page-container>
    <div v-else class="flex flex-center" style="height: 100%; padding-top: 50vh;">
      <q-spinner color="primary" :size="120"></q-spinner>
    </div>
    <grant-assistant
      :opp_id="opp_id"
      :sidebarInitialState="false"
    ></grant-assistant>
  </q-layout>
</template>

<script>
import { defineComponent } from "vue";
import { renderMarkdown } from "@/helpers/helpers";
import axios from "axios";

export default defineComponent({
  name: "Grant Info",
  data() {
    return {
      grant: null,
      process_step: 1,
      opp_id: this.$route.params.id,
      tab: "eligibility",
      activeDoc: null,
    }
  },
  created() {
    this.getGrantInfo();
  },
  computed: {
    contentStyle() {
      // If the sidebar is open, add 40vh margin to the right
      if (this.isSidebarOpen) {
        return {
          paddingRight: "420px",
        };
      } else {
        return {
          paddingRight: "0px",
        };
      }
    },
    slideLabel() {
      return `Average grant award: $${this.grant.avg_funding.toLocaleString()}`;
    },
    markerLabels() {
      return [
        {
          value: this.grant.min_funding,
          label: `$${this.grant.min_funding.toLocaleString()}`,
        },
        {
          value: this.grant.max_funding,
          label: `$${this.grant.max_funding.toLocaleString()}`,
        },
      ];
    },
  },
  methods: {
    handleSidebarToggle(isOpen) {
      this.isSidebarOpen = isOpen; // Keep track of the sidebar state
    },
    handleSectionId(sectionId) {
      this.section_id = sectionId;
    },
    handleSubmit(formAnswers) {
      // Send the answers to your backend or perform other actions
      // Then load the next section's questions if any
      this.allAnswers[this.currentSectionIndex] = formAnswers;
      if (this.currentSectionIndex < this.sections.length) {
        this.currentSectionIndex++;
      }
      if (this.$refs.dynamicFormRef) {
        this.$refs.dynamicFormRef.formSubmitted = true;
      }
    },
    backToSearch() {
      this.$router.push({ name: "Search" });
    },
    getGrantInfo() {
      // Get grant info from the server
      axios
        .get("/api/v1/get-grant-info", {
          params: {
            opp_id: this.opp_id,
          },
        })
        .then((response) => {
          console.log("INFO RESPONSE:", response.data);
          this.grant = response.data;
          if (Object.keys(this.grant.mk_files).length > 0) {
            this.activeDoc = Object.keys(this.grant.mk_files)[0];
          }
        })
        .catch((error) => {
          console.error("Error fetching grant info:", error);
        });
    },
    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Helper function to get the ordinal suffix for day numbers
      function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      }

      const date = new Date(dateString);
      const day = date.getDate();
      const monthName = months[date.getMonth()];
      const year = date.getFullYear();

      return `${monthName} ${day}${getOrdinalSuffix(day)}, ${year}`;
    },
    renderMarkdownWrapper(text) {
      return renderMarkdown(text);
    },
  },
});
</script>

<style scoped>
.custom-stepper,
.custom-stepper .q-stepper__tab,
.custom-stepper .q-stepper__content {
  border: none !important;
}

.container {
  height: 100vh;
  width: 100%;
}

.content {
  height: 100%;
  width: 90%;
  /* padding-left: 40px; */
  box-sizing: border-box;
  transition: padding-right 0.3s ease;
  align-items: left;
  /* Smooth transition for the margin change */
}

.section-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-center-image {
  display: block;
  margin: 40px auto 0px; /* Centers the image */
  width: 30%;
  max-width: 300px;
  min-width: 200px;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.active-doc {
  background-color: var(--accent);
  color: white;
}

.markdown-body {
  /* Add your markdown styles here */
  line-height: 1.6;
  color: var(--text);
  border-radius: 5px;
  font-family: var(--title-2);
}

.hyperlink {
  text-decoration: underline;
}

.hyperlink:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}
</style>
