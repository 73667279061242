<template>
  <div v-if="sectionData">
    <div v-if="showPopup" class="popup-wrapper">
      <PopUp :text="popupText" @response="handlePopupResponse"/>
    </div>
    
    <q-input v-model="sectionData.name" label="Section Title" @change="updateSectionUI()"/>
    <br>
    <q-input
      v-model="sectionData.description"
      label="Section Description"
      filled
      type="textarea"
      @change="updateSectionUI()"
    />
    <br>
    <q-checkbox 
      color="primary"  
      keep-color
      indeterminate-icon="check_box_outline_blank"
      unchecked-icon="check_box_outline_blank"
      v-model="sectionData.table"
      label="Table"
      @change="updateSectionUI()"
    />
    <br>
    <div class="json-editor">
      <VueJSONEditor
        :content="{json: sectionData.display}"
        :onChange="updateDisplay"
        label="Display Logic"
      />
    </div>
    <br>
    <q-btn v-if="checkQuestionOrder()" @click="reorderQuestions()">Update Question Order</q-btn>
    <q-expansion-item
      v-for="(question, qIndex) in sectionData.questions"
      :key="qIndex"
      switch-toggle-side
      expand-separator
      :label="question.name"
      caption="Click to expand"
      class="justify-start"
    >
      <template v-slot:header>
        <div class="row items-center justify-between" style="width: 100%">
          <span><span class="text-weight-bold">Question:</span> {{ question.question_text ? truncateDisplayName(question.question_text, 90) : 'Untitled' }}</span>
          <div>
            <q-btn v-if="!question.id" @click.stop="createQuestion(question)">
              Create Question
            </q-btn>
            <q-btn v-if="question.id && question.changed" @click="saveQuestion(qIndex)">
              Save Question
            </q-btn>
            <q-btn v-if="question.id" @click.stop="onDeleteClicked(deleteQuestion, question.id)">
              <q-icon name="delete"/>
            </q-btn>
            <q-btn v-if="qIndex > 0" @click.stop="moveQuestion(question.id, -1)">
              <q-icon name="north"/>
            </q-btn>
            <q-btn v-if="qIndex < sectionData.questions.length - 1" @click.stop="moveQuestion(question.id, 1)">
              <q-icon name="south"/>
            </q-btn>
          </div>
        </div>
      </template>
      <div class="q-pa-lg">
        <!-- Content for each question can go here, if needed -->
        <QuestionEditor :ref="`questionEditor-${qIndex}`" v-if="question" :questionData="question"></QuestionEditor>
      </div>
    </q-expansion-item>
    <q-btn v-if="!addingNewQuestion && sectionData.id" @click="addNewQuestion()">Add Question</q-btn>
  </div>
</template>

<script>
import axios from "axios";
// import { inject, onMounted } from 'vue';

export default {
  name: "SectionEditor",
  props: {
    sectionData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      serverData: null,
      addingNewSection: false,
      addingNewQuestion: false,
      addingNewInput: false,
      showPopup: false,
      popupText: 'Are you sure you want to delete this?',
      pendingAction: null,
    };
  },
  mounted() {
    this.serverData = JSON.parse(JSON.stringify(this.sectionData));
    // Assuming docData is fetched or set here
    this.sectionData.changed = false;
    this.sectionData.questionsVisible = true; // Directly assign the property
  },
  computed: {
    sortedSections() {
      return [...this.docData.sections].sort((a, b) => a.order - b.order);
    }
  },
  methods: {
    truncateDisplayName(displayName, maxLength = 55) {
      if (displayName.length > maxLength) {
        return displayName.substring(0, maxLength) + "...";
      }
      return displayName;
    },
    onDeleteClicked(action, ...params) {
      this.showPopup = true;
      this.pendingAction = { action, params };
    },
    handlePopupResponse(response) {
      this.showPopup = false;
      if (response === 'yes' && this.pendingAction) {
        this.pendingAction.action(...this.pendingAction.params);
      }
      this.pendingAction = null;
    },

    addNewQuestion() {
      // Create a new blank section object
      const newQuestion = {
        name: '',
        question_text: '',
        question_type: '',
        display: true,
        repeatable: false,
        table: false,
        required: true,
        description: '',
        inputs: [],
        order: this.sectionData.questions.length
      };

      // Add this new document to the grantData.documents array
      this.sectionData.questions.push(newQuestion);
      this.serverData.questions.push(newQuestion);

      // Update state to reflect a new document is being added
      this.addingNewQuestion = true;
    },

    updateSectionUI() {
      // Compare the two sections to detect any differences
      // Here, you need to define how to compare sections. For example:
      this.sectionData.changed = this.sectionData.name !== this.serverData.name || 
                                this.sectionData.description !== this.serverData.description ||
                                this.sectionData.table !== this.serverData.table ||
                                this.sectionData.display !== this.serverData.display;
      // ... add other comparisons as needed based on your section structure ...

      console.log("Section changed status:", this.sectionData.changed);
      return this.sectionData.changed;
    },

    updateDisplay(content) {
      console.log("onChange", content);
      this.sectionData.display = content.json;
      this.updateSectionUI();
    },

    saveSection() {
      console.log("Saving section:",this.sectionData.name);

      try {
          const url = "api/v1/update-section/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"section": this.sectionData}, { headers })
              .then(response => {
                  this.serverData.name = this.sectionData.name;
                  this.serverData.description = this.sectionData.description;
                  this.serverData.table = this.sectionData.table;
                  this.serverData.display = this.sectionData.display;
                  this.sectionData.changed = false;
                  console.log("Successfully updated section")
              })
              .catch(error => {
                  console.error(
                      "Error updating section:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating section ${this.sectionData.name}:`,
              error
          );
      }
    },

    createQuestion(questionData) {
      try {
          const url = "api/v1/create-question/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"question": questionData, "sectionId": this.sectionData.id, "index": 0}, { headers })
              .then(response => {
                let questionIndex = this.sectionData.questions.findIndex(question => question === questionData);
                if (questionIndex !== -1) {
                    // Update server data for the specific question
                    this.sectionData.questions[questionIndex] = JSON.parse(JSON.stringify(response.data.serialized));

                    // Update grant data for the specific question
                    this.serverData.questions[questionIndex] = JSON.parse(JSON.stringify(response.data.serialized));

                    // Set changed back to false for the specific question
                    this.sectionData.questions[questionIndex].changed = false;
                } else {
                    console.error("Question not found with id:", this.sectionData.id);
                }
                console.log("Successfully created question")
                this.addingNewQuestion = false;
              })
              .catch(error => {
                  console.error(
                      "Error creating question:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error making API call`,
              error
          );
      }
    },

    saveQuestion(index) {
      console.log("Saving question in wrapper:",index);
      // Access the correct SectionEditor by its ref
      const questionEditorRef = this.$refs[`questionEditor-${index}`];
      console.log("QUESTION EDITOR REF:",questionEditorRef);
      if (questionEditorRef && questionEditorRef[0]) {
        // Call saveSection on the QuestionEditor component
        questionEditorRef[0].saveQuestion();
      }
    },

    deleteQuestion(question_id) {
      try {
          const url = "api/v1/delete-question/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"question_id": question_id}, { headers })
              .then(response => {
                  console.log("Successfully deleted question")
                  let index = this.sectionData.questions.findIndex(question => question.id === question_id);

                  if (index !== -1) { // Check if the document was found
                      this.sectionData.questions.splice(index, 1); // Remove the section
                      this.serverData.questions.splice(index, 1); // Remove the section from server data
                  }
              })
              .catch(error => {
                  console.error(
                      "Error deleting question:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating question ${question_id}:`,
              error
          );
      }
    },

    moveQuestion(question_id, shift = 0) {
      let questionIndex = -1;

      questionIndex = this.sectionData.questions.findIndex(question => question.id === question_id);


      // Calculate the new position within the same section
      const newPosition = questionIndex + shift;
      
      // Ensure newPosition is within the bounds of the section's question list
      if (newPosition < 0 || newPosition >= this.sectionData.questions.length) {
        console.log("Shift out of bounds within the section");
        return;
      }

      // Remove the question from its current position
      const [question] = this.sectionData.questions.splice(questionIndex, 1);

      // Insert the question at its new position within the same section
      this.sectionData.questions.splice(newPosition, 0, question);

      console.log("QUESTIONS AFTER MOVE:", this.sectionData.questions);
    },
    
    reorderQuestions() {
      // Reorder questions
      console.log("Reordering questions");
      const questionIndexMap = this.sectionData.questions.reduce((acc, question, index) => {
        acc[question.id] = index;
        return acc;
      }, {});
      console.log("QUESTION ORDER MAP:", questionIndexMap);
      try {
          const url = "api/v1/update-question-order/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"section_id": this.sectionData.id, "order_map": questionIndexMap}, { headers })
              .then(response => {
                  console.log("Successfully reordered section questions")
                  // Update the order values of the sections locally
                  this.sectionData.questions.forEach((question, index) => {
                    question.order = index;
                  });
              })
              .catch(error => {
                  console.error(
                      "Error reordering document sections:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
        console.error(
            `Error updating section ${this.sectionData.id}:`,
            error
        );
      }
    },
    checkQuestionOrder() {
      console.log("CHECKING SECTION ORDER", this.sectionData.questions);
      for (let i = 0; i < this.sectionData.questions.length; i++) {
          if (this.sectionData.questions[i].order !== i && this.sectionData.questions[i].id !== null) {
              return true;
          }
      }
      // No mismatches found, section orders are consistent with their indices
      return false;
    },
  },
};
</script>

<style>
.json-editor {
  display: flex;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.header button {
  max-height: 20px;
}

.popup-wrapper {
  position: fixed; /* Fixed position relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for a semi-transparent background */
  z-index: 1000; /* Ensure it's on top */
}

.popup {
  /* Your existing popup styles */
  /* Add styles for padding, background, etc., as necessary */
  max-width: 90%; /* Optional: to ensure it doesn't get too wide on large screens */
  box-sizing: border-box; /* To include padding and borders in the width */
  z-index: 1001; /* Ensure it's above the semi-transparent background */
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically in the container */
  align-items: right; /* Centers horizontally in the container */
  /* text-align: center;      Ensures text within the children is centered */
  /* max-height: 100vh;           Takes up the full viewport height */
  padding: 0px; /* Optional padding */
  box-sizing: border-box;
  width: 100%;
}

.grant-container {
  padding-left: 0;
}

.document-container {
  padding-left: 20px; /* Indent documents */
}

.section-container {
  padding-left: 40px; /* Further indent sections */
}

.question-container {
  padding-left: 60px; /* Further indent questions */
}

.input-container {
  padding-left: 80px; /* Further indent inputs */
}

.grant-input, .document-input, .section-input, .question-input, .input-input {
  display: block; /* Make inputs take the full width of the container */
  margin-bottom: 10px; /* Space out inputs */
  width: 500px;
}

.dropdown-icon {
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.is-open {
  transform: rotate(90deg); /* Rotates the icon to point downwards */
}

h2, h3, h4 {
  cursor: pointer;
}

.arrow {
    display: inline-block;
}
.up {
  transform: rotate(-90deg);
}
.down {
  transform: rotate(90deg);
}

</style>
