<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container
      v-if="bill"
      class="column bg-tertiary items-center"
      style="overflow: auto"
    >

      <div class="row justify-between items-center" style="width: 100%; height: 50px;">
        <q-btn
          flat
          label="Back to Bill"
          icon="arrow_back_ios"
          class="q-ml-md q-mt-md"
          @click="backToSearch"
        ></q-btn>
        <q-btn
          v-if="!signedIn"
          color="primary"
          label="Sign In"
          class="q-mr-md q-mt-md"
          @click="showSignIn = true"
        >
        </q-btn>
        <q-btn
          v-else
          rounded
          color="primary"
          label=""
          class="q-mr-md q-mt-md"
          @click="$router.push({ name: 'User' })"
        >
          <q-icon name="person" color="white" />
          <q-tooltip
            class="bg-primary text-white text-body2"
            :offset="[10, 10]"
          >My Account</q-tooltip>
        </q-btn>
      </div>

      <div
        class="column q-gutter-md"
        style="padding: 40px 40px 0px 40px; max-width: 1200px"
      >
        <div class="row justify-between">
          <div ref="xmlContainer" id="xmlContainer"></div>
        </div>

      </div>
      
    </q-page-container>
    <div v-else class="flex flex-center" style="height: 100%; padding-top: 50vh;">
      <q-spinner color="primary" :size="120"></q-spinner>
    </div>
    <bill-assistant
      :opp_id="bill_id"
      :sidebarInitialState="true"
      @highlight="highlightText"
    ></bill-assistant>
  </q-layout>

  <q-dialog v-model="showSignIn">
    <sign-in-comp @signinComplete="signinComplete" @goToReg="goToRegistration" :linkToReg="false"></sign-in-comp>
  </q-dialog>

  <q-dialog v-model="showSignUp">
    <register-comp role="legislation" @registrationComplete="handleRegistrationComplete" @goToSignIn="goToSignIn" :linkToSignIn="false"></register-comp>
  </q-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { renderMarkdown } from "@/helpers/helpers";
import axios from "axios";
import Fuse from "fuse.js"; // Assuming you're using Fuse.js directly

export default defineComponent({
  name: "Bill",
  data() {
    return {
      bill: null,
      process_step: 1,
      bill_id: this.$route.params.id,
      tab: "eligibility",
      activeDoc: null,
      showSignIn: false,
      showSignUp: false,
      signedIn: false,
      store: null,
      billLines: [],
      fuse: null,
    }
  },
  async mounted() {
    this.store = useStore();
    this.signedIn = await this.checkLoggedIn();
    this.getBillInfo();
  },
  computed: {
    contentStyle() {
      // If the sidebar is open, add 40vh margin to the right
      if (this.isSidebarOpen) {
        return {
          paddingRight: "420px",
        };
      } else {
        return {
          paddingRight: "0px",
        };
      }
    },
  },
  methods: {
    handleSidebarToggle(isOpen) {
      this.isSidebarOpen = isOpen; // Keep track of the sidebar state
    },
    handleSectionId(sectionId) {
      this.section_id = sectionId;
    },
    handleSubmit(formAnswers) {
      // Send the answers to your backend or perform other actions
      // Then load the next section's questions if any
      this.allAnswers[this.currentSectionIndex] = formAnswers;
      if (this.currentSectionIndex < this.sections.length) {
        this.currentSectionIndex++;
      }
      if (this.$refs.dynamicFormRef) {
        this.$refs.dynamicFormRef.formSubmitted = true;
      }
    },
    backToSearch() {
      // console.log("BILL ID:", this.bill);
      this.$router.push(`/bill-info/${this.bill.bill_id}`);
    },
    displayHTML() {
      const contentDiv = this.$refs.xmlContainer;
      // console.log("BILL", this.bill);
      if (this.bill && this.bill.html) {
        contentDiv.innerHTML = this.bill.html; // Directly set innerHTML to the HTML content
      } else {
        console.log('No HTML content available');
      }
    },
  getBillInfo() {
    axios.get("/api/v1/get-bill", { params: { bill_id: this.bill_id } })
      .then(response => {
        this.bill = response.data;
        // Ensure Vue has updated the DOM after setting `bill`
        this.$nextTick(() => {
          this.displayHTML()
          this.getBillLines() // Ensures this runs only after transformXML completes
        });
      })
      .catch(error => {
        console.error("Error fetching bill info:", error);
      });
  },
  searchFuse(searchTerm = "Administrator of the National Oceanic and Atmospheric Administration") {
    const results = searchTerm ? this.fuse.search(searchTerm) : [];
    // console.log("FUSE SEARCH RESULTS:", results);
    if (results.length > 0) {
      const bestMatch = results[0].item;
      // console.log("Best match:", bestMatch);
      return bestMatch;
    }
  },
  clearHighlighting() {
    const contentDiv = this.$refs.xmlContainer;
    if (!contentDiv) return;

    const paragraphs = contentDiv.getElementsByTagName('p');
    
    // Convert paragraphs to array and use a traditional for loop
    const paragraphsArray = Array.from(paragraphs);
    for (let i = 0; i < paragraphsArray.length; i++) {
        const p = paragraphsArray[i];
        // Reset or maintain no background for empty or whitespace-only paragraphs
        p.style.backgroundColor = 'transparent';
    }
  },
  checkLineMatch(line) {
    // Perform the search using Fuse.js configured elsewhere
    const best_match = this.searchFuse(line);
    console.log("SEARCHING LINE:", line);
    console.log("BEST MATCH:", best_match);

    // Check if a best match is found
    if (best_match) {
        return true;
    } else {
        return false;
    }
  },
  highlightText(lines) {
    this.clearHighlighting();
    // console.log("HIGHLIGHT LINES:", lines);
    let firstHighlighted = false; // flag to track if the first highlighted paragraph has been scrolled to
    for (const line of lines) {
      // console.log("\n\nMATCHING LINE:", line);
      const best_match = this.searchFuse(line);
      // console.log("BEST MATCH:", best_match);
      const contentDiv = this.$refs.xmlContainer;
      if (!contentDiv || !best_match) continue;

      const paragraphs = contentDiv.getElementsByTagName('p');
      
      // Convert paragraphs to array and use a traditional for loop
      const paragraphsArray = Array.from(paragraphs);
      for (let i = 0; i < paragraphsArray.length; i++) {
          const p = paragraphsArray[i];
          // Remove all whitespace from paragraph text
          const normalizedParagraphText = p.textContent.trim();

          // Check if the paragraph text is non-empty after removing whitespace
          if (normalizedParagraphText.length > 0) {
              // Check if normalized paragraph text is contained within the normalized chunk text
              if (best_match.includes(normalizedParagraphText) || normalizedParagraphText.includes(best_match)) {
                  p.style.backgroundColor = 'yellow'; // Highlight the paragraph
                  // Scroll to the first highlighted paragraph
                  if (!firstHighlighted) {
                      p.scrollIntoView({ behavior: 'smooth', block: 'start' });
                      firstHighlighted = true; // Set the flag so no other paragraphs are scrolled to
                      break; // Exit the loop after highlighting the first matching paragraph
                  }
              }
          } else {
              // Reset or maintain no background for empty or whitespace-only paragraphs
              p.style.backgroundColor = 'transparent';
          }
      }
    }
  },
  getBillLines() {
    const contentDiv = this.$refs.xmlContainer;
    if (!contentDiv) return;

    const paragraphs = contentDiv.getElementsByTagName('p');

    Array.from(paragraphs).forEach(p => {
        // Trim the paragraph text to remove leading and trailing whitespace
        const trimmedText = p.textContent.trim();
        
        // Check if the trimmed paragraph text is non-empty
        if (trimmedText.length > 0) {
            this.billLines.push(trimmedText);
        }
    });
    this.fuse = new Fuse(this.billLines, {
      includeScore: true,
      threshold: 0.4,
      keys: ["text"]
    });
    // console.log("BILL LINES:", this.billLines);
  },
  checkLoggedIn() {
    return this.store.dispatch("checkToken")
      .then(signedIn => {
        return signedIn;  // Return the signed in status for further processing
      })
      .catch(error => {
        console.error("Error checking token:", error);
        return false;  // Handle the error case by returning false
      });
  },
  renderMarkdownWrapper(text) {
    return renderMarkdown(text);
  },
  handleRegistrationComplete() {
      this.signedIn = checkLoggedIn();
      // console.log("Registration complete");
      this.showSignUp = false;
    },
    signinComplete() {
      this.signedIn = checkLoggedIn();
      // console.log("Signin complete:", signedIn.value);
      this.showSignIn = false;
    },
    goToRegistration() {
      this.showSignIn = false;
      this.showSignUp = true;
    },
    goToSignIn() {
      this.showSignUp = false;
      this.showSignIn = true;
    },
  },
});
</script>

<style scoped>
.custom-stepper,
.custom-stepper .q-stepper__tab,
.custom-stepper .q-stepper__content {
  border: none !important;
}

.container {
  height: 100vh;
  width: 100%;
}

.content {
  height: 100%;
  width: 90%;
  /* padding-left: 40px; */
  box-sizing: border-box;
  transition: padding-right 0.3s ease;
  align-items: left;
  /* Smooth transition for the margin change */
}

.section-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-center-image {
  display: block;
  margin: 40px auto 0px; /* Centers the image */
  width: 30%;
  max-width: 300px;
  min-width: 200px;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.active-doc {
  background-color: var(--accent);
  color: white;
}

.markdown-body {
  /* Add your markdown styles here */
  line-height: 1.6;
  color: var(--text);
  border-radius: 5px;
  font-family: var(--title-2);
}

.hyperlink {
  text-decoration: underline;
}

.hyperlink:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.highlight {
    background-color: yellow;
    color: black;
}
</style>
