<template>
    <div ref="xmlContainer" id="xmlContainer"></div>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import { useStore } from "vuex";
  import axios from "axios";
  import Fuse from "fuse.js"; // Assuming you're using Fuse.js directly
  
  export default defineComponent({
    name: "Bill",
    data() {
      return {
        bill: null,
        process_step: 1,
        tab: "eligibility",
        activeDoc: null,
        showSignIn: false,
        showSignUp: false,
        signedIn: false,
        store: null,
        billLines: [],
        fuse: null,
      };
    },
    async mounted() {
      this.store = useStore();
      this.signedIn = await this.checkLoggedIn();
      this.getBillInfo();
    },
    methods: {
      async transformXML(bill_id) {
        const xslUrl = '/xsl/billres.xsl';
        if (!this.$refs.xmlContainer) {
          console.warn("XML container not available.");
          throw new Error("XML container not available"); // Correct error handling in async method
        }
        try {
          const xslResponse = await fetch(xslUrl);
          const xslText = await xslResponse.text();
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(this.bill.xml, "text/xml");
          const xslDoc = parser.parseFromString(xslText, "text/xml");
          const xsltProcessor = new XSLTProcessor();
          xsltProcessor.importStylesheet(xslDoc);
          const resultDocument = xsltProcessor.transformToFragment(xmlDoc, document);
          const serializer = new XMLSerializer();
          const string = serializer.serializeToString(resultDocument);
          const contentDiv = this.$refs.xmlContainer;
          contentDiv.innerHTML = ''; // Clear previous content
          contentDiv.appendChild(resultDocument);
          console.log("RESULT DOCUMENT", string);
          try {
            await axios.post("/api/v1/set-bill-text-html/", {
                bill_text_id: bill_id,
                html: string
            });
            } catch (error) {
            console.error('Error posting data:', error);
            }
        } catch (error) {
          console.error('Error transforming XSL:', error);
          this.$refs.xmlContainer.innerHTML = 'Error transforming XML.';
          throw error; // Re-throw error after handling
        }
      },
        isChrome() {
            const userAgent = navigator.userAgent;
            return userAgent.includes("Chrome") && !userAgent.includes("Edge") && !userAgent.includes("OPR");
        },
        async getBillInfo() {
            if (!this.isChrome()) {
                alert("This feature is only supported in Google Chrome.");
                return;
            }
            
            try {
                const response = await axios.get("/api/v1/get-no-html-bill-ids");
                for (const id of response.data) {
                    try {
                        const billResponse = await axios.get("/api/v1/get-bill", { params: { bill_id: id } });
                        this.bill = billResponse.data;
                        await this.$nextTick();
                        await this.transformXML(id);
                        // this.getBillLines(); // Ensures this runs only after transformXML completes
                    } catch (error) {
                        console.error(`Error fetching bill info for bill ID ${id}:`, error);
                    }
                }
            } catch (error) {
                console.error("Error fetching bill IDs:", error);
            }
            },
      async checkLoggedIn() {
        // Placeholder function for actual login check logic
        return false; // Default return
      },
      getBillLines() {
        // Placeholder for getting bill lines
      }
    }
  });
  </script>
  
  <style scoped>
  </style>