<template>
    <div class="q-my-md assistant-container bg-grey-1 bordered" @mouseenter="isMouseOver = true"
        @mouseleave="isMouseOver = false" style="height: 100vh;">
        <div class="column justify-between full-height full-width" style="height: 100%;">

            <div class="col-1">
                <q-btn round @click="$emit('close', true)" icon="close"
                class="q-ma-none q-mb-none q-mr-none q-pa-none q-ml-md" style="width: 40px; height: 40px" flat />
                <h4 class="col text-center">Aidy Assistant</h4>
                <q-separator inset />
            </div>

            <q-scroll-area class="col" style="flex-grow: 1;">
                <div class="q-pa-md row justify-center" style="padding: 11px 0px;">
                    <div style="width: 100%;">
                        <div>
                            <div class="message bot-message"
                                v-html="renderMarkdown('Hi there, what questions about these bills can I answer for you?')">
                            </div>
                        </div>
                        <div v-for="(interaction, index) in interactions" :key="index">
                            <div v-html="renderMarkdown(interaction.text)"
                                :class="{ 'user-message': interaction.from === 'user', 'bot-message': interaction.from !== 'user' }">
                            </div>
                            <div v-if="interaction.sources !== undefined" class="q-pl-sm q-gutter-sm">
                                <q-btn v-for="source in interaction.sources" round size="sm" :label="source.index + 1"
                                    color="accent" @click="$emit('highlight', source.content)">
                                    <q-tooltip>
                                        See source
                                    </q-tooltip>
                                </q-btn>
                            </div>
                        </div>
                        <div v-if="isTyping" style="padding: 10px;" class="q-mb-lg">
                            <p>{{ loadingText }}</p>
                            <q-spinner-dots size="2rem" />
                        </div>
                    </div>
                </div>
            </q-scroll-area>

            <q-input square v-model="inputText" filled type="text" autogrow placeholder="Ask here..."
                @keypress.enter="handleKeyPress" :class="{ 'accent-color': inputText }" style="width: 100%;" class="absolute-bottom bg-white">
                <template v-slot:append>
                    <q-btn @click="handleSubmit" icon="send" type="submit" :disabled="!inputText" round />
                </template>
            </q-input>

        </div>
    </div>
</template>


<script>
import { ref, toRefs, nextTick, onMounted, defineEmits } from "vue";
import axios from "axios";
import { renderMarkdown as renderMarkdownExternal } from "@/helpers/helpers";

export default {
    name: "AllBillAssistant",
    props: {
        bills: Array,
    },
    setup(props, { emit }) {
        const { bills } = toRefs(props);
        const interactions = ref([]);
        const inputText = ref("");
        const isTyping = ref(false);
        const scrollContainer = ref(null);
        const isMouseOver = ref(false);
        const isScrollingUp = ref(false);
        const loadingText = ref("")

        function renderMarkdown(markdownText) {
            return renderMarkdownExternal(markdownText);
        }

        // check to see if user scrolls up to stop the auto scroll
        function handleScroll(event) {
            if (isMouseOver && event.deltaY < 0) {
                isScrollingUp.value = true;
            }
        }

    
        // auto scroll to the bottom
        function scrollToBottom() {
            nextTick(() => {
                if (scrollContainer.value && !isScrollingUp.value) {
                    scrollContainer.value.scrollIntoView({ block: "end", inline: "nearest" })
                }
            });
        }

        function handleSubmit(historyCount = 5) {
            isTyping.value = true;
            const userText = inputText.value;
            interactions.value.push({ text: userText, from: "user" });
            inputText.value = ""; // Clear the input field after submission
            isScrollingUp.value = false;
            scrollToBottom();

            // isolate the bill ids
            const bill_ids = bills.value.map(bill => bill.id)

            const recentInteractions = interactions.value
                .slice(-historyCount)
                .map((interaction) => ({
                    role: interaction.from === "user" ? "user" : "assistant",
                    content: interaction.text,
                }));

            // Fetch data using POST method
            fetch(`${axios.defaults.baseURL}/api/state/chat-all-statebills/`, {
                //Be sure to update this for different environments
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    messages: recentInteractions,
                    bill_ids: bill_ids,
                }),
            })
                .then((response) => {
                    const reader = response.body.getReader();
                    let chunks = "";
                    let firstChunkReceived = false;

                    reader.read().then(function processChunk({ done, value }) {
                        if (done) {
                            isTyping.value = false;
                            return;
                        }

                        chunks += new TextDecoder().decode(value);

                        // Check for complete JSON objects and process them
                        while (chunks.indexOf("}") !== -1) {
                            let chunkEnd = chunks.indexOf("}") + 1;
                            let chunk = chunks.substring(0, chunkEnd);
                            chunks = chunks.substring(chunkEnd);

                            let data = JSON.parse(chunk);

                            let contentText = ""

                            // Checking for the status chunks
                            if (data.status_msg == true) {
                                loadingText.value = data.content;
                            }
                            else {

                                // Hide the typing element when the first chunk is received
                                if (!firstChunkReceived) {
                                    contentText = data.content.replace("<br>", " "); // Replace line breaks with spaces
                                    interactions.value.push({ text: contentText, from: "bot" });
                                    firstChunkReceived = true;
                                    isScrollingUp.value = false;
                                    scrollToBottom();
                                } else {
                                    isTyping.value = false;
                                    if (data.hasOwnProperty('index')) {
                                        if (!interactions.value[interactions.value.length - 1].hasOwnProperty('sources')) {
                                            interactions.value[interactions.value.length - 1].sources = [];
                                        }
                                        interactions.value[interactions.value.length - 1].sources.push(data);
                                    } else {
                                        contentText = data.content.replace("<br>", " "); // Replace line breaks with spaces
                                        interactions.value[interactions.value.length - 1]["text"] += contentText;
                                        scrollToBottom();
                                    }
                                }
                            }
                        }

                        return reader.read().then(processChunk);
                    });
                })
                .catch((error) => {
                    isTyping.value = false;
                    console.error("Error:", error);
                    interactions.value.push({
                        text: "Sorry, an error occurred.",
                        from: "bot",
                    });
                });
        }

        function handleKeyPress(event) {
            if (event.shiftKey) {
                return;
            }
            event.preventDefault();
            handleSubmit();
        }

        return {
            renderMarkdown,
            interactions,
            isTyping,
            inputText,
            handleSubmit,
            handleKeyPress,
            scrollContainer,
            loadingText,
            isMouseOver,
        };
    },
};
</script>

<style scoped>
.full-height {
    height: 100%;
}

.assistant-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* Ensure container does not exceed parent */
}

.chat-container {
    flex-grow: 1;
    overflow-y: auto;
    /* Ensure chat messages are scrollable */
    max-height: calc(100% - 60px);
    /* Adjust height to leave space for input */
}

.user-message {
    text-align: right;
    margin-left: auto;
    padding: 8px;
    margin-bottom: 4px;
}

.bot-message {
    background-color: #E0E0E0;
    text-align: left;
    padding: 8px;
    margin-bottom: 4px;
}

.accent-color {
    color: var(--accent);
}

button:focus,
input:focus {
    outline: none;
}

.chat-container {
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.chat-msg {
    margin: 5px 0px;
    width: 90%;
    padding: 10px;
    border-radius: 10px;
    font-family: var(--title2);
}

.chat-user {
    align-self: flex-end;
    text-align: right;
    background-color: var(--bg-grey-3);
    color: var(--text);
}

.chat-bot {
    align-self: flex-end;
    text-align: left;
    background-color: var(--accent);
    color: var(--text);
}

.chat-header {
    min-width: 500px;
}

.thin-line {
    border: 0;
    border-top: 1px solid rgb(128 128 128);
    width: 80%;
    margin: 10px auto;
}

p {
    margin: 0;
}

/* Define the CSS classes for the slide-right transition */
.slide-right-enter-active,
.slide-right-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-right-enter-from {
    transform: translateX(100%);
    opacity: 0;
}

.slide-right-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
</style>
<style>
.custom-h3 {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: normal;
}

.custom-h4 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
}
</style>