<template>
  <div v-if="questionData">
    <div v-if="showPopup" class="popup-wrapper">
      <PopUp :text="popupText" @response="handlePopupResponse"/>
    </div>
    
    <q-input
      v-model="questionData.name"
      label="Question Name:"
      @change="updateQuestionUI()"
    />
    <br>
    <q-input
      v-model="questionData.question_text"
      label="Question Text:"
      filled
      type="textarea"
      @change="updateQuestionUI()"
    />
    <br>
    <q-select 
      v-model="questionData.question_type" 
      :options="q_type_choices"
      label="Question Type:"
      color="primary"
      @change="updateQuestionUI()"
    />
    <br>
    <div class="json-editor">
      <VueJSONEditor
        :content="{json: questionData.display}"
        :onChange="updateDisplay"
        label="Display Logic"
      />
    </div>
    <br>
    <q-checkbox 
      color="primary"  
      keep-color
      indeterminate-icon="check_box_outline_blank"
      unchecked-icon="check_box_outline_blank"
      v-model="questionData.repeatable"
      label="Repeatable"
      @change="updateQuestionUI()"
    />
    <br>
    <q-checkbox 
      color="primary"  
      keep-color
      indeterminate-icon="check_box_outline_blank"
      unchecked-icon="check_box_outline_blank"
      v-model="questionData.required"
      label="Required"
      @change="updateQuestionUI()"
    />
    <br>
    <q-btn v-if="checkInputOrder()" @click="reorderInputs()">Update Input Order</q-btn>
    <q-expansion-item
      v-for="(input, iIndex) in questionData.inputs"
      :key="iIndex"
      switch-toggle-side
      expand-separator
      :label="input.name"
      caption="Click to expand"
      class="justify-start"
    >
      <template v-slot:header>
        <div class="row items-center justify-between" style="width: 100%">
          <span><span class="text-weight-bold">Input:</span> {{ input.name ? input.name : 'Untitled' }}</span>
          <div>
            <q-btn v-if="!input.id" @click.stop="createInput(input)">
              Create Input
            </q-btn>
            <q-btn v-if="input.id && input.changed" @click="saveInput(iIndex)">
              Save Input
            </q-btn>
            <q-btn v-if="input.id" @click.stop="onDeleteClicked(deleteInput, input.id)">
              <q-icon name="delete"/>
            </q-btn>
            <q-btn v-if="iIndex > 0" @click.stop="moveInput(input.id, -1)">
              <q-icon name="north"/>
            </q-btn>
            <q-btn v-if="iIndex < questionData.inputs.length - 1" @click.stop="moveInput(input.id, 1)">
              <q-icon name="south"/>
            </q-btn>
          </div>
        </div>
      </template>
      <div class="q-pa-lg">
        <InputEditor :ref="`inputEditor-${iIndex}`" v-if="input" :inputData="input"></InputEditor>
      </div>
    </q-expansion-item>
    <q-btn v-if="!addingNewInput && questionData.id && !add_existing" @click="addNewInput()">Create New Input</q-btn>
    <q-btn v-if="!addingNewInput && questionData.id && !add_existing" @click="addExistingInput()">Add Existing Input</q-btn>
    <br>
    <q-select 
      v-if="add_existing"
      v-model="add_existing_name"
      use-input
      hide-selected
      fill-input
      input-debounce="0"
      :options="grantInputs"
      label="Existing input to add:"
      color="primary"
      @filter="filterFn"
      @update:model-value="getExistingInput()"
    />
    <q-btn v-if="add_existing && add_existing_name!==''" @click="popExistingInput()">Add this input</q-btn>
  </div>
</template>

<script>
import axios from "axios";
import { inject, onMounted } from 'vue';

export default {
  name: "QuestionEditor",
  props: {
    questionData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      serverData: null,
      addingNewInput: false,
      showPopup: false,
      popupText: 'Are you sure you want to delete this?',
      pendingAction: null,
      q_type_choices: [
        "MC",
        "SA",
        "CA",
        "MP",
        "UP"
      ],
      add_existing: false,
      add_existing_name: "",
    };
  },
  setup() {
    const grantInputs = inject('grant_input');

    console.log("GRANT INPUTS in SECTION:", grantInputs.value);

    onMounted(() => {
      console.log("GRANT INPUTS:", grantInputs);
    });

    return { 
      grantInputs,
      filterFn (val, update, abort) {
        update(() => {
          const needle = val.toLowerCase();
          grantInputs.value = grantInputs.value.filter(v => v.toLowerCase().indexOf(needle) > -1);
          console.log("FILTERED GRANT INPUTS:", grantInputs.value);
        })
      }
    };
  },
  mounted() {
    this.serverData = JSON.parse(JSON.stringify(this.questionData));
    // Assuming docData is fetched or set here
    this.questionData.changed = false;
    this.questionData.questionsVisible = true; // Directly assign the property
  },
  methods: {
    addExistingInput() {
      this.add_existing = true;
    },
    getExistingInput() {
      console.log("GET EXISTING INPUT:", this.add_existing_name);
    },
    async popExistingInput() {
      console.log("POP EXISTING INPUT:", this.add_existing_name);
      this.add_existing = false;
      try {
        const response = await axios.get(`api/v1/add-input-editor`, {
          params: {
            input_name: this.add_existing_name,
            question_id: this.questionData.id,
          },
        });
        const existingInput = response.data;
        console.log("EXISTING INPUT:", existingInput);
        this.questionData.inputs.push(existingInput);
        // return files;
      } catch (error) {
        console.error("Error fetching input:", error);
      }
    },
    onDeleteClicked(action, ...params) {
      this.showPopup = true;
      this.pendingAction = { action, params };
    },
    handlePopupResponse(response) {
      this.showPopup = false;
      if (response === 'yes' && this.pendingAction) {
        this.pendingAction.action(...this.pendingAction.params);
      }
      this.pendingAction = null;
    },

    createInput(inputData) {
      try {
          const url = "api/v1/create-input/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };
          axios.post(url, {"input": inputData, "questionId": this.questionData.id, "index": 0}, { headers })
              .then(response => {
                let inputIndex = this.questionData.inputs.findIndex(input => input === inputData);
                if (inputIndex !== -1) {
                    // Update server data for the specific input
                    this.serverData.inputs[inputIndex] = JSON.parse(JSON.stringify(response.data.serialized));

                    // Update grant data for the specific input
                    this.questionData.inputs[inputIndex] = JSON.parse(JSON.stringify(response.data.serialized));

                    // Set changed back to false for the specific input
                    this.questionData.inputs[inputIndex].changed = false;
                } else {
                    console.error("Question not found with id:", this.questionData.id);
                }
                this.addingNewInput = false;
                console.log("Successfully created input")
              })
              .catch(error => {
                this.addingNewInput = false;
                console.error(
                    "Error creating input:",
                    error.response ? error.response.data : error.message
                );
              });
      } catch (error) {
          console.error(
              `Error making API call`,
              error
          );
      }
    },

    moveInput(input_id, shift = 0) {
      let inputIndex = -1;

      inputIndex = this.questionData.inputs.findIndex(input => input.id === input_id);


      // Calculate the new position within the same section
      const newPosition = inputIndex + shift;
      
      // Ensure newPosition is within the bounds of the section's question list
      if (newPosition < 0 || newPosition >= this.questionData.inputs.length) {
        console.log("Shift out of bounds within the question");
        return;
      }

      // Remove the question from its current position
      const [input] = this.questionData.inputs.splice(inputIndex, 1);

      // Insert the question at its new position within the same section
      this.questionData.inputs.splice(newPosition, 0, input);

      console.log("INPUTS AFTER MOVE:", this.questionData.inputs);
    },

    deleteInput(input_id) {
      try {
          const url = "api/v1/delete-input/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"input_id": input_id, "question_id": this.questionData.id}, { headers })
              .then(response => {
                  console.log("Successfully deleted input")
                  let index = this.questionData.inputs.findIndex(input => input.id === input_id);

                  if (index !== -1) { // Check if the document was found
                      this.questionData.inputs.splice(index, 1); // Remove the section
                      this.serverData.inputs.splice(index, 1); // Remove the section from server data
                  }
              })
              .catch(error => {
                  console.error(
                      "Error deleting input:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating input ${input_id}:`,
              error
          );
      }
    },
    
    reorderInputs() {
      console.log("Reordering inputs");

      // Create an order map for the inputs
      const inputOrderMap = this.questionData.inputs.reduce((acc, input, index) => {
          acc[input.id] = index;
          return acc;
      }, {});
      console.log("INPUT ORDER MAP:", inputOrderMap);

      try {
          const url = "api/v1/update-input-order/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          // Send the input order map to the API
          axios.post(url, { "question_id": this.questionData.id, "order_map": inputOrderMap }, { headers })
              .then(response => {
                  console.log("Successfully reordered question inputs")
                  this.questionData.inputs.forEach((input, index) => {
                    input.order = index;
                  });
              })
              .catch(error => {
                  console.error(
                      "Error reordering question inputs:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating question ${question_id}:`,
              error
          );
      }
    },

    checkInputOrder() {
      for (let i = 0; i < this.questionData.inputs.length; i++) {
          if (this.questionData.inputs[i].order !== i && this.questionData.inputs[i].id !== null) {
              return true;
          }
      }
      // No mismatches found, section orders are consistent with their indices
      return false;
    },

    saveQuestion() {
      console.log("QUESTION DATA:",this.questionData);
      try {
          const url = "api/v1/update-question/";
          const headers = {
              ...axios.defaults.headers.common,
              "Content-Type": "application/json",
          };

          axios.post(url, {"question": this.questionData}, { headers })
              .then(response => {
                  this.serverData.name = this.questionData.name;
                  this.serverData.description = this.questionData.description;
                  this.questionData.changed = false;
                  console.log("Successfully updated question")
              })
              .catch(error => {
                  console.error(
                      "Error updating question:",
                      error.response ? error.response.data : error.message
                  );
              });
      } catch (error) {
          console.error(
              `Error updating question ${this.serverData.name}:`,
              error
          );
      }
    },

    saveInput(index) {
      console.log("Saving input in wrapper:",index);
      // Access the correct SectionEditor by its ref
      const inputEditorRef = this.$refs[`inputEditor-${index}`];
      console.log("INPUT EDITOR REF:",inputEditorRef);
      if (inputEditorRef && inputEditorRef[0]) {
        // Call saveSection on the QuestionEditor component
        inputEditorRef[0].saveInput();
      }
    },

    addNewInput() {
      // Create a new blank input object
      const newInput = {
        name: '',
        input_type: '',
        label_text: '',
        placeholder: true,
        calculation: null,
        config: {},
        order: this.questionData.inputs.length
      };

      // Add this new input to the question's inputs array
      this.questionData.inputs.push(newInput);

      // Update state to reflect a new input is being added
      this.addingNewInput = true;
    },

    updateQuestionUI() {
      console.log("HELLOOO")
      // console.log("UPDATING QUESTION:",this.questionData.question_text[:50]);

      // this.sectionData.changed = this.sectionData.name !== this.serverData.name || 
      //                           this.sectionData.description !== this.serverData.description ||
      //                           this.sectionData.display !== this.serverData.display;
      console.log("LIVE DISPLAY:", this.questionData.display);
      console.log("SERVER DISPLAY:", this.serverData.display);
      // this.questionData.changed = this.questionData.name !== this.serverData.name ||
      //                             this.questionData.question_text !== this.serverData.question_text ||
      //                             this.questionData.question_type !== this.serverData.question_type ||
      //                             this.questionData.display !== this.serverData.display;
      this.questionData.changed = this.questionData.name !== this.serverData.name ||
                              this.questionData.question_text !== this.serverData.question_text || 
                              this.questionData.question_type !== this.serverData.question_type || 
                              this.questionData.display !== this.serverData.display || 
                              this.questionData.repeatable !== this.serverData.repeatable || 
                              this.questionData.required !== this.serverData.required;

      console.log("Question changed status:", this.questionData.changed);
      // return this.questionData.changed;
    },

    updateDisplay(content) {
      console.log("onChange", content);
      this.questionData.display = content.json;
      this.updateQuestionUI();
    },
  },
};
</script>

<style>
.json-editor {
  display: flex;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
}

.header button {
  max-height: 20px;
}

.popup-wrapper {
  position: fixed; /* Fixed position relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for a semi-transparent background */
  z-index: 1000; /* Ensure it's on top */
}

.popup {
  /* Your existing popup styles */
  /* Add styles for padding, background, etc., as necessary */
  max-width: 90%; /* Optional: to ensure it doesn't get too wide on large screens */
  box-sizing: border-box; /* To include padding and borders in the width */
  z-index: 1001; /* Ensure it's above the semi-transparent background */
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers vertically in the container */
  align-items: right; /* Centers horizontally in the container */
  /* text-align: center;      Ensures text within the children is centered */
  /* max-height: 100vh;           Takes up the full viewport height */
  padding: 0px; /* Optional padding */
  box-sizing: border-box;
  width: 100%;
}

.grant-container {
  padding-left: 0;
}

.document-container {
  padding-left: 20px; /* Indent documents */
}

.section-container {
  padding-left: 40px; /* Further indent sections */
}

.question-container {
  padding-left: 60px; /* Further indent questions */
}

.input-container {
  padding-left: 80px; /* Further indent inputs */
}

.grant-input, .document-input, .section-input, .question-input, .input-input {
  display: block; /* Make inputs take the full width of the container */
  margin-bottom: 10px; /* Space out inputs */
  width: 500px;
}

.dropdown-icon {
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.is-open {
  transform: rotate(90deg); /* Rotates the icon to point downwards */
}

h2, h3, h4 {
  cursor: pointer;
}

.arrow {
    display: inline-block;
}
.up {
  transform: rotate(-90deg);
}
.down {
  transform: rotate(90deg);
}

</style>
