<template>
    <div  class="editor">
        <div
          class="editor__floating-menu"
          :style="{ top: menuPosition.top + 'px', left: menuPosition.left + 'px' }"
          :class="{ 'is-active': isMenuActive }"
        >
        <q-btn
            v-for="(item, index) in items"
            :key="index"
            flat
            dense
            :class="{ 'is-active': item.isActive() }"
            @click="item.command"
            class="q-mx-xs small-button"
        >
            <component :is="item.icon" /> 
          </q-btn>
        </div>
    </div>
  </template>
  
  <script>
  import { ref, computed, watch, onMounted} from 'vue'
  import { EditorContent } from '@tiptap/vue-3'
  import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'

  import {
  Check,
  ChevronDown,
  Heading1,
  Heading2,
  Heading3,
  TextQuote,
  ListOrdered,
  List,
  TextIcon,
  Code,
  CheckSquare,
  Grid3X3
} from "lucide-vue-next";
  
  export default {
    components: {
      EditorContent,
    },
    props: {
      editor: {
        type: Object,
        required: true
      },
      editorRef: {
        type: Object,
        required: true
      },

    },
    setup(props) {
      const editor = ref(props.editor);
      const menuPosition = ref({ top: 0, left: 0 });
      const editorRef = ref(props.editorRef);
      const menuWidth = ref(0);
  
      const isMenuActive = computed(() => {
      const { selection } = editor.value.state;
      if (!editor.value.view.hasFocus() || !selection || !selection.$cursor) return false;

      // Determine if the selection is in a special context
      const isInSpecialContext = () => {
        let depth = selection.$anchor.depth;
        while (depth >= 0) {
          const node = selection.$anchor.node(depth);
          if (node && (node.type.name === 'table' || node.type.name === 'row' ||
                      node.type.name === 'cell' || node.type.name === 'bullet_list' ||
                      node.type.name === 'ordered_list' || node.type.name === 'list_item')) {
            return true;
          }
          depth--;
        }
        return false;
      };

      if (isInSpecialContext()) {
        return false;
      }

      // Check if the current node is a blank paragraph
      const parentNode = selection.$cursor.parent;
      if (parentNode && parentNode.type.name === 'paragraph' && parentNode.content.size === 0) {
        const coords = editor.value.view.coordsAtPos(selection.$cursor.pos);
        const editorRect = editorRef.value.getBoundingClientRect();

        menuPosition.value = {
          top: coords.top - editorRect.top + window.scrollY,  // Adjusted to be relative to the editor
          left: coords.left - editorRect.left + window.scrollX + menuWidth.value / 2 +20
        };
        return true;
      }

      return false;
    });








      const items = [
      {
        label: 'H1',
        icon: Heading1, // Replace this with your actual icon
        command: () => editor.value.commands.toggleHeading({ level: 1 }),
        isActive: () => editor.value.isActive('heading', { level: 1 })
      },
      {
        label: 'H2',
        icon: Heading2, // Adjust icon accordingly
        command: () => editor.value.commands.toggleHeading({ level: 2 }),
        isActive: () => editor.value.isActive('heading', { level: 2 })
      },
      {
          name: "Heading 3",
          icon: Heading3,
          command: () =>
            props.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => props.editor.isActive("heading", { level: 3 }),
        },
        // {
        //   name: "To-do List",
        //   icon: CheckSquare,
        //   command: () => props.editor.chain().focus().toggleTaskList().run(),
        //   isActive: () => props.editor.isActive("taskItem"),
        // },
        {
          name: "Bullet List",
          icon: List,
          command: () => props.editor.chain().focus().toggleBulletList().run(),
          isActive: () => props.editor.isActive("bulletList"),
        },
        {
          name: "Numbered List",
          icon: ListOrdered,
          command: () => props.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => props.editor.isActive("orderedList"),
        },
        {
          name: "Quote",
          icon: TextQuote,
          command: () =>
            props.editor
              .chain()
              .focus()
              .toggleNode("paragraph", "paragraph")
              .toggleBlockquote()
              .run(),
          isActive: () => props.editor.isActive("blockquote"),
        },
        {
          name: "Code",
          icon: Code,
          command: () => props.editor.chain().focus().toggleCodeBlock().run(),
          isActive: () => props.editor.isActive("codeBlock"),
        },
        {
          name: "Table",
          icon: Grid3X3, // Use the mdiTable icon imported at the top of the script
          command: () => props.editor.chain().focus().insertTable({
            rows: 3,
            cols: 3,
            withHeaderRow: true,
            cellOptions: {
              padding: '12px',
              minWidth: '100px',
              maxWidth: '300px',
            }
          }).run(),
          isActive: () => props.editor.isActive("table"),
        }
      ];
  
      // Watch the editor state to reactively update the floating menu visibility
      watch(() => editor.value.state.selection, () => {
        // You could expand this logic to handle more specific conditions
      }, { deep: true });
  
      onMounted(() => {
      // Assume the menu element has a ref="menuElement"
      const menuElement = editorRef.value.querySelector('.editor__floating-menu');
      if (menuElement) {
        menuWidth.value = menuElement.offsetWidth;
      }
    });
      return {
        editor,
        items,
        isMenuActive,
        menuPosition,
        editorRef
      };
    }
  }
  </script>
  
  <style scoped lang="scss">
  .editor {
    &__floating-menu {
      position: absolute;
      z-index: 100; // This should be consistent, choose either 1 or 100
      margin-top: -0.25rem;
      transform: translate(-50%, -10%); // This line is redundant in the second block
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s, visibility 0.2s;
      &.is-active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  </style>
  