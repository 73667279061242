<template>
  <q-layout view="hHh LpR fff">
    <div v-if="!loading">
      <q-drawer
        class="bg-grey-1 text-primary shadow-1 justify-between"
        show-if-above
        :width="240"
        :breakpoint="400"
      >
        <img
          class="q-pa-lg"
          alt=""
          v-if="org_name !== 'Tapestry International'"
          src="/logo.svg"
          style="width: 100%;"
        />
        <img
        class="q-pa-lg"
          alt=""
          v-if="org_name == 'Tapestry International'"
          src="/tapestry.png"
          style="width: 200px; height: auto"
        />
        <q-list v-if="user_type == 'legislation'">
          <q-item :class="{ 'bg-secondary': isActive('BillSearch') }" clickable
            @click="router.push({ name: 'BillSearch' })" v-ripple>
            <q-item-section avatar style="min-width: 40px">
              <q-icon name="gavel" />
            </q-item-section>
            <q-item-section>
              <h6 class="cursor-pointer">Federal</h6>
            </q-item-section>
          </q-item>
          <q-item :class="{ 'bg-secondary': isActive('StateBillSearch') }" clickable
            @click="router.push({ name: 'StateBillSearch' })" v-ripple>
            <q-item-section avatar style="min-width: 40px">
              <q-icon name="account_balance" />
            </q-item-section>
            <q-item-section>
              <h6 class="cursor-pointer">State</h6>
            </q-item-section>
          </q-item>
          <q-item :class="{ 'bg-secondary': isActive('BookmarkedBills') }" class="cursor-pointer" clickable
            @click="router.push({ name: 'BookmarkedBills' })" v-ripple>
            <q-item-section avatar style="min-width: 40px">
              <q-icon name="bookmark" />
            </q-item-section>
            <q-item-section>
              <h6 class="cursor-pointer">Saved Bills</h6>
            </q-item-section>
          </q-item>
        </q-list>
        <q-list v-else>
          <q-item
            v-if="user_type !== 'applicant' && user_type !== 'legislation'"
            :class="{ 'bg-secondary': isActive('Dashboard') }"
            clickable
            @click="router.push({ name: 'Dashboard' })"
            v-ripple
          >
            <q-item-section avatar style="min-width: 40px">
              <q-icon name="dashboard" />
            </q-item-section>
            <q-item-section>
              <h6>Dashboard</h6>
            </q-item-section>
          </q-item>
          <q-item
            v-else-if="user_type == 'applicant'"
            :class="{ 'bg-secondary': isActive('Portal') }"
            clickable
            @click="router.push({ name: 'Portal' })"
            v-ripple
          >
            <q-item-section avatar style="min-width: 40px">
              <q-icon name="dashboard" />
            </q-item-section>
            <q-item-section>
              <h6>Portal</h6>
            </q-item-section>
          </q-item>
        </q-list>
        <q-list class="absolute-bottom" style="margin-bottom: 20px">
          <q-item
            v-if="user_type != 'applicant'"
            :class="{ 'bg-secondary': isActive('User') }"
            clickable
            @click="router.push({ name: 'User' })"
            v-ripple
          >
            <q-item-section avatar style="min-width: 40px">
              <q-icon name="person" />
            </q-item-section>
            <q-item-section>
              <h6 class="cursor-pointer">My Team</h6>
            </q-item-section>
          </q-item>
          <q-item clickable @click="router.push({ name: 'SignIn' })" v-ripple>
            <q-item-section avatar style="min-width: 40px">
              <q-icon name="logout" />
            </q-item-section>
            <q-item-section>
              <h6 class="cursor-pointer">Sign Out</h6>
            </q-item-section>
          </q-item>
        </q-list>
      </q-drawer>
      <q-page-container
        class="row justify-center items-center full-height"
      >
        <router-view
          class="col justify-center full-height"
          style="max-width:1500px;"
          @update:appID="appID = $event"
        />
      </q-page-container>

      <aidy-assistant
        v-if="showAssistant"
        :q_and_a="page_content"
        :sidebarInitialState="false"
      ></aidy-assistant>
    </div>
  </q-layout>
</template>

<script>
import { ref, watch, computed } from "vue";
import { onMounted } from "vue";
import { fetchUser } from "@/api/userApi";
import { useRoute, useRouter } from "vue-router";
import AidyAssistant from "../components/AidyAssistant.vue";

export default {
  components: { AidyAssistant },
  name: "Home", // Corrected typo here
  setup() {
    const rightDrawerOpen = ref(false);
    const leftDrawerOpen = ref(true);
    const appID = ref(null);

    const route = useRoute();
    const router = useRouter();
    const isActive = (routeName) => {
      return route.name === routeName;
    };

    const page_content = ref("");

    const contentUpdate = (newContent) => {
      page_content.value = newContent;
    };

    const showAssistant = computed(() => route.meta.showAssistant);
    const loading = ref(false);
    const user_type = ref("");
    const org_name = ref("");
    onMounted(async () => {
      try {
        // await fetchUserData();
        loading.value = true;
        const response = await fetchUser();
        // await fetchUploads();

        user_type.value = response.role;
        // console.log("USER TYPE:", user_type.value);
        org_name.value = response.org_name;
        loading.value = false;
      } catch (error) {
        console.error("Error in mounted hook:", error);
      } finally {
      }
    });
    const updateAppID = (newAppID) => {
      appID.value = newAppID; // Assuming appID is a ref
    };

    return {
      miniState: ref(true),
      rightDrawerOpen,
      leftDrawerOpen,
      appID,
      isActive,
      router,
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },
      toggleRightDrawer() {
        rightDrawerOpen.value = !rightDrawerOpen.value;
      },
      page_content,
      contentUpdate,
      user_type,
      showAssistant,
      updateAppID,
      org_name,
      loading,
    };
  },
};
</script>
