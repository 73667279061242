<template>
  <div class="body">
    <sign-in-comp></sign-in-comp>
  </div>
</template>
<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { fetchUser, goHome } from "@/api/userApi";

const loading = ref(false); // loading state

const store = useStore();

const instance = getCurrentInstance();

// Created lifecycle hook
onMounted(() => {
  store.dispatch("logout"); // Logout the user
});

</script>
<style scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    to top,
    #fa9600 0%,
    var(--accent-semi) 50%,
    #ffffff 100%
  );
}
</style>
