<template>
  <div class="body">
    <div v-if="!loading">
      <section>
        <h5 class="text-weight-bold">Applicant</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          This is the primary applicant for this application. They will receive
          access to the Aidy upload portal.
        </div>
        <div class="q-pa-md row items-center q-gutter-md">
          <div v-for="applicant in applicants" :key="applicant.id">
            <q-card style="max-width: 17em">
              <q-icon name="person" color="primary" size="17em"></q-icon>

              <q-card-section>
                <div class="text-h6 ellipsis">
                  {{ applicant.first_name }} {{ applicant.last_name }}
                </div>
                <div class="text-h9 ellipsis">
                  {{ applicant.email }}
                </div>
                <q-badge
                  outline
                  align="middle"
                  :color="getBadgeColor(applicant)"
                >
                  {{ applicant.roles[orgId] }}
                </q-badge>
                <q-toggle
                  class="q-pa-none q-ma-none"
                  size="xs"
                  v-model="applicant.show_forms"
                  color="orange"
                  label="Show Forms"
                  @click.stop="updateShowForms(applicant)"
                />
              </q-card-section>
            </q-card>
          </div>
          <div v-if="pendingInvites" v-for="pending in pendingInvites[orgId]" :key="pending.id">
            <q-card style="max-width: 17em">
              <q-icon
                name="person"
                color="primary"
                size="17em"
                style="opacity: 0.3; filter: blur(2px)"
              ></q-icon>

              <q-card-section>
                <div
                  class="text-h6"
                  style="
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                ></div>
                <div class="text-h6 ellipsis">Invitation Sent</div>
                <div class="text-h9 ellipsis">{{ pending.to }}</div>
                <q-badge
                  outline
                  align="middle"
                  :color="getBadgeColor(pending)"
                  class="flex-shrink-0"
                >
                  {{ pending.role }}
                </q-badge>
              </q-card-section>
              <div class="absolute-top">
                <q-btn
                  class="full-width q-pt-sm"
                  color="white bg-primary"
                  label="Revoke Invitation"
                  size="sm"
                  :loading="revokingId == pending.id"
                  :disable="revokingId == pending.id"
                  @click.stop="confirmRevoke(pending.id)"
                  flat
                ></q-btn>
              </div>
            </q-card>
          </div>
          <div class="justify-center">
            <q-btn
              round
              icon="add"
              color="accent"
              @click="dialogVisible = true"
            >
              <q-tooltip class="bg-grey-7">Invite Applicant</q-tooltip></q-btn
            >
          </div>
          <q-dialog v-model="dialogVisible">
            <q-card v-if="!sendingInvite" style="width: 400px">
              <q-card-section>
                <div class="text-h6">Invite User</div>
              </q-card-section>

              <q-card-section>
                <q-input
                  v-model="emailAddress"
                  label="Email Address"
                  :rules="[
                    (val) =>
                      /.+@.+\..+/.test(val) || 'Must be a valid email address',
                  ]"
                />
                <q-select
                  v-model="userType"
                  label="User Type"
                  :options="[
                    { label: 'Applicant', value: 'applicant' },
                    { label: 'Vendor', value: 'vendor' },
                  ]"
                />
              </q-card-section>

              <q-card-section align="right">
                <q-btn
                  flat
                  label="Cancel"
                  color="primary"
                  @click="dialogVisible = false"
                />
                <q-btn
                  flat
                  label="Invite"
                  color="primary"
                  @click="inviteUser"
                />
              </q-card-section>
            </q-card>
            <q-spinner v-else color="primary" :size="80"></q-spinner>
          </q-dialog>
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Vendor Documents</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          Here are the documents that the vendor should complete. They can see
          these on their portal.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-for="doc in vendor_docs" :key="doc.id">
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              style="cursor: pointer"
              @click.stop="
                this.$router.push({
                  name: 'Document',
                  params: { id: doc.id },
                })
              "
            >
              <q-card-section class="row">
                <div
                  class="text-body1 text-weight-medium ellipsis"
                  style="word-wrap: break-word"
                >
                  {{ doc.display_name }}
                </div>
              </q-card-section>

              <q-card-actions class="row">
                <q-icon
                  v-if="doc.complete"
                  name="check"
                  color="primary"
                  size="32px"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Applicant Documents</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          Here are the documents that the applicants should complete. They can
          see these on their portal.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-for="doc in applicant_docs" :key="doc.id">
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              style="cursor: pointer"
              @click.stop="
                this.$router.push({
                  name: 'Document',
                  params: { id: doc.id },
                })
              "
            >
              <q-card-section class="row">
                <div
                  class="text-body1 text-weight-medium ellipsis"
                  style="word-wrap: break-word"
                >
                  {{ doc.display_name }}
                </div>
              </q-card-section>
              <q-card-actions class="row">
                <q-icon
                  v-if="doc.complete"
                  name="check"
                  color="primary"
                  size="32px"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Vendor Uploads</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          Here are the documents the vendor has uploaded through the Aidy
          portal. You can add more yourself.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-for="file in vendor_uploads" :key="file.id">
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              @click.stop="openPdfPreview(file.id)"
              style="cursor: pointer"
            >
              <q-card-section class="row">
                <div
                  class="text-body1 text-weight-medium ellipsis"
                  style="word-wrap: break-word"
                >
                  {{ file.name }}
                </div>
              </q-card-section>

              <!-- <q-separator primary /> -->

              <q-card-actions class="row justify-between">
                <q-btn flat @click.stop="handleDownload(file.id)">
                  <q-icon name="download" color="primary" size="32px" />
                </q-btn>
                <q-btn flat @click.stop="removeFile(file.id)">
                  <q-icon name="delete_forever" color="primary" size="32px" />
                </q-btn>
              </q-card-actions>
            </q-card>
          </div>
          <div>
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              style="opacity: 0.7"
              @click="triggerFileInput('vendorFileInput')"
            >
              <q-card-section v-if="!vendorUploading" class="row">
                <div
                  class="text-body1 text-weight-medium"
                  style="overflow: hidden"
                >
                  Upload New Document
                </div>
              </q-card-section>
              <div v-else class="flex flex-center full-height">
                <q-spinner color="primary" :size="50"></q-spinner>
              </div>
            </q-card>
            <input
              type="file"
              style="display: none"
              ref="vendorFileInput"
              accept=".pdf, .docx"
              @change="onFileSelected($event, 'VENDOR_UPLOAD')"
            />
          </div>
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Applicant Uploads</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          Here are the documents the applicant has uploaded through the Aidy
          portal. You can add more yourself.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-for="file in applicant_uploads" :key="file.id">
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              @click.stop="openPdfPreview(file.id)"
              style="cursor: pointer"
            >
              <q-card-section class="row">
                <div
                  class="text-body1 text-weight-medium ellipsis"
                  style="word-wrap: break-word"
                >
                  {{ file.name }}
                </div>
              </q-card-section>

              <q-card-actions class="row justify-between">
                <q-btn flat @click.stop="handleDownload(file.id)">
                  <q-icon name="download" color="primary" size="32px" />
                </q-btn>
                <q-btn flat @click.stop="removeFile(file.id)">
                  <q-icon name="delete_forever" color="primary" size="32px" />
                </q-btn>
              </q-card-actions>
            </q-card>
          </div>
          <div>
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              style="opacity: 0.7"
              @click="triggerFileInput('applicantFileInput')"
            >
              <q-card-section v-if="!applicantUploading" class="row">
                <div
                  class="text-body1 text-weight-medium"
                  style="overflow: hidden"
                >
                  Upload New Document
                </div>
              </q-card-section>
              <div v-else class="flex flex-center full-height">
                <q-spinner color="primary" :size="50"></q-spinner>
              </div>
            </q-card>
            <input
              type="file"
              style="display: none"
              ref="applicantFileInput"
              accept=".pdf, .docx"
              @change="onFileSelected($event, 'APPLICANT_UPLOAD')"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else class="flex flex-center q-mt-lg" style="height: 100%">
      <q-spinner color="primary" :size="80"></q-spinner>
    </div>
    <q-dialog v-model="showPdfPreviewModal" class="full-width">
      <q-card class="full-width">
        <q-card-section class="row items-center">
          <div
            v-if="iframeLoading"
            class="flex flex-center"
            style="width: 100%; height: 700px"
          >
            <q-spinner color="primary" size="80px" />
          </div>
          <iframe
            v-else
            ref="pdfPreviewIframe"
            width="100%"
            height="700px"
          ></iframe>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            label="Close"
            color="primary"
            @click="showPdfPreviewModal = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {
  fetchFiles,
  deleteFile,
  saveFile,
  downloadFile,
  getPdfPreviewUrl,
  fetchDocs,
} from "@/api/fileApi";
import { fetchAppUsers, inviteAs, revokeInvite } from "@/api/userApi";

export default {
  name: "Application",
  data() {
    return {
      proposal: null,
      loading: false,
      showPdfPreviewModal: false,
      iframeLoading: false,
      formAnswers: {},
      eligibilityFlags: [],
      iq_id: null,
      uploads: null,
      vendorUploading: false,
      applicantUploading: false,
      appID: null,
      requested_files: [""],
      saving: false,
      dialogVisible: false,
      emailAddress: "",
      userType: { label: "Applicant", value: "applicant" },
      applicants: null,
      pendingInvites: null,
      sendingInvite: false,
      revokingId: null,
      vendor_docs: null,
      applicant_docs: null,
      vendor_uploads: null,
      applicant_uploads: null,
      user: {
        first_name: "No Applicant",
        last_name: "",
        email: "No Email Provided",
        org_name: "No Organization Provided",
        invited: false,
        show_forms: false,
        edit: false,
        slug: "",
      },
    };
  },
  props: {
    inputAppId: {
      type: Number,
      default: null,
    },
    orgId: {
      type: Number,
      default: null,
    },
    userProp: {
      type: Object,
      default: null,
    },
    requestedFiles: {
      type: Array,
      default: [""],
    },
  },
  watch: {
    // Watcher for the requestedFiles prop
    requestedFiles: {
      handler(newVal, oldVal) {
        this.requested_files = newVal;
      },
      deep: true, // This ensures the watcher will detect changes within the array
    },
  },
  computed: {
    statusClass() {
      return (status) => {
        switch (status) {
          case "complete":
            return "card-complete";
          case "partial":
            return "card-incomplete";
          case "incomplete":
          default:
            return "card-incomplete";
        }
      };
    },
  },
  mounted: async function () {
    this.loading = true; // Start loading
    this.appID = this.inputAppId;
    this.requested_files = this.requestedFiles;
    this.vendor_docs = await fetchDocs(this.appID, "vendor");
    this.applicant_docs = await fetchDocs(this.appID, "applicant");
    const [applicantResponse, vendorResponse] = await Promise.all([
      fetchAppUsers(this.appID, "applicant"),
      fetchAppUsers(this.appID, "vendor"),
    ]);

  // Combine the users into the applicants array
    this.applicants = [...applicantResponse.users, ...vendorResponse.users];

    await this.fetchPending();
    await this.fetchUploads(this.appID);
    if (this.userProp !== null) {
      this.user = this.user || {}; // Initialize this.user if it's not already an object
      for (const key in this.userProp) {
        if (this.userProp.hasOwnProperty(key)) {
          this.user[key] = this.userProp[key];
        }
      }
    }
    this.loading = false;
  },
  methods: {
    async fetchUploads(app_id) {
      this.vendor_uploads = await fetchFiles(app_id, "VENDOR_UPLOAD");
      this.applicant_uploads = await fetchFiles(app_id, "APPLICANT_UPLOAD");
    },
    async openPdfPreview(fileId) {
      this.showPdfPreviewModal = true;
      this.iframeLoading = true;
      try {
        const url = await getPdfPreviewUrl(fileId);
        this.iframeLoading = false; // IMPORTANT: set to false BEFORE the url is set
        this.$nextTick(() => {
          if (this.$refs.pdfPreviewIframe) {
            this.$refs.pdfPreviewIframe.src = url;
          }
        });
      } catch (error) {
        // Handle the error here, e.g., by showing an error message
        console.error("Failed to load PDF preview:", error);
        this.$q.notify({
          color: "negative",
          position: "top",
          message: "This file type cannot be previewed",
          icon: "report_problem",
        });
      }
    },
    triggerFileInput(refName) {
      this.$refs[refName].click();
    },
    getBadgeColor(user) {
      if (user.role === "coordinator") return "accent";
      else if (user.role === "applicant") return "primary";
      else return "primary";
    },
    async fetchPending() {
      const response = await axios.get("api/v1/get-pending/", {
        params: {
          appID: this.appID,
        },
      });
      // console.log("RESPONSE", response);
      this.pendingInvites = response.data;
      console.log("PINDING I", this.pendingInvites);
    },
    async revokeInvitation(invite_id) {
      this.revokingId = invite_id;
      await revokeInvite(invite_id);
      await this.fetchPending();
      this.revokingId = null;
    },

    confirmRevoke(pendingId) {
      this.$q
        .dialog({
          title: "Confirm Revoke",
          message:
            "Are you sure that you want to revoke this invitation? You can always re-invite the user later.",
          ok: { color: "negative", label: "Revoke" },
          cancel: { color: "grey", label: "Cancel" },
          persistent: true,
        })
        .onOk(async () => {
          try {
            await this.revokeInvitation(pendingId);
          } catch (error) {
            console.error("Error deleting file:", error);
          }
        });
    },
    async inviteUser() {
      this.sendingInvite = true;
      // API call to invite user goes here
      const response = await inviteAs(
        this.emailAddress,
        this.userType.value,
        this.appID,
        this.orgId,
      );
      // console.log("RESPONSE", response);
      // Reset email address after sending
      this.emailAddress = "";
      // Close dialog
      await this.fetchPending();
      this.dialogVisible = false;
      this.sendingInvite = false;
    },
    async removeFile(fileId) {
      this.loading = true;
      await deleteFile(fileId);
      await this.fetchUploads(this.appID);
      this.loading = false;
    },
    async onFileSelected(event, file_type) {
      if (file_type === "VENDOR_UPLOAD") {
        this.vendorUploading = true;
      } else {
        this.applicantUploading = true;
      }
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        this.vendorUploading = false;
        this.applicantUploading = false;
        // Early return if no file is selected
        return;
      }

      try {
        console.log("FILE TYPE", file_type);
        await saveFile(selectedFile, this.appID, file_type);
        await this.fetchUploads(this.appID);
        this.$q.notify({
          type: "positive",
          color: "accent",
          message: "File uploaded successfully",
        });
      } catch (error) {
        this.$q.notify({
          type: "negative",
          message: error.message,
        });
      } finally {
        // Ensure the input is cleared and uploading is reset in both success and failure cases
        event.target.value = null;
        this.vendorUploading = false;
        this.applicantUploading = false;
      }
    },

    goToGrantApp(appId) {
      this.$router.push({ name: "GrantApp", params: { id: appId } });
    },
    downloadDocument(id) {
      this.isLoading = true; // Show the spinner

      try {
        const url = `api/v1/get-pdf/?doc_draft_id=${id}`;

        axios
          .get(url, {
            responseType: "blob", // Important for handling the PDF file
          })
          .then((response) => {
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });

            // Create a Blob URL
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Create a temporary link element
            const downloadLink = document.createElement("a");
            downloadLink.href = pdfUrl;
            downloadLink.setAttribute("download", "document.pdf"); // Assign a file name for the download

            // Append to the document and trigger a click
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Remove the link after triggering download
            document.body.removeChild(downloadLink);

            this.loading = false; // Hide the spinner after processing
          })
          .catch((error) => {
            this.isLoading = false; // Hide the spinner in case of an error
            console.error(
              "Error fetching PDF:",
              error.response ? error.response.data : error.message
            );
          });
      } catch (error) {
        this.isLoading = false;
        console.error("Error in form submission or PDF generation:", error);
      }
    },
    addFileSlot() {
      this.requested_files.push("");
    },
    copyInviteLink() {
      // Get the base URL of the current page
      const baseUrl = window.location.origin;

      // Construct the invitation link
      const url = `${baseUrl}/register/${this.user.slug}`;

      // Create a temporary text area to use for copying
      const textArea = document.createElement("textarea");
      textArea.value = url;
      document.body.appendChild(textArea);

      // Select the text and copy it to the clipboard
      textArea.select();
      document.execCommand("copy");

      // Remove the temporary text area from the document
      document.body.removeChild(textArea);

      // Optional: Notify the user that the link has been copied
      // console.log("Link copied to clipboard:", url);
    },
    async handleDownload(fileId) {
      try {
        await downloadFile(fileId);
      } catch (error) {
        console.error("Error downloading file:", error);
        // Handle any errors, such as showing a notification to the user
      }
    },
    async updateRequestedFiles() {
      this.saving = true;
      const formData = new FormData();

      // Append each file name individually
      this.requested_files.forEach((filename) => {
        formData.append("filenames[]", filename);
      });

      formData.append("app_id", this.appID);

      // console.log("FORM DATA:", formData);

      await axios
        .post("/api/v1/update-requested-files/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.saving = false;
          this.requested_files = response.data.filenames;
        })
        .catch((error) => {
          this.saving = false;
          console.error(
            "Error updating requested files:",
            error.response ? error.response.data : error.message
          );
          // Handle errors here
        });
    },
    async updateShowForms(applicant) {
      let data = {
        user_email: applicant.email,
        show_forms: applicant.show_forms,
      };
      try {
        const url = "api/v1/update-show-forms/";
        const headers = {
          ...axios.defaults.headers.common,
          "Content-Type": "application/json",
        };
        axios
          .post(url, data, { headers })
          .then((response) => {
            applicant.show_forms = response.data.show_forms;
          })
          .catch((error) => {
            console.error(
              "Error updating user_info:",
              error.response ? error.response.data : error.message
            );
          });
      } catch (error) {
        console.error(`Error updating document ${this.docData.name}:`, error);
      }
    },
    truncateDisplayName(displayName) {
      const maxLength = 55;
      if (displayName.length > maxLength) {
        return displayName.substring(0, maxLength) + "...";
      }
      return displayName;
    },
  },
};
</script>

<style scoped>
section {
  margin-top: 30px;
}
.body {
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
  max-width: 1300px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items vertically */
  width: 100%;
}

.my-card {
  width: 180px;
  height: 200px;
}

.active-card:hover {
  box-shadow: 0 0 5px 2px var(--accent);
  cursor: pointer;
}

.score-card-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* Add other styles as needed, e.g., full width or specific width */
}

.checklist ul {
  list-style-type: none;
  padding: 0;
}

.checklist li {
  margin-bottom: 10px;
}

.item {
  /* Styles for your list item */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.trashIcon {
  margin-right: 10px; /* Space between the icon and text */
  color: var(--accent); /* Icon color */
  /* Other styles as needed */
}

.card {
  width: 150px;
  height: 195px; /* 1.3:1 ratio like letter paper (1.62 for golden ratio) */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separates progress bar and button */
  padding: 0px;
}

.card:hover {
  border-color: var(--accent);
  background-color: var(--accent);
  color: white;
  cursor: pointer;
  /* Ensure the text color is white on hover */
  .delete-button {
    color: white; /* Set the color of the delete button to white */
  }
}

.card-upload {
  color: var(--accent-semi);
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card-complete {
  color: var(--accent);
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-complete:hover {
  color: white;
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-partial {
  border-radius: 8px;
  border: dashed;
  border-color: var(--accent);
  color: var(--accent);
}

.card-incomplete {
  border-radius: 8px;
  border-color: var(--accent-semi);
  /* color: var(--accent-semi); */
  color: var(--accent);
}
.upload-input {
  display: none;
}

.upload-label {
  display: flex;
  cursor: pointer;
  flex-shrink: 0;
}

.delete-button {
  color: var(--accent);
  border: 2px;
  border-radius: 2px;
  border: solid;
  background: none;
  border-color: var(--accent-semi);
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
}
.completion-bar {
  width: 70%; /* Adjust as needed */
  height: 20px; /* Height of the progress bar */
  background-color: #ddd; /* Background of the progress bar */
  border-radius: 10px; /* Optional: for rounded corners */
  overflow: hidden; /* Ensures the inner bar stays within the radius */
}

.completion-bar-inner {
  height: 100%;
  background-color: rgb(1, 150, 250); /* Color of the filled area */
  border-radius: 10px; /* Match the radius of the outer bar */
  /* Width is set dynamically in your Vue template */
}
.divider {
  border: none; /* Remove the default border */
  height: 1px; /* Set the height of the divider */
  background-color: rgba(0, 0, 0, 0.7); /* Light grey color */
  margin: 10px 0; /* Add some vertical spacing */
}

.proposal-text {
  /* background-color: rgba(0, 0, 0, 0.02); */
  border-radius: 8px;
  padding: 20px;

  cursor: pointer;
  transition: background-color 0.1s;
}
.proposal-text:hover {
  /* background-color: rgba(0, 0, 0, 0.03); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Full viewport height minus any headers or footers if they exist */
  height: 100vh;
  /* Or if it should be the full height of a parent container, you could use 100% instead */
  /* height: 100%; */
  width: 100%;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 125px;
  height: 125px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 100%;
}

.card-spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
}

.card {
  width: 150px;
  height: 195px; /* 1.3:1 ratio like letter paper (1.62 for golden ratio) */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  transition: background-color 0.1s;
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  transition: background-color 0.1s;
  overflow: hidden; /* Ensures that the folded corner does not spill out of the card's box */
}
.card::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Width of the folded corner */
  height: 10px; /* Height of the folded corner */
  background-color: rgba(0, 0, 0, 0.2); /* Same as the card's background */
  transform: translate(4px, -4px) rotate(45deg); /* Adjust the position and angle */
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.3); /* Optional: add shadow to the folded corner */
}

.card:hover {
  border-color: var(--accent);
  background-color: var(--accent);
  color: white;
  cursor: pointer;
  /* Ensure the text color is white on hover */
  .delete-button {
    color: white; /* Set the color of the delete button to white */
  }
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separates progress bar and button */
  padding: 0px;
}
.card-complete {
  color: var(--accent);
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-incomplete {
  border-radius: 8px;
  border-color: var(--accent-semi);
  /* color: var(--accent-semi); */
  color: var(--accent);
}

.completion-bar-inner {
  height: 100%;
  background-color: rgb(1, 150, 250); /* Color of the filled area */
  border-radius: 10px; /* Match the radius of the outer bar */
  /* Width is set dynamically in your Vue template */
}
.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separates progress bar and button */
  padding: 0px;
}
</style>
