import { createStore } from "vuex";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { get, set } from "@vueuse/core";

const store = createStore({
  state: {
    accessToken: localStorage.getItem("accessToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    tokenExpiration: null, // Add this to store the expiration time
    status: "", // to handle the status of the login process
    isPaidUser: false, // New state property to store the payment status
    // isCoordinator: false,
    appID: localStorage.getItem("appID") || null,
    docID: localStorage.getItem("docID") || null,
    sectionID: localStorage.getItem("sectionID") || null,
    search: "",
    searchQuery: "",
    searchType: "default",
    bills: [],
    highlightedStates: [],
    states: [],
    stateBillId: null,
    currStateBill: null,
  },
  mutations: {
    setAppID(state, appID) {
      state.appID = appID;
      localStorage.setItem("appID", appID);
    },
    setDocID(state, docID) {
      state.docID = docID;
      localStorage.setItem("docID", docID);
    },
    setSectionID(state, sectionID) {
      state.sectionID = sectionID;
      localStorage.setItem("sectionID", sectionID);
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { accessToken, refreshToken }) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

      //console.log('SETTING ACCESS TOKEN', accessToken);
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;

      const decoded = jwtDecode(accessToken);
      state.tokenExpiration = decoded.exp * 1000;
      //console.log("STATE TOKEN EXPIRIATION", state.tokenExpiration);
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      //console.log('LOGGING OUT');
      state.status = "";
      state.accessToken = "";
      state.refreshToken = "";
      state.user = null;
      state.tokenExpiration = null;
      state.isPaidUser = false; // Consider resetting this as well
      state.appID = null;
      state.docID = null;
      state.sectionID = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("appID");
      localStorage.removeItem("docID");
      localStorage.removeItem("sectionID");
      delete axios.defaults.headers.common["Authorization"];
    },
    set_paid_status(state, isPaid) {
      state.isPaidUser = isPaid;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setSearchQuery(state, searchQuery) {
      state.searchQuery = searchQuery;
    },
    setSearchType(state, searchType) {
      state.searchType = searchType;
    },
    setBills(state, bills) {
      state.bills = bills;
    },
    setHighlightedStates(state, highlightedStates) {
      state.highlightedStates = highlightedStates;
    },
    setSearchQuery(state, query) {
      state.searchQuery = query;
    },
    setSearchResults(state, results) {
      state.searchResults = results;
    },
    setHighlightedStates(state, states) {
      state.highlightedStates = states;
    },
    setStates(state, states) {
      state.states = states;
    },
    setStateBillId(state, id) {
      state.stateBillId = id;
    },
    setCurrStateBill(state, bill) {
      state.currStateBill = bill;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post("/api/v1/sign-in/", user)
          .then((resp) => {
            const accessToken = resp.data.access; // Access token
            const refreshToken = resp.data.refresh; // Refresh token

            try {
              commit("auth_success", { accessToken, refreshToken });
              resolve(resp); // Resolve the promise here after successful commit
            } catch (error) {
              console.error("Error decoding token:", error);
              commit("auth_error");
              reject(error); // Reject the promise in case of an error
            }
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            delete axios.defaults.headers.common["Authorization"];
            reject(err); // Reject the promise in case of an error
          });
      });
    },
    logout({ commit }) {
      commit("logout");
    },
    async checkToken({ state, commit }) {
      // If there's no access token, log out immediately.
      if (!state.accessToken) {
        commit("logout"); // Logout on validation failure
        return false; // Indicate that token validation failed or token was missing.
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${state.accessToken}`;
        try {
          await axios.post("/api/v1/validate-token/");
          return true; // Indicate that token is valid.
        } catch (error) {
          console.error("Token validation failed:", error);
          commit("logout"); // Logout on validation failure
          return false; // Indicate that token validation failed.
        }
      }
    },
    checkPaymentStatus({ commit, state }) {
      if (state.accessToken) {
        return new Promise((resolve, reject) => {
          axios
            .get("/api/v1/check-paid/", {
              headers: { Authorization: `Bearer ${state.accessToken}` },
            })
            .then((resp) => {
              // Assuming your API returns a boolean `is_paid` field
              commit("set_paid_status", resp.data.is_paid);
              resolve(resp.data.is_paid);
            })
            .catch((err) => {
              console.error("Error checking payment status:", err);
              // Check if the error is due to an unauthorized or forbidden access
              if (
                err.response &&
                (err.response.status === 401 || err.response.status === 403)
              ) {
                commit("set_paid_status", false);
                resolve(false);
              } else {
                // For other types of errors, you might want to reject
                reject(err);
              }
            });
        });
      } else {
        // No access token, user is not logged in
        commit("set_paid_status", false);
        return Promise.resolve(false);
      }
    },
    updateSearchQuery({ commit }, query) {
      commit("setSearchQuery", query);
    },
    updateSearchResults({ commit }, results) {
      commit("setSearchResults", results);
    },
    updateHighlightedStates({ commit }, states) {
      commit("setHighlightedStates", states);
    },
    updateStates({ commit }, states) {
      commit("setStates", states);
    },
    updateStateBillId({ commit }, id) {
      commit("setStateBillId", id);
    },
    updateCurrStateBill({ commit }, bill) {
      commit("setCurrStateBill", bill);
    },
  },
  getters: {
    getAppID: (state) => state.appID,
    getDocID: (state) => state.docID,
    getSectionID: (state) => state.sectionID,
    isLoggedIn: (state) => !!state.accessToken,
    authStatus: (state) => state.status,
    isPaidUser: (state) => state.isPaidUser,
    isTokenExpired: (state) => {
      const now = Date.now();
      return state.tokenExpiration && now >= state.tokenExpiration;
    },
    getSearchQuery(state) {
      return state.searchQuery;
    },
    getSearchResults(state) {
      return state.searchResults || []; // Default to an empty array if undefined
    },
    getHighlightedStates(state) {
      return state.highlightedStates || [];
    },
    getStates(state) {
      return state.states || [];
    },
    getStateBillId(state) {
      return state.stateBillId;
    },
    getCurrStateBill(state) {
      return state.currStateBill;
    },
  },
});

export default store;
