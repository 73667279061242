<template>
  <div class="body">
    <div v-if="!loading">
      <div v-if="user_data && user_data.role === 'applicant'" class="header">
        <div class="top-bar">
          <h4 class="text-weight-regular">
            <span v-if="user_data && user_data.first_name"
              >Hi {{ user_data.first_name }},</span
            >
            <span v-else>Hi there,</span>
            welcome to your REAP application portal
          </h4>
        </div>
        <q-separator color="grey-7" />
      </div>

      <section v-if="user_data">
        <h4 class="text-weight-bold">
          REAP Application for {{ user_data.org_name }}
        </h4>
      </section>
      <section>
        <h5 class="text-weight-bold">Onboarding</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          Complete this questionnaire to provide necessary information for the project.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-for="document in onboarding_documents" :key="document.id">
            <q-card
              clickable
              class="my-card active-card bg-white text-primary column justify-between no-wrap cursor-pointer"
              @click.stop="
                this.$router.push({
                  name: 'Document',
                  params: { id: document.id },
                })
              "
            >
              <q-card-section class="row">
                <div class="text-body1 text-weight-medium">
                  {{ truncateDisplayName(document.display_name) }}
                </div>
              </q-card-section>
              <q-card-actions class="row">
                <q-icon
                  v-if="document.complete"
                  name="check"
                  color="primary"
                  size="32px"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </section>
      <section>
        <h5 class="text-weight-bold">Applicant Documents</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          The following information will be needed to complete the application. 
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-for="document in assigned_documents" :key="document.id">
            <q-card
              clickable
              class="my-card active-card bg-white text-primary column justify-between no-wrap cursor-pointer"
              @click.stop="
                this.$router.push({
                  name: 'Document',
                  params: { id: document.id },
                })
              "
            >
              <q-card-section class="row">
                <div class="text-body1 text-weight-medium">
                  {{ truncateDisplayName(document.display_name) }}
                </div>
              </q-card-section>
              <q-card-actions class="row">
                <q-icon
                  v-if="document.complete"
                  name="check"
                  color="primary"
                  size="32px"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Applicant Attachments</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          If you have other documents to attach to the application, add them
          here.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-if="uploads" v-for="upload in uploads">
            <q-card
              class="my-card bg-white text-primary column justify-between no-wrap"
              @click.stop="openPdfPreview(upload.id)"
              style="cursor: pointer"
            >
              <q-card-section class="row">
                <div
                  class="text-body1 text-weight-medium ellipsis"
                  style="word-wrap: break-word"
                >
                  {{ upload.name }}
                </div>
              </q-card-section>

              <!-- <q-separator primary /> -->

              <q-card-actions class="row justify-between">
                <q-btn flat @click.stop="handleDownload(upload.id)">
                  <q-icon name="download" color="primary" size="32px" />
                </q-btn>
                <q-btn flat @click.stop="removeFile(upload.id)">
                  <q-icon name="delete_forever" color="primary" size="32px" />
                </q-btn>
              </q-card-actions>
            </q-card>
          </div>
          <div>
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              style="opacity: 0.7"
              @click="triggerFileInput"
            >
              <q-card-section v-if="!uploading" class="row">
                <div
                  class="text-body1 text-weight-medium"
                  style="overflow: hidden"
                >
                  Upload New Document
                </div>
              </q-card-section>
              <div v-else class="flex flex-center full-height">
                <q-spinner color="primary" :size="50"></q-spinner>
              </div>
            </q-card>
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              @change="onFileSelected"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else class="flex flex-center" style="height: 100%">
      <q-spinner color="primary" :size="80"></q-spinner>
    </div>
  </div>
</template>


<script>
import { ref, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { onMounted } from "vue";
import { fetchUser } from "@/api/userApi";
import {
  fetchFiles,
  fetchForms,
  deleteFile,
  saveFile,
  downloadFile,
} from "@/api/fileApi";

export default {
  name: "Portal",

  setup() {
    const loading = ref(false); // Initialize the loading state
    const showForms = ref(false);
    const route = useRoute();

    const fileInput = ref(null); // Define a ref to hold the DOM element

    const triggerFileInput = () => {
      nextTick(() => {
        if (fileInput.value) {
          fileInput.value.click();
        }
      });
    };

    const uploading = ref(false); // Initialize the uploading state
    const uploads = ref(null); // Initialize the uploads state
    // file, app_id, file_class, file_id = null
    const onFileSelected = async (event, id) => {
      const MAX_SIZE = 26214400;
      const selectedFile = event.target.files[0];
      uploading.value = true; // Set uploading to true when the upload starts
      if (selectedFile && selectedFile.size > MAX_SIZE) {
        this.$q.notify({
          type: "negative",
          message: `$File(s) were too large. Size limit is 25MB`,
        });
        // Reset the input value to allow the same file to be selected again if needed
        event.target.value = "";
        this.uploading = false;
      } else {
        await saveFile(selectedFile, app_id.value, "APPLICANT_UPLOAD");
        uploads.value = await fetchFiles(app_id.value, "APPLICANT_UPLOAD");
        uploading.value = false;
      }
    };

    // Get user info (especially App ID)
    const user_data = ref(null);

    const onboarding_documents = ref(null);
    const assigned_documents = ref(null);

    const fetchPortalInfo = async (appId) => {
      try {
        // Assuming `route` is already defined elsewhere in your setup function
        const response = await axios.get("api/v1/get-portal-info/", {});
        // console.log("RESPONSE:", response);
        onboarding_documents.value = response.data.onboarding_documents;
        assigned_documents.value = response.data.assigned_documents;
        app_id.value = response.data.app_id;
      } catch (error) {
        console.error("Error fetching portal info:", error);
      }
    };

    const removeFile = async (fileId) => {
      loading.value = true;
      await deleteFile(fileId);
      uploads.value = await fetchFiles(app_id.value, "APPLICANT_UPLOAD");
      loading.value = false;
    };

    const handleDownload = async (fileId) => {
      try {
        await downloadFile(fileId);
      } catch (error) {
        console.error("Error downloading file:", error);
        // Handle any errors, such as showing a notification to the user
      }
    };

    const truncateDisplayName = (displayName) => {
      const maxLength = 55;
      if (displayName.length > maxLength) {
        return displayName.substring(0, maxLength) + "...";
      }
      return displayName;
    };

    const upload_docs = ref([]);
    const documents = ref([]);

    const app_id = ref(null);
    onMounted(async () => {
      loading.value = true;
      try {
        app_id.value = route.params.id ? route.params.id : null;
        user_data.value = await fetchUser();
        if (user_data.value.apps.length === 1) {
          app_id.value = user_data.value.apps[0];
        }
        uploads.value = await fetchFiles(app_id.value, "APPLICANT_UPLOAD");

        await fetchPortalInfo(app_id.value);
        // Get forms if supposed to show forms
        if (showForms.value) {
          let allDocs = await fetchForms(app_id.value);
          for (let doc of allDocs) {
            // Use `of` to iterate over array elements
            if (doc.upload_doc) {
              upload_docs.value.push(doc); // Use `.value` to access the reactive array
            } else {
              documents.value.push(doc); // Same here
            }
          }
        }
      } catch (error) {
        console.error("Error in mounted hook:", error);
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
      uploading,
      uploads,
      fileInput,
      triggerFileInput,
      onFileSelected,
      removeFile,
      documents,
      upload_docs,
      handleDownload,
      truncateDisplayName,
      showForms,
      user_data,
      onboarding_documents,
      assigned_documents,
    };
  },
};
</script>

<style scoped>
section {
  margin-top: 30px;
  margin-bottom: 0px;
}
.body {
  padding: 40px;
  padding-top: 20px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
  max-width: 1300px;
}

.my-card {
  width: 180px;
  height: 200px;
}

.active-card:hover {
  box-shadow: 0 0 5px 2px var(--accent);
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items vertically */
  width: 100%;
}

.top-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 20px;
}

.my-card {
  width: 180px;
  height: 200px;
}

.active-card:hover {
  box-shadow: 0 0 5px 2px var(--accent);
  cursor: pointer;
}
</style>