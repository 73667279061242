<template>
  <div class="form-field-sharpbase">
    <div class="label-and-spinner">
      <!-- Spinner -->
      <span class="spinner" v-if="isLoading"></span>
      <div v-html="renderMarkdownWrapper(question.question_text)"></div>
    </div>
    <div style="display: flex; flex-direction: column">
      <q-radio
        v-for="input in question.inputs"
        :key="input.id"
        v-model="computedInputName"
        color="primary"
        keep-color
        :val="input.name"
        :label="input.label_text"
        :name="`question-${question.id}`"
        :id="`choice-${input.id}`"
        @click="setInputValue(input.name)"
      />
    </div>
    <div v-if="predictFailed" class="predict-failed-text">
      We couldn't find an answer to this question in your docs; please answer it
      manually
    </div>
    <div v-if="requiredFlag && question.required" class="required-flag-text">
      This question is required
    </div>
    <!-- <button @click.prevent="predict">Predict Q</button> -->
  </div>
</template>

<script>
import axios from "axios";
import { renderMarkdown } from "@/helpers/helpers";
export default {
  name: "MultipleChoice",
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      required: false,
      default: () => ({}), // Default to an empty object
    },
    draft_doc_id: {},
  },
  data() {
    return {
      isLoading: false,
      predictFailed: false,
      requiredFlag: false,
    };
  },
  computed: {
    computedInputName() {
      // Find the input whose name corresponds to a true value in this.answers
      const selectedInput = this.question.inputs.find(
        (input) => this.answers[input.name] === true
      );

      // Return the name of the found input, or null if no such input is found
      return selectedInput ? selectedInput.name : null;
    },
  },
  methods: {
    renderMarkdownWrapper(text) {
      return renderMarkdown(text);
    },
    setInputValue(inputName) {
      const inputStates = {};

      // Iterate through all inputs
      this.question.inputs.forEach((input) => {
        // Set the state of each input: true for the selected one, false for others
        inputStates[input.name] = input.name === inputName;
      });
      // Emit an update event with the input states
      this.$emit("answerUpdate", this.question, inputStates);
    },
    togglePredictFailed() {
      this.predictFailed = !this.predictFailed;
    },
    toggleRequiredFlag(value) {
      if (value === undefined) {
        // If no value is provided, toggle the flag
        this.requiredFlag = !this.requiredFlag;
      } else {
        // If a value is provided, set the flag to that value
        this.requiredFlag = value;
      }
    },
    answered() {
      return this.computedInputName;
    },
    async predict() {
      if (!this.answered()) {
        this.isLoading = true;
        try {
          // Construct the URL with query parameters
          // console.log("PREDICTING QUESTION ID:",question.id);
          const url = `api/v1/predict-question/?q_id=${this.question.id}&doc_id=${this.draft_doc_id}`;

          await axios
            .get(url)
            .then((response) => {
              const answers = response.data;
              // console.log(
              //   `Question ${this.question.id} predicted successfully:`,
              //   answers
              // );
              this.isLoading = false;
              if (Object.keys(answers).length === 0) {
                this.togglePredictFailed();
              } else {
                Object.entries(answers).forEach(([inputName, value]) => {
                  this.setInputValue(inputName, value);
                });
              }
              // this.populateQuestionInputs(this.question.id, response.data); // TODO update
            })
            .catch((error) => {
              console.error(
                `Error predicting question ${this.question.id}:`,
                error.response ? error.response.data : error.message
              );
            });
        } catch (error) {
          console.error(
            `Error in predictSection for question ${this.uestion.id}:`,
            error
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.question-text {
  font-family: var(--title-2);
  font-size: 1rem;
  color: var(--text);
  margin-bottom: 0.5rem;
  text-align: left;
  max-width: 800px;
}

.label-and-spinner {
  display: flex;
  align-items: center; /* Aligns label and spinner vertically */
  position: relative;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid rgb(1, 150, 250); /* Blue */
  border-right: 4px solid rgb(1, 150, 250); /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
  margin-left: 10px; /* Adjust as needed */
  position: absolute; /* Position the spinner absolutely within its container */
  left: -40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.label-text {
  font-family: var(--title-2);
  font-size: 0.9rem;
  color: var(--text);
  margin-bottom: 0.25rem;
  text-align: left;
  max-width: 800px;
}

.form-group {
  border: 2px solid var(--accent); /* Set the border color for the fieldset */
  padding: 10px; /* Add padding around the content */
  margin-bottom: 1rem; /* Space between each form group if stacked */
}

.form-group legend {
  color: var(--text); /* Set the color of the legend text */
  padding: 0 10px; /* Add some padding on the sides of the legend text */
  margin-bottom: 0.5rem; /* Space below the legend */
  width: auto; /* Make sure the width is only as wide as the content */
}
.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem; /* Space between each radio option */
}

.radio-custom {
  display: inline-block;
  width: 20px; /* Width of the custom radio button */
  height: 20px; /* Height of the custom radio button */
  margin-right: 10px; /* Space between the radio button and the label */
  border: 2px solid var(--accent); /* Border color of the radio button */
  border-radius: 50%; /* Rounded corners of the radio button (circle) */
  cursor: pointer;
  position: relative;
}

/* Style for when the radio is checked */
.radio-input:checked + .radio-custom {
  background-color: var(--accent); /* Background color when checked */
  border-color: var(--accent); /* Border color when 1 */
}

/* Style for the indicator inside the radio button */
.radio-input:checked + .radio-custom::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px; /* Smaller than the custom radio button to create a dot */
  height: 10px; /* Smaller than the custom radio button to create a dot */
  background-color: var(--accent); /* Color of the dot */
  border-radius: 50%; /* Circle shape */
}

.radio-option {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem; /* Space between each radio option */
  cursor: pointer;
}

.radio-input {
  opacity: 0;
  position: absolute;
}

.predict-failed-text {
  font-family: var(--title-2);
  color: var(--accent);
  font-size: 12px;
}

.required-flag-text {
  font-family: var(--title-2);
  color: var(--q-negative);
  font-size: 12px;
}
</style>
