<template>
  <div class="body">
    <div v-if="!loading">
      <section>
        <h5 class="text-weight-bold">Collect and Upload</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          This is information that will need to be collected and should be done
          early in the application process. 
        </div>

        <div v-if="uploads" class="q-pa-md row items-start q-gutter-md">
          <div v-for="upload in uploads">
            <q-card
              clickable
              class="my-card active-card bg-white text-primary column justify-between no-wrap cursor-pointer"
              @click.stop="
                this.$router.push({
                  name: 'Document',
                  params: { id: upload.id },
                })
              "
            >
              <q-card-section class="row">
                <div class="text-body1 text-weight-medium">
                  {{ truncateDisplayName(upload.display_name) }}
                </div>
              </q-card-section>

              <q-card-actions class="row">
                <q-icon
                  v-if="upload.complete"
                  name="check"
                  color="primary"
                  size="32px"
                />
              </q-card-actions>
            </q-card>
          </div>
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Forms To Complete</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          The following forms will need to be completed for your application.
        </div>
        <div v-if="documents" class="q-pa-md row items-start q-gutter-md">
          <div v-for="doc in documents">
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap cursor-pointer"
              @click.stop="
                this.$router.push({ name: 'Document', params: { id: doc.id } })
              "
            >
              <q-card-section class="row">
                <div class="text-body1 text-weight-medium">
                  {{ truncateDisplayName(doc.display_name) }}
                </div>
                <!-- <div class="text-subtitle2">by John Doe</div> -->
              </q-card-section>

              <!-- <q-separator primary /> -->

              <q-card-actions class="row">
                <q-btn flat @click.stop="confirmDownload(doc.id)">
                  <q-icon name="download" color="primary" size="32px" />
                </q-btn>
                <q-icon
                  v-if="doc.complete"
                  name="check"
                  color="primary"
                  size="32px"
                />
                <!-- <q-btn
                    v-if="doc.display_name === 'Environmental Checklist'"
                    flat
                    @click.stop="confirmEnvChecklist"
                  >
                    <template v-if="isLoading">
                      <q-spinner color="primary" size="32px" />
                    </template>
                    <template v-else>
                      <q-icon name="play_arrow" size="32px" color="primary"/>
                    </template>
                  </q-btn>  -->
              </q-card-actions>
            </q-card>

              
          </div>
          <q-card
                class="my-card active-card bg-white text-primary column justify-between no-wrap cursor-pointer"
                @click.stop="this.$router.push({ name: 'TechnicalReport', params: { id: appID } })"
              >
            <q-card-section class="row">
              
                <div class="text-body1 text-weight-medium">
                  Technical Report
                </div>
              </q-card-section>
            </q-card>
        </div>
      </section>

      <section>
        <h5 class="text-weight-bold">Supplemental Attachments</h5>
        <div class="text-body1 text-blue-grey-9 text-weight-medium">
          If you have other documents to attach to the application, add them
          here. You may consider attaching diagrams, drawings, and photos that
          may be useful for the reviewer. Everything included here will be
          included in the final application.
        </div>
        <div class="q-pa-md row items-start q-gutter-md">
          <div v-if="attachments" v-for="attachment in attachments">
            <q-card
              class="my-card bg-white text-primary column justify-between no-wrap"
              @click.stop="openPdfPreview(attachment.id)"
              style="cursor: pointer"
            >
              <q-card-section class="row">
                <div
                  class="text-body1 text-weight-medium ellipsis"
                  style="word-wrap: break-word"
                >
                  {{ attachment.name }}
                </div>
              </q-card-section>

              <!-- <q-separator primary /> -->

              <q-card-actions class="row justify-between">
                <q-btn flat @click.stop="handleDownload(attachment.id)">
                  <q-icon name="download" color="primary" size="32px" />
                </q-btn>
                <q-btn flat @click.stop="deleteAttachment(attachment.id)">
                  <q-icon name="delete_forever" color="primary" size="32px" />
                </q-btn>
              </q-card-actions>
            </q-card>
          </div>
          <div>
            <q-card
              class="my-card active-card bg-white text-primary column justify-between no-wrap"
              style="opacity: 0.7"
              @click="triggerFileInput"
            >
              <q-card-section v-if="!uploading" class="row">
                <div
                  class="text-body1 text-weight-medium"
                  style="overflow: hidden"
                >
                  Upload New Document
                </div>
              </q-card-section>
              <div v-else class="flex flex-center full-height">
                <q-spinner color="primary" :size="50"></q-spinner>
              </div>
            </q-card>
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              @change="onFileSelected"
            />
          </div>
        </div>
      </section>
    </div>
    <div v-else class="flex flex-center q-pt-lg">
      <q-spinner color="primary" :size="80"></q-spinner>
    </div>

    <q-dialog v-model="showPdfPreviewModal" class="full-width">
      <q-card class="full-width">
        <q-card-section class="row items-center">
          <div
            v-if="iframeLoading"
            class="flex flex-center"
            style="width: 100%; height: 700px"
          >
            <q-spinner color="primary" size="80px" />
          </div>
          <iframe
            v-else
            ref="pdfPreviewIframe"
            width="100%"
            height="700px"
          ></iframe>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            label="Close"
            color="primary"
            @click="showPdfPreviewModal = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showDialog" persistent>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        "
      >
        <div
          class="q-pa-md"
          style="
            background: white;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px;
          "
        >
          <q-spinner color="primary" size="50px"></q-spinner>
          <div style="margin-top: 20px; font-weight: bold">
            Automating environmental checklist...
          </div>
        </div>
      </div>
    </q-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { downloadFile, saveFile, getPdfPreviewUrl } from "@/api/fileApi";
import { checkTaskStatus } from '@/api/polling';

export default {
  name: "Application",
  data() {
    return {
      uploads: null,
      documents: null,
      attachments: null,
      loading: false,
      uploading: false,
      iframeLoading: false,
      appID: null,
      status: null,
      showPdfPreviewModal: false,
      isLoading: false,
      showDialog: false,
    };
  },
  props: {
    inputAppId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    statusClass() {
      return (status) => {
        switch (status) {
          case "complete":
            return "card-complete";
          case "partial":
            return "card-incomplete";
          case "incomplete":
          default:
            return "card-incomplete";
        }
      };
    },
  },
  mounted: async function () {
    this.loading = true; // Start loading
    this.appID = this.inputAppId;

    try {
      // Assuming fetchDocuments and fetchAttachments are async functions or return a Promise
      await this.fetchDocuments();
      await this.fetchAttachments();
    } catch (error) {
      console.error("Error in mounted hook:", error);
      // Handle any errors that occur during fetch
    } finally {
      this.loading = false; // Stop loading after both functions have completed
    }
  },
  methods: {
    async confirmEnvChecklist() {
      this.$q
        .dialog({
          title: "Confirm",
          message:
            "Do you want to run the environmental checklist scripts? This will grab relevant reports and put them into the document. It may take up to five minutes.",
          cancel: true,
          persistent: true,
        })
        .onOk(() => {
          this.showDialog = true;

          this.triggerEnvChecklist()
            .then(() => {
              this.showDialog = false;
            })
            .catch((error) => {
              console.error("An error occurred:", error);
              this.showDialog = false;
            });
        });
    },
    async triggerEnvChecklist() {
      try {
        // Start the long-running task and get its task ID
        const response = await axios.get(`api/v1/run-env-checklist`, {
          params: { appID: this.appID },
        });
        const taskId = response.data.task_id;
        console.log("Task ID:", taskId);

        // Define your callbacks
        const onSuccess = (result) => {
          console.log("Task completed successfully:", result);
          // Handle task completion success here, e.g., update UI or show a message
        };

        const onFailure = (error) => {
          console.error("Task failed:", error);
          // Handle task failure here, e.g., show an error message
        };

        const onPending = () => {
          console.log("Task is still pending...");
          // Optionally handle the pending state, e.g., update UI to show loading status
        };

        // Start polling the task status
        checkProcessStatus(taskId, onSuccess, onFailure, onPending);
      } catch (error) {
        console.error("Error initiating the environmental checklist:", error);
        // Handle errors in initiating the task, e.g., show an error message
      }
    },
    async openPdfPreview(fileId) {
      this.showPdfPreviewModal = true;
      this.iframeLoading = true;
      try {
        const url = await getPdfPreviewUrl(fileId);
        this.iframeLoading = false; // IMPORTANT: set to false BEFORE the url is set
        // console.log("REFS", this.$refs);
        // console.log("URL", url);
        this.$nextTick(() => {
          if (this.$refs.pdfPreviewIframe) {
            this.$refs.pdfPreviewIframe.src = url;
          }
        });
      } catch (error) {
        // Handle the error here, e.g., by showing an error message
        console.error("Failed to load PDF preview:", error);
        this.$q.notify({
          color: "negative",
          position: "top",
          message: "This file type cannot be previewed",
          icon: "report_problem",
        });
        this.showPdfPreviewModal = false;
        this.iframeLoading = false;
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async confirmDownload(docId) {
      const doc = this.documents.find((document) => document.id === docId);
      // If the document is incomplete, show a confirmation dialog
      if (doc.complete) {
        this.$q
          .dialog({
            title: "Confirm Download",
            message:
              "This document is not yet complete. Are you sure you want to download it?",
            ok: { color: "primary", label: "Download" },
            cancel: { color: "grey", label: "Cancel" },
            persistent: true,
          })
          .onOk(async () => {
            this.downloadDocument(docId);
          });
      } else {
        //otw just download
        this.downloadDocument(docId);
      }
    },
    async fetchAttachments() {
      try {
        // console.log("Fetching files");
        const file_response = await axios.get(`api/v1/get-files`, {
          params: {
            app_id: this.appID, // Pass the docClass value as a query parameter
            file_class: "SUPPLEMENTARY",
          },
        });
        // console.log(file_response.data);
        this.attachments = file_response.data.map((file) => ({
          id: file.id, // Use the actual ID property from your objects
          name: file.name, // Use the actual title property from your objects
          type: file.type,
        }));
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    async onFileSelected(event) {
      this.uploading = true;
      const selectedFile = event.target.files[0];
      if (!selectedFile) {
        this.uploading = false;
        // Early return if no file is selected
        return;
      }

      try {
        await saveFile(selectedFile, this.appID, "SUPPLEMENTARY");
        this.$q.notify({
          type: "accent",
          message: "File uploaded successfully",
        });
        await this.fetchAttachments();
        this.uploading = false;
      } catch (error) {
        this.$q.notify({
          type: "negative",
          message: error.message,
        });
      } finally {
        // Ensure the input is cleared and uploading is reset in both success and failure cases
        event.target.value = null;
        this.uploading = false;
      }
    },
    async deleteAttachment(fileId) {
      this.loading = true;
      // console.log(fileId);
      const response = await axios.post(`api/v1/delete-file/`, {
        params: {
          file_id: fileId,
        },
      });
      await this.fetchAttachments();
      this.loading = false;
    },
    async fetchDocuments() {
      try {
        const doc_response = await axios.get(`api/v1/get-app`, {
          params: {
            id: this.appID,
          },
        });
        if ("redirect" in doc_response.data) {
          // console.log("REDIRECTING");
          this.$router.push({
            name: "Document",
            params: { id: doc_response.data.redirect },
          });
        } else {
          this.appID = doc_response.data.id;
          this.status = doc_response.data.status;
          this.documents = doc_response.data.documents
            .filter((doc) => doc.assigned_to && doc.assigned_to.coordinator)
            .map((doc) => ({
              id: doc.doc_id, // Use the actual ID property from your objects
              display_name: doc.display_name, // Use the actual title property from your objects
              complete: doc.complete,
            }));

          this.uploads = doc_response.data.upload_docs
            .filter((doc) => doc.assigned_to && doc.assigned_to.coordinator)
            .map((doc) => ({
              id: doc.doc_id, // Use the actual ID property from your objects
              display_name: doc.display_name, // Use the actual title property from your objects
              complete: doc.complete,
            }));
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    async handleDownload(fileId) {
      try {
        await downloadFile(fileId);
      } catch (error) {
        console.error("Error downloading file:", error);
        // Handle any errors, such as showing a notification to the user
      }
    },
    downloadDocument(id) {
      this.isLoading = true; // Show the spinner

      const url = `api/v1/get-pdf/?doc_draft_id=${id}`;

      axios
        .get(url, {
          responseType: "blob", // Important for handling binary files like PDF and XLSX
        })
        .then((response) => {
          const contentDisposition = response.headers["content-disposition"];
          let filename = "downloaded_document"; // Default filename without extension

          if (contentDisposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, ""); // Removes quotes around the filename if present
            }
          }

          // Determine the content type from the response and set the default file extension
          const contentType = response.headers["content-type"];
          let fileExtension = "pdf"; // Default file extension
          if (
            contentType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            fileExtension = "xlsx";
          } else if (contentType === "application/pdf") {
            fileExtension = "pdf";
          }

          // Ensure filename has the correct extension based on the MIME type
          if (!filename.endsWith(`.${fileExtension}`)) {
            filename = `${filename}.${fileExtension}`;
          }

          // Create a Blob object with the correct MIME type
          const blob = new Blob([response.data], { type: contentType });

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);

          // Create a temporary link element
          const downloadLink = document.createElement("a");
          downloadLink.href = blobUrl;
          downloadLink.setAttribute("download", filename); // Use the extracted filename

          // Append to the document and trigger a click
          document.body.appendChild(downloadLink);
          downloadLink.click();

          // Remove the link after triggering download
          document.body.removeChild(downloadLink);

          this.isLoading = false; // Hide the spinner after processing
        })
        .catch((error) => {
          this.isLoading = false; // Hide the spinner in case of an error
          console.error(
            "Error fetching the document:",
            error.response ? error.response.data : error.message
          );
        });
    },
    truncateDisplayName(displayName) {
      const maxLength = 55;
      if (displayName.length > maxLength) {
        return displayName.substring(0, maxLength) + "...";
      }
      return displayName;
    },
  },
};
</script>

<style scoped>
section {
  margin-top: 30px;
}
.body {
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
}

.item {
  /* Styles for your list item */
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.my-card {
  width: 180px;
  height: 200px;
}

.active-card:hover {
  box-shadow: 0 0 5px 2px var(--accent);
  cursor: pointer;
}

.card {
  width: 150px;
  height: 195px; /* 1.3:1 ratio like letter paper (1.62 for golden ratio) */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  transition: background-color 0.1s;
  position: relative; /* Needed for absolute positioning of pseudo-elements */
  overflow: hidden; /* Ensures that the folded corner does not spill out of the card's box */
  color: var(--accent);
}
.card::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Width of the folded corner */
  height: 10px; /* Height of the folded corner */
  background-color: rgba(0, 0, 0, 0.2); /* Same as the card's background */
  transform: translate(4px, -4px) rotate(45deg); /* Adjust the position and angle */
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.3); /* Optional: add shadow to the folded corner */
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separates progress bar and button */
  padding: 0px;
}

.card-upload {
  color: var(--accent-semi);
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-complete {
  color: var(--accent);
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card-complete:hover {
  color: white;
  /* box-shadow: 0 4px 8px var(--accent); */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.upload-input {
  display: none;
}

.completion-bar {
  width: 80%; /* Adjust as needed */
  height: 20px; /* Height of the progress bar */
  background-color: #ddd; /* Background of the progress bar */
  border-radius: 10px; /* Optional: for rounded corners */
  overflow: hidden; /* Ensures the inner bar stays within the radius */
}

.completion-bar-inner {
  height: 100%;
  background-color: rgb(1, 150, 250); /* Color of the filled area */
  border-radius: 10px; /* Match the radius of the outer bar */
  /* Width is set dynamically in your Vue template */
}
.divider {
  border: none; /* Remove the default border */
  height: 1px; /* Set the height of the divider */
  background-color: rgba(0, 0, 0, 0.7); /* Light grey color */
  margin: 10px 0; /* Add some vertical spacing */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Full viewport height minus any headers or footers if they exist */
  height: 100vh;
  /* Or if it should be the full height of a parent container, you could use 100% instead */
  /* height: 100%; */
  width: 100%;
}
.custom-loading-dialog .q-dialog__actions {
  display: none;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 125px;
  height: 125px;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 100%;
}

.card-spinner {
  border: 8px solid #f3f3f3; /* Light grey border */
  border-top: 8px solid var(--accent); /* Use your accent color here */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.2s linear infinite;
}
</style>
