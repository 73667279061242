<template>
  <div class="body">
    <q-spinner
      v-if="isLoading"
      class="absolute-center"
      color="primary"
      size="100px"
    />
    <div v-if="!isLoading">
      <div class="body">
        <q-spinner
          v-if="isLoading"
          class="absolute-center"
          color="primary"
          size="100px"
        />
        <div v-if="currentUser">
          <div class="header">
            <div class="top-bar">
              <h4 class="text-weight-medium">My Team</h4>
              <q-chip
                v-if="currentUser"
                icon="groups"
                class="text-weight-bold"
                color="accent"
                text-color="white"
              />
            </div>
          </div>
          <q-separator color="grey-7 q-mb-lg" />

          <section>
            <h5 class="text-weight-bold">Me</h5>
            <div class="text-blue-grey-9 text-weight-medium">
              Your account information
            </div>
            <q-separator color="grey-5 q-mb-md" />
            <q-card style="max-width: 17em" v-if="currentUser">
              <q-icon name="person" color="primary" size="17em"></q-icon>

              <q-card-section>
                <div class="text-h6 ellipsis">
                  {{ currentUser.first_name }} {{ currentUser.last_name }}
                </div>
                <div class="text-h9 ellipsis">
                  {{ currentUser.email }}
                </div>
                <q-badge
                  outline
                  align="middle"
                  :color="getBadgeColor(currentUser)"
                >
                  {{ currentUser.role }}
                </q-badge>
              </q-card-section>
            </q-card>
          </section>
          <section v-for="org in currentUser.orgs">
            <h5 class="text-weight-bold">{{ org.name }} - Organization Members</h5>
            <div class="text-blue-grey-9 text-weight-medium">
              All members in your Organization
            </div>
            <q-separator color="grey-5 q-mb-md" />
            <div
              class="q-pa-md row items-center q-gutter-md"
              style="padding-left: 0"
            >
              <div v-for="member in members[org.id]" :key="member.id">
                <q-card style="max-width: 17em">
                  <q-icon name="person" color="primary" size="17em"></q-icon>

                  <q-card-section>
                    <div class="text-h6 ellipsis">
                      {{ member.first_name }} {{ member.last_name }}
                    </div>
                    <div class="text-h9 ellipsis">
                      {{ member.email }}
                    </div>
                    <q-badge
                      outline
                      align="middle"
                      :color="getBadgeColor(member)"
                    >
                      {{ member.roles[org.id] }}
                    </q-badge>
                  </q-card-section>
                </q-card>
              </div>
              <div v-for="pending in pendingInvites[org.id]" :key="pending.id">
                <q-card
                  v-if="
                    currentUser &&
                    (currentUser.role === 'coordinator' ||
                      currentUser.role === 'admin')
                  "
                  style="max-width: 17em"
                >
                  <q-icon
                    name="person"
                    color="primary"
                    size="17em"
                    style="opacity: 0.3; filter: blur(2px)"
                  ></q-icon>

                  <q-card-section>
                    <div class="text-h6 ellipsis">Invitation Sent</div>
                    <div class="text-h9 ellipsis">
                      {{ pending.to }}
                    </div>
                    <q-badge
                      outline
                      align="middle"
                      :color="getBadgeColor(pending)"
                      class="flex-shrink-0"
                    >
                      {{ pending.role }}
                    </q-badge>
                  </q-card-section>
                  <div class="absolute-top">
                    <q-btn
                      class="full-width q-pt-sm"
                      color="white bg-primary"
                      label="Revoke Invitation"
                      size="sm"
                      :loading="revokingId == pending.id"
                      :disable="revokingId == pending.id"
                      @click.stop="confirmRevoke(pending.id)"
                      flat
                    ></q-btn>
                  </div>
                </q-card>
              </div>
              <div class="justify-center">
                <q-btn
                  v-if="
                    currentUser &&
                    (currentUser.role === 'coordinator' ||
                      currentUser.role === 'admin')
                  "
                  round
                  icon="add"
                  color="accent"
                  @click="inviteTo = org.id; dialogVisible = true"
                >
                  <q-tooltip class="bg-grey-7">Invite User</q-tooltip></q-btn
                >
              </div>
              <q-dialog v-model="dialogVisible">
                <q-card v-if="!sendingInvite" style="width: 400px">
                  <q-card-section>
                    <div class="text-h6">Invite Coordinator</div>
                  </q-card-section>

                  <q-card-section>
                    <q-input
                      v-model="emailAddress"
                      label="Email Address"
                      :rules="[
                        (val) =>
                          /.+@.+\..+/.test(val) ||
                          'Must be a valid email address',
                      ]"
                    />
                    <q-select
                      filled
                      v-model="asType"
                      :options="['coordinator', 'vendor_coordinator']"
                      label="As..."
                    ></q-select>
                  </q-card-section>

                  <q-card-section align="right">
                    <q-btn
                      flat
                      label="Cancel"
                      color="primary"
                      @click="dialogVisible = false"
                    />
                    <q-btn
                      flat
                      label="Invite"
                      color="primary"
                      @click="inviteUser(inviteTo)"
                    />
                  </q-card-section>
                </q-card>
                <q-spinner v-else color="primary" :size="80"></q-spinner>
              </q-dialog>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";
import {
  fetchOrgUsers,
  fetchUser,
  inviteAs,
  revokeInvite,
} from "@/api/userApi";

export default {
  name: "Org",
  setup() {
    const isLoading = ref(false);
    const dialogVisible = ref(false);
    const emailAddress = ref("");
    const asType = ref("");
    const members = ref(null);
    const currentUser = ref(null);
    const pendingInvites = ref(null);
    const sendingInvite = ref(false);
    const revokingId = ref(null);
    const $q = useQuasar();
    const inviteTo = ref(null);

    // Converted to the Composition API style
    onMounted(async () => {
      isLoading.value = true;
      members.value = await fetchOrgUsers();
      currentUser.value = await fetchUser();
      await fetchPending();
      isLoading.value = false;
    });

    const getBadgeColor = (user) => {
      if (user.role === "coordinator") return "accent";
      else if (user.role === "applicant") return "primary";
      else return "primary";
    };

    const fetchPending = async () => {
      const response = await axios.get("api/v1/get-pending/");
      pendingInvites.value = response.data;
    };

    const revokeInvitation = async (invite_id) => {
      revokingId.value = invite_id;
      await revokeInvite(invite_id);
      await fetchPending();
      revokingId.value = null;
    };

    const confirmRevoke = async (pendingId) => {
      $q.dialog({
        title: "Confirm Revoke",
        message:
          "Are you sure that you want to revoke this invitation? You can always re-invite the user later.",
        ok: { color: "negative", label: "Revoke" },
        cancel: { color: "grey", label: "Cancel" },
        persistent: true,
      }).onOk(async () => {
        try {
          isLoading.value = true;
          await revokeInvitation(pendingId);
          isLoading.value = false;
        } catch (error) {
          console.error("Error deleting file:", error);
        }
      });
    };

    const inviteUser = async (org_id) => {
      sendingInvite.value = true;
      const response = await inviteAs(emailAddress.value, asType.value, null, org_id); // Ensure appID is reactive or passed correctly
      emailAddress.value = "";
      await fetchPending();
      dialogVisible.value = false;
      sendingInvite.value = false;
      inviteTo.value = null;
    };

    // Ensure appID is managed correctly, possibly as a reactive property if it changes
    const appID = ref(null); // Example, you may need to adjust based on your actual usage

    return {
      isLoading,
      dialogVisible,
      emailAddress,
      asType,
      members,
      currentUser,
      pendingInvites,
      sendingInvite,
      revokingId,
      getBadgeColor,
      confirmRevoke,
      inviteUser,
      inviteTo,
      appID, // Make sure to return it if used in the template
    };
  },
};
</script>

<style scoped>
.body {
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
  width: 100%;
  font-family: var(--title2);
  max-width: 1300px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items vertically */
  width: 100%;
  padding-top: 40px;
}

.top-bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 20px;
}
</style>
