<template>
  <BubbleMenu
      :editor="editor"
      style="display: flex; transform: translateX(0%); bottom: 100%; left: 100%; align-items: center; background-color: white; box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); width: fit-content; padding: 0.25rem;"
    >
    <div class = "flex" style="display: flex; align-items: center; justify-content: space-between;">
        <button class="ai-button" @click.stop="showAIPopup" style="background-color: #0096fa; color: white; border: none; border-radius: 3px; padding: 5px 7px; cursor: pointer; z-index: 10; margin-right: 2px;margin-left: 2px;">
          <font-awesome-icon icon="fas fa-wand-magic-sparkles" style="color: white;" />
          <strong>  AI </strong>
        </button>
        
        <NodeSelector :editor="editor" />
        <q-btn
          v-for="(item, index) in items"
          :key="index"
          flat
          dense
          :icon="item.icon"
          @click="item.command"
          class="q-mx-xs"
          style="cursor: pointer; background: none; color: black; padding: 2px 4px; margin-right: 2px;margin-left: 2px;"
        />
        <!-- <TableSelector :editor="editor" /> -->
        <LinkSelector :editor="editor" />
        <ColorSelector :editor="editor" /> 
  </div>
</BubbleMenu>
</template>


<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { onMounted, ref } from 'vue';
import { useEditor, BubbleMenu } from "@tiptap/vue-3";
import NodeSelector from "./NodeSelector.vue";
import ColorSelector from "./ColorSelector.vue";
import LinkSelector from "./LinkSelector.vue";
import TableSelector from "./table.vue";

// Add the icon to the library
library.add(faWandMagicSparkles);

export default {
  components: {
    FontAwesomeIcon,
    NodeSelector,
    ColorSelector,
    LinkSelector,
    BubbleMenu,
    TableSelector
  },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    // Define items as a reactive property
    const items = ref([
    {
          name: 'bold',
          isActive: () => props.editor.isActive('bold'),
          command: () => props.editor.chain().focus().toggleBold().run(),
          icon: 'format_bold',
        },
        {
          name: 'italic',
          isActive: () => props.editor.isActive('italic'),
          command: () => props.editor.chain().focus().toggleItalic().run(),
          icon: 'format_italic',
        },
        {
          name: 'underline',
          isActive: () => props.editor.isActive('underline'),
          command: () => props.editor.chain().focus().toggleUnderline().run(),
          icon: 'format_underline',
        },
    ]);

    // Methods as functions inside setup
    const applyFormat = (format) => {
      emit('format', format);
    };

    const showAIPopup = () => {
      emit('showAIPopup');
    };

    // Return everything that should be accessible in the template
    return { items, applyFormat, showAIPopup };
  }
};
</script>

  