<template>
  <div class="popup">
    <p>{{ text }}</p>
    <button @click="emitResponse('yes')">Yes</button>
    <button @click="emitResponse('no')">No</button>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    text: String
  },
  methods: {
    emitResponse(response) {
      this.$emit('response', response);
    }
  }
};
</script>

<style>
/* Add your styles for the popup here */
.popup {
    /* Sample styling */
    background-color: white;
    color: black;
    padding: 20px;
    border: 1px solid #ccc;
    text-align: center;
}
</style>
