// Assuming you're using ES Modules syntax
import { marked, Renderer } from "marked";

export function renderMarkdown(markdown) {
  if (markdown) {
    const renderer = new Renderer();
    renderer.heading = function (text, level) {
      if (level === 3) {
        // Apply a CSS class to all h3 elements
        return `<h3 class="custom-h3">${text}</h3>\n`;

        // Or apply inline styles directly to all h3 elements
        // return `<h3 style="color: red; font-weight: bold;">${text}</h3>\n`;
      } else if (level === 4) {
        return `<h4 class="custom-h4">${text}</h4>\n`;
      } else {
        // Use default behavior for other heading levels
        return `<h${level}>${text}</h${level}>\n`;
      }
    };
    renderer.link = (href, title, text) => {
      return `<a target="_blank" href="${href}" title="${title}" style="color: rgba(0, 150, 250); font-weight:bold;text-decoration: underline;">${text}</a>`;
    };
    return marked(markdown, { renderer });
  } else {
    return "";
  }
}
