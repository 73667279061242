<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-1 text-primary shadow-1 justify-between"
      :width="240"
      style="overflow: hidden;"
    >
      <img class="q-pa-lg" :src="require('@/assets/logo.svg')" style="width: 100%;"/>
      <q-list>
        <q-item :class="{ 'bg-secondary': isActive('BillSearch') }" clickable
          @click="router.push({ name: 'BillSearch' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="gavel" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Federal</h6>
          </q-item-section>
        </q-item>
        <q-item :class="{ 'bg-secondary': isActive('StateBillSearch') }" clickable
          @click="router.push({ name: 'StateBillSearch' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="account_balance" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">State</h6>
          </q-item-section>
        </q-item>
        <q-item :class="{ 'bg-secondary': isActive('BookmarkedBills') }" class="cursor-pointer" clickable
          @click="router.push({ name: 'BookmarkedBills' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="bookmark" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Saved Bills</h6>
          </q-item-section>
        </q-item>
      </q-list>
      <q-list v-if="signedIn" class="absolute-bottom" style="margin-bottom: 20px">
        <q-item clickable @click="router.push({ name: 'SignIn' })" v-ripple>
          <q-item-section avatar style="min-width: 40px">
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <h6 class="cursor-pointer">Sign Out</h6>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container
      class="row justify-center bg-tertiary"
      style="height: 100vh; overflow: auto"
    >
    <q-page class="column justify-start">
      <div class="column items-center q-px-lg">
        <div class="row justify-end" style="width: 100%; height: 50px;">
          <q-btn
            v-if="!signedIn"
            color="primary"
            label="Sign In"
            class="q-mr-md q-mt-md"
            @click="showSignIn = true"
          >
          </q-btn>
          <q-btn
            v-else
            rounded
            color="primary"
            label=""
            class="q-mr-md q-mt-md"
            @click="router.push({ name: 'User' })"
          >
            <q-icon name="person" color="white" />
            <q-tooltip
              class="bg-primary text-white text-body2"
              :offset="[10, 10]"
            >My Account</q-tooltip>
          </q-btn>
        </div>
        
        <div style="max-width: 750px; min-width: 350px;">
          <div class="row justify-center">
            <h3 class="q-py-lg" style="text-align: center;">Aidy Newsletter Signup</h3>
          </div>
          <div v-if="submitSuccess">
            <div class="row justify-center">
              <q-icon name="check_circle" color="accent" style="font-size: 12em"/>
            </div>
            <div  class="row justify-center q-pt-lg">
              <div class="text-h6" style="text-align: center;">You're signed up! We'll send you your first custom Aidy newsletter soon.</div>
            </div>
          </div>

          <div v-else>
            <div class="row justify-center">
              <div class="text-subtitle1" style="text-align: center;">Start generating your custom newsletter to keep track of the congressional and state legislation issues that matter to you!</div>
            </div>
            <form @submit.prevent.stop="onSubmit">
              <div class="row justify-center q-pt-lg">
                <q-input
                  v-model="topic"
                  ref="topicRef"
                  label="The topic you want to hear about (provide as little or as much detail as you'd like)"
                  placeholder="Clean carbon...."
                  filled
                  autogrow
                  lazy-rules
                  :rules="rules"
                  style="width: 100%"
                />
              </div>
              
              <div class="row justify-center q-pt-lg">
                <q-checkbox 
                  v-model="congress"
                  ref="congressRef"
                  label="Congress" 
                  color="orange"
                  class="q-pl-xs"
                  style="width: 100%"
                />
              </div>
              <div class="row justify-center q-pb-lg">
                <q-checkbox 
                  v-model="allStates"
                  ref="allStatesRef"
                  label="All States" 
                  color="orange"
                  class="q-pl-xs"
                  style="width: 100%"
                />
              </div>
              <div v-if="!allStates" class="row justify-center">
                <q-option-group
                  v-model="selectedStates"
                  ref="statesRef"
                  :options="states"
                  color="orange"
                  type="checkbox"
                  inline
                  style="width: 100%"
                />
              </div>
              <div v-if="congress" class="row justify-center q-pt-lg">
                <q-select
                  filled
                  v-model="members"
                  label="Members"
                  ref="membersRef"
                  use-input
                  use-chips
                  multiple
                  input-debounce="0"
                  :options="filteredMembers"
                  @add="membersRef.updateInputValue('');"
                  @filter="filterFn"
                  style="width: 100%"
                />
              </div>

              <div class="row justify-center q-pt-lg">
                <q-select 
                  v-model="frequency"
                  ref="frequencyRef"
                  :options="['Daily', 'Weekly']"
                  label="Newsletter frequency"
                  lazy-rules
                  style="width: 100%"
                />
              </div>
              
              <div class="row justify-center q-pt-lg">
                <q-input
                  v-model="name"
                  ref="nameRef"
                  label="Your Name"
                  placeholder="Joe Smith"
                  filled
                  lazy-rule
                  :rules="rules"
                  style="width: 100%"
                />
              </div>
              <div class="row justify-center q-pt-lg">
                <q-input
                  v-model="email"
                  ref="emailRef"
                  label="Your Email"
                  placeholder="name@example.com"
                  filled
                  type="email"
                  lazy-rules
                  :rules="rules"
                  style="width: 100%"
                />
              </div>
              <div class="row justify-center q-pt-lg">
                <q-input
                  v-model="org_name"
                  ref="orgRef"
                  label="Your Organization's Name"
                  placeholder="K Street Consulting"
                  filled
                  lazy-rules
                  :rules="rules"
                  style="width: 100%"
                />
              </div>

              <div class="row justify-center q-py-lg">
                <q-btn
                  color="accent"
                  size="lg"
                  label="Sign Up"
                  :loading="submitting"
                  no-caps
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>

        
      </div>
    </q-page>
    </q-page-container>
  </q-layout>

  <q-dialog v-model="showSignIn">
    <sign-in-comp @signinComplete="signinComplete" @goToReg="goToRegistration" :linkToReg="false"></sign-in-comp>
  </q-dialog>

  <q-dialog v-model="showSignUp">
    <register-comp role="legislation" @registrationComplete="handleRegistrationComplete" @goToSignIn="goToSignIn" :linkToSignIn="false"></register-comp>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed, provide } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { fetchUser, goHome } from "@/api/userApi";
import axios from "axios";
import BillList from "@/components/BillList.vue";
import {
  formatFilters,
  extractUniqueAttributesAndConvertToList,
  filterOpportunitiesBySelections,
} from "@/helpers/search.js";
import Fuse from "fuse.js"; // Assuming you're using Fuse.js directly
import { useQuasar } from 'quasar'

export default {
  name: "BillSearch",
  components: {
    BillList,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const $q = useQuasar()

    const topicRef = ref(null);
    const congressRef = ref(null);
    const statesRef = ref(null);
    const frequencyRef = ref(null);
    const nameRef = ref(null);
    const membersRef = ref(null);
    const emailRef = ref(null);
    const orgRef = ref(null);

    const topic = ref("");

    const congress = ref(true);
    const allStates = ref(false);

    const frequency = ref("Weekly");

    const name = ref("");

    const email = ref("");

    const org_name = ref("");

    const selectedStates = ref([]);

    const members = ref([]);

    const memberMap = ref({});

    const memberOptions = ref([]);

    const filteredMembers = ref(memberOptions.value);

    const submitting = ref(false);

    const submitSuccess = ref(false);

    const states = ref([
      { label: 'Alabama', value: 'al' },
      { label: 'Alaska', value: 'ak' },
      { label: 'Arizona', value: 'az' },
      { label: 'Arkansas', value: 'ar' },
      { label: 'California', value: 'ca' },
      { label: 'Colorado', value: 'co' },
      { label: 'Connecticut', value: 'ct' },
      { label: 'Delaware', value: 'de' },
      { label: 'Florida', value: 'fl' },
      { label: 'Georgia', value: 'ga' },
      { label: 'Hawaii', value: 'hi' },
      { label: 'Idaho', value: 'id' },
      { label: 'Illinois', value: 'il' },
      { label: 'Indiana', value: 'in' },
      { label: 'Iowa', value: 'ia' },
      { label: 'Kansas', value: 'ks' },
      { label: 'Kentucky', value: 'ky' },
      { label: 'Louisiana', value: 'la' },
      { label: 'Maine', value: 'me' },
      { label: 'Maryland', value: 'md' },
      { label: 'Massachusetts', value: 'ma' },
      { label: 'Michigan', value: 'mi' },
      { label: 'Minnesota', value: 'mn' },
      { label: 'Mississippi', value: 'ms' },
      { label: 'Missouri', value: 'mo' },
      { label: 'Montana', value: 'mt' },
      { label: 'Nebraska', value: 'ne' },
      { label: 'Nevada', value: 'nv' },
      { label: 'New Hampshire', value: 'nh' },
      { label: 'New Jersey', value: 'nj' },
      { label: 'New Mexico', value: 'nm' },
      { label: 'New York', value: 'ny' },
      { label: 'North Carolina', value: 'nc' },
      { label: 'North Dakota', value: 'nd' },
      { label: 'Ohio', value: 'oh' },
      { label: 'Oklahoma', value: 'ok' },
      { label: 'Oregon', value: 'or' },
      { label: 'Pennsylvania', value: 'pa' },
      { label: 'Rhode Island', value: 'ri' },
      { label: 'South Carolina', value: 'sc' },
      { label: 'South Dakota', value: 'sd' },
      { label: 'Tennessee', value: 'tn' },
      { label: 'Texas', value: 'tx' },
      { label: 'Utah', value: 'ut' },
      { label: 'Vermont', value: 'vt' },
      { label: 'Virginia', value: 'va' },
      { label: 'Washington', value: 'wa' },
      { label: 'West Virginia', value: 'wv' },
      { label: 'Wisconsin', value: 'wi' },
      { label: 'Wyoming', value: 'wy' }
    ]);

    const rules = ref([
      val => (val && val.length > 0) || 'Please enter something'
    ])

    const loading = ref(false);
    const leftDrawerOpen = ref(false);
    const search = ref("");
    const searchQuery = ref("");
    provide('searchQuery', searchQuery);

    const companyName = ref("");
    provide('companyName', companyName);

    const showAdvanced = ref(false);
    const showDateOptions = ref(false);
    const byWebsite = ref("");
    const byDate = ref("Any time");
    const error = ref("");

    const coreTech = ref("");
    const productDescription = ref("");

    const suggestedQs = ref([
      'Carbon border adjustment',
      'State Medicaid spending',
      'Permitting reform',
      'Oil and gas subsidies',
      'State Dept budget',
    ]);

    function onClear() {
      companyName.value = "";
      coreTech.value = "";
      productDescription.value = "";
      byWebsite.value = "";
      byDate.value = "Any time";
    }

    function changeDate(option) {
      byDate.value = option;
      showDateOptions.value = false;
    }

    function toggleLeftDrawer() {
      leftDrawerOpen.value = !leftDrawerOpen.value;
    }

    function searchSuggestion(suggested_query) {
      search.value = suggested_query;
      fetchSearchResults();
    }

    function filterFn (val, update) {
      update(() => {
        if (val === '') {
          filteredMembers.value = memberOptions.value;
        }
        else {
          const needle = val.toLowerCase();
          filteredMembers.value = memberOptions.value.filter(v => v.toLowerCase().indexOf(needle) > -1);
        }
      })
    }

    const isActive = (routeName) => {
      return route.name === routeName;
    };

    const showSignIn = ref(false);
    const showSignUp = ref(false);

    const signedIn = ref(false);
    

    const checkLoggedIn = () => {
      return store.dispatch("checkToken")
        .then(signedIn => {
          // console.log("Signed in?", signedIn);
          return signedIn;  // Return the signed in status for further processing
        })
        .catch(error => {
          console.error("Error checking token:", error);
          return false;  // Handle the error case by returning false
        });
    };

    onMounted(async () => {
      signedIn.value = await checkLoggedIn();
      try {
        const response = await axios.get("api/v1/get-all-members/");
        memberMap.value = response.data;
        memberOptions.value = Object.keys(memberMap.value);
      } catch (error) {
        console.error("Error fetching opportunities:", error);
      }
    });

    function handleRegistrationComplete() {
      signedIn.value = checkLoggedIn();
      // console.log("Registration complete");
      showSignUp.value = false;
    }

    function signinComplete() {
      signedIn.value = checkLoggedIn();
      // console.log("Signin complete:", signedIn.value);
      showSignIn.value = false;
      $q.notify('Login successful!')
    }

    function goToRegistration() {
      showSignIn.value = false;
      showSignUp.value = true;
    }

    function goToSignIn() {
      showSignUp.value = false;
      showSignIn.value = true;
    }

    async function onSubmit() {
      submitting.value = true;
      topicRef.value.validate();
      nameRef.value.validate();
      emailRef.value.validate();
      orgRef.value.validate();

      // Generate a list of values for the subset of keys
      const selectedMemberIds = members.value.map(key => memberMap.value[key]);

      if (topicRef.value.hasError || nameRef.value.hasError || emailRef.value.hasError || orgRef.value.hasError) {
          // form has error
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const sendParam = urlParams.get('send') === 'true';
        const plaintext = urlParams.get('plaintext') === 'true';
        const enrich = urlParams.get('enrich') === 'true';
        const response = await axios.post(`api/v1/join-newsletter-waitlist/`, {
          params: {
            topic: topic.value,
            allStates: allStates.value,
            congress: congress.value,
            frequency: frequency.value,
            name: name.value,
            email: email.value,
            org_name: org_name.value,
            states: selectedStates.value,
            member_ids: selectedMemberIds,
            send: sendParam,
            plaintext: plaintext,
            enrich: enrich,
          },
        });
        if (response.status === 200 || response.status === 201) {
          submitSuccess.value = true;
        }
      }
      
      submitting.value = false;
    };

    return {
      router,
      loading,
      signedIn,
      checkLoggedIn,
      topicRef,
      congressRef,
      statesRef,
      frequencyRef,
      nameRef,
      membersRef,
      emailRef,
      orgRef,
      selectedStates,
      states,
      topic,
      congress,
      allStates,
      members,
      memberOptions,
      memberMap,
      filteredMembers,
      frequency,
      name,
      email,
      org_name,
      submitting,
      submitSuccess,
      rules,
      leftDrawerOpen,
      searchSuggestion,
      onClear,
      changeDate,
      toggleLeftDrawer,
      showSignIn,
      showSignUp,
      handleRegistrationComplete,
      signinComplete,
      goToRegistration,
      goToSignIn,
      isActive,
      onSubmit,
      filterFn,
    };
  },
};
</script>
<style scoped>
.clickable-title {
  transition: color 0.1s, font-weight 0.1s;
}

.clickable-title:hover {
  color: rgba(0, 150, 250); /* This is an example color, change it as needed */
}
</style>