import { createRouter, createWebHistory } from "vue-router";
import store from "../store"; // Import your Vuex store
import axios from "axios";
import Home from "../views/Home.vue";
import SignIn from "../views/SignIn.vue";
import Document from "../views/Document.vue";
import NewsletterSignup from "../views/NewsletterSignup.vue";
import Bill from "../views/Bill.vue";
import BillInfo from "../views/BillInfo.vue";
import BookmarkedBills from "../views/BookmarkedBills.vue";
import Upload from "../views/UploadDoc.vue";
import Register from "../views/Register.vue";
import User from "../views/User.vue";
import GrantApp from "../views/GrantApp.vue";
import MyApps from "../views/MyApps.vue";
import Eligibility from "../views/Eligibility.vue";
import Search from "../views/Search.vue";
import BillSearch from "../views/BillSearch.vue";
import StateBillSearch from "../views/StateLegislation/StateBillSearch.vue";
import StateBillInfo from "../views/StateLegislation/StateBillInfo.vue";
import GrantInfo from "../views/GrantInfo.vue";
import SAF from "../views/SAF.vue";
import Label from "../views/Label.vue";
import Plans from "../views/Plans.vue";
import Application from "../views/Application.vue";
import Overview from "../views/Overview.vue";
import OverviewSS from "../views/OverviewSS.vue";
import GrantEditor from "../views/GrantEditor.vue";
import DocEditor from "../views/DocEditor.vue";
import AppContainer from "../views/AppContainer.vue";
import Dashboard from "../views/Dashboard.vue";
import Portal from "../views/Portal.vue";
import VendorPortal from "../views/VendorPortal.vue";
import Forgot from "../views/Forgot.vue";
import PasswordReset from "../views/PasswordReset.vue";
import Join from "../views/Join.vue";
import TiptapEditor from '@/components/TipTap/Editor.vue';
import ReportTemplateList from '../views/templatedashboard.vue';
import ConvertBillToHTML from "../views/ConvertBillToHTML.vue";
import { Bookmark } from "lucide-vue-next";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Aidy" },
    children: [
      {
        path: "document/:id", // child route
        name: "Document",
        component: Document,
        meta: { title: "Aidy | Document" },
        props: (route) => ({
          id: route.params.id,
          sectionId: route.query.sectionId,
        }),
        meta: { requiresAuth: true, requiresPaid: true, showAssistant: true },
      },
      {
        path: "grant-app/:id", // child route
        name: "GrantApp",
        component: GrantApp,
        meta: { title: "Aidy | Application" },
        props: true,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "upload", // child route
        name: "Upload",
        component: Upload,
        meta: { title: "Aidy | Upload" },
        props: false,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "portal/:step?", // child route
        name: "Portal",
        component: Portal,
        meta: { title: "Aidy | Portal" },
        props: true,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "vendor-portal/:id?", // child route
        name: "Vendor Portal",
        component: VendorPortal,
        meta: { title: "Aidy | Vendor Portal" },
        props: true,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "",
        name: "User",
        meta: { title: "Aidy | Profile" },
        component: User,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "bills",
        name: "BookmarkedBills",
        meta: { title: "Aidy | Bookmarked Bills" },
        component: BookmarkedBills,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "apps",
        name: "MyApps",
        meta: { title: "Aidy | My Applications" },
        component: MyApps,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "app/:id?",
        name: "AppContainer",
        meta: { title: "Aidy | My Application" },
        component: AppContainer,
        meta: { requiresAuth: true, requiresPaid: false },
      },
      {
        path: "grant-editor/:id", // child route
        name: "Grant Editor",
        component: GrantEditor,
        meta: { title: "Aidy | Grant Editor" },
        props: true,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "doc-editor/:id", // child route
        name: "DocEditor",
        component: DocEditor,
        meta: { title: "Aidy | DocEditor" },
        props: true,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "plans",
        name: "Plans",
        meta: { title: "Aidy | Plans" },
        component: Plans,
        meta: { requiresAuth: true },
      },
      {
        path: "application",
        name: "Application",
        meta: { title: "Aidy | Apply" },
        component: Application,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "overview",
        name: "Overview",
        meta: { title: "Aidy | Overview" },
        component: Overview,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "overview-ss",
        name: "OverviewSS",
        meta: { title: "Aidy | Overview" },
        component: OverviewSS,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      {
        path: "dashboard",
        name: "Dashboard",
        meta: { title: "Aidy | Dashboard" },
        component: Dashboard,
        meta: { requiresAuth: true, requiresPaid: true },
      },
      // ... add other child routes here as needed
    ],
  },
  {
    path: "/sign-in",
    name: "SignIn",
    meta: { title: "Aidy | Sign In" },
    component: SignIn,
  },
  {
    path: "/forgot",
    name: "Forgot",
    meta: { title: "Aidy | Forgot Password" },
    component: Forgot,
  },
  {
    path: "/reset-password/:uid/:token",
    name: "PasswordReset",
    component: PasswordReset,
    meta: { title: "Aidy | Reset Password" },
    props: true, // This passes route params as props to the component
  },
  {
    path: "/register",
    name: "Register",
    meta: { title: "Aidy | Register" },
    component: Register,
  },
  {
    path: '/join',
    name: 'Join',
    meta: { title: "Aidy | Join" },
    component: Join,
  },
  {
    path: "/eligibility", // default child path
    name: "Eligibility",
    component: Eligibility,
    meta: { title: "Aidy | Eligibility", showAssistant: true },
  },
  {
    path: "/search", // default child path
    name: "Search",
    component: Search,
    meta: { title: "Aidy | SBIR Search", showAssistant: true },
  },
  {
    path: '/grant-info/:id?',
    name: 'GrantInfo',
    component: GrantInfo,
    meta: { title: "Aidy | Grant Info", showAssistant: true },
  },


  {
    path: "/newsletter-signup", // default child path
    name: "NewsletterSignup",
    component: NewsletterSignup,
    meta: { title: "Aidy | Newsletter Signup", showAssistant: false },
  },
  {
    path: "/bill-search", // default child path
    name: "BillSearch",
    component: BillSearch,
    meta: { title: "Aidy | Bill Search", showAssistant: false },
  },
  {
    path: "/statebill-search", // default child path
    name: "StateBillSearch",
    component: StateBillSearch,
    meta: { title: "Aidy | State Bill Search", showAssistant: false },
  },
  {
    path: '/statebill-info/:id',
    name: 'StateBillInfo',
    component: StateBillInfo,
    meta: { title: "Aidy | Bill Info", showAssistant: false },
  },
  {
    path: '/bill-info/:id',
    name: 'BillInfo',
    component: BillInfo,
    meta: { title: "Aidy | Bill Info", showAssistant: false },
  },
  {
    path: "/bill/:id", // child route
    name: "Bill",
    component: Bill,
    meta: { title: "Aidy | Bill Text", showAssistant: true },
    props: true,
  },
  {
    path: "/convert", // child route
    name: "ConvertBillToHTML",
    component: ConvertBillToHTML,
    meta: { title: "Aidy | Bill Text", showAssistant: true },
    props: true,
  },
  {
    path: "/saf", // default child path
    name: "SAF",
    component: SAF,
    meta: { title: "REAP Eligibility | SAF Grant Services" },
  },
  {
    path: "/sun",
    name: "SUN",
    component: Label,
    meta: { title: "REAP Eligibility | Solar United Neighbors" },
  },
  {
    path: "/tapestry",
    name: "Tapestry International",
    component: Label,
    meta: { title: "REAP Eligibility | Tapestry International" },
  },
  {
    path: "/cgf",
    name: "Cleaner Greener Future",
    component: Label,
    meta: { title: "REAP Eligibility | Cleaner Greener Future" },
  },
  {
    path: '/tiptap-editor',
    name: 'TiptapEditor',
    component: TiptapEditor
  },
  {
  path: '/technicalreport/:id',
  name: 'TechnicalReport',
  component: () => import('@/views/technicalreport.vue'),
  props: true
  },
  { path: '/templates', component: ReportTemplateList }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isValidToken = await store.dispatch("checkToken");
    if (!isValidToken) {
      // Token is not valid or expired, redirect to sign-in
      return next({ path: '/sign-in' }); // Adjust the path as necessary
    }
    // If the token is valid, just proceed
  }
  // Set document title
  document.title = to.meta.title || "Default App Title";
  next(); // Proceed for routes that don't require authentication or after handling auth routes
});

  // Only check payment status if the user is authenticated and the route requires a paid user
  // if (
  //   to.matched.some((record) => record.meta.requiresPaid)
  // ) {
  //   try {
  //     const isPaid = await store.dispatch("checkPaymentStatus");
  //     // COORDINATORS ARE ALWAYS RETURNED AS PAID TRUE
  //     if (!isPaid && to.name != "Document") {
  //       //console.log('PRE NOTIFY');
  //       // Notify.create({
  //       //   message: 'You must pay to view the entire site',
  //       //   color: 'warning',
  //       //   position: 'top-right',
  //       //   timeout: 3000, // Adjust time as needed
  //       // });
  //       //console.log('POST NOTIFY');
  //       next({ name: "Plans" });
  //       return;
  //     }
  //   } catch (error) {
  //     console.error("Error checking payment status:", error);
  //     next({ name: "SignIn" });
  //     return;
  //   }
  // }


export default router;
