<template>
  <div class="body">
    <register-comp></register-comp>
  </div>
</template>
<script>
import { ref, watch, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "Register",
  props: {
    user: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const error = ref(""); // Data property for username error
    const loading = ref(false);

    const grant = ref(null);
    const member_type = ref(null);

    // Converted methods
    const logout = () => {
      store.dispatch("logout");
    };

    onMounted(async () => {
      loading.value = true;
      grant.value = route.query.grant;
      // console.log("GRANT", grant.value);
      member_type.value = route.query.member_type;
      // console.log("USER TYPE", member_type.value);
      await store.dispatch("logout"); // Assuming logout might return a Promise
      loading.value = false;
    });

    return {
      error,
      loading,
    };
  },
};
</script>
<style scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    to top,
    #fa9600 0%,
    var(--accent-semi) 50%,
    #ffffff 100%
  );
}
</style>
