import axios from "axios";

export const fetchFiles = async (app_id, file_class) => {
  try {
    const response = await axios.get(`api/v1/get-files`, {
      params: {
        app_id: app_id, // Pass the docClass value as a query parameter
        file_class: file_class,
      },
    });
    const files = response.data.map((file) => ({
      id: file.id,
      name: file.name,
    }));
    return files;
  } catch (error) {
    console.error("Error fetching files:", error);
  }
};


export const fetchNotes = async (appID) => {
  try {
    const response = await axios.get("api/v1/fetch-notes/", {
      params: {
        appID: appID
      }
    });
    return response.data.notes; // Assuming you want to return the data from the response
  } catch (error) {
    console.error("Error fetching application users:", error);
    throw error; // Rethrow or handle error as needed
  }
}

export const fetchTechReport = async (appID) => {
  try {
    const response = await axios.get("api/v1/fetch-tech-report/", {
      params: {
        appID: appID
      }
    });
    console.log("HERE IS A RESPONSE tr ", response.data.technical_report)
    return response.data.technical_report; 
  } catch (error) {
    console.error("Error fetching technical report:", error);
    throw error; // Rethrow or handle error as needed
  }
}


export const fetchForms = async (app_id = null) => {
  try {
    const response = await axios.get(`api/v1/get-forms`, {
      params: {
        app_id: app_id,
      },
    });
    const forms = response.data.map((doc) => ({
      id: doc.doc_id, // Use the actual ID property from your objects
      display_name: doc.display_name, // Use the actual title property from your objects
      complete: doc.complete,
      upload_doc: doc.upload_doc,
    }));
    return forms;
  } catch (error) {
    console.error("Error fetching forms:", error);
  }
};

export const deleteFile = async (fileId) => {
  const response = await axios.post(`api/v1/delete-file/`, {
    params: {
      file_id: fileId,
    },
  });
};

// Sync with AbstractFile object for allowed types
export const saveFile = async (file, app_id, file_class, file_id = null) => {
  const MAX_SIZE = 26214400; // 25 MB
  const allowedTypes = [
    'image/jpeg', 'image/png', 'image/gif', // Images
    'application/vnd.ms-powerpoint', // PowerPoint .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint .pptx
    'text/csv', // CSV files
    'application/vnd.ms-excel', // Excel .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel .xlsx
    'application/pdf', // PDF files
  ];

  if (file && file.size > MAX_SIZE) {
    throw new Error(`File(s) were too large. Size limit is 25MB.`);
  }

  if (file && !allowedTypes.includes(file.type)) {
    throw new Error(`Invalid file type. Please Try again.`);
  }

  const formData = new FormData();
  formData.append("file", file);
  formData.append("app_id", app_id);
  formData.append("file_class", file_class);

  if (file_id !== null) {
    formData.append("file_id", file_id);
  }

  try {
    const response = await axios.post("/api/v1/save-file/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log("File uploaded successfully:", response.data);
  } catch (error) {
    console.error(
      "Error uploading file:",
      error.response ? error.response.data : error.message
    );
    // Handle errors here
  }
};

export const downloadFile = async (fileId) => {
  try {
    const response = await axios.get(`/api/v1/download-file/`, {
      params: { file_id: fileId },
      responseType: "blob", // This is crucial
    });

    // Extract filename from the Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    let filename = "downloaded_file";
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
      if (filenameMatch.length === 2) {
        filename = filenameMatch[1];
      }
    }

    // Create a Blob from the response and download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    // Clean up and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file:", error);
    // Handle errors here
  }
};

export const downloadAnswerFile = async (draft_doc_id, input_name, repeat_idx=null) => {
  try {
    const response = await axios.get(`/api/v1/download-answer-file/`, {
      params: { draft_doc_id:draft_doc_id, input_name: input_name, repeat: repeat_idx},
      responseType: "blob", // This is crucial
    });

    // Extract filename from the Content-Disposition header
    const contentDisposition = response.headers["content-disposition"];
    let filename = "downloaded_file";
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
      if (filenameMatch.length === 2) {
        filename = filenameMatch[1];
      }
    }

    // Create a Blob from the response and download the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    // Clean up and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file:", error);
    // Handle errors here
  }
};

export async function previewAnswerFile(draft_doc_id, input_name, repeat_idx=null) {
  try {
    const response = await axios.get(`/api/v1/preview-answer-file/`, {
      params: { draft_doc_id:draft_doc_id, input_name: input_name, repeat: repeat_idx},
      responseType: "blob", // This is crucial
    });
    // Create a Blob from the response and download the file
    const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    return fileURL;
  } catch (error) {
    console.error("Error downloading file:", error);
    // Handle errors here
  }
};

export async function getPdfPreviewUrl(fileId) {
  try {
    // console.log('fileId', fileId);
    const response = await axios.get("/api/v1/get-file-preview/", {
      params: { file_id: fileId },
      responseType: 'blob'  // This line is crucial for handling PDF data
    });
    // Create a URL for the PDF blob
    // console.log('response', response);
    // console.log('response.data', response.data);
    const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    return fileURL;
  } catch (error) {
    console.error('Error fetching file preview:', error);
    throw error;
  }
}

export const fetchDocs = async (app_id, doc_class) => {
  try {
    // console.log('doc_class', doc_class);
    const response = await axios.get(`api/v1/get-draft-docs`, {
      params: {
        app_id: app_id,
        doc_class: doc_class,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching docs:", error);
  }
}