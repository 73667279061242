<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-white"
      :width="280"
    >
      <q-scroll-area class="fit">
        <img class="q-pa-lg" :src="require('@/assets/logo.svg')" />
        <h4 class="q-pb-sm text-center">Filters</h4>
        <q-separator inset class="q-my-sm" />
        <q-list padding>
          <h6 class="q-pl-md">Categories</h6>
          <q-chip
            v-for="category in categories"
            :key="category.text"
            clickable
            v-ripple
            :outline="category.selected"
            @click="category.selected = !category.selected"
            class="q-ml-md grey-3 text-caption"
          >
            {{ category.text }}
          </q-chip>

          <q-separator inset class="q-my-sm" />

          <h6 class="q-pl-md">Phase</h6>
          <q-chip
            v-for="phase in phases"
            :key="phase.text"
            clickable
            v-ripple
            :outline="phase.selected"
            @click="phase.selected = !phase.selected"
            class="q-ml-md grey-3 text-caption"
          >
            {{ phase.text }}
          </q-chip>

          <q-separator inset class="q-my-sm" />

          <h6 class="q-pl-md">Program</h6>
          <q-chip
            v-for="program in programs"
            :key="program.text"
            clickable
            v-ripple
            :outline="program.selected"
            @click="program.selected = !program.selected"
            class="q-ml-md grey-3 text-caption"
          >
            {{ program.text }}
          </q-chip>

          <q-separator inset class="q-my-sm" />

          <h6 class="q-pl-md">Agency</h6>
          <q-chip
            v-for="department in departments"
            :key="department.text"
            clickable
            v-ripple
            :outline="department.selected"
            @click="department.selected = !department.selected"
            class="q-ml-md grey-3 text-caption"
          >
            {{ department.text }}
          </q-chip>
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container
      class="row justify-center bg-tertiary"
      style="height: 100vh; overflow: auto"
    >
      <div class="column" style="width: 100%;">
        <div class="row justify-end" style="width: 100%; height: 50px;">
          <q-btn
            v-if="!signedIn"
            color="primary"
            label="Sign In"
            class="q-mr-md q-mt-md"
            @click="showSignIn = true"
          >
          </q-btn>
          <q-btn
            v-else
            rounded
            color="primary"
            label=""
            class="q-mr-md q-mt-md"
            @click="router.push({ name: 'User' })"
          >
            <q-icon name="person" color="white" />
            <q-tooltip
              class="bg-primary text-white text-body2"
              :offset="[10, 10]"
            >My Account</q-tooltip>
          </q-btn>
        </div>
        
        <div class="row justify-center" style="max-width: 1200px">
          <q-input
            dense
            outlined
            autogrow
            standout
            rounded
            v-model="search"
            bg-color="white"
            autofocus
            placeholder="What are you working on?"
            style="width: 500px"
            class="q-mt-lg q-mb-lg"
            @keydown.enter.prevent="fetchSbirSearchResults"
          >
            <template v-slot:prepend>
              <q-icon v-if="search === ''" name="search" />
              <q-icon
                v-else
                name="clear"
                class="cursor-pointer"
                @click="search = ''"
              />
            </template>
          </q-input>
          <div
            v-if="filteredOpps.length === 0 && !loading"
            class="text-center q-mt-md"
            style="width: 100%;"
          >
            <p class="text-center">Click enter for AI search...</p>
          </div>
          <template v-else>
            <OppList :opps="filteredOpps" :loading="loading" :searchQuery="searchQuery"/>
          </template>
        </div>
      </div>
      
    </q-page-container>
  </q-layout>

  <q-dialog v-model="showSignIn">
    <sign-in-comp @signinComplete="signinComplete" @goToReg="goToRegistration" :linkToReg="false"></sign-in-comp>
  </q-dialog>

  <q-dialog v-model="showSignUp">
    <register-comp role="search" @registrationComplete="handleRegistrationComplete" @goToSignIn="goToSignIn" :linkToSignIn="false"></register-comp>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed, provide } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { fetchUser, goHome } from "@/api/userApi";
import axios from "axios";
import OppList from "@/components/OppList.vue";
import {
  extractUniqueAttributesAndConvertToList,
  filterOpportunitiesBySelections,
} from "@/helpers/search.js";
import Fuse from "fuse.js"; // Assuming you're using Fuse.js directly

export default {
  name: "Search",
  components: {
    OppList,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const loading = ref(false);
    const leftDrawerOpen = ref(false);
    const search = ref("");
    const searchQuery = ref("");
    provide('searchQuery', searchQuery);

    const companyName = ref("");
    provide('companyName', companyName);

    const showAdvanced = ref(false);
    const showDateOptions = ref(false);
    const byWebsite = ref("");
    const byDate = ref("Any time");
    const error = ref("");

    const coreTech = ref("");
    const productDescription = ref("");

    function onClear() {
      companyName.value = "";
      coreTech.value = "";
      productDescription.value = "";
      byWebsite.value = "";
      byDate.value = "Any time";
    }

    function changeDate(option) {
      byDate.value = option;
      showDateOptions.value = false;
    }

    function toggleLeftDrawer() {
      leftDrawerOpen.value = !leftDrawerOpen.value;
    }

    const opps = ref([]);
    const categories = ref([]);
    const phases = ref([]);
    const programs = ref([]);
    const departments = ref([]);

    const showSignIn = ref(false);
    const showSignUp = ref(false);

    const signedIn = ref(false);
    

    const checkLoggedIn = () => {
      return store.dispatch("checkToken")
        .then(signedIn => {
          return signedIn;  // Return the signed in status for further processing
        })
        .catch(error => {
          console.error("Error checking token:", error);
          return false;  // Handle the error case by returning false
        });
    };

    onMounted(async () => {
      signedIn.value = await checkLoggedIn();
      try {
        loading.value = true;
        const response = await axios.get("api/v1/search-opportunities/");
        opps.value = response.data;
        await extractUniqueAttributesAndConvertToList(
          opps.value,
          "categories",
          categories
        );
        await extractUniqueAttributesAndConvertToList(
          opps.value,
          "phase",
          phases
        );
        await extractUniqueAttributesAndConvertToList(
          opps.value,
          "program",
          programs
        );
        await extractUniqueAttributesAndConvertToList(
          opps.value,
          "department",
          departments
        );

        // Now transform the set of keys into the desired list format
        loading.value = false;
      } catch (error) {
        console.error("Error fetching opportunities:", error);
        loading.value = false;
      }
    });

    const fuseOptions = {
      threshold: 0.3, // Lower threshold for closer matches
      distance: 1000, // Increase distance for more flexibility in character positions
      minMatchCharLength: 1, // Require at least 3 characters to match
      useExtendedSearch: false, // Advanced search options (set to true if needed)
      // Optionally, assign weights to keys
      keys: [
        {
          name: "gen_title",
          weight: 0.4, // Higher weight means this field is more important
        },
        {
          name: "gen_description",
          weight: 0.2, // Higher weight means this field is more important
        },
        {
          name: "category_list",
          weight: 0.4, // Higher weight means this field is more important
        },
      ],
    };

    const filteredOpps = computed(() => {
      // Setup for selections
      const selections = [
        { type: "categories", items: categories.value },
        { type: "phase", items: phases.value },
        { type: "program", items: programs.value },
        { type: "department", items: departments.value },
        // Additional selections...
      ];

      let filtered = filterOpportunitiesBySelections(opps.value, selections);
      // Apply Fuse.js search if there's a search term
      if (search.value && searchQuery.value === "") {
        const fuse = new Fuse(filtered, fuseOptions);
        const searchResults = fuse.search(search.value);
        filtered = searchResults.map((result) => result.item);
      }

      return filtered;
    });

    const fetchSbirSearchResults = async () => {
      try {
        loading.value = true;
        searchQuery.value = search.value;
        const params = new URLSearchParams({
          query: search.value,
        });

        const response = await axios.get(`/api/v1/sbir-search/?${params}`);
        opps.value = response.data;
        loading.value = false;
      } catch (error) {
        console.error("There was an error fetching the data:", error);
        error.value = error; // Store the error in state, if any
      }
    };

    function handleRegistrationComplete() {
      signedIn.value = checkLoggedIn();
      // console.log("Registration complete");
      showSignUp.value = false;
    }

    function signinComplete() {
      signedIn.value = checkLoggedIn();
      // console.log("Signin complete:", signedIn.value);
      showSignIn.value = false;
    }

    function goToRegistration() {
      showSignIn.value = false;
      showSignUp.value = true;
    }

    function goToSignIn() {
      showSignUp.value = false;
      showSignIn.value = true;
    }

    return {
      router,
      loading,
      signedIn,
      checkLoggedIn,
      leftDrawerOpen,
      search,
      searchQuery,
      showAdvanced,
      showDateOptions,
      companyName,
      coreTech,
      productDescription,
      byWebsite,
      byDate,
      filteredOpps,
      fetchSbirSearchResults,
      categories,
      phases,
      programs,
      departments,
      onClear,
      changeDate,
      toggleLeftDrawer,
      showSignIn,
      showSignUp,
      handleRegistrationComplete,
      signinComplete,
      goToRegistration,
      goToSignIn,
    };
  },
};
</script>