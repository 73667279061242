<template>
    <div class="container">
        <button @click="goToPreviousSection" class="nav-button">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </button>
        <div class="placeholders-container">
            <div v-for="(section, index) in sections" :key="index"
                 :class="['placeholder', { 'current': currentSectionId === section.id }]"
                 @click="setCurrentSectionId(section.id)">
                <span class="tooltip">{{ section.name }}</span>
            </div>
        </div>
        <button @click="goToNextSection" class="nav-button">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'SectionPlaceholders',
    props: {
        sections: {
            type: Array,
            required: true
        },
        currentSectionId: {
            type: [String, Number],
            required: true
        }
    },
    mounted() {
        // console.log("SECTIONS:",this.sections);
        // console.log("CURRENT SECTION ID:",this.currentSectionId);
    },
    methods: {
        setCurrentSectionId(id) {
            // Emit an event to notify the parent component
            this.$emit('update:currentSectionId', id);
        },
        goToPreviousSection() {
            const currentIndex = this.sections.findIndex(section => section.id === this.currentSectionId);
            if (currentIndex > 0) {
                this.setCurrentSectionId(this.sections[currentIndex - 1].id);
            }
        },
        goToNextSection() {
            const currentIndex = this.sections.findIndex(section => section.id === this.currentSectionId);
            if (currentIndex < this.sections.length - 1) {
                this.setCurrentSectionId(this.sections[currentIndex + 1].id);
            }
        }
    },
}
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: row;
    align-items: center; /* Align items vertically in the center */
    justify-content: center; /* Center the items horizontally */
}

.placeholders-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.placeholder {
    background-color: transparent;
    border-color: rgb(1, 150, 250);
    border-width: 3px;
    border-style: solid;
    padding: 6px;
    margin: 5px;
    border-radius: 8px;
    width: 5px;
    height: 5px;
    position: relative;
    cursor: pointer;
}

.placeholder:hover {
    border-color: var(--accent);
}

.current, .placeholder.current:hover {
    border-color: rgb(1, 150, 250);
    background-color: rgb(1, 150, 250); /* Blue background for current section */
}

.nav-button {
    background-color: rgb(1, 150, 250); /* Blue color */
    color: white;
    border: none;
    border-radius: 50%; /* Make it a circle */
    padding: 10px; /* Adjust padding to make it more circular */
    margin: 0 25px;
    cursor: pointer;
    font-size: 16px; /* Adjust font size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Fixed width */
    height: 30px; /* Fixed height */
}

.nav-button:hover {
    background-color: var(--accent);
}

.inactive-nav-button {
    background-color: grey; /* Blue color */
    color: white;
    border: none;
    border-radius: 50%; /* Make it a circle */
    padding: 10px; /* Adjust padding to make it more circular */
    margin: 0 25px;
    cursor: not-allowed;
    font-size: 16px; /* Adjust font size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Fixed width */
    height: 30px; /* Fixed height */
}

.tooltip {
  position: absolute;
  visibility: hidden;
  bottom: 130%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.3s;
}

.placeholder:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
</style>
  