<template>
    <div class="editor-container"> 
      <div
        class="editor__floating-menu"
        v-if="tableActive"
        :style="menuStyle"  
      >
      <q-btn
          v-for="option in tableOptions"
          :key="option.name"
          flat
          dense
          class="editor-btn"
          @click="performAction(option.command)"
          @mouseover="showTooltip(option.name)"
          @mouseleave="hideTooltip"
      >
        <div class="btn-content">
          <!-- <component :is="option.icon" class="button-icon"/> -->
          <span class="button-text">{{ option.description.toLowerCase() }}</span>
        </div>
        <q-tooltip v-if="tooltip === option.name">{{ option.description }}</q-tooltip>
      </q-btn>
      </div>
    </div>
  </template>
  
  


  <script>
  import { ref, watch, watchEffect, computed, nextTick, defineProps } from 'vue';
  import {  Table, Columns2, Trash2, ChevronsUp, ChevronsDown, TableCellsMerge, TableCellsSplit } from 'lucide-vue-next';
  
  export default{

    props: {
      editor: {
        type: Object,
        required: true
      },
      editorRef: {
        type: Object,
        required: true
      }
    },
    setup(props) {
        const visible = ref(false);
        const tooltip = ref(null);
        const editor = ref(props.editor);
        const editorRef = ref(props.editorRef)

  const icons = {
    Table, Columns2, Trash2, ChevronsUp, ChevronsDown, TableCellsMerge, TableCellsSplit
  };

  const tableOptions = [
  // {
  //     name: 'insertTable',
  //     icon: Table,
  //     description: 'Insert Table',
  //     command: () => props.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
  //   },
    {
      name: 'addColumnBefore',
      icon: Columns2,
      description: 'Add Column Before',
      command: () => props.editor.chain().focus().addColumnBefore().run()
    },
    {
      name: 'addColumnAfter',
      icon: Columns2,
      description: 'Add Column After',
      command: () => props.editor.chain().focus().addColumnAfter().run()
    },
    {
      name: 'deleteColumn',
      icon: Trash2,
      description: 'Delete Column',
      command: () => props.editor.chain().focus().deleteColumn().run()
    },
    {
      name: 'addRowBefore',
      icon: ChevronsUp,
      description: 'Add Row Before',
      command: () => props.editor.chain().focus().addRowBefore().run()
    },
    {
      name: 'addRowAfter',
      icon: ChevronsDown,
      description: 'Add Row After',
      command: () => props.editor.chain().focus().addRowAfter().run()
    },
    {
      name: 'deleteRow',
      icon: Trash2,
      description: 'Delete Row',
      command: () => props.editor.chain().focus().deleteRow().run()
    },
    {
      name: 'deleteTable',
      icon: Trash2,
      description: 'Delete Table',
      command: () => props.editor.chain().focus().deleteTable().run()
    },
    {
      name: 'mergeCells',
      icon: TableCellsMerge,
      description: 'Merge Cells',
      command: () => props.editor.chain().focus().mergeCells().run()
    },
    {
      name: 'splitCell',
      icon: TableCellsSplit,
      description: 'Split Cell',
      command: () => props.editor.chain().focus().splitCell().run()
    },
    // {
    //   name: 'toggleHeaderColumn',
    //   icon: Columns2,
    //   description: 'Toggle Header Column',
    //   command: () => props.editor.chain().focus().toggleHeaderColumn().run()
    // },
    // {
    //   name: 'toggleHeaderRow',
    //   icon: Columns2,
    //   description: 'Toggle Header Row',
    //   command: () => props.editor.chain().focus().toggleHeaderRow().run()
    // },
    // {
    //   name: 'toggleHeaderCell',
    //   icon: Columns2,
    //   description: 'Toggle Header Cell',
    //   command: () => props.editor.chain().focus().toggleHeaderCell().run()
    // },
  ];

  const menuPosition = ref({ top: 0, left: 0 });
  function updateMenuPosition() {
      nextTick(() => {
        // Find the selection in the document
        const selection = document.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const selectedElement = range.startContainer.parentNode;

          // Check if the selection's parent node is a table or within a table
          const tableElement = selectedElement.closest('table');
          if (tableElement) {
            const tableRect = tableElement.getBoundingClientRect();
            const editorRect = props.editorRef.getBoundingClientRect();
            menuPosition.value = {
              top: tableRect.top  -140,
              left: tableRect.left -115
            };
          } 
        }
      });
    }




  const tableIsActive = ref(false);

  watchEffect(() => {
  if (props.editor.value) {
    tableIsActive.value = props.editor.value.isActive('table');
    
  } 
});

    const tableActive = computed(() => {
        
        return props.editor.isActive('table');
        });

  watch(() => props.editor.isActive('table'), (isActive) => {
    updateMenuPosition();
  });
// Computed style for dynamic menu positioning
    const menuStyle = computed(() => {
    return {
        top: `${menuPosition.value.top}px`,
        left: `${menuPosition.value.left}px`
    };
    });

  function performAction(command) {
    if (!props.editor || !command) return;
    command();
  }

  function showTooltip(name) {
    tooltip.value = name;
  }

  function hideTooltip() {
    tooltip.value = null;
  }

 


  return {
    editor,
    editorRef,
    visible,
    tooltip,
    tableOptions,
    menuPosition,
    menuStyle,
    tableActive,
    menuStyle,
    performAction,
    showTooltip,
    hideTooltip
  };
    }
  }
  </script>
  
  
  <style scoped>
  .table-options {
    display: flex;
    gap: 10px;
    background-color: #f4f4f4;
    padding: 8px;
    border-radius: 8px;
  }
  
  .option {
    position: relative;
  }
  
  .tooltip {
    position: absolute;
    bottom: 100%;
    background-color: black;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    white-space: nowrap;
  }

  .editor-container {
  position: relative; /* Container must be positioned */
}

.editor__floating-menu {
  position: absolute;
  z-index: 100; /* Make sure it floats above other content */
  background: white; /* Background for visibility */
  border: 1px solid #ccc; /* Optional styling */
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Optional styling */
  width: 100%; /* Set the max-width to 50% of the parent */
  overflow-x: auto;
}

.button-max-width {
  max-width: 150px; /* Adjust the max-width as needed */
  white-space: normal;
}
  
 

.editor-btn {
  display: inline-flex; /* Align items in a row */
  align-items: center; /* Center items vertically */
  max-width: 120px; /* Max width of the button */
  white-space: normal; /* Allows text wrapping */
  font-size: 12px; /* Adjust font size as needed */
  text-transform: none; /* Prevents automatic capitalization */
  line-height: 1.2; /* Adjust line spacing */
}

.btn-content {
  display: flex;
  align-items: center; /* Aligns the icon and text vertically */
  overflow: hidden; /* Prevents overflow */
  justify-content: flex-start; /* Ensures content is left-aligned within the button */
}


.button-icon {
  margin-right: 5px; /* Space between icon and text */
  width: 24px; /* Set width of icon */
  height: 24px; /* Set height of icon */
  flex-shrink: 0; /* Prevents icon from shrinking */
}

.button-text {
  flex-grow: 1; /* Allows text to expand and fill space */
  flex-shrink: 1; /* Allows text to shrink if needed */
  text-align: left;
}

.editor-container {
  position: relative; /* Container must be positioned */
}


  </style>
  