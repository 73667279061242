import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faGlobe,
  faFeather,
  faRightFromBracket,
  faUser,
  faLock,
  faWandMagicSparkles,
  faFile,
  faFolder,
  faChevronDown,
  faArrowRight,
  faChevronRight,
  faChevronLeft,
  faPaperPlane,
  faTrash,
  faEnvelope,
  faPlus,
  faDownload,
  faMagnifyingGlass,
  faCheck,
  faQuestion,
  faCircleQuestion,
  faX,
  faCircleXmark,
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons"; // Import the feather icon

import SideBar from "./components/SideBar.vue";
import AidyAssistant from "./components/AidyAssistant.vue";
import GrantAssistant from "./components/GrantAssistant.vue";
import BillAssistant from "./components/BillAssistant.vue";
import StateBillAssistant from "./views/StateLegislation/Components/BillAssistant.vue";
import DynamicForm from "./components/DynamicForm.vue";
import LandingForm from "./components/LandingForm.vue";
import PdfViewer from "./components/PdfViewer.vue";
import SectionNav from "./components/SectionNav.vue";
import ScoreCard from "./components/ScoreCard.vue";
import ShortAnswer from "./components/forms/ShortAnswer.vue";
import LongAnswer from "./components/forms/LongAnswer.vue";
import MultipleChoice from "./components/forms/MultipleChoice.vue";
import CheckAll from "./components/forms/CheckAll.vue";
import UserUpload from "./components/forms/UserUpload.vue";
import DynamicTable from "./components/forms/DynamicTable.vue";
import RenderMarkdown from "./components/forms/RenderMarkdown.vue";
import PopUp from "./components/PopUp.vue";

import SignInComp from "./components/SignInComp.vue";
import RegisterComp from "./components/RegisterComp.vue";

import GrantEdit from "./components/GrantEdit.vue";
import DocumentEditor from "./components/DocumentEditor.vue";
import SectionEditor from "./components/SectionEditor.vue";
import QuestionEditor from "./components/QuestionEditor.vue";
import InputEditor from "./components/InputEditor.vue";

import VueJSONEditor from "./components/VueJSONEditor.vue";

import BoldIcon from "vue-material-design-icons/FormatBold.vue";
import ItalicIcon from "vue-material-design-icons/FormatItalic.vue";
import UnderlineIcon from "vue-material-design-icons/FormatUnderline.vue";
import BulletListIcon from "vue-material-design-icons/FormatListBulleted.vue";
import NumberListIcon from "vue-material-design-icons/FormatListNumbered.vue";
import AlignLeftIcon from "vue-material-design-icons/AlignHorizontalLeft.vue";
import AlignCenterIcon from "vue-material-design-icons/AlignHorizontalCenter.vue";
import AlignRightIcon from "vue-material-design-icons/AlignHorizontalRight.vue";
import ColorFillIcon from "vue-material-design-icons/FormatColorFill.vue";
import "vue-material-design-icons/styles.css";

import { Quasar, Dialog, Notify, QBtn, QInput, QSelect, QRadio } from "quasar";
// Import Quasar styles

// Import icon libraries if needed
// import "./novel_vue/styles/index.css";
// import "./novel_vue/styles/Prosemirror.css";
// import "./novel_vue/styles/tailwind.css";
import "@quasar/extras/material-icons/material-icons.css";
import "./css/global.css";
import "./css/quasar.scss";
import "./css/BillResStyle.css";


library.add(
  faGlobe,
  faFeather,
  faRightFromBracket,
  faUser,
  faLock,
  faWandMagicSparkles,
  faFile,
  faFolder,
  faChevronDown,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faTrash,
  faEnvelope,
  faPlus,
  faDownload,
  faMagnifyingGlass,
  faQuestion,
  faCircleQuestion,
  faCheck,
  faX,
  faCircleXmark,
  faCircleCheck,
  faCircleExclamation
);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.baseURL = "https://backend.aidygrants.com";
// axios.defaults.baseURL = "https://api.dev.aidygrants.com"

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Quasar, {
  plugins: {
    Dialog,
    Notify,
  },
});

// If axios is used as a plugin
app.config.globalProperties.$axios = axios;

app.component("SideBar", SideBar);
app.component("AidyAssistant", AidyAssistant);
app.component("GrantAssistant", GrantAssistant);
app.component("BillAssistant", BillAssistant);
app.component("StateBillAssistant", StateBillAssistant);

app.component("PdfViewer", PdfViewer);
app.component("SectionNav", SectionNav);
app.component("DynamicForm", DynamicForm);
app.component("LandingForm", LandingForm);
app.component("ScoreCard", ScoreCard);
app.component("ShortAnswer", ShortAnswer);
app.component("MultipleChoice", MultipleChoice);
app.component("CheckAll", CheckAll);
app.component("LongAnswer", LongAnswer);
app.component("UserUpload", UserUpload);
app.component("DynamicTable", DynamicTable);
app.component("RenderMarkdown", RenderMarkdown);
app.component("PopUp", PopUp);

app.component("SignInComp", SignInComp);
app.component("RegisterComp", RegisterComp);

app.component("GrantEdit", GrantEdit);
app.component("DocumentEditor", DocumentEditor);
app.component("SectionEditor", SectionEditor);
app.component("QuestionEditor", QuestionEditor);
app.component("InputEditor", InputEditor);

app.component("VueJSONEditor", VueJSONEditor);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("bold-icon", BoldIcon);
app.component("italic-icon", ItalicIcon);
app.component("underline-icon", UnderlineIcon);
app.component("bullet-list-icon", BulletListIcon);
app.component("number-list-icon", NumberListIcon);
app.component("align-left-icon", AlignLeftIcon);
app.component("align-center-icon", AlignCenterIcon);
app.component("align-right-icon", AlignRightIcon);
app.component("color-fill-icon", ColorFillIcon);

app.mount("#app");
