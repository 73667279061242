<template>
  <div>
    <div
      class="col-12 bg-white q-pa-lg"
      style="
        width: 360px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      "
    >
      <div>
        <div class="row justify-center q-mb-md">
          <!-- Centered row with margin bottom -->
          <img src="/logo.svg" style="width: 200px" />
        </div>
        <div class="row justify-center q-ma-md text-h5 text-primary">
          Register
        </div>
        <!-- Centered row with margin bottom -->
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="firstName"
            type="text"
            label="First Name"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="person" color="orange" /> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="lastName"
            type="text"
            label="Last Name"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="person" color="orange" /> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-none">
          <q-input
            outlined
            v-model="email"
            type="text"
            label="Email"
            :rules="[
              (val) => /.+@.+\..+/.test(val) || 'Must be a valid email address',
            ]"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="email" color="orange" /> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="organization"
            type="text"
            label="Organization"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="language" color="orange" /> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="password1"
            :type="isPwd ? 'password' : 'text'"
            label="New Password"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="lock" color="orange" /> </template
            ><template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              ></q-icon> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="password2"
            :type="isPwd ? 'password' : 'text'"
            label="Confirm New Password"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="lock" color="orange" /> </template
            ><template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              ></q-icon> </template
          ></q-input>
        </div>
        <div v-if="error" class="row justify-center text-negative q-ma-md">
          {{ error }}
        </div>
        <!-- Centered row with margin bottom -->
        <div class="row justify-center">
          <q-btn
            flat
            :loading="loading"
            class="bg-primary text-white"
            @click="registerUser"
            label="Register"
            style="width: 100%; height: 50px"
          />
        </div>
        <!-- Centered row -->
        <div class="row justify-between q-mt-md">
          <div
            class="col text-left cursor-pointer"
            @click="goToSignin"
          >
            Sign In
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, toRefs, getCurrentInstance } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { fetchUser, goHome } from "@/api/userApi";

export default {
  name: "RegisterComp",
  props: {
    user: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "coordinator",
    },
    linkToSignIn: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { user, role, linkToSignIn } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const email = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const organization = ref("");
    const password1 = ref("");
    const password2 = ref("");
    const error = ref(""); // Data property for username error
    const loading = ref(false);
    const isPwd = ref(true);

    const grant = ref(null);

    const instance = getCurrentInstance();

    // Converted methods
    const logout = () => {
      store.dispatch("logout");
    };

    const registerUser = async () => {
      const payload = {
        email: email.value,
        first_name: firstName.value,
        last_name: lastName.value,
        organization: organization.value,
        password1: password1.value,
        password2: password2.value,
        grant: grant.value,
        member_type: role.value,
      };

      try {
        loading.value = true;
        await axios.post("/api/v1/register/", payload);
        await store.dispatch("login", {
          username: email.value,
          password: password1.value,
        });
        goHome(router);
        // Emitting an event after navigating home
        instance.emit('registrationComplete', {});
      } catch (err) {
        console.error(err);
        loading.value = false;
        // If the error response has a message, display it; otherwise, display a default message
        error.value =
          err.response && err.response.data && err.response.data.detail
            ? err.response.data.detail
            : "An unexpected error occurred. Please try again.";
      }
    };

    const goToSignin = () => {
      if (linkToSignIn.value) {
        router.push({ name: "SignIn" });
      } else {
        instance.emit('goToSignIn', {});
      }
    };

    return {
      email,
      firstName,
      lastName,
      organization,
      password1,
      password2,
      error,
      registerUser,
      isPwd,
      loading,
      router,
      linkToSignIn,
      goToSignin,
    };
  },
};
</script>

<style>

</style>
