<template>
  <div>
    <div
      class="col-12 bg-white q-pa-lg"
      style="
        width: 360px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      "
    >
      <div>
        <div class="row justify-center q-mb-md">
          <!-- Centered row with margin bottom -->
          <img src="/logo.svg" style="width: 200px" />
        </div>
        <div class="row justify-center q-ma-md text-h5 text-primary">
          Sign In
        </div>
        <!-- Centered row with margin bottom -->
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="email"
            type="email"
            label="Email"
            style="width: 100%"
            ><template v-slot:prepend>
              <q-icon name="mail" color="orange" /> </template
          ></q-input>
        </div>
        <div class="row-1 justify-center q-mb-md">
          <q-input
            outlined
            v-model="password"
            :type="isPwd ? 'password' : 'text'"
            label="Password"
            style="width: 100%"
          >
            <template v-slot:prepend>
              <q-icon name="lock" color="orange" /> </template
            ><template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              ></q-icon> </template
          ></q-input>
        </div>
        <div v-if="error" class="row justify-center text-negative q-ma-md">
          {{ error }}
        </div>
        <!-- Centered row with margin bottom -->
        <div class="row justify-center">
          <q-btn
            flat
            :loading="loading"
            class="bg-primary text-white"
            @click="signIn"
            label="Sign In"
            style="width: 100%; height: 50px"
          />
        </div>
        <div class="row justify-center q-mt-md">
          <div
            class="col text-left cursor-pointer"
            @click="goToRegister"
          >
            Sign Up
          </div>
          <div
            class="col text-center cursor cursor-pointer"
            @click="router.push({ name: 'Forgot' })"
          >
            Forgot Password?
          </div>
        </div>
        <!-- Centered row -->
      </div>
    </div>
  </div>


  
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { fetchUser, goHome } from "@/api/userApi";

// Defining props
const { linkToReg } = defineProps({
  linkToReg: {
    type: Boolean,
    default: true,
  }
});

const email = ref("");
const password = ref(""); // password state
const error = ref(null); // error state
const isPwd = ref(true); // isPwd state
const loading = ref(false); // loading state

const router = useRouter();
const store = useStore();

const instance = getCurrentInstance();

const goToRegister = () => {
  if (linkToReg) {
    router.push({ name: "Register" });
  } else {
    instance.emit('goToReg', {});
  }
};

// SignIn method
const signIn = async () => {
  loading.value = true;
  // Prepare the data to be sent
  const userData = {
    username: email.value,
    password: password.value,
  };

  // Dispatch the Vuex action
  try {
    await store.dispatch("login", userData);
    goHome(router);
    instance.emit('signinComplete', {});
  } catch (signInError) {
    // Handle errors
    if (
      signInError.response &&
      signInError.response.data &&
      signInError.response.data.error
    ) {
      error.value = signInError.response.data.error;
    } else {
      error.value = "An error occurred during login.";
    }
    loading.value = false;
  }
};
</script>

<style>

</style>
