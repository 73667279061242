<template>
    <div class="q-pa-md">
        <q-btn @click="showCreateTemplateDialog = true" label="Create New Template" color="primary" class="q-mb-md" />
        <q-dialog v-model="showCreateTemplateDialog">
        <q-card>
            <q-card-section>
            <div class="text-h6">Create New Template</div>
            </q-card-section>

            <q-card-section>
            <q-input v-model="newTemplateName" filled label="Template Name" />
            </q-card-section>

            <q-card-section align="right">
            <q-btn flat label="Cancel" color="primary" @click="showCreateTemplateDialog = false" />
            <q-btn flat label="Submit" color="primary" @click="createTemplate" />
            </q-card-section>
        </q-card>
        </q-dialog>
      <q-list bordered v-if="templates.length > 0 && !loading">
        <q-item v-for="template in templates" :key="template.id" clickable @click="selectTemplate(template)">
          <q-item-section>{{ template.template_name }}</q-item-section>
        </q-item>
      </q-list>
      <q-spinner v-if="loading" color="primary" />
      <div v-if="!templates.length && !loading">No templates found.</div>
  
      <div v-if="selectedTemplate">
        <q-separator spaced class="q-my-md" />
        <Editor :defaultValue="selectedTemplate.template_data" @update:currentValue="handleEditorUpdate" />
        <q-btn label="Save Template" @click="saveTemplate" class="q-mt-md" color="primary" />
      </div>

      
    </div>
  </template>
  
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import Editor from "@/components/TipTap/Editor.vue";
  import { useQuasar } from 'quasar';
  import axios from 'axios';
  
  const $q = useQuasar();
  const templates = ref([]);
  const selectedTemplate = ref(null);
  const updateTemplate = ref(null);
  const loading = ref(false);
  const showCreateTemplateDialog = ref(false);
  
const fetchTemplates = async () => {
  loading.value = true; // Assuming 'loading' is a reactive reference to track loading state
  try {
    const response = await axios.post('/api/v1/fetch-report-templates/');
    console.log(response.data)
    templates.value = response.data; 
    console.log(templates)
  } catch (error) {
    console.error('Failed to fetch templates:', error);
    $q.notify({
      type: 'negative',
      message: 'Error loading templates'
    });
  } finally {
    loading.value = false;
  }
};
const selectTemplate = (template) => {
  if (selectedTemplate.value && selectedTemplate.value.id === template.id) {
    selectedTemplate.value = null;
  } else {
    selectedTemplate.value = template;
    updateTemplate.value = template;
  }
};
  
  const handleEditorUpdate = (newContent) => {
 
    if (updateTemplate.value) {
      updateTemplate.value.template_data = newContent;
    }
  };
  const saveTemplate = async () => {
  if (updateTemplate.value) {
    try {
      await axios.put(`/api/v1/save-report-template/${updateTemplate.value.id}/`, updateTemplate.value);
      $q.notify({
        type: 'positive',
        message: 'Template saved successfully'
      });
    } catch (error) {
      console.error('Failed to save template:', error);
      $q.notify({
        type: 'negative',
        message: 'Failed to save template: ' + error.message
      });
    }
  }
};

  
  onMounted(fetchTemplates);


  const newTemplateName = ref('');
  const createTemplate = async () => {
  if (newTemplateName.value.trim()) {
    try {
      const response = await axios.post('/api/v1/create-report-template/', {
        template_name: newTemplateName.value,
        template_data: {}
      });
      templates.value.push(response.data);
      selectedTemplate.value = response.data;
      showCreateTemplateDialog.value = false;
      newTemplateName.value = '';
      $q.notify({
        type: 'positive',
        message: 'Template created successfully'
      });
    } catch (error) {
      console.error('Failed to create template:', error);
      $q.notify({
        type: 'negative',
        message: 'Error creating template: ' + error.message
      });
    }
  } else {
    $q.notify({
      type: 'warning',
      message: 'Template name cannot be empty'
    });
  }
};
  </script>
  