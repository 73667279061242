export async function extractUniqueAttributesAndConvertToList(responseData, attributeName, targetRefList) {
    const uniqueKeys = new Set();
    responseData.forEach(opp => {
      let attributeValues = opp[attributeName];
      if (typeof attributeValues === 'object' && attributeValues !== null && !Array.isArray(attributeValues)) {
        Object.keys(attributeValues).forEach(key => {
          // Add key to the set if it's not an empty string
          if (key !== '') {
            uniqueKeys.add(key);
          }
        });
      } else if (typeof attributeValues === 'string' && attributeValues !== '') {
        // Add attribute value to the set if it's not an empty string
        uniqueKeys.add(attributeValues);
      }
    });
  
    // Convert the set of keys into an array, sort them, and map to the desired list format,
    // but filter out any empty strings just in case
    targetRefList.value = Array.from(uniqueKeys).filter(key => key !== '').sort().map(key => ({
      text: key,
      selected: false,
    }));
}

export async function formatFilters(filterOptions, targetRefList) {
  const uniqueKeys = new Set();
  
  Object.keys(filterOptions).forEach(key => {
    uniqueKeys.add(key);
  });
  // Convert the set of keys into an array, sort them, and map to the desired list format,
  // but filter out any empty strings just in case
  targetRefList.value = Array.from(uniqueKeys).filter(key => key !== '').sort().map(key => ({
    text: key,
    selected: false,
  }));
}

export function filterOpportunitiesBySelections(opps, selections) {
  if (!opps || opps.length === 0) {
    return [];
  }

  return opps.filter(opp => {
    return selections.every(selection => {
      // Extract selected items for this selection category (e.g., categories, phases)
      const selectedItems = selection.items
        .filter(item => item.selected)
        .map(item => item.text);

      // If no items are selected in this category, skip filtering by this category
      if (selectedItems.length === 0) {
        return true;
      }

      // Adjust the property name in opp to match the selection type (e.g., opp.categories is an object)
      const oppAttributeValues = opp[selection.type];
      if (Array.isArray(oppAttributeValues)) {
        // If the attribute is an array, check for intersection
        return oppAttributeValues.some(value => selectedItems.includes(value));
      } else if (typeof oppAttributeValues === 'object') {
        // If the attribute is an object, check if any selected item key exists
        return selectedItems.some(item => item in oppAttributeValues);
      } else {
        // For string values, directly compare
        return selectedItems.includes(oppAttributeValues);
      }
    });
  });
}