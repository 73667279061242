<template>
  <div class="body">
    <div
      class="col-12 bg-white q-pa-lg"
      style="
        width: 360px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      "
    >
      <!-- Full width column -->
      <div class="row justify-center q-mb-md">
        <!-- Centered row with margin bottom -->
        <img src="/logo.svg" style="width: 200px" />
      </div>
      <div class="row justify-center q-mb-md text-grey-8 text-center">
        Please enter the email you used to make your account
      </div>
      <!-- Centered row with margin bottom -->
      <div class="row-1 justify-center q-mb-md">
        <q-input
          outlined
          v-model="email"
          type="email"
          label="Email"
          style="width: 100%"
          ><template v-slot:prepend>
            <q-icon name="mail" color="orange" /> </template
        ></q-input>
      </div>
      <!-- Error message -->
      <!-- Centered row with margin bottom -->
      <div class="row justify-center">
        <q-btn
          flat
          class="bg-primary text-white text-weight-bold"
          :loading="loading"
          @click="resetPassword"
          label="Reset Password"
          style="width: 100%; height: 50px"
        ></q-btn>
      </div>
      <div class="row justify-center q-mt-md text-red text-center">
        {{ registerError }}
      </div>
      <!-- Centered row -->
    </div>
    <q-dialog v-model="alert">
      <q-card class="q-pa-sm">
        <q-card-section>
          <div class="text-h6 text-primary">Success!</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Password reset email sent!
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            label="OK"
            color="primary"
            @click="router.push({ name: 'SignIn' })"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

export default {
  setup() {
    const email = ref("");
    const registerError = ref("");
    const loading = ref(false);
    const alert = ref(false);
    const router = useRouter();

    const resetPassword = async () => {
      try {
        loading.value = true;
        registerError.value = "";
        // Make a backend call using Axios to send password reset email
        await axios.post("/api/v1/send-reset-email/", { email: email.value });
        loading.value = false;

        // Show success message or redirect to a success page
        // alert("Password reset email sent!");
        alert.value = true;
      } catch (error) {
        console.log(error);
        registerError.value = error.response.data.detail;
        // Handle error, show error message, or redirect to an error page
        loading.value = false;
        // alert("Failed to reset password. Got error: ", error.error);
      }
    };

    return {
      email,
      resetPassword,
      registerError,
      loading,
      alert,
      router,
    };
  },
};
</script>

<style scoped>
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(
    to top,
    #fa9600 0%,
    var(--accent-semi) 50%,
    #ffffff 100%
  );
}
</style>
