<template>
  <div :class="$style.sidebar">
    <div :class="$style.sidebar1">
      <div :class="$style.topleftBig">
        <template>
          <div :class="$style.sidebar">
            <div :class="$style.sidebar1">
              <div :class="$style.topleftBig">
                <img
                  :class="$style.logoIcon"
                  alt=""
                  src="/logo.svg"
                  @click="this.$router.push({ name: 'User' })"
                />
                <div class="organization-name">Your Organization Name</div>
              </div>
              <!-- Rest of the code -->
            </div>
          </div>
        </template>
      </div>
      <div :class="[$style.menu, $style.justifyStart]">
        <div
          :class="[
            $style.menuItem1,
            { [$style.active]: activeElement === 'Dashboard' },
          ]"
        >
          <div
            :class="[$style.menuItems]"
            @click="handleSidebarClick('Dashboard')"
          >
            <font-awesome-icon
              :class="$style.iconiconForMenu"
              :icon="['fas', 'folder']"
            />
            <div :class="$style.narrative">Dashboarrd</div>
            <h6>Dashboard</h6>
          </div>
        </div>
      </div>
      <div :class="[$style.menu, $style.justifyEnd]">
        <div
          :class="[
            $style.menuItem1,
            { [$style.active]: activeElement === 'User' },
          ]"
          @click="handleSidebarClick('User')"
        >
          <div @click="toggleDropdown" :class="$style.menuItems">
            <font-awesome-icon
              :class="$style.iconiconForMenu"
              :icon="['fas', 'user']"
            />
            <div :class="$style.narrative">My Acccount</div>
          </div>
        </div>
        <div :class="$style.menuItem1" @click="signOut">
          <div @click="toggleDropdown" :class="$style.menuItems">
            <font-awesome-icon
              :class="$style.iconiconForMenu"
              :icon="['fas', 'right-from-bracket']"
            />
            <div :class="$style.narrative">Sign Oout</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SideBar",
  data() {
    return {
      isDropdownOpen: false,
      activeElement: null,
    };
  },
  props: {
    documents: Array,
  },
  methods: {
    ...mapActions(["logout"]), // Map the `login` Vuex action
    handleSidebarClick(routeName) {
      this.activeElement = routeName;
      this.$router.push({ name: routeName });
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    navigateToDocument(id) {
      this.$router.push({ name: "Document", params: { id: id } });
    },
    signOut() {
      this.logout()
        .then(() => {
          this.$router.push({ name: "SignIn" });
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    },
  },
};
</script>

<style module>
.logoIcon {
  /* Ensures the logo keeps its aspect ratio and fits within the container */
  object-fit: contain; /* This now correctly applies to the image */
  max-width: 100%; /* Will not exceed the container's width */
  height: auto; /* Maintains the aspect ratio */
}
.topleftBig {
  width: 100%;
  /* Removed overflow: hidden to allow the logo to show fully */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-7xs) var(--padding-sm);
  box-sizing: border-box; /* This ensures padding is included in the width */
  border-bottom: 1px solid var(--accent);
}
.iconiconForMenu {
  position: relative;
  width: 24px;
  height: 24px;
}
.application {
  position: relative;
  letter-spacing: 0.08em;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.menuItems {
  border-radius: var(--br-5xs);
  font-size: 18px;
  /* background-color: var(--accent); */
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.subList {
  width: 100%;
  /* margin-left: 40px;
  align-self: flex-end; */
}
.subMenuItems {
  padding-left: 20px; /* Adjust the padding as needed */
  border-radius: var(--br-5xs);
  /* background-color: var(--accent); */
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-xs);
}

.subMenuItems:hover {
  background-color: #ffcc80; /* Light orange */
}

.subMenuItems:active {
  background-color: var(--accent);
  color: white;
}

.chevronRotate {
  transform: rotate(90deg);
  transition: transform 0.3s; /* This will make the rotation smooth */
}
.narrative {
  flex: 1;
  margin-left: 10px;
  font-family: var(--title-2);
  position: relative;
  font-weight: 400;
}

/* .narrative:hover {
  font-weight: 500;
} */

.menuItem1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  color: var(--accent);
  /* background-color: var(--accent); */
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: background-color 0.1s;
}

.menuItem1:hover {
  background-color: var(--accent-semi);
  font-weight: 500;
}

.active {
  background-color: var(--accent-semi);
}

.menu {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.divider {
  align-self: stretch;
  position: relative;
  background-color: var(--color-palettes-indigo-50);
  height: 1px;
}
.sidebar1 {
  /* background-color: var(--accent-semi); */
  background-color: rgba(0, 0, 0, 0.04);
  border-right: 1px solid var(--color-palettes-indigo-50);
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  justify-content: space-between; /* Adjust to ensure footer is pushed to the bottom */
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xl) var(--padding-base) var(--padding-13xl);
  gap: var(--gap-5xl);
}
.sidebar {
  width: 245px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0px;
  height: 100vh;
}
</style>
