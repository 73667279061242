<template>
  <div class="container" style="containerStyle">
    <main class="content" :style="contentStyle">
      <a href="https://safgrantservices.com/" target="_blank">
        <img :src="`/saf-logo.png`" alt="Top Image" class="top-center-image">
      </a>
      <div class="section-container">
        <div v-if="!loading && sections">
          <div v-if="currentSectionIndex < sections.length">
            <div class="section-details">
              <landing-form
                ref="dynamicFormRef"
                :questions="sections[currentSectionIndex].questions"
                :section_name="sections[currentSectionIndex].name"
                :formAnswers="answers"
                :docId="this.docId"
                :app_id="this.appId"
                :doc_name="this.document.display_name"
                :final_section=true
                :docs_uploaded="this.docs_uploaded"
                demo_link="https://safgrantservices.com/"
                demo_text="Schedule a consultation"
                demo_email="reap@safgrantservices.com"
                org_name="SAF Grant Services"
                @formSubmitted="generatePDF"
              />
            </div>
          </div>
        </div>
        <div v-else class="spinner-container">
          <div class="spinner"></div>
        </div>
      </div>
      <div class="footer">
        <span class="powered-by-text">Powered by</span>
        <a href="https://aidygrants.com/" target="_blank">
          <img src="../assets/aidy_lockup.png" alt="Logo" class="bottom-center-logo">
        </a>
      </div>
    </main>
    <aside>
      <aidy-assistant v-if="!loading && sections"
        :section_id="section_id"
        :sidebarInitialState="isSidebarOpen"
        :q_and_a="sections[currentSectionIndex].questions"
        @update:isOpen="handleSidebarToggle"
      ></aidy-assistant>
    </aside>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "Eligibility",
  data() {
    return {
      grant: null,
      isSidebarOpen: false, // Initial state of the sidebar
      section_id: 0,
      document: null,
      sections: null,
      answers: {},
      docId: null,
      appId: null,
      currentSectionIndex: 0,
      allAnswers: [],
      loading: false,
      docs_uploaded: false,
    };
  },
  created() {
    this.getEligiblityDoc();
  },
  computed: {
    contentStyle() {
      // If the sidebar is open, add 40vh margin to the right
      if (this.isSidebarOpen) {
        return {
          paddingRight: "320px",
        };
      } else {
        return {
          paddingRight: "0px",
        };
      }
    },
  },
  methods: {
    handleSidebarToggle(isOpen) {
      this.isSidebarOpen = isOpen; // Keep track of the sidebar state
    },
    handleSectionId(sectionId) {
      this.section_id = sectionId;
    },
    async getEligiblityDoc() {
      try {
        const response = await axios.get(`api/v1/get-eligibility-quiz/`);
        this.document = response.data;
        this.docId = this.document.id;

        // After the document is loaded, load each section
        if (this.document.sections && this.document.sections.length > 0) {
          this.sections = this.document.sections;
          this.$emit("update:sectionId", this.sections[0].id); // Emit an event when toggled
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    },
    handleSubmit(formAnswers) {
      // console.log("Form Answers:", formAnswers);
      // Send the answers to your backend or perform other actions
      // Then load the next section's questions if any
      this.allAnswers[this.currentSectionIndex] = formAnswers;
      if (this.currentSectionIndex < this.sections.length) {
        this.currentSectionIndex++;
      }
      if (this.$refs.dynamicFormRef) {
        this.$refs.dynamicFormRef.formSubmitted = true;
      }
    },
  },
});
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
}

.content {
  height: 100%;
  width: 90%;
  /* padding-left: 40px; */
  box-sizing: border-box;
  transition: padding-right 0.3s ease;
  align-items: left;
  /* Smooth transition for the margin change */
}

.top-center-image {
  display: block;
  margin: 40px auto 0px; /* Centers the image */
  width: 30%;
  max-width: 300px;
  min-width: 200px;
}

.section-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  text-align: center; /* Center aligns the content */
  padding: 20px 0; /* Adds padding at top and bottom */
}

.powered-by-text {
  margin-right: 10px; /* Spacing between text and logo */
  vertical-align: middle; /* Aligns text with the logo */
}

.bottom-center-logo {
  width: 14%;
  max-width: 200px;
  vertical-align: middle; /* Aligns logo with the text */
}

</style>
