<template>
  <div class="label-and-spinner">
    <!-- Spinner element -->
    <span class="spinner" v-if="isLoading"></span>
    <div v-html="renderMarkdownWrapper(question.question_text)"></div>
  </div>
  <div class="checkbox-container">
    <label
      v-for="input in question.inputs"
      :key="input.id"
      class="checkbox-option"
    >
      <q-checkbox
        color="primary"
        keep-color
        indeterminate-icon="check_box_outline_blank"
        unchecked-icon="check_box_outline_blank"
        v-model="answers[input.name]"
        :id="`choice-${input.id}`"
        :value="input.label_text"
        :label="input.label_text"
        @click="toggleCheckbox(input.name)"
      />
    </label>
  </div>
  <div v-if="predictFailed" class="predict-failed-text">
    We couldn't find an answer to this question in your docs; please answer it
    manually
  </div>
  <div v-if="requiredFlag && question.required" class="required-flag-text">
    This question is required
  </div>
  <!-- <button @click.prevent="predict">Predict Q</button> -->
</template>

<script>
import axios from "axios";
import { renderMarkdown } from "@/helpers/helpers";
export default {
  name: "CheckAll",
  props: {
    question: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      required: false,
      default: () => ({}), // Default to an empty object
    },
    draft_doc_id: {},
  },
  data() {
    return {
      isLoading: false,
      predictFailed: false,
      requiredFlag: false,
    };
  },
  methods: {
    renderMarkdownWrapper(text) {
      return renderMarkdown(text);
    },
    // Converts nulls to
    getDefault(inputName) {
      return false;
      if (!(inputName in this.answers)) {
        return false;
      }
      return this.answers[inputName] ? this.answers[inputName] : false;
    },
    toggleCheckbox(inputName) {
      // console.log("CHECKBOX TOGGLED:", inputName);
      const updatedVal = {};
      updatedVal[inputName] = this.answers[inputName];
      // Now that this q has been answered, set all other inputs to false that don't already have a value
      for (const input of this.question.inputs) {
        // console.log("CHECKING INPUT:", input.name);
        if (
          input.name !== inputName &&
          !this.answers.hasOwnProperty(input.name)
        ) {
          // console.log("ADDING EMPTY INPUT:", input.name);
          updatedVal[input.name] = false;
        }
      }
      // console.log("UPDATED CA VALS:", updatedVal);
      this.$emit("answerUpdate", this.question, updatedVal);
    },
    togglePredictFailed() {
      this.predictFailed = !this.predictFailed;
    },
    toggleRequiredFlag(value) {
      if (value === undefined) {
        // If no value is provided, toggle the flag
        this.requiredFlag = !this.requiredFlag;
      } else {
        // If a value is provided, set the flag to that value
        this.requiredFlag = value;
      }
    },
    setInputValue(inputName, value) {
      const updatedVal = {};
      updatedVal[inputName] = value;
      this.$emit("answerUpdate", this.question, updatedVal);
      this.isLoading = false;
    },
    answered() {
      for (const input of this.question.inputs) {
        if (
          this.answers.hasOwnProperty(input.name) &&
          this.answers[input.name]
        ) {
          return true;
        }
      }
      return false;
    },
    async predict() {
      if (!this.answered()) {
        this.isLoading = true;
        try {
          // Construct the URL with query parameters
          // console.log("PREDICTING QUESTION ID:",question.id);
          const url = `api/v1/predict-question/?q_id=${this.question.id}&doc_id=${this.draft_doc_id}`;

          await axios
            .get(url)
            .then((response) => {
              const answers = response.data;
              // console.log(
              //   `Question ${this.question.id} predicted successfully:`,
              //   answers
              // );
              this.isLoading = false;
              if (Object.keys(answers).length === 0) {
                this.togglePredictFailed();
              } else {
                Object.entries(answers).forEach(([inputName, value]) => {
                  this.setInputValue(inputName, value);
                });
              }
            })
            .catch((error) => {
              console.error(
                `Error predicting question ${this.question.id}:`,
                error.response ? error.response.data : error.message
              );
            });
        } catch (error) {
          console.error(
            `Error in predictSection for question ${this.uestion.id}:`,
            error
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.checkbox-container.two-column {
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;
}

.checkbox-container.two-column .checkbox-option {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 0.25rem;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem; /* Adjust margin for spacing */
}

.checkbox-option {
  flex: 0 0 50%; /* Each option takes up 50% of the container width */
  box-sizing: border-box;
  padding: 0.25rem; /* Adjust padding for spacing */
}

.question-text {
  font-family: var(--title-2);
  font-size: 1rem;
  color: var(--text);
  margin-bottom: 0.5rem;
  text-align: left;
}

.label-and-spinner {
  display: flex;
  align-items: center; /* Aligns label and spinner vertically */
  position: relative;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid rgb(1, 150, 250); /* Blue */
  border-right: 4px solid rgb(1, 150, 250); /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 2s linear infinite;
  margin-left: 10px; /* Adjust as needed */
  position: absolute; /* Position the spinner absolutely within its container */
  left: -40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.label-text {
  font-family: var(--title-2);
  font-size: 0.9rem;
  color: var(--text);
  margin-bottom: 0.25rem;
  text-align: left;
}
.form-group {
  border: 2px solid var(--accent); /* Set the border color for the fieldset */
  padding: 10px; /* Add padding around the content */
  margin-bottom: 1rem; /* Space between each form group if stacked */
}

.form-group legend {
  color: var(--text); /* Set the color of the legend text */
  padding: 0 10px; /* Add some padding on the sides of the legend text */
  margin-bottom: 0.5rem; /* Space below the legend */
  width: auto; /* Make sure the width is only as wide as the content */
}

.checkbox-option {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem; /* Space between each checkbox option */
  cursor: pointer;
}

.checkbox-input {
  opacity: 0;
  position: absolute;
}

.checkbox-custom {
  display: inline-block;
  width: 20px; /* Width of the custom checkbox */
  height: 20px; /* Height of the custom checkbox */
  margin-right: 10px; /* Space between the checkbox and the label */
  border: 2px solid var(--accent); /* Border color of the checkbox */
  border-radius: 25%; /* Circle shape for a rounded checkbox */
  cursor: pointer;
  position: relative;
}

.checkbox-input:checked + .checkbox-custom {
  background-color: var(--accent); /* Background color when checked */
  border-color: var(--accent); /* Border color when checked */
}

.checkbox-input:checked + .checkbox-custom::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px; /* Width of the inner square */
  height: 12px; /* Height of the inner square */
  background-color: var(
    --background-color
  ); /* Color for the checkmark or inner square */
  border-radius: 2px; /* Optional: if you want rounded corners inside the checkbox */
}

label {
  cursor: pointer;
  user-select: none;
}

.predict-failed-text {
  font-family: var(--title-2);
  color: var(--accent);
  font-size: 12px;
}

.required-flag-text {
  font-family: var(--title-2);
  color: var(--q-negative);
  font-size: 12px;
}
</style>
