<template>
  <div class="markdown-renderer">
    <div
      v-html="renderMarkdownWrapper(question.question_text)"
      class="markdown-body"
    ></div>
  </div>
</template>
  
  <script>
import { renderMarkdown } from "@/helpers/helpers";

export default {
  name: "RenderMarkdown",
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  methods: {
    renderMarkdownWrapper(text) {
      return renderMarkdown(text);
    },
  },
};
</script>
  
<style scoped>
.markdown-body {
  /* Add your markdown styles here */
  line-height: 1.6;
  color: var(--text);
  border-radius: 5px;
  font-family: var(--title-2);
}
</style>