<template>
    <div class="row">
        <q-select filled v-model="vote_obj" :options="options" label="Action" :dense="dense" :options-dense="denseOpts"
            style="width: 50%" class="ellipsis" />
    </div>
    <div v-if="vote_obj != null" class="row justify-between">
        <div class="col q-ml-lg">
            <h6 class="text-weight-bold">Yeas</h6>
            <q-list class="q-pt-lg">
                <Member v-for="elem in yeas" :key="elem.name" :member="elem" />
            </q-list>
        </div>
        <q-separator vertical inset></q-separator>
        <div class="col q-ml-lg">
            <h6 class="text-weight-bold">Nays</h6>
            <q-list class="q-pt-lg">
                <Member v-for="elem in nays" :key="elem.name" :member="elem" />
            </q-list>
        </div>
        <q-separator vertical inset></q-separator>
        <div class="col q-ml-lg">
            <h6 class="text-weight-bold">Not Voting</h6>
            <q-list class="q-pt-lg">
                <Member v-for="elem in not_voting" :key="elem.name" :member="elem" />
            </q-list>
        </div>
    </div>
</template>


<script>
import Member from './Member.vue';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

export default {
    name: 'VoteDisplay',
    props: {
        votes: {
            type: Array,
            required: true
        }
    },
    components: {
        Member
    },
    setup(props) {

        const { votes } = props;

        console.log(votes)

        var options_in = votes.map((vote, ind) => {
            return {
                label: vote.motion_text,
                value: ind
            }
        });

        const yeas = ref([]);
        const nays = ref([]);
        const not_voting = ref([]);
        const vote_obj = ref(options_in[0]);
        const options = ref(options_in);
        const dense = ref(true);
        const denseOpts = ref(true);

        // onMounted(async () => {
        //     const response = await axios.get('/api/v1/get-votes-from-actions', {
        //         params: {
        //             action_id: 271,
        //         },
        //     })

        //     const data = response.data;
        //     yeas.value = data.yeas;
        //     nays.value = data.nays;
        //     not_voting.value = data.not_voting;

        // });

        const onVoteChange = (vote_val) => {
            yeas.value = votes[vote_val.value].yeas;
            nays.value = votes[vote_val.value].nays;
            not_voting.value = votes[vote_val.value].not_voting;
        }

        watch(vote_obj, onVoteChange, { immediate: true })

        return {
            yeas,
            nays,
            not_voting,
            vote_obj,
            options,
            dense,
            denseOpts
        }
    }
}
</script>