<template>
  <q-layout view="hHh lpR fFf">
    <q-page-container v-if="bill" class="column bg-tertiary items-center" style="overflow: auto">

      <div class="row justify-between" style="width: 100%; height: 50px;">
        <q-btn flat label="Back to Search" icon="arrow_back_ios" class="q-mr-md q-mt-md" @click="backToSearch"></q-btn>
        <q-btn v-if="!signedIn" color="primary" label="Sign In" class="q-mr-md q-mt-md" @click="showSignIn = true">
        </q-btn>
        <q-btn v-else rounded color="primary" label="" class="q-mr-md q-mt-md" @click="$router.push({ name: 'User' })">
          <q-icon name="person" color="white" />
          <q-tooltip class="bg-primary text-white text-body2" :offset="[10, 10]">My Account</q-tooltip>
        </q-btn>
      </div>

      <div style="max-width: 1500px;">
        <q-card class="q-pa-md q-ma-md">
          <q-card-section horizontal class="justify-between">
            <q-card-section>
              <div class="q-gutter-y-md">
                <div class="row">
                  <q-img
                    :src="`https://aidy-public-downloads.s3.amazonaws.com/leg/state_flags/${bill.state_code}.svg`"
                    spinner-color="white"
                    style="height: 100px; max-width: 100px; border-radius: 50%;"
                    class='col q-pa-sm'
                  ></q-img>
                  <div class="col block q-pl-lg">
                    <h5 class=" cursor-pointer">{{ bill.title }}</h5>
                    <div class="row justify-start items-center q-gutter-sm">
                      <q-chip class="text-weight-bold" color="accent" text-color="white">{{ bill.state_abbr }}</q-chip>
                      <h6>{{ bill.bill_number }}</h6>
                      <q-chip v-for="(committee, index) in bill.committees" :key="index"
                        class="text-weight-bold q-mr-sm" color="accent" text-color="white">
                        {{ committee }}
                      </q-chip>
                    </div>
                  </div>
                </div>
                <p class="text-weight-regular" style="text-align: justify; letter-spacing: 0.02em;">{{ bill.summary }}
                </p>
              </div>
              <div class="q-gutter-sm row inline items-center full-width">
                <div v-if="bill.actions.length > 0" class="col">
                  <b>Latest Action: </b>
                  <q-chip icon="event" class="text-weight-bold" color="primary" text-color="white" size="sm">
                    {{ formatDate(bill.actions[0].date) }}
                  </q-chip>
                  {{ bill.actions[0].description }}
                </div>
                <div class="justify-end flex col">
                  <q-btn rounded flat color="white" no-caps @click="downloadBillText" target="_blank"
                    class="q-pr-none q-mr-sm">
                    <q-icon name="download" color="black" />
                  </q-btn>
                  <q-btn rounded flat color="white" no-caps :href="this.bill.sources[0].url" target="_blank"
                    class="q-pl-none q-ml-sm">
                    <q-icon name="link" color="black" />
                  </q-btn>
                </div>
              </div>
            </q-card-section>

          </q-card-section>
        </q-card>

        <q-tabs v-model="tab" dense class="row text-grey q-mt-lg" active-color="primary" indicator-color="primary"
          align="left" narrow-indicator>
          <q-tab name="content" label="Content"></q-tab>
          <q-tab name="information" label="Information"></q-tab>
        </q-tabs>

        <q-separator></q-separator>

        <q-tab-panels v-model="tab" animated class="shadow-2 rounded-borders" style="height: 1000px;">
          <q-tab-panel name="content">
            <StateBill :id="bill_id" />
          </q-tab-panel>
          <q-tab-panel name="information">
            <div class="row justify-between">
              <div class="col q-ml-lg">
                <h6 class="text-weight-bold">Sponsors</h6>
                <q-list class="q-pt-lg">
                  <q-item clickable v-ripple v-for="(sponsor, index) in bill.sponsors" :key="index">
                    <q-item-section>
                      <q-item-label lines="1">{{ sponsor }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
              <q-separator vertical inset></q-separator>
              <div class="col q-ml-lg">
                <h6 class="text-weight-bold">Actions</h6>
                <q-list>
                  <q-stepper v-model="action_step" ref="stepper" color="primary" vertical animated
                    style="box-shadow: none !important">
                    <q-step v-for="(step, index) in bill.actions" :key="index" :name="index + 1"
                      :title="step.description" :caption="formatDate(step.date)" :icon="step.icon"
                      :done="action_step > bill.actions.length" inactive-color="accent"
                    >
                      <q-stepper-navigation>
                        <q-btn v-if="action_step < bill.actions.length" @click="$refs.stepper.next()" color="primary"
                          label="Previous Action" />
                        <q-btn v-if="action_step > 1" flat color="primary" @click="$refs.stepper.previous()"
                          label="Next" class="q-ml-sm" />
                      </q-stepper-navigation>
                    </q-step>
                    <!-- <template v-slot:navigation>
                      
                    </template> -->
                  </q-stepper>
                </q-list>
              </div>
            </div>
          </q-tab-panel>

        </q-tab-panels>
      </div>
    </q-page-container>
    <div v-else class="flex flex-center" style="height: 100%; padding-top: 50vh;">
      <q-spinner color="primary" :size="120"></q-spinner>
    </div>
  </q-layout>

  <q-dialog v-model="showSignIn">
    <sign-in-comp @signinComplete="signinComplete" @goToReg="goToRegistration" :linkToReg="false"></sign-in-comp>
  </q-dialog>

  <q-dialog v-model="showSignUp">
    <register-comp role="legislation" @registrationComplete="handleRegistrationComplete" @goToSignIn="goToSignIn"
      :linkToSignIn="false"></register-comp>
  </q-dialog>

</template>


<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { renderMarkdown } from "@/helpers/helpers";
import StateBill from "./StateBill.vue";
import axios from "axios";
import html2pdf from "html2pdf.js";

export default defineComponent({
  name: "StateBillInfo",
  components: {
    StateBill
  },
  data() {
    return {
      bill: null,
      action_step: 1,
      bill_id: this.$route.params.id,
      tab: "content",
      activeDoc: null,
      store: null,
      showSignIn: false,
      showSignUp: false,
      signedIn: false,
      textId: 6,
    }
  },
  async mounted() {
    this.store = useStore();
    this.signedIn = await this.checkLoggedIn();
    this.getBillInfo();
  },
  computed: {
    // textId() {
    //   return this.bill.textVersions[0].id;
    // },
    contentStyle() {
      // If the sidebar is open, add 40vh margin to the right
      if (this.isSidebarOpen) {
        return {
          paddingRight: "420px",
        };
      } else {
        return {
          paddingRight: "0px",
        };
      }
    },
  },
  methods: {
    handleSidebarToggle(isOpen) {
      this.isSidebarOpen = isOpen; // Keep track of the sidebar state
    },
    handleSectionId(sectionId) {
      this.section_id = sectionId;
    },
    handleSubmit(formAnswers) {
      // Send the answers to your backend or perform other actions
      // Then load the next section's questions if any
      this.allAnswers[this.currentSectionIndex] = formAnswers;
      if (this.currentSectionIndex < this.sections.length) {
        this.currentSectionIndex++;
      }
      if (this.$refs.dynamicFormRef) {
        this.$refs.dynamicFormRef.formSubmitted = true;
      }
    },
    backToSearch() {
      this.$router.push({ name: "StateBillSearch" });
    },
    getBillInfo() {
      // Get grant info from the server
      axios
        .get("/api/state/get-statebill-info", {
          params: {
            bill_id: this.bill_id,
          },
        })
        .then((response) => {
          this.bill = response.data;
          // console.log("TEXT ID:", this.bill.textVersions[0].id);
          // this.textId = this.bill.textVersions[0].id;
          // if (Object.keys(this.grant.mk_files).length > 0) {
          //   this.activeDoc = Object.keys(this.grant.mk_files)[0];
          // }
        })
        .catch((error) => {
          console.error("Error fetching grant info:", error);
        });
    },
    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Helper function to get the ordinal suffix for day numbers
      function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      }

      const date = new Date(dateString);
      const day = date.getDate();
      const monthName = months[date.getMonth()];
      const year = date.getFullYear();

      return `${monthName} ${day}${getOrdinalSuffix(day)}, ${year}`;
    },
    checkLoggedIn() {
      return this.store.dispatch("checkToken")
        .then(signedIn => {
          return signedIn;  // Return the signed in status for further processing
        })
        .catch(error => {
          console.error("Error checking token:", error);
          return false;  // Handle the error case by returning false
        });
    },
    renderMarkdownWrapper(text) {
      return renderMarkdown(text);
    },
    handleRegistrationComplete() {
      this.signedIn = checkLoggedIn();
      // console.log("Registration complete");
      this.showSignUp = false;
    },
    signinComplete() {
      this.signedIn = checkLoggedIn();
      // console.log("Signin complete:", signedIn.value);
      this.showSignIn = false;
    },
    goToRegistration() {
      this.showSignIn = false;
      this.showSignUp = true;
    },
    goToSignIn() {
      this.showSignUp = false;
      this.showSignIn = true;
    },
    downloadBillText() {
      axios.get("/api/state/get-statebill", { params: { bill_id: this.bill_id } })
        .then(response => {
          var billDownload = response.data;
          console.log(billDownload)
          var isPDF = billDownload.content_type === ".pdf";
          this.$nextTick(() => {
            if (!isPDF && billDownload.file_content) {
              // if this is html content
              html2pdf(billDownload.file_content, {
                margin: 1,
                filename: this.bill.title + ".pdf",
              });
            }
            else if (isPDF) {
              var link = document.createElement('a');
              link.href = billDownload.file_url;
              link.download = this.bill.title + '.pdf';
              link.target = "_blank";
              link.dispatchEvent(new MouseEvent('click'));
            }
          });
        })
        .catch(error => {
          console.error("Error fetching bill info:", error);
        });
    },
  },
});
</script>

<style scoped>
.custom-stepper,
.custom-stepper .q-stepper__tab,
.custom-stepper .q-stepper__content {
  border: none !important;
}

.container {
  height: 100vh;
  width: 100%;
}

.content {
  height: 100%;
  width: 90%;
  /* padding-left: 40px; */
  box-sizing: border-box;
  transition: padding-right 0.3s ease;
  align-items: left;
  /* Smooth transition for the margin change */
}

.section-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-center-image {
  display: block;
  margin: 40px auto 0px;
  /* Centers the image */
  width: 30%;
  max-width: 300px;
  min-width: 200px;
}

.back-text:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}

.active-doc {
  background-color: var(--accent);
  color: white;
}

.markdown-body {
  /* Add your markdown styles here */
  line-height: 1.6;
  color: var(--text);
  border-radius: 5px;
  font-family: var(--title-2);
}

.hyperlink {
  text-decoration: underline;
}

.hyperlink:hover {
  font-weight: 500;
  color: var(--accent);
  cursor: pointer;
}
</style>
