<template>
  <div>
    <q-card class="q-pa-md q-ma-md">
      <q-card-section horizontal class="justify-between">
        <q-card-section>
          <div class="q-gutter-y-md">
            <div class="row">
              <q-img
                :src="`https://aidy-public-downloads.s3.amazonaws.com/leg/state_flags/${bill.state_code}.svg`"
                spinner-color="white"
                style="height: 100px; max-width: 100px; border-radius: 50%;"
                class='col q-pa-sm'
              ></q-img>
              <div class="col block q-pl-lg">
                <h5 @click="navigateToBill(bill.id)" class="clickable-title cursor-pointer ellipsis-2-lines">{{
                  bill.title }}</h5>
                <div class="row justify-start items-center q-gutter-sm">
                  <q-chip class="text-weight-bold" color="accent" text-color="white">{{ bill.state_abbr }}</q-chip>
                  <h6>{{ bill.bill_number }}</h6>
                  <q-chip v-for="(committee, index) in bill.committees" :key="index" class="text-weight-bold q-mr-sm"
                    color="accent" text-color="white">
                    {{ committee }}
                  </q-chip>
                </div>
              </div>
            </div>
            <p class="text-weight-regular" style="text-align: justify; letter-spacing: 0.02em;">{{ bill.summary }}</p>
          </div>
          <div class="q-gutter-sm q-mt-md">
            <div v-if="bill.actions">
              <b>Latest Action: </b>
              <q-chip icon="event" class="text-weight-bold" color="primary" text-color="white" size="sm">
                {{ formatDate(bill.actions.date) }}
              </q-chip>
              {{ bill.actions.description }}
            </div>
          </div>
        </q-card-section>
        <!-- commenting out bookmark for now -->
        <q-card-actions vertical class="justify-around q-px-md">
          <q-btn
            :loading="bookmarkLoading"
            flat
            round
            transparent
            :disabled="!isLoggedIn"
            :color="isBookmarked ? 'primary' : 'secondary'"
            :icon="isBookmarked ? 'notifications_active' : 'notifications'"
            size="lg"
            @click="toggleBookmark(bill.id)"
          >
            <q-tooltip
              v-if="!isLoggedIn"
              class="bg-primary text-body2 flex flex-center text-center non-selectable"
              style="width: 150px"
              :offset="[10, 10]"
            >
              Must be signed in to track bill
            </q-tooltip>
            <q-tooltip
              v-if="isLoggedIn && !isBookmarked"
              class="bg-primary text-body2 flex flex-center text-center non-selectable"
              style="width: 150px"
              :offset="[10, 10]"
            >
              Track bill
            </q-tooltip>
            <q-tooltip
              v-if="isLoggedIn && isBookmarked"
              class="bg-primary text-body2 flex flex-center text-center non-selectable"
              style="width: 150px"
              :offset="[10, 10]"
            >
              Untrack bill
            </q-tooltip>
          </q-btn>
        </q-card-actions>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { computed, ref, toRefs, inject, watchEffect } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";

export default {
  name: "BillCard",
  props: {
    bill: {
      type: Object,
      required: true,
    },
    bookmarked: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { bill } = toRefs(props);
    const searchQuery = inject("searchQuery");
    const router = useRouter();
    const isBookmarked = ref(bill.value.is_bookmarked || false);

    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isLoggedIn);

    const bookmarkLoading = ref(false);

    function navigateToBill(id) {
      router.push(`/statebill-info/${id}`);
    }

    const toggleBookmark = async () => {
      bookmarkLoading.value = true;
      emit("bookmark-toggle", bill.value.id);

      try {
        const response = await axios.get("/api/state/bookmark-state-bill", {
          params: { bill_id: bill.value.id },
        });
        bill.value.is_bookmarked = !isBookmarked.value;
        isBookmarked.value = !isBookmarked.value;
        bookmarkLoading.value = false;
      } catch (err) {
        bookmarkLoading.value = false;
        console.error("An unknown error occurred");
      }
    };

    const formatDate = (dateString) => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return "th";
        switch (day % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      }

      const date = new Date(dateString);
      const day = date.getDate();
      const monthName = months[date.getMonth()];
      const year = date.getFullYear();

      return `${monthName} ${day}${getOrdinalSuffix(day)}, ${year}`;
    };

    return {
      navigateToBill,
      bookmarkLoading,
      searchQuery,
      isLoggedIn,
      toggleBookmark,
      formatDate,
      isBookmarked,
    };
  },
};
</script>

<style scoped>
.clickable-title {
  transition: color 0.1s, font-weight 0.1s;
}

.clickable-title:hover {
  color: rgba(0, 150, 250);
}

.state-abbr {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
</style>
